import { getIn, setIn } from '@soundtrackyourbrand/object-utils.js';
import { isPromise } from './type-checking';
/**
 * Utility to check if a given validation result is an error.
 *
 * @param result - Validation result to check
 */
export function isValidationError(result) {
    return typeof result !== 'boolean' && result != null;
}
/**
 * Validate a model (any type of object) according to the given validations object.
 *
 * @param validations - Validation definition
 * @param model - Model to validate
 * @param options - Validator options
 * @return `null` if model is valid, otherwise an object with errors for each field.
 *         If any of the validation functions return a promise the return value
 *         of this function also becomes a promise.
 */
export function validateModel(validations, model = {}, options = {}) {
    const errors = {};
    const promises = [];
    let valid = true;
    Object.keys(validations).forEach((field) => {
        let rules = validations[field];
        if (!rules) {
            return;
        }
        if (!Array.isArray(rules)) {
            rules = [rules];
        }
        if (rules.length) {
            const error = validateField(rules, model, field, options);
            if (error) {
                if (isPromise(error)) {
                    promises.push(error.then((error) => {
                        if (error) {
                            setIn(errors, field, error);
                            valid = false;
                        }
                    }));
                }
                else {
                    setIn(errors, field, error);
                    valid = false;
                }
            }
        }
    });
    return promises.length
        ? Promise.all(promises).then(() => (valid ? null : errors))
        : valid
            ? null
            : errors;
}
/**
 * Validate a single field given a list of rules and the model.
 *
 * @param fieldValidators - List of rules to check against the field value
 * @param model - Model to validate
 * @param field - Path of model to validate
 * @param options - Validator options
 * @return `null` if validation passed, otherwise a string or renderable React node
 */
export function validateField(fieldValidators, model, field, options = {}) {
    if (!fieldValidators) {
        return null;
    }
    const value = getIn(model, field);
    // Return already active validation if present
    const pending = options.pendingAsyncValidations;
    if (pending?.[field] && pending[field].value === value) {
        return pending[field].promise;
    }
    const validators = Array.isArray(fieldValidators)
        ? fieldValidators
        : [fieldValidators];
    const promises = [];
    for (let i = 0; i < validators.length; i++) {
        const rule = validators[i];
        const result = rule(value, model, field, options);
        if (isPromise(result)) {
            promises.push(result);
        }
        else if (rule.message ? !result : isValidationError(result)) {
            // Return synchronously, ignoring any pending async validators
            promises.length = 0;
            // Support legacy style validation functions (which are always
            // synchronous), with message exposed separately
            return rule.message
                ? typeof rule.message === 'function'
                    ? rule.message(value, model, field)
                    : rule.message
                : result;
        }
    }
    if (promises.length) {
        const promise = Promise.all(promises).then((results) => {
            // Return first validation error
            return results.find(isValidationError) || null;
        });
        if (pending) {
            pending[field] = { promise, value };
            promise.finally(() => {
                if (pending[field]?.promise === promise) {
                    delete pending[field];
                }
            });
        }
        return promise;
    }
    return null;
}
