export type JwtPayload = {
  /** JWT ID */
  jti?: string
  /** Issued at UNIX timestamp (seconds) */
  iat?: number
  /** Expiry UNIX timestamp (seconds) */
  exp?: number
  /** Issuer */
  iss?: string
  /** Viewer type (commonly `user` or `interactive_device`) */
  typ?: 'user' | 'interactive_device'
  /** Viewer ID (commonly ID of user or device) */
  sub?: string
  /** Email used for log in (SSO only) */
  email?: string
  /** SSO Provider (SSO only) */
  provider?: string
  /** SSO provider id (SSO only) */
  sso_id?: string
}

/** Extracts and returns the payload of a given JWT token string */
export function jwtPayload(jwt: string | undefined): JwtPayload {
  if (!jwt || typeof jwt !== 'string') {
    return {}
  }
  const payload = jwt.replace(/#.*$/, '').split('.')[1]
  if (!payload) {
    return {}
  }
  try {
    return JSON.parse(atob(payload))
  } catch (error) {
    // Only swallow parsing errors
    if (!`${error}`.includes('invalid character')) {
      throw error
    }
  }
  return {}
}
