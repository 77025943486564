import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
import shallowEqual from 'shallowequal';
import SortObject from './lib/sort-object';
const FlexTableRow = React.memo(({ index, data, rowMapper, columns }) => {
    if (rowMapper) {
        data = rowMapper(data, index);
    }
    if (data == null) {
        return null;
    }
    const rowProps = Object.assign({}, data['props']);
    rowProps.className = classNames('table__row', rowProps.className);
    rowProps.children = columns.map((column, columnIndex) => {
        const { prop, render, header, sortable, className, ...columnProps } = column;
        const value = typeof data.get === 'function' ? data.get(prop) : data[prop];
        return React.createElement('div', Object.assign({
            key: `cell-${index}-${columnIndex}`,
            className: classNames('table__cell', className),
            role: 'cell',
            children: typeof render !== 'function'
                ? value
                : render({
                    value,
                    data,
                    column,
                    columnIndex,
                    rowIndex: index,
                }),
        }, columnProps));
    });
    return React.createElement(data['tag'] || 'div', {
        role: 'row',
        ...rowProps,
    });
});
FlexTableRow.displayName = 'FlexTableRow';
export class FlexTable extends React.PureComponent {
    static displayName = 'FlexTable';
    static TableRow = FlexTableRow;
    static TableBody = FlexTableBody;
    static TableColumnHeader = FlexTableColumnHeader;
    static sort = SortObject.fromString;
    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.shape({
            prop: PropTypes.string.isRequired,
            sortable: PropTypes.bool,
            header: PropTypes.node,
            className: PropTypes.string,
            render: PropTypes.func,
        })),
        headerComponent: PropTypes.elementType.isRequired,
        rowComponent: PropTypes.elementType.isRequired,
        bodyComponent: PropTypes.elementType.isRequired,
        footer: PropTypes.node,
        data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
        renderDeps: PropTypes.array,
        rowMapper: PropTypes.func,
        onSort: PropTypes.func,
        sort: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.instanceOf(SortObject),
        ]),
        sortMapper: PropTypes.func,
        keyMapper: PropTypes.func,
    };
    static defaultProps = {
        headerComponent: FlexTableColumnHeader,
        rowComponent: FlexTableRow,
        bodyComponent: FlexTableBody,
    };
    state = {};
    /** Memoized `columns` prop passed to <TableRow>, see updateColumns(). */
    memoizedColumns = this.updateColumns(this.props.columns, true);
    UNSAFE_componentWillMount() {
        this.updateState(this.props, this.props.sort);
    }
    UNSAFE_componentWillReceiveProps(props) {
        this.updateColumns(props.columns, 
        // Force re-render if `renderDeps` is omitted or has changed
        props.renderDeps == null ||
            !shallowEqual(props.renderDeps, this.props.renderDeps));
        this.updateState(props);
    }
    /**
     * Memoizes the `columns` prop excluding props irrelevant to <TableRow>.
     * Persisted across renders as `this.memoizedColumns`.
     */
    updateColumns(columns, forceUpdate = false) {
        let hasChanged = forceUpdate || columns.length !== this.memoizedColumns.length;
        const changed = columns.map(({ header, sortable, ...column }, index) => {
            column['key'] = column.prop || index;
            hasChanged =
                hasChanged || !shallowEqual(column, this.memoizedColumns[index]);
            return column;
        });
        if (hasChanged) {
            this.memoizedColumns = changed;
        }
        return this.memoizedColumns;
    }
    /**
     * Sorts and memoizes `data` whenever relevant props changes.
     */
    updateState(props, sort) {
        // Nothing changed and sorting already applied
        if (this.state.sort &&
            this.state.sort === sort &&
            props.sort === this.props.sort &&
            props.data === this.props.data &&
            props.sortMapper === this.props.sortMapper &&
            props.onSort === this.props.onSort) {
            return;
        }
        let { data } = props;
        // Prop changes should update active sorting
        if (props.sort !== this.props.sort) {
            sort = props.sort;
        }
        if (typeof sort === 'string') {
            sort = SortObject.fromString(sort);
        }
        // Uncontrolled mode: apply current sorting
        if (!props.onSort && props.sort) {
            sort = sort || this.state.sort;
            // Avoid mutating source array
            if (Array.isArray(data)) {
                data = data.slice();
            }
            if (!sort.isEmpty()) {
                data = data.sort(sort.comparator(props.sortMapper));
            }
        }
        // Always keep internal state consistent with props
        this.setState({ sort, data });
    }
    handleSort(prop, event) {
        let sort = this.state.sort || new SortObject();
        if (event && (event.ctrlKey || event.altKey || event.metaKey)) {
            sort = sort.remove(prop);
        }
        else {
            sort = sort.column(prop, !!(event && event.shiftKey));
        }
        if (this.props.onSort) {
            return this.props.onSort(sort, this.props.sortMapper);
        }
        return this.updateState(this.props, sort);
    }
    render() {
        const { rowMapper, rowComponent, headerComponent, bodyComponent, columns, footer, onSort, sortMapper, keyMapper, renderDeps, data: dataProp, sort: sortProp, ...tableProps } = this.props;
        const { data, sort } = this.state;
        let renderColumnHeaders = false;
        const columnHeaders = columns.map((column, index) => {
            // Destruct all props that shouldn't be injected into <TableRow> here
            const { header, sortable, ...props } = column;
            // We shouldn't render headers if none of them have a title
            renderColumnHeaders = renderColumnHeaders || header != null;
            props['key'] = props.prop || index;
            return React.createElement(headerComponent, {
                key: props.prop || index,
                prop: props.prop,
                className: props.className,
                header,
                // Determine if this column is being actively sorted
                sort: sortable && sort?.getColumnDirection(props.prop),
                onSort: sortable ? this.handleSort.bind(this, props.prop) : undefined,
            });
        });
        const body = React.createElement(bodyComponent, {
            bodyProps: {
                role: 'rowgroup',
                className: 'table__body',
            },
            /*
              `columns` is the only prop "normally guaranteed" to change
              between renders. The `renderDeps` prop can be used to memoize
              this, and thereby control when to re-render <TrackRow>s.
            */
            columns: this.memoizedColumns,
            data: data,
            rowComponent,
            rowMapper,
            keyMapper,
        });
        return (_jsxs("div", { ...tableProps, role: "table", className: classNames('table', tableProps['className']), children: [renderColumnHeaders && (_jsx("div", { role: "rowgroup", className: "table__head", children: _jsx("div", { role: "row", className: "table__row", children: columnHeaders }) })), body, footer && (_jsx("div", { role: "rowgroup", className: "table__footer", children: footer }))] }));
    }
}
FlexTableBody.displayName = 'FlexTableBody';
function FlexTableBody({ data, columns, rowComponent, rowMapper, keyMapper, bodyProps, }) {
    return (_jsx("div", { ...bodyProps, children: data.map((data, index) => {
            return data
                ? React.createElement(rowComponent, {
                    key: keyMapper ? keyMapper(data, index) : index,
                    index,
                    data,
                    rowMapper,
                    columns,
                })
                : null;
        }) }));
}
FlexTableColumnHeader.propTypes = {
    header: PropTypes.node,
    sort: PropTypes.oneOf([undefined, false, 'asc', 'desc']),
    prop(props, propName, componentName) {
        if (typeof props.onSort === 'function' && typeof props.prop !== 'string') {
            return new Error(`FlexTableColumnHeader[prop] must be provided for sortable columns`);
        }
        return null;
    },
    onSort: PropTypes.func,
};
FlexTableColumnHeader.displayName = 'FlexTableColumnHeader';
function FlexTableColumnHeader({ header, sort, onSort, ...forwardedProps }) {
    const onInteraction = React.useCallback((event) => {
        if (event.type === 'keypress' && event.key !== 'Enter') {
            return;
        }
        onSort?.(event);
    }, [onSort]);
    return (_jsxs("div", { ...forwardedProps, role: "columnheader", "aria-sort": sort
            ? (sort + 'ending')
            : undefined, tabIndex: 0, onClick: onInteraction, onKeyPress: onInteraction, className: classNames('table__header', {
            'table__header--sortable': !!onSort,
            [`table__header--${sort || 'unsorted'}`]: !!onSort,
        }, forwardedProps['className']), children: [!!header && (onSort ? _jsx("span", { role: "button", children: header }) : header), !!header && ' ', !!onSort && _jsx("span", { className: "table__sort-indicator" })] }));
}
