export function isPromise(obj) {
    return (!!obj &&
        (typeof obj === 'object' || typeof obj === 'function') &&
        typeof obj['then'] === 'function');
}
export const isFunction = (obj) => typeof obj === 'function';
export const isObject = (obj) => obj !== null && typeof obj === 'object';
export const isEmptyObject = (obj, ignoreUndefined = false) => {
    for (const prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop) ||
            (ignoreUndefined && obj[prop] === undefined)) {
            return false;
        }
    }
    return true;
};
export const isInteger = (obj) => String(Math.floor(Number(obj))) === obj;
export const isString = (obj) => Object.prototype.toString.call(obj) === '[object String]';
// eslint-disable-next-line no-self-compare
export const isNaN = (obj) => obj !== obj;
