import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import portalContainer, { CONTAINER_CLASS } from './lib/portal-container';
export const PortalContainerContext = React.createContext({
    get current() {
        return portalContainer();
    },
});
PortalContainer.displayName = 'PortalContainer';
export function PortalContainer({ children }) {
    const container = React.useRef(null);
    return (_jsxs(_Fragment, { children: [_jsx(PortalContainerContext.Provider, { value: container, children: children }), _jsx("div", { ref: container, className: CONTAINER_CLASS })] }));
}
PortalContainerPortal.displayName = 'PortalContainer.Portal';
export function PortalContainerPortal({ children }) {
    const container = React.useContext(PortalContainerContext);
    return ReactDOM.createPortal(children, container.current);
}
