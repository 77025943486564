import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { FormProvider, useFormContext } from './useForm';
import { FormSpy } from './useFormSpy';
HookForm.displayName = 'HookForm';
HookForm.propTypes = {
    form: PropTypes.object.isRequired,
    omitContext: PropTypes.bool,
    enabledDuringSubmit: PropTypes.bool,
};
/**
 * Used in combination with `useForm()` to:
 * - Expose the provided `form` (`useForm()` return value) to children using
 *   React context
 * - Wrap the children with a `<form>` element
 * - Wrap the children with a `<fieldset>` element that gets `disabled` during
 *   form submissions (unless `enabledDuringSubmit={false}`)
 */
export function HookForm({ form, omitContext = false, enabledDuringSubmit = false, formRef, children, ...props }) {
    // Access context from prop or context hierarchy
    const parentContext = useFormContext();
    if (!form && parentContext) {
        form = parentContext;
    }
    if (!form) {
        throw new Error(`HookForm: form context missing`);
    }
    let body = (_jsx("form", { ref: formRef, onSubmit: form.handleSubmit, action: "#", noValidate: true, ...props, children: _jsx(FormSpy, { children: ({ disabled }) => (_jsx("fieldset", { disabled: !!disabled && (!enabledDuringSubmit || disabled === 'submitting'), children: children })) }) }));
    if (!omitContext && form !== parentContext) {
        body = _jsx(FormProvider, { value: form, children: body });
    }
    return body;
}
