/**
 * Seeded random number generator.
 * Given a seed string it returns a function which generates a random number.
 *
 * Adapted from GRC's UHE PRNG (https://www.grc.com/otg/uheprng.htm).
 *
 * @param {String} seed - Seed to initialize with.
 * @return {Function} Number generating function.
 */
export default function seededRandom(seed) {
    const o = 48; // set the 'order' number of ENTROPY-holding 32-bit values
    const s = new Array(o); // declare our intermediate variables array
    let c = 1; // init the 'carry' used by the multiply-with-carry (MWC) algorithm
    let p = o; // init the 'phase' (max-1) of the intermediate variable pointer
    let n = 0xefc8249d; // current mash value
    seed = seed || ' ';
    function mash(data) {
        for (let i = 0; i < data.length; i++) {
            n += data.charCodeAt(i);
            let h = 0.02519603282416938 * n;
            n = h >>> 0;
            h -= n;
            h *= n;
            n = h >>> 0;
            h -= n;
            n += h * 0x100000000; // 2^32
        }
        return (n >>> 0) * 2.3283064365386963e-10; // 2^-32
    }
    // Initialize mash value
    mash(seed);
    // Generate intermediate variables array from the seed
    for (let i = 0; i < seed.length; i++) {
        for (let j = 0; j < o; j++) {
            s[j] = mash(seed.charCodeAt(i).toString());
            if (s[j] < 0) {
                s[j] += 1;
            }
        }
    }
    function rawprng() {
        if (++p >= o) {
            p = 0;
        }
        const t = 1768863 * s[p] + c * 2.3283064365386963e-10; // 2^-32
        return (s[p] = t - (c = t | 0));
    }
    /**
     * Number generating function returned.
     * Returns a float between [0, 1] when no arguments are provided.
     * Returns an integer between [0, upto] when one argument is provided.
     *
     * @param {Number} [upto]
     * @return {Number} A random number.
     */
    return function next(upto) {
        const num = rawprng() + ((rawprng() * 0x200000) | 0) * 1.1102230246251565e-16; // 2^-53
        return upto == null ? num : Math.floor((upto + 1) * num);
    };
}
