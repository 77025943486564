/**
 * Attaches field level validation errors to an existing `Error`-like object on
 * the `validationErrors` property (if the provided argument isn't empty).
 *
 * Errors thrown with this property defined within a `onSubmit()` handler (or
 * promise chain) triggered by `useForm()` will be identified as validation
 * errors.
 *
 * @return The object passed as the first argument (`errorObject`)
 */
export function attachValidationErrors(
/** The error/object to attach validation errors to */
errorObject, 
/** The validation errors to attach */
validationErrors = null, 
/** Pass `false` to not override existing field error(s) */
overwrite = true) {
    const paths = validationErrors && typeof validationErrors === 'object'
        ? Object.keys(validationErrors)
        : [];
    if (!errorObject) {
        const msg = paths.length
            ? 'Validation errors: ' + paths.join(', ')
            : 'Validation errors';
        errorObject = new Error(msg);
    }
    if (validationErrors && paths.length) {
        const errors = (errorObject.validationErrors ||= {});
        paths.forEach((path) => {
            if (overwrite || errors[path] === undefined) {
                errors[path] = validationErrors[path];
            }
        });
    }
    return errorObject;
}
