import { jsx as _jsx } from "react/jsx-runtime";
import extend from 'extend';
// Adapted from https://github.com/andreypopp/react-textarea-autosize/
import * as React from 'react';
import { InputBase } from './InputBase';
import { fieldComponent } from './fieldComponent';
import { useField } from './useField';
export const Textarea = fieldComponent(({ ...props }, ref) => {
    /** Autosize rows by default - specify any positive integer to disable */
    const autogrow = !props.rows;
    props.floatingLabel ??= !props.placeholder;
    const [baseProps, fieldProps] = InputBase.partitionProps(props);
    if (autogrow) {
        fieldProps.style = extend({}, props.style, { overflow: 'hidden' });
    }
    const field = useField(fieldProps, {
        ref,
        fieldType: 'textarea',
    });
    if (autogrow) {
        field.props.rows = 1;
        field.props.className = 'textarea--autogrow';
    }
    React.useLayoutEffect(() => {
        const textarea = field.ref.current;
        if (!textarea || !autogrow) {
            return;
        }
        const resize = () => {
            const style = window.getComputedStyle(textarea);
            let diff = 0;
            // If the textarea is set to border-box, it's not necessary to
            // subtract the padding.
            if (style.getPropertyValue('box-sizing') !== 'border-box') {
                diff =
                    parseInt(style.getPropertyValue('padding-bottom') || '0', 10) +
                        parseInt(style.getPropertyValue('padding-top') || '0', 10);
            }
            textarea.style.height = 'auto';
            // Trigger browser layout to resize the textarea
            const { scrollHeight } = textarea;
            textarea.style.height = `${scrollHeight - diff}px`;
        };
        resize();
        textarea.addEventListener('input', resize);
        textarea.addEventListener('change', resize);
        return () => {
            textarea.removeEventListener('input', resize);
            textarea.removeEventListener('change', resize);
        };
    }, [field.ref, autogrow]);
    return field.wrap(_jsx(InputBase, { fieldRef: field.ref, ...baseProps, value: field.props.value, children: _jsx("textarea", { ...field.props }) }));
}, {
    displayName: 'Textarea',
});
