import type { Mixpanel } from 'mixpanel-browser'

export type LoadableScript = {
  __load: () => Promise<any>
}
export type DataLayer = Record<string, unknown>[] & Partial<LoadableScript>
export type MixpanelObject = Mixpanel &
  Partial<LoadableScript> &
  Record<string, unknown>[] & {
    _i: any[]
    __SV: any
  }
export type IntercomObject = {
  (command: string, ...args: unknown[]): void
  q: any[]
  c: (args: any) => void
} & Partial<LoadableScript>
/** See https://developers.intercom.com/installing-intercom/docs/configuration */
export type IntercomSettings = {
  app_id?: string
  hide_default_launcher?: boolean
  alignment?: string
  horizontal_padding?: number
  vertical_padding?: number
}
export type HotjarObject = {
  (method: string, ...args: unknown[]): void
  q: any[]
} & Partial<LoadableScript>
export type HotjarSettings = {
  hjid: string
  hjsv: number
}
export type AppsflyerObject = {
  (what: 'pba', action: string, ...args: unknown[]): void
  id?: any
  q?: any[]
  plugins?: any
} & Partial<LoadableScript>

declare global {
  interface Window {
    dataLayer?: DataLayer
    mixpanel?: MixpanelObject
    Intercom?: IntercomObject
    intercomSettings?: IntercomSettings
    AF?: AppsflyerObject
    hj?: HotjarObject
    _hjSettings?: HotjarSettings
  }
}

/**
 * Sets up the Google Tag Manager dataLayer API without automatically loading GTM.
 */
export function setupGtm(
  gtmContainerId: string,
  existingDataLayer = window.dataLayer,
): DataLayer {
  const dataLayer = (existingDataLayer || []) as DataLayer
  if (!existingDataLayer) {
    dataLayer.__load ||= scriptLoader(
      'https://www.googletagmanager.com/gtm.js?id=' + gtmContainerId,
    )
    dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    })
  }

  return (window.dataLayer = dataLayer)
}

/**
 * Sets up the mixpanel API without automatically loading the Mixpanel SDK.
 * Adapted from mixpanel-browser/mixpanel-jslib-snippet.js
 */
export function setupMixpanel(
  mixpanelToken: string,
  existingMixpanel: Partial<MixpanelObject> | undefined = window.mixpanel,
): MixpanelObject {
  if (existingMixpanel) {
    existingMixpanel.__load ||= () => Promise.resolve()
    return (window.mixpanel = existingMixpanel as MixpanelObject)
  }

  const mixpanel: MixpanelObject = [] as any
  const win = window
  win.mixpanel = mixpanel

  mixpanel.__load ||= scriptLoader(
    'https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js',
  )

  // Manually defined since the lazy loaded SDK doesn't define it
  mixpanel.get_distinct_id = () => ''

  /* eslint-disable */
  let script,
    first_script,
    gen_fn,
    functions,
    i,
    lib_name = 'mixpanel'
  mixpanel._i = []

  // @ts-ignore
  mixpanel.init = function (token: any, config: any, name: any) {
    // support multiple mixpanel instances
    let target = mixpanel
    if (typeof name !== 'undefined') {
      // @ts-ignore
      target = mixpanel[name] = []
    } else {
      name = lib_name
    }

    // Pass in current people object if it exists
    target.people = target['people'] || []
    // @ts-ignore
    target.toString = function (no_stub) {
      let str = lib_name
      if (name !== lib_name) {
        str += '.' + name
      }
      if (!no_stub) {
        str += ' (stub)'
      }
      return str
    }
    target.people.toString = function () {
      // @ts-ignore 1 instead of true for minifying
      return target.toString(1) + '.people (stub)'
    }

    function _set_and_defer(target: any, fn: any) {
      let split = fn.split('.')
      if (split.length == 2) {
        target = target[split[0]]
        fn = split[1]
      }
      target[fn] = function () {
        target.push([fn].concat(Array.prototype.slice.call(arguments, 0)))
      }
    }

    // create shallow clone of the public mixpanel interface
    // Note: only supports 1 additional level atm, e.g. mixpanel.people.set, not mixpanel.people.set.do_something_else.
    functions =
      'disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders start_session_recording stop_session_recording people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove'.split(
        ' ',
      )
    for (i = 0; i < functions.length; i++) {
      _set_and_defer(target, functions[i])
    }

    // special case for get_group(): chain method calls like mixpanel.get_group('foo', 'bar').unset('baz')
    let group_functions = 'set set_once union unset remove delete'.split(' ')
    // @ts-ignore
    target.get_group = function () {
      let mock_group: Record<string, any> = {}

      let call1_args = arguments
      let call1 = ['get_group'].concat(
        Array.prototype.slice.call(call1_args, 0),
      )

      function _set_and_defer_chained(fn_name: string) {
        mock_group[fn_name] = function () {
          const call2_args = arguments
          const call2 = [fn_name].concat(
            Array.prototype.slice.call(call2_args, 0),
          )
          target.push([call1, call2])
        }
      }
      for (let i = 0; i < group_functions.length; i++) {
        _set_and_defer_chained(group_functions[i])
      }
      return mock_group
    }

    // register mixpanel instance
    mixpanel._i.push([token, config, name])
  }

  // Snippet version, used to fail on new features w/ old snippet
  mixpanel.__SV = 1.2
  /* eslint-enable */

  // @ts-ignore
  mixpanel.init(mixpanelToken, {
    // Share super properties across subdomains
    persistence: 'cookie',
    api_host: 'https://api-eu.mixpanel.com',
    cross_subdomain_cookie: true,
    // Deterministic name (`mp_${persistence_name}`)
    persistence_name: 'super',
    secure_cookie: window.location.protocol === 'https:',
    ignore_dnt: true,
    save_referrer: true, // Built-in referral tracking
    store_google: true, // Built-in UTM params tracking
    record_mask_text_selector: 'mp-mask',
  })

  return mixpanel
}

/**
 * Sets up the Hotjar API.
 * When `hotjarId` is true we only return the stub and assume Hotjar will be loaded externally.
 */
export function setupHotjar(
  hotjarId: string | boolean,
  existingHotjar = window.hj,
): HotjarObject {
  let hotjar = existingHotjar
  if (typeof hotjar !== 'function') {
    hotjar = Object.assign(
      function () {
        hotjar!.q.push(arguments)
      },
      {
        q: [],
      },
    )
  }
  window.hj = hotjar
  if (!existingHotjar && typeof hotjarId === 'string') {
    window._hjSettings = Object.assign(window._hjSettings || {}, {
      hjid: hotjarId,
      hjsv: 7,
    })
    hotjar.__load =
      hotjar.__load ||
      scriptLoader(
        `https://static.hotjar.com/c/hotjar-${hotjarId}.js?sv=${window._hjSettings.hjsv}`,
      )
  }
  return hotjar
}

/**
 * Sets up the Intercom API without automatically loading the Mixpanel SDK.
 */
export function setupIntercom(
  intercomAppId: string,
  existingIntercom = window.Intercom,
  settings?: Record<string, any>,
): IntercomObject {
  window.intercomSettings = Object.assign(
    window.intercomSettings || {},
    settings,
    {
      app_id: intercomAppId,
    },
  )
  let intercom = existingIntercom
  if (typeof intercom === 'function') {
    // @ts-ignore This expression is not callable.
    intercom('reattach_activator')
    // @ts-ignore This expression is not callable.
    intercom('update', window.intercomSettings)
  } else {
    intercom = Object.assign(
      function () {
        intercom!.c(arguments)
      },
      {
        q: [],
        c(args: any) {
          intercom!.q.push(args)
        },
      },
    )
  }
  if (!existingIntercom) {
    intercom.__load =
      intercom.__load ||
      scriptLoader('https://widget.intercom.io/widget/' + intercomAppId)
  }
  return (window.Intercom = intercom)
}

/**
 * Sets up the Appsflyer SDK API without automatically loading the full SDK.
 */
export function setupAppsflyer(
  appsflyerToken?: string,
  existingAppsflyer = window.AF,
): AppsflyerObject {
  let af = existingAppsflyer
  if (!af) {
    ;(window as any).AppsFlyerSdkObject = 'AF'
    af = function () {
      ;(window.AF!.q = window.AF!.q || []).push(
        [Date.now()].concat(Array.prototype.slice.call(arguments)),
      )
    } as AppsflyerObject
    af.id = {
      pba: { webAppId: appsflyerToken },
    }
    af.plugins = {}
    af.__load = scriptLoader('https://websdk.appsflyer.com?st=pba')
  }

  return (window.AF = af)
}

/**
 * Returns a function that will inject a <script async src="..."> tag into the
 * document once. The generated function returns a promise which resolves once
 * the injected script has been loaded.
 *
 * @param url - Script URL
 * @returns Function that triggers the injection if not yet done
 */
function scriptLoader(url: string) {
  let promise: Promise<Event>
  return () => {
    return (promise ||= new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.async = true
      // @ts-ignore `fetchpriority` is not known by typescript
      script.fetchpriority = 'low'
      script.src = url
      script.onload = resolve
      const x = document.getElementsByTagName('script')[0]
      x!.parentNode!.insertBefore(script, x)
    }))
  }
}
