/** Level of consent given by user */
export enum ConsentValue {
  /** Only required cookies */
  Required = 1,
  /** Allow analytics, but nothing used for marketing */
  Analytics,
  /** The most permissive option, includes analytics */
  Marketing,
}

/** Method user consented through */
export enum ConsentMethod {
  /** Existing cookie */
  Cookie = 'cookie',
  /** Outside EU and thus automatically consented */
  Implicit = 'implicit',
  /** Simply accepted */
  Accept = 'accept',
  /** Multiple choice (able to select `ConsentValue`) */
  MultiChoice = 'multichoice',
  /** Clicked 'accept all' when presented with multiple choices */
  AcceptAll = 'acceptall',
  /** Clicked 'reject all' in initial modal */
  RejectAll = 'rejectall',
  /** Inherited from legacy cookie */
  Inherited = 'inherited',
}

export type ConsentCallback = (
  consentValue: ConsentValue,
  consentMethod?: ConsentMethod,
) => void

// The consent modal should not show up on the cookie/privacy policy or cookie table pages
// as visitors should be able to read them before consenting.
export const IS_COOKIE_RELATED_URL_REGEX =
  /^https:\/\/www\.soundtrackyourbrand\.com\/(?:[a-z]{2}\/)?legal\/(?:cookies\/?|privacy-policy\/?|cookies\/?#cookie-table)$/
