export const CONTAINER_CLASS = 'ui-portal-container';
let container = null;
export default function lazilyCreatePortalContainer() {
    if (!container) {
        container = document.createElement('div');
        container.className = CONTAINER_CLASS;
        document.body.appendChild(container);
    }
    return container;
}
