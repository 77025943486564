import type * as playbackSlice from './slice.js'

export function calculateProgress(
  playbackState: Pick<playbackSlice.State, 'status' | 'progress' | 'track'>,
) {
  if (!playbackState.progress || !playbackState.track?.durationMs) {
    return {}
  }
  const nowDiffMs =
    Date.now() - new Date(playbackState.progress.receivedAt).getTime()
  const latencyMs =
    new Date(playbackState.progress.receivedAt).getTime() -
    new Date(playbackState.progress.updatedAt).getTime()
  let positionMs = playbackState.progress.progressMs + latencyMs
  if (playbackState.status === 'playing') {
    positionMs += nowDiffMs ?? 0
  }
  positionMs = Math.max(Math.min(positionMs, playbackState.track.durationMs), 0)
  const remainingMs = playbackState.track.durationMs - positionMs
  const progress = positionMs / playbackState.track.durationMs
  return { latencyMs, nowDiffMs, positionMs, remainingMs, progress }
}
