import en from './locale/en.json';
// {{<tag>|<children>}} tags in translations replaced by dom elements
// for each <tag> there should be a param { <tag>: <dom-element> }
const TAG_REGEXP = /(\{\{[a-z0-9_-]+(?:\|.+?)?}})/gi;
const TAG_PARTS_REGEXP = /\{\{([a-z0-9_-]+)(?:\|(.+?))?}}/i;
/**
 * Attempts to load a glossary using a dynamic import of the provided locale. If no glossary
 * exists for the provided locale, or if the dynamic import fails, the English glossary is returned.
 */
export function loadGlossary(locale) {
  let p = Promise.resolve({
    default: en
  });
  switch (locale) {
    case 'sv':
      p = import(/* webpackChunkName: "_/_/packages/tracking/src/locale/sv_json" */'./locale/sv.json');
      break;
    case 'nl':
      p = import(/* webpackChunkName: "_/_/packages/tracking/src/locale/nl_json" */'./locale/nl.json');
      break;
    case 'de':
      p = import(/* webpackChunkName: "_/_/packages/tracking/src/locale/de_json" */'./locale/de.json');
      break;
    case 'fr':
      p = import(/* webpackChunkName: "_/_/packages/tracking/src/locale/fr_json" */'./locale/fr.json');
      break;
    case 'es':
      p = import(/* webpackChunkName: "_/_/packages/tracking/src/locale/es_json" */'./locale/es.json');
  }
  return p.then(result => result?.default || en).catch(err => {
    console.error(`Unable to load consent modal localization for '${locale}'`, err);
    return en;
  });
}

/**
 * Returns a t-function similar to the one in business but with support for only one kind of tag. See `t.test.tsx`
 * for example usage. The t-function falls back to the default glossary if key does not exist in provided glossary.
 *
 * @param glossary - The glossary of the language to use primarily, defaults to English
 * @param defaultGlossary - The glossary to fall back on for keys that are not found in glossary, defaults to English
 */
export function getT(glossary, defaultGlossary = en) {
  function t(key, interpolations, returnArray) {
    const result = getKey(key, glossary) || getKey(key, defaultGlossary);
    if (result === undefined) {
      return `invalid key path: ${key}`;
    }
    if (interpolations === false || interpolations === undefined) {
      return result;
    }
    const interpolated = result.split(TAG_REGEXP).map(part => {
      const tagMatch = part.match(TAG_PARTS_REGEXP);
      if (tagMatch) {
        const tagName = tagMatch[1];
        const children = tagMatch[2];
        const interpolation = interpolations[tagName];
        if (isHtmlElement(interpolation)) {
          interpolation.innerText = children;
          return interpolation;
        }
        if (typeof interpolation === 'string' || typeof interpolation === 'number') {
          if (children !== undefined) {
            return `Got string when HTMLElement was expected for interpolation ${part}`;
          }
          return interpolation;
        }
        return `No interpolation found with name ${tagName}, have ${Object.keys(interpolations).join(',')}`;
      }
      return part;
    });
    if (returnArray !== false) {
      return interpolated;
    }
    return isStringishArray(interpolated) ? interpolated.join('') : `Cannot convert ${result} to string as interpolations contain tags`;
  }
  return t;
}
function isStringishArray(array) {
  return !array.some(isHtmlElement);
}
function isHtmlElement(input) {
  return !!input && typeof input === 'object' && 'nodeType' in input;
}
function getKey(key, glossary) {
  if (!glossary) {
    return undefined;
  }
  const result = key.split('.').reduce((glossary, keyPart, i, array) => {
    if (glossary == null || typeof glossary !== 'object') {
      // We get here if there are key parts left, but we've already reached a string
      // or if the glossary contains null values.
      return undefined;
    }
    return glossary[keyPart];
  }, glossary);
  if (typeof result !== 'string') {
    return undefined;
  }
  return result;
}