/* eslint-env browser */
/* eslint no-var:0, object-shorthand:0 */
/** Tracks number of disabled scroll layers, used for `enableOne()` */
var disabledCount = 0;
/** Store original style & scrolling state */
var originalStyle = {};
var scrollTop;
/** Memoized scrollbar width calculated by `getScrollbarWidth()` */
var scrollbarWidth;
/**
    Target element to apply CSS to when disabling scrolling.
    Should wrap all statically positioned content,
    but not contain overlay elements such as modals/popups.
    Modify via setTarget()
  */
var target = typeof document === 'undefined' ? undefined : document.body;
var OVERRIDDEN_STYLE_PROPS = [
    'position',
    'height',
    'top',
    'left',
    'right',
    'overflow',
    'overflowX',
    'overflowY',
];
function setTarget(element) {
    target = element;
}
/**
 * Memoized scrollbar width (if visible) calculation.
 * Since it's only run once it won't respond to scrollbar width changes
 * (eg: toggling mobile viewport simulation, toggling visible scrollbars on Mac).
 *
 * @return {Number} Scrollbar width in pixels
 */
function getScrollbarWidth() {
    if (scrollbarWidth == null) {
        var doc = document.documentElement;
        var scrollDummy = document.createElement('div');
        scrollDummy.setAttribute('style', 'width:99px;height:99px;position:absolute;top:-999px;overflow:scroll');
        doc.appendChild(scrollDummy);
        scrollbarWidth = scrollDummy.offsetWidth - scrollDummy.clientWidth;
        doc.removeChild(scrollDummy);
    }
    return scrollbarWidth;
}
/**
 * Disables document scrolling without modifying the scroll state or viewport.
 */
function disable() {
    if (typeof document === 'undefined')
        return;
    // Track number of disables while avoiding re-applications
    if (disabledCount++)
        return;
    var rect = target.getBoundingClientRect();
    var scrollbarWidth = getScrollbarWidth();
    originalStyle = OVERRIDDEN_STYLE_PROPS.reduce((obj, prop) => {
        obj[prop] = target.style[prop];
        return obj;
    }, {});
    scrollTop = window.pageYOffset;
    target.style.position = 'fixed';
    target.style.height = rect.height + 'px';
    target.style.top = -scrollTop + 'px';
    target.style.left = '0';
    target.style.right = scrollbarWidth + 'px';
    target.style.overflow = 'hidden';
}
/**
 * Enable scrolling by restoring document style to original state.
 */
function enable() {
    if (typeof document === 'undefined')
        return;
    OVERRIDDEN_STYLE_PROPS.forEach((prop) => {
        target.style[prop] = originalStyle[prop] || '';
    });
    window.scroll(0, scrollTop);
    disabledCount = 0;
}
/**
 * Only re-enable scrolling when called as many times as `disable()` has been.
 */
function enableOne() {
    if (--disabledCount === 0) {
        enable();
    }
}
// Main export
var scrollToggle = {
    setTarget: setTarget,
    disable: disable,
    enable: enable,
    enableOne: enableOne,
};
export default scrollToggle;
