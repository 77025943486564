import classNames from 'classnames';
import * as React from 'react';
import { BaseButton } from './BaseButton';
/**
 * @experimental <FlatButton /> is new and subject to change. It supports Levels designs for flat text buttons in compact layout and default layout.
 * For external links, use `<FlatButton tag='a' href='...' />`.
 * For flat-button design on internal router Links, use `<FlatButtonLink />`.
 *
 * @usage
 * ```jsx
 * import { FlatButton } from '@soundtrackyourbrand/ui'
 *
 * <FlatButton variant="primary">Primary action</FlatButton>
 * <FlatButton variant="subtle">Secondary action</FlatButton>
 * <FlatButton compact>Compact layout</FlatButton>
 * ```
 */
export const FlatButton = React.forwardRef(FlatButtonInner);
Object.assign(FlatButton, { displayName: 'FlatButton' });
function FlatButtonInner({ compact, variant = 'subtle', ...attrs }, ref) {
    Object.assign(attrs, {
        ref,
        className: classNames(attrs.className, 'flat-button', `flat-button--${variant}`, {
            'button--compact': compact,
        }),
    });
    return React.createElement(BaseButton, attrs, attrs.children);
}
