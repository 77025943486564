import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { PortalContainerContext } from './PortalContainer';
/**
 * Portal which doesn't bubble events upwards the _component_
 * hierarchy like the default `ReactDOM.createPortal()` does.
 *
 * Can operate in two modes, controlled by `supportContextInsteadOfBubbling`:
 * - true: Supports React contexts but prevents DOM events within it from
 *   triggering `window` event listeners. Portals are rendered into the closest
 *   <PortalContainer> ancestor.
 * - false: (legacy mode) Events bubble as expected (according to DOM), but
 *   React context only get their default values.
 *
 * This component will be deprecated if the React team ever decides to add a
 * way to prevent syntethic events from bubbling upwards the React component
 * hierarchy. See https://github.com/facebook/react/issues/11387
 */
export class Portal extends React.Component {
    static propTypes = {
        /**
         * It's currently not possible to support both React contexts and proper
         * event bubbling, so this prop may be used to pick which feature to support.
         */
        supportContextInsteadOfBubbling: PropTypes.bool,
        /** Called after render */
        onRender: PropTypes.func,
        /** Legacy mode only: custom DOM node to render portal inside */
        container: PropTypes.object,
    };
    static contextType = PortalContainerContext;
    // eslint-disable-next-line react/sort-comp
    portalRef = React.createRef();
    get isLegacy() {
        return !this.props.supportContextInsteadOfBubbling;
    }
    get container() {
        return (this.isLegacy && this.props.container) || this.context.current;
    }
    componentDidMount() {
        this.triggerRender();
    }
    componentDidUpdate(prevProps) {
        if (this.isLegacy && this.props.container !== prevProps.container) {
            // Move portal to new container
            this.legacyNode.parentNode.removeChild(this.legacyNode);
            this.container.appendChild(this.legacyNode);
        }
        this.triggerRender();
    }
    componentWillUnmount() {
        this.destroyLegacyNode();
    }
    destroyLegacyNode() {
        if (this.legacyNode) {
            // eslint-disable-next-line react/no-deprecated
            ReactDOM.unmountComponentAtNode(this.legacyNode);
            this.legacyNode.parentNode.removeChild(this.legacyNode);
            this.legacyNode = null;
        }
    }
    triggerRender() {
        if (!this.isLegacy) {
            this.props.onRender?.(this.portalRef.current);
            return;
        }
        if (!this.legacyNode) {
            this.legacyNode = document.createElement('div');
            this.legacyNode.className = 'ui-portal ui-portal--legacy';
            this.container.appendChild(this.legacyNode);
        }
        ReactDOM.unstable_renderSubtreeIntoContainer(this, this.props.children, this.legacyNode, this.props.onRender);
    }
    render() {
        if (this.isLegacy) {
            return null;
        }
        return ReactDOM.createPortal(_jsx("div", { ...BLOCKED_EVENT_LISTENERS, ref: this.portalRef, className: "ui-portal ui-portal--context", children: this.props.children }), this.container);
    }
}
// Taken from https://reactjs.org/docs/events.html
const BLOCKED_EVENT_LISTENERS = [
    // Clipboard
    'onCopy',
    'onCut',
    'onPaste',
    // Composition
    // 'onCompositionEnd', 'onCompositionStart', 'onCompositionUpdate',
    // Keyboard
    'onKeyDown',
    'onKeyPress',
    'onKeyUp',
    // Focus
    // 'onFocus', 'onBlur',
    // Form
    'onChange',
    'onInput',
    'onInvalid',
    'onSubmit',
    // Mouse
    'onClick',
    'onContextMenu',
    'onDoubleClick',
    'onDrag',
    'onDragEnd',
    'onDragEnter',
    'onDragExit',
    'onDragLeave',
    'onDragOver',
    'onDragStart',
    'onDrop',
    'onMouseDown',
    'onMouseEnter',
    'onMouseLeave',
    'onMouseMove',
    'onMouseOut',
    'onMouseOver',
    'onMouseUp',
    // Pointer
    'onPointerDown',
    'onPointerMove',
    'onPointerUp',
    'onPointerCancel',
    'onGotPointerCapture',
    'onLostPointerCapture',
    'onPointerEnter',
    'onPointerLeave',
    'onPointerOver',
    'onPointerOut',
    // Selection
    'onSelect',
    // Touch
    'onTouchCancel',
    'onTouchEnd',
    'onTouchMove',
    'onTouchStart',
    // UI
    'onScroll',
    // Wheel
    'onWheel',
    // Media
    'onAbort',
    'onCanPlay',
    'onCanPlayThrough',
    'onDurationChange',
    'onEmptied',
    'onEncrypted',
    'onEnded',
    'onError',
    'onLoadedData',
    'onLoadedMetadata',
    'onLoadStart',
    'onPause',
    'onPlay',
    'onPlaying',
    'onProgress',
    'onRateChange',
    'onSeeked',
    'onSeeking',
    'onStalled',
    'onSuspend',
    'onTimeUpdate',
    'onVolumeChange',
    'onWaiting',
    // Image
    'onLoad',
    'onError',
    // Animation
    'onAnimationStart',
    'onAnimationEnd',
    'onAnimationIteration',
    // Transition
    'onTransitionEnd',
    // Other
    'onToggle',
].reduce((obj, name) => {
    obj[name] = stopPropagation;
    return obj;
}, {});
function stopPropagation(event) {
    event.stopPropagation();
}
