import type { ApolloClient } from '@apollo/client'
import type { ResultOf } from '@graphql-typed-document-node/core'
import {
  type AnyAction,
  type EnhancedStore,
  type GetThunkAPI,
  type PayloadAction,
  type StoreEnhancer,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import * as tysonSlice from '@soundtrack/playback/tyson/slice'
import { scope } from '@soundtrack/utils/log'
import { graphql } from '../graphql/gql.js'
import { jwtPayload } from './jwt.js'

const log = scope('auth')

export interface AuthConfg {
  /** Mixpanel tracking function */
  track(
    event: string,
    props: Record<string, string | number | boolean | undefined>,
  ): void

  /** By default new userIds are requested, return false to allow fetching legacy user ids */
  shouldRequestLegacyUserId?(): boolean
}

type RootState = { auth: SliceState }
type AppThunkExtra = { apollo: ApolloClient<any> }
type Store = EnhancedStore<
  RootState,
  AnyAction,
  [
    StoreEnhancer<
      Pick<GetThunkAPI<{ state: RootState; extra: AppThunkExtra }>, 'dispatch'>
    >,
  ]
>
type AppThunk<ReturnType = any> = {
  (
    dispatch: Store['dispatch'],
    getState: () => RootState,
    extraArgument: AppThunkExtra,
  ): ReturnType
}

/** Minimum time in milliseconds before token expiry to trigger a refresh */
const MIN_REMAINING_TOKEN_LIFETIME = 10e3

/**
 * Keeping track of the token migration. Hopefully we don't need to react to any more changes within the migration,
 * but if we do it can be useful to have stored a version.
 * @deprecated TODO: Remove after 2024-12-31
 */
const MIGRATE_TOKEN_VERSION = 2

/**
 * Persisted auth state
 *
 * The auth store and it's corresponding redux selectors are the primary source of truth for:
 * - Currently logged in user id
 * - Auth/refresh tokens for logged in users
 */
export type SliceState = Readonly<{
  /** Current zone (If player is paired) */
  zoneId?: string
  /** Whether Tyson is paired */
  tysonPaired?: boolean
  /** Authenticated user */
  userId?: string
  /** Auth token for currently authenticated user - refreshed by {@link tokenRefreshScheduler} */
  token?: string
  /** Where is the token coming from? */
  tokenSource?: 'login' | 'tyson'
  /** Special non-expiring token used to generate a new `token` */
  refreshToken?: string
  /** When `token` expires as UNIX timestamp */
  expiresAt?: number
  /** True while logging in */
  loggingIn?: boolean
  /** Per-user hash required to boot Intercom SDK */
  intercomHash?: string

  /**
   * Temporary tracker of whether we've migrated to the URI-based user tokens.
   * @deprecated TODO: Remove after 2024-12-31
   */
  migratedTokenVersion?: number
}>

/** Data required to generate a valid logged in {@link SliceState} */
type MinimumLoginPayload = Readonly<{
  token: string
  /** Defaults to "login" */
  tokenSource?: 'login' | 'tyson'
  refreshToken?: string
  intercomHash?: string | null | undefined
}>

const INITIAL_STATE: Readonly<SliceState> = {}

export function createAuthSlice(config: AuthConfg) {
  const selectTokenPayload = createSelector(
    [(state: SliceState) => state.token],
    (token) => {
      return token ? jwtPayload(token) : undefined
    },
  )

  const slice = createSlice({
    name: 'auth',
    initialState: INITIAL_STATE,
    reducers: {
      reset: () => INITIAL_STATE,

      updateLogin: (_state, action: PayloadAction<MinimumLoginPayload>) => {
        return loggedInStateFromPayload(action.payload)
      },
    },

    extraReducers: (builder) => {
      builder
        .addCase(login.pending, (state) => {
          state.loggingIn = true
        })
        .addCase(login.fulfilled, (_state, action) => {
          return loggedInStateFromPayload(action.payload)
        })
        .addCase(login.rejected, () => {
          return INITIAL_STATE
        })
        .addCase(refreshToken.pending, (state, action) => {
          const refreshToken = action.meta.arg
          if (refreshToken) {
            state.refreshToken = refreshToken
            state.loggingIn = !state.token
          }
        })
        .addCase(
          tysonSlice.ActionTypes.update,
          (state, action: ReturnType<typeof tysonSlice.actions.update>) => {
            // Tyson token state change
            const token = action.data.session?.token
            const hasLoginToken = !!state.token && state.tokenSource !== 'tyson'
            if (token && !hasLoginToken) {
              state.token = token.token
              state.tokenSource = 'tyson'
            }

            // Pairing state changes
            const pair = action.data.session?.isPaired
            if (pair) {
              const tysonPaired = pair.isPaired
              const { zoneId } = state
              if (!tysonPaired && state.tysonPaired && zoneId) {
                state.zoneId = undefined
              } else if (tysonPaired !== state.tysonPaired) {
                state.zoneId = tysonPaired ? zoneId : undefined
              }
              state.tysonPaired = tysonPaired
            }

            // Paired zone state changes
            const zone = action.data.session?.soundZone
            if (zone?.id && state.tysonPaired) {
              state.zoneId = zone.id
            }

            return state
          },
        )
        .addMatcher(refreshToken.settled, (state) => {
          state.loggingIn = false
        })
    },
    selectors: {
      validToken: selectValidToken,
      /** Returns token (can be expired) for currently authenticated user if logged in. */
      userToken(state): string | undefined {
        return state.tokenSource !== 'tyson' ? state.token : undefined
      },
      /** Returns token (can be expired) for currently authenticated user (if logged in) or tyson session (if paired with code) */
      token(state): string | undefined {
        return state.token
      },
      /** Returns true if there's a token that can be used to interact with backend/query non-public data */
      hasToken(state): boolean {
        return !!selectValidToken(state)
      },
      refreshToken(state): string | undefined {
        return state.refreshToken
      },
      tokenPayload: selectTokenPayload,
      loggingIn(state): boolean {
        return !!state.loggingIn
      },
      loggedIn(state): boolean {
        return !!state.token && state.tokenSource !== 'tyson'
      },
      tysonPaired(state) {
        return state.tysonPaired
      },
      expiresAt(state) {
        return state.expiresAt
      },
      userId(state) {
        return state.userId
      },
      zoneId(state) {
        return state.zoneId
      },
      intercomHash(state) {
        return state.intercomHash
      },
      hasMigratedToken(state) {
        return (
          state.migratedTokenVersion &&
          state.migratedTokenVersion >= MIGRATE_TOKEN_VERSION
        )
      },
    },
  })

  /**
   * Manually track inflight refresh mutation outside redux in cases where it's
   * triggered multiple times before redux state has been updated.
   */
  let inflightRefresh: {
    promise?: Promise<
      ResultOf<typeof RefreshLoginDoc>['refreshLogin'] | null | undefined
    >
    refreshTokenUsed?: string
  } = {}

  /**
   * Triggers a token refresh using either provided refreshToken or stored one.
   * Also used to log in using refresh token from SSO.
   */
  const refreshToken = createAsyncThunk<
    string | null | undefined,
    string | undefined,
    {
      state: RootState
      rejectValue: undefined
      extra: AppThunkExtra
    }
  >(
    'auth/refreshToken',
    async (
      /** Optional new refresh token to use */
      refreshToken,
      { getState, dispatch, extra: { apollo } },
    ) => {
      const { auth } = getState()
      refreshToken ||= auth.refreshToken!

      // Bail if no refreshToken is available
      if (!refreshToken) {
        log.debug(
          'refreshToken: bailing from refresh due to missing refreshToken',
        )
        return null
      }

      // Bail if there's already a refresh in-flight, unless another refreshToken is provided
      if (
        !inflightRefresh.promise ||
        refreshToken !== (inflightRefresh.refreshTokenUsed || auth.refreshToken)
      ) {
        log.debug('refreshToken: requesting new token')
        // Set promise before calling mutate so that the in-flight check above also works for this mutate call
        inflightRefresh.promise = Promise.resolve(undefined)
        inflightRefresh.refreshTokenUsed = refreshToken

        const promise = apollo
          .mutate({
            mutation: RefreshLoginDoc,
            variables: {
              input: {
                refreshToken,
                uriBased: !config.shouldRequestLegacyUserId?.(),
              },
            },
            context: { auth: false },
          })
          .then((res) => {
            if (!res.data) {
              throw new Error(
                'refreshToken: Refresh token response contains no data',
              )
            }
            log.debug('refreshToken: new token received')
            return res.data.refreshLogin
          })
          .catch((error) => {
            log.debug('refreshToken: error refreshing token', error)
            log.report(error)
            throw error
            // TODO: We can pass through Apollo error, but RTK serializeCheck doesn't like this
            // thunkApi.rejectWithValue(error) / thunkApi.rejectWithValue(undefined)
          })
          .finally(() => {
            // Reset the inflight state if it's still pointing to this request
            if (inflightRefresh.promise === promise) {
              inflightRefresh = {}
            }
          })
        inflightRefresh.promise = promise

        promise.then((data) => {
          if (!data?.token) return
          dispatch(slice.actions.updateLogin(data))
        })
      } else {
        log.debug('refreshToken: reusing already in-flight request')
      }

      if (inflightRefresh.promise) {
        return inflightRefresh.promise.then((data) => data?.token)
      }

      return undefined
    },
    {
      serializeError: (error) => error as any, // Passthrough error instances
    },
  )

  /** Log in using either email and password, or by token. */
  const login = createAsyncThunk<
    MinimumLoginPayload,
    { email: string; password: string } | { token: string },
    {
      state: RootState
      extra: AppThunkExtra
    }
  >(
    'auth/login',
    async (arg, { extra: { apollo }, rejectWithValue }) => {
      if ('token' in arg) {
        const { token } = arg
        return apollo
          .query({
            query: LoginDataDoc,
            fetchPolicy: 'network-only',
            context: { auth: token },
          })
          .then((res) => {
            return {
              token,
              intercomHash: res.data.intercomHash!,
            }
          })
      } else {
        const { email, password } = arg
        return apollo
          .mutate({
            mutation: LoginUserDoc,
            variables: {
              input: {
                email,
                password,
                uriBased: !config.shouldRequestLegacyUserId?.(),
              },
            },
            context: {
              auth: false,
              expectedErrors: ['UNAUTHENTICATED'],
            },
          })
          .then((res) => {
            if (!res.data) {
              throw new Error(`auth: Expected login response to contain data`)
            }
            return res.data.loginUser! satisfies MinimumLoginPayload
          })
          .catch((error) => {
            throw rejectWithValue(error)
          })
      }
    },
    {
      serializeError: (error) => error as any, // Passthrough error instances
    },
  )

  const actions = {
    ...slice.actions,

    /**
     * Returns token similar to {@link selectors.validToken}, with the only
     * difference being that the thunk will dispatch {@link actions.refreshToken}
     * if logged in as user and the stored token has expired.
     */
    maybeUpdateToken:
      (): AppThunk<Promise<string | null | undefined>> =>
      (dispatch, getState) => {
        const state = getState()
        const token = slice.selectors.validToken(state)

        const hasMigratedToken = slice.selectors.hasMigratedToken(state)
        const migrate =
          !hasMigratedToken &&
          !!state.auth.refreshToken &&
          !config.shouldRequestLegacyUserId?.()
        if (migrate) {
          log.debug('Should migrate the token, force refreshing...')
        }

        if (token && !migrate) {
          return Promise.resolve(token)
        }

        const promise = state.auth.refreshToken
          ? dispatch(actions.refreshToken()).unwrap()
          : Promise.resolve(undefined)

        if (migrate) {
          promise.then((newToken) => {
            if (trackedMigrationEvent) return
            trackedMigrationEvent = true
            config.track('Auth - Migrated User Token With URI', {
              'Token Prev Subject': jwtPayload(token)?.sub,
              'Token Next Subject': jwtPayload(newToken!)?.sub,
              'Migration Version': MIGRATE_TOKEN_VERSION,
            })
          })
        }

        return promise
      },

    /**
     * Triggers a token refresh using either provided refreshToken or stored one.
     * Also used to log in using refresh token from SSO.
     */
    refreshToken,

    /**
     * Log in using either email and password, or by token.
     * Token is only used when authenticating with SAML (Session expires
     * with the token, since no `refreshToken` is provided.)
     */
    login,
  }

  /** Returns the auth state representation of a given JWT token payload */
  function loggedInStateFromPayload({
    token,
    tokenSource = 'login',
    refreshToken,
    intercomHash,
  }: MinimumLoginPayload): SliceState {
    const payload = jwtPayload(token)
    const expiry = (payload.exp || 0) * 1e3
    return {
      token,
      tokenSource,
      refreshToken,
      expiresAt: expiry ? new Date(expiry).getTime() : undefined,
      loggingIn: false,
      userId: payload.typ === 'user' ? payload.sub : undefined,
      intercomHash: intercomHash || undefined,
      migratedTokenVersion: config.shouldRequestLegacyUserId?.()
        ? undefined
        : MIGRATE_TOKEN_VERSION,
    }
  }

  /** Only returns the current user token if valid and not about to expire */
  function selectValidToken(state: SliceState): string | undefined {
    const { token, refreshToken, expiresAt } = state
    return token &&
      (!refreshToken ||
        !expiresAt ||
        expiresAt > Date.now() + MIN_REMAINING_TOKEN_LIFETIME)
      ? token
      : undefined
  }

  /** @deprecated Remove after 2024-12-31 */
  let trackedMigrationEvent = false

  /**
   * Self-contained token refresher that subscribes to the auth store and
   * automatically requests a new token using the {@link SliceState} `refreshToken`
   * (when available) before the current token expires.
   *
   * Runs until the returned callback is called.
   *
   * @example
   * Usage in React
   * ```tsx
   * React.useEffect(() => tokenRefreshScheduler(store), [store])
   * ```
   */
  function tokenRefreshScheduler(store: Store) {
    let timeout: any = null

    const refresh = () => {
      return store.dispatch(actions.refreshToken())
    }

    const scheduleRefresh = (expiresAt: number | undefined) => {
      clearTimeout(timeout)
      timeout = null
      if (typeof expiresAt !== 'number') {
        return
      }
      const payload = slice.selectors.tokenPayload(store.getState())
      const refreshIn = Math.min(
        Math.max(0, expiresAt * 1e3 - Date.now()), // refresh before token expires
        payload?.iat ? ((expiresAt - payload.iat) * 1e3) / 2 : 24 * 3600e3, // halfway through token lifetime, defaulting to 24hr if `iat` is missing
        12 * 3600e3, // every 4 hours
      )
      timeout = setTimeout(refresh, refreshIn)
    }

    let oldExpiresAt: number | undefined
    const unsubscribe = store.subscribe(() => {
      const state = store.getState()
      const expiresAt = slice.selectors.expiresAt(state)
      if (expiresAt !== oldExpiresAt) {
        oldExpiresAt = expiresAt
        scheduleRefresh(expiresAt)
      }
    })

    return () => {
      unsubscribe()
      clearTimeout(timeout)
    }
  }

  return {
    actions,
    reducer: slice.reducer,
    selectors: slice.selectors,
    loggedInStateFromPayload,
    tokenRefreshScheduler,
  }
}

const LoginUserDoc = graphql(`
  mutation LoginUser($input: LoginUserInput!) {
    loginUser(input: $input) {
      userId
      token
      refreshToken
      intercomHash
    }
  }
`)

const LoginDataDoc = graphql(`
  query LoginData {
    intercomHash
    me {
      __typename
      ... on User {
        id
      }
    }
  }
`)

const PairDeviceDoc = graphql(`
  mutation PairDevice($input: DevicePairInput!) {
    devicePair(input: $input) {
      token
      refreshToken
      expiresAt
    }
  }
`)

const RefreshLoginDoc = graphql(`
  mutation RefreshLogin($input: RefreshLoginInput!) {
    refreshLogin(input: $input) {
      token
      refreshToken
      intercomHash
    }
  }
`)
