import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
export function nl2br(text) {
    if (React.isValidElement(text)) {
        return React.cloneElement(text, null, text.props.children);
    }
    if (Array.isArray(text)) {
        return React.Children.map(text, nl2br);
    }
    if (typeof text !== 'string') {
        return text;
    }
    return text
        .split(/(\r\n|\r|\n)/g)
        .map((x, i) => (isNewline(x) ? _jsx("br", {}, 'br-' + i) : x));
}
export function nl2p(text, convertBr = true) {
    if (typeof text !== 'string') {
        return text;
    }
    return text
        .split(/(?:\r\n|\r|\n){2,}/g)
        .map((x, i) => _jsx("p", { children: convertBr ? nl2br(x) : x }, 'p-' + i));
}
export function nl2pString(text, dangerous) {
    if (typeof text !== 'string') {
        return text;
    }
    text = text.replace(/(?:\r\n|\r|\n)+/g, (m) => isNewline(m) ? '<br>' : '</p>\n<p>');
    if (text !== '') {
        text = `<p>${text}</p>`;
    }
    return dangerous ? { __html: text } : text;
}
export function isNewline(str) {
    return str === '\n' || str === '\r\n' || str === '\r';
}
