export type Falsy = undefined | null | false | 0 | ''

/**
 * Useful for .filter()ing out `undefined`, `null`, `0`, `''`, and `false` values from an array.
 */
export function isTruthy<T>(value: T | Falsy): value is T {
  return !!value
}

/**
 * Useful for .filter()ing out `undefined` and `null` values from an array.
 *
 * @returns Whether the value is defined and not `null`
 */
export function isDefined<T>(value: T | undefined | null): value is T {
  return value != null
}

export function onlyHasKey<K extends string>(
  object: object,
  key: K,
): object is { [P in K]: unknown } {
  for (const k in object) {
    if (Object.prototype.hasOwnProperty.call(object, k) && k !== key) {
      return false
    }
  }
  return true
}
