import type {
  EnhancedStore,
  StoreEnhancer,
  ThunkDispatch,
  UnknownAction,
} from '@reduxjs/toolkit'
import type * as playback from './slice.js'
import type * as tyson from './tyson/slice.js'

export type Store = EnhancedStore<
  RootState,
  UnknownAction,
  [
    StoreEnhancer<{
      dispatch: ThunkDispatch<RootState, object, UnknownAction>
    }>,
  ]
>
export interface RootState {
  playback: playback.ReducerState
  tyson: tyson.ReducerState
}

export function curry<
  T extends (playerId: string, rootState: RootState) => any,
>(
  selector: T,
): {
  (playerId: string, rootState: RootState): ReturnType<T>
  (playerId: string): (rootState: RootState) => ReturnType<T>
} {
  return (playerId: string, rootState?: RootState) =>
    rootState === undefined
      ? (rootState: RootState) => selector(playerId, rootState)
      : selector(playerId, rootState)
}
