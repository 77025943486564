/**
 * Returns true if `obj` is an object containing all of the enumerable
 * properties of `subset`, with their values equal by reference.
 *
 * @param obj - The object to test
 * @param subset - The object containing the required props
 * @return True iff `subset` is a shallow subset of `obj`
 */
export function isShallowSubsetOf(obj, subset) {
    if (subset === obj) {
        return true;
    }
    if (!subset ||
        !obj ||
        typeof subset !== 'object' ||
        typeof obj !== 'object') {
        return false;
    }
    const keys = Object.keys(subset);
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (!Object.prototype.hasOwnProperty.call(obj, key) ||
            obj[key] !== subset[key]) {
            return false;
        }
    }
    return true;
}
/**
 * Returns a lightweight proxy for the given `proxiedObject` which tracks
 * property access in a separate object (`accessTracker.current`).
 *
 * Uses `Object.defineProperties()` instead of `Proxy` to support IE11,
 * with the downside that all properties must be known beforehand.
 *
 * @param proxiedObject - Object to proxy
 * @param accessTracker - React-like ref object in which prop accesses are tracked
 * @return An object with the same properties as `proxiedObject`
 */
export function accessedPropsProxy(proxiedObject, accessTracker) {
    // @ts-ignore
    const proxy = {};
    Object.keys(proxiedObject).forEach((prop) => {
        Object.defineProperty(proxy, prop, {
            enumerable: true,
            get() {
                if (prop in proxiedObject) {
                    return (accessTracker.current[prop] = proxiedObject[prop]);
                }
                return undefined;
            },
        });
    });
    return proxy;
}
/**
 * Partition the provided object into two;
 * one object containing only the requested keys,
 * and the other object containing all other keys.
 *
 * Does not mutate the source object.
 *
 * @param source - Object to partition
 * @param keys - List of properties to include in the first object and exclude from the second
 * @return `[included, excluded]`
 */
export function partitionObject(source, keys) {
    const included = {};
    const excluded = Object.assign({}, source);
    if (source) {
        keys.forEach((prop) => {
            included[prop] = source[prop];
            delete excluded[prop];
        });
    }
    return [included, excluded];
}
/**
 * Returns a shallow copy of an object containing only the properties for which
 * `filterFn` returns true.
 *
 * @param source - Object to filter
 * @param [filterFn=Boolean] - Filter function
 * @return Filtered copy of `source` object
 */
export function filterObject(source, filterFn = Boolean) {
    const result = {};
    for (const prop in source) {
        if (Object.prototype.hasOwnProperty.call(source, prop) &&
            filterFn(source[prop], prop)) {
            result[prop] = source[prop];
        }
    }
    return result;
}
export default {
    accessedPropsProxy,
    filterObject,
    isShallowSubsetOf,
    partitionObject,
};
