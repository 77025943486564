/**
 * Returns an object similar to `Event` when called using any supported UI
 * `onChange` signature (native or legacy).
 */
export const eventFromOnChange = (...args) => {
    const { length } = args;
    const last = args[length - 1];
    const event = { type: 'change', target: { value: undefined } };
    // Import enumerable properties from source event if present
    if (isPseudoEvent(last)) {
        if (typeof last.persist === 'function') {
            last.persist();
        }
        Object.assign(event, last);
        event.target = Object.assign(event.target, last.target);
    }
    switch (length) {
        case 0:
            throw new Error(`Invalid onChange call: expected 1-3 arguments but got none`);
        case 1:
            if (!isPseudoEvent(args[0])) {
                event.target.value = args[0];
            }
            break;
        case 2:
            if (isPseudoEvent(args[1])) {
                event.target.value = args[0];
                break;
            }
        // fallthrough as n=3 handles (value, field, [event]) signature
        case 3: {
            event.target.value = args[0];
            const name = Array.isArray(args[1]) ? args[1].join('.') : args[1];
            if (typeof name === 'string') {
                event.target.name = name;
            }
        }
    }
    return event;
};
export function isPseudoEvent(x) {
    return !!x && typeof x === 'object' && typeof x['target']?.name === 'string';
}
