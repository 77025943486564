import classNames from 'classnames';
import * as React from 'react';
import { BaseButton } from './BaseButton';
/**
 * @experimental <PillButton /> is subject to change. Supports Levels variants of pill-shaped buttons.
 * For external links, use `<PillButton tag='a' href='...' />`.
 * For pill-shaped button design on internal router Links, use `<PillButtonLink />`.
 * @usage
 * ```jsx
 * import { PillButton } from '@soundtrackyourbrand/ui'
 *
 * <PillButton variant="primary">Click me</PillButton>
 * ```
 */
export const PillButton = React.forwardRef(PillButtonInner);
Object.assign(PillButton, { displayName: 'PillButton' });
function PillButtonInner({ variant = 'outlined', ...attrs }, ref) {
    Object.assign(attrs, {
        ref,
        className: classNames(attrs.className, `button--${variant}`),
    });
    return React.createElement(BaseButton, attrs, attrs.children);
}
