import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { Icon } from './Icon';
import { Popper } from './Popper';
import { containsElement, findParent, isFocusableElement } from './lib/dom';
import { elementPropType } from './lib/element-prop-type';
/*
  Translates Dropdown attach+direction into Popper placements:
  down left = bottom-end
  down right = bottom-start
  up left = top-end
  up right = top-start
*/
const POPPER_PLACEMENT = {
    up: 'top',
    down: 'bottom',
    left: 'end',
    right: 'start',
};
const DROPDOWN_OPTION_CLASS = 'dropdown__option';
DropdownUnmodified.dropdownType = 'Unmodified';
function DropdownUnmodified(props) {
    return props.children;
}
class DropdownSelectTrigger extends React.Component {
    render() {
        return (_jsx("div", { ...this.props, role: "button", className: classNames(this.props.className, 'dropdown-trigger--select') }));
    }
}
class DropdownContextMenuTrigger extends React.Component {
    render() {
        return _jsx(Icon, { name: "menu", role: "button", ...this.props });
    }
}
function DropdownOptionIcon(props) {
    return (_jsx(Icon, { middle: true, ...props, className: classNames('dropdown__option-icon', props.className) }));
}
/* eslint-disable-next-line valid-jsdoc */
/**
 * Self-contained Dropdown component, including trigger.
 *
 * Renders a context menu button trigger by default which may be overridden by
 * providing another React element as `trigger`. `Dropdown.Select` and
 * `Dropdown.ContextMenu` are two possible trigger alternatives.
 *
 * Options are defined as child elements, with some difference depending on
 * whether or not `manuallyRenderOptions` is enabled.
 *
 * With `manuallyRenderOptions` enabled:
 * ```jsx
 * <Dropdown ...>
 *   <a className={Dropdown.optionClassName} ... />
 *   <Button className={Dropdown.optionClassName} ... />
 * </Dropdown>
 * ```
 *
 * With `manuallyRenderOptions` disabled (default, legacy behaviour) all direct
 * children of the component are cloned, with the className being added
 * automatically. Children which should not be rendered as options can be
 * wrapped with `<Dropdown.Unmodified>`.
 * ```jsx
 * <Dropdown ...>
 *   <a ... />
 *   <Button ... />
 * </Dropdown>
 * ```
 *
 * Dropdown contents (options and anything else) can be lazily rendered on open
 * by passing a function as the `children` prop, thereby avoiding potentially
 * expensive renders to be thrown away while the dropdown is closed.
 *
 * The rendered DOM hierarchy differs depending on the `portal` prop:
 * - `false` (default):
 *   Wraps the trigger (and dropdown if active) with a `.dropdown-wrapper`
 *   element which also gets any additional `className` set.
 * - `true`:
 *   Renders active dropdown in a React portal, avoiding the need for any wrapper element. `className` is added to the active `.dropdown` element.
 *
 * Any click on an open dropdown will close it by default. This can be
 * prevented by adding the following listener to any content that should be
 * clickable:
 * `<div onClick={Dropdown.stopPropagation} />`
 */
export class Dropdown extends React.PureComponent {
    /**
      Use this as `className` value to style something like an option:
      <a className={Dropdown.optionClassName} ... />
    */
    static optionClassName = DROPDOWN_OPTION_CLASS;
    /**
      When `manuallyRenderOptions` is disabled (default legacy mode) wrapping children of
      <Dropdown> with this prevents them from being modified.
    */
    static Unmodified = DropdownUnmodified;
    /** Default context menu trigger */
    static ContextMenu = DropdownContextMenuTrigger;
    /** <select> ish trigger (contains text and select arrow) */
    static Select = DropdownSelectTrigger;
    /** Used to render a icon on the left margin of a `.dropdown__option` (should be the first child of said option) */
    static OptionIcon = DropdownOptionIcon;
    /** Can be attached to onClick of dropdown children to prevent clicks from closing the dropdown */
    static stopPropagation = stopPropagation;
    static propTypes = {
        placement: PropTypes.string,
        direction: PropTypes.oneOf(['up', 'down']),
        attach: PropTypes.oneOf(['left', 'right']),
        trigger: PropTypes.node.isRequired,
        portal: PropTypes.bool.isRequired,
        className: PropTypes.string,
        anchor: elementPropType,
        activeTitle: PropTypes.string,
        manuallyRenderOptions: PropTypes.bool,
        onOpen: PropTypes.func,
        onClose: PropTypes.func,
        // + <Popper> props
    };
    static defaultProps = {
        placement: 'bottom-end',
        offset: '0,4',
        // @ts-ignore
        trigger: _jsx(DropdownContextMenuTrigger, {}),
        portal: false,
    };
    state = {
        active: false,
    };
    triggerRef = React.createRef();
    ignoreEvent = false;
    openDropdown = (event) => {
        this.setState({ active: true });
        this.props.onOpen?.(event);
    };
    closeDropdown = (event) => {
        this.setState({ active: false });
        this.props.onClose?.(event);
    };
    toggleDropdown = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (this.ignoreEvent) {
            this.ignoreEvent = false;
            return;
        }
        this[this.state.active ? 'closeDropdown' : 'openDropdown'](event);
    };
    maybeCloseDropdown = (event) => {
        // TODO: Consider only closing when clicking on <a>/<button> tags
        if (findParent(event.target, isFocusableElement, event.currentTarget)) {
            return; // ignore clicks on focusable elements
        }
        this.closeDropdown(event);
    };
    handleClickOutside = (event) => {
        let triggerNode = this.triggerRef.current;
        if (triggerNode && !(triggerNode instanceof HTMLElement)) {
            triggerNode = ReactDOM.findDOMNode(triggerNode);
        }
        this.ignoreEvent =
            !!triggerNode && containsElement(triggerNode, event.target, true);
        this.closeDropdown(event);
    };
    render() {
        let { activeTitle, attach, children, className, direction, manuallyRenderOptions, onClose, onOpen, trigger, ...popperProps } = this.props;
        const triggerElement = React.cloneElement(trigger, {
            key: 'dropdown-trigger',
            ref: this.triggerRef,
            onClick: children ? this.toggleDropdown : undefined,
            className: classNames('dropdown-trigger', !children && 'dropdown-trigger--disabled', this.state.active && 'dropdown-trigger--active', this.props.trigger.props.className),
            title: activeTitle,
            // tabIndex: '0',
            'aria-haspopup': children ? 'true' : undefined,
            'aria-expanded': children && this.state.active ? 'true' : undefined,
            // 'aria-controls': '' // TODO: Menu doesn't have any ID yet
        });
        let contents = null;
        if (this.state.active) {
            if (typeof children === 'function') {
                children = children();
            }
            if (!manuallyRenderOptions) {
                children = React.Children.map(children, (child) => {
                    if (!child ||
                        typeof child !== 'object' ||
                        !child.type ||
                        child.type.dropdownType === DropdownUnmodified.dropdownType) {
                        return child;
                    }
                    return React.cloneElement(child, {
                        className: classNames(DROPDOWN_OPTION_CLASS, child.props.className),
                        // role: 'menuitem', // TODO: Children might not support this prop
                    });
                });
            }
            if (popperProps.anchor === undefined) {
                popperProps.anchor = this.triggerRef.current;
            }
            contents = (_jsx(Popper, { ...popperProps, supportContextInsteadOfBubbling: true, placement: popperProps.placement ||
                    POPPER_PLACEMENT[direction || 'down'] +
                        '-' +
                        POPPER_PLACEMENT[attach || 'right'], className: classNames('dropdown', this.props.portal && className), role: "menu", "data-testid": "user-dropdown", onClick: this.maybeCloseDropdown, onClickOutside: this.handleClickOutside, children: children, clickOutsideCapture: true }, "dropdown"));
        }
        if (this.props.portal) {
            return [triggerElement, contents];
        }
        return (_jsxs("div", { className: classNames('dropdown-wrapper', {
                'dropdown-wrapper--active': this.state.active,
            }, className), children: [triggerElement, contents] }));
    }
}
function stopPropagation(event) {
    event.stopPropagation();
}
