// Required to make typescript bundle the file
export default true

if (
  typeof Element !== 'undefined' &&
  // eslint-disable-next-line no-prototype-builtins
  !Element.prototype.hasOwnProperty('remove')
) {
  Object.defineProperty(Element.prototype, 'remove', {
    configurable: true,
    enumerable: true,
    writable: true,
    value() {
      this.parentNode.removeChild(this)
    },
  })
}
