import type { DesktopTypes } from '@soundtrack/desktop-shared-types'
import React from 'react'
import { useLatest } from './useLatest'
import { hotkeyMatches } from './utils'
export { hotkeyMatches } from './utils'

const protectedTags = ['INPUT', 'TEXTAREA', 'SELECT']

export function useCaptureHotkeys(
  capture?: (hotkey: DesktopTypes.Hotkey) => void,
  handledHotkeys?: DesktopTypes.Hotkey[],
) {
  const captureRef = useLatest(capture)
  const handledHotkeysRef = useLatest(handledHotkeys)

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const hotkey: DesktopTypes.Hotkey = {
        key: event.key,
        altKey: event.altKey,
        ctrlKey: event.ctrlKey,
        metaKey: event.metaKey,
        shiftKey: event.shiftKey,
      }
      if (
        // Make sure the event target is not a tag whose behaviour should not be overridden.
        !(
          event.target instanceof HTMLElement &&
          protectedTags.includes(event.target.nodeName)
        ) &&
        // Make sure the captured hotkey is in the list of handled ones, if such a list is passed.
        (!handledHotkeysRef.current ||
          handledHotkeysRef.current.some((handled) =>
            hotkeyMatches(hotkey, handled),
          ))
      ) {
        event.preventDefault()
        captureRef.current?.(hotkey)
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [captureRef, handledHotkeysRef])
}
