import type { Playback, Selectors } from './base.js'
import * as playbackSlice from './slice.js'
import type { RootState } from './storeUtils.js'

const nullPlaybackSelectors: Selectors = Object.fromEntries(
  Object.entries(playbackSlice.selectors).map(([key, selector]) =>
    selector.length >= 2
      ? [key, (state: RootState) => (selector as any)('', state)]
      : [key, selector],
  ),
) as any

export const NullPlayback: Playback = new Proxy(
  {
    init() {},
    dispose() {},
  } as any,
  {
    get(target, p) {
      const prop = p as keyof Playback
      switch (prop) {
        case 'volumeResolution':
          return 100
        case 'selectors':
          return nullPlaybackSelectors
        case '$$typeof' as any:
        case 'playerId':
          return ''
        case 'init':
        case 'dispose':
          return target[p]
        default:
          console.warn(`NullPlayback.${prop} was called, this is a no-op`)
          return () => Promise.resolve()
      }
    },
  },
)
