import { jsx as _jsx } from "react/jsx-runtime";
import { Link } from '@tanstack/react-router';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
export class Tabset extends React.Component {
    static displayName = 'Tabset';
    static propTypes = {
        tabs: (...args) => {
            const [props] = args;
            const arrErr = PropTypes.array.apply(this, args);
            if (arrErr) {
                return arrErr;
            }
            // Allow any array as tabs if active and onChange is set
            if (typeof props.active === 'number' && props.onChange) {
                return null;
            }
            // Otherwise require an array of {to, label} objects
            return PropTypes.arrayOf(PropTypes.shape({
                to: PropTypes.string,
                label: PropTypes.node,
                /** Allow force-setting of "active" class */
                isActive: PropTypes.bool,
            })).apply(this, args);
        },
        active: PropTypes.number,
        onChange: PropTypes.func,
        type: PropTypes.oneOf(['normal', 'wide', 'pill']).isRequired,
    };
    static defaultProps = {
        type: 'normal',
    };
    onChange(index) {
        this.props.onChange(index);
    }
    render() {
        const { tabs, active, onChange, type, className, activeColor, ...props } = this.props;
        const containerCx = classNames('tabset', type !== 'normal' ? `tabset--${type}` : null, className);
        return (_jsx("ul", { className: containerCx, ...props, children: tabs.map((tab, index) => {
                let elem = null;
                if (typeof tab === 'object' && !React.isValidElement(tab)) {
                    const Elem = tab.elem || Link;
                    const cx = classNames('tab', tab.className, {
                        active: tab.isActive === true,
                    });
                    elem = (_jsx(Elem, { to: tab.to, className: cx, activeProps: { className: 'active' }, activeOptions: { exact: !!tab.isIndex }, children: tab.label }));
                }
                else {
                    const isActive = active === index;
                    const cx = classNames('tab', { active: isActive });
                    elem = (_jsx("a", { className: cx, onClick: this.onChange.bind(this, index), children: tab }));
                }
                return _jsx("li", { children: elem }, `tab-${index}`);
            }) }));
    }
}
