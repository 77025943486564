/**
 * Schedule a low priority background task.
 */
export function backgroundTask(task: () => void) {
  if ('scheduler' in globalThis) {
    globalThis.scheduler.postTask(task, { priority: 'background' })
  } else if ('reqeustIdleCallback' in globalThis) {
    globalThis.requestIdleCallback(task)
  } else {
    setTimeout(task, 0)
  }
}

/**
 * Typescript is currently lacking types for the scheduler api
 * copied from https://github.com/microsoft/TypeScript-DOM-lib-generator/pull/1249
 */
declare global {
  type TaskPriority = 'user-blocking' | 'user-visible' | 'background'

  interface SchedulerPostTaskOptions {
    delay?: number
    priority?: TaskPriority
    signal?: AbortSignal
  }

  interface Scheduler {
    postTask<T, P extends readonly unknown[] | []>(
      callback: (...params: P) => T,
      options?: SchedulerPostTaskOptions,
      ...args: P
    ): Promise<T>
  }

  var scheduler: Scheduler
}
