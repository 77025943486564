export function debounce(fn, delay) {
    let timer;
    return function (...args) {
        // @ts-ignore
        const context = this;
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(context, args);
        }, delay);
    };
}
export function throttle(fn, delay) {
    let timer;
    let previous = 0;
    return function (...args) {
        // @ts-ignore
        const context = this;
        const now = Number(new Date());
        if (previous && now < previous + delay) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                previous = now;
                fn.apply(context, args);
            }, now - previous + delay);
        }
        else {
            previous = now;
            fn.apply(context, args);
        }
    };
}
