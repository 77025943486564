import type { DocumentNode, OperationDefinitionNode } from 'graphql'

/**
 * Returns the first operation definition in the provided GraphQL query AST, if
 * any. Similar to `getMainDefinition` from `@apollo/client`/`graphql` except
 * that it doesn't validate the document or generate an temporary array.
 */
export function getMainDefinition(doc: DocumentNode) {
  const definition = doc?.definitions.find(
    (d) => d.kind === 'OperationDefinition' && d.operation,
  ) as OperationDefinitionNode
  if (!definition) {
    throw new Error(
      `Expected a parsed GraphQL query with a query/mutation/subscription`,
    )
  }
  return definition
}
