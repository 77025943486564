export const COMPOSER_SUBTYPE_MAP: Record<string, string> = {
  manual: 'custom',
  'spotify-composer': 'synced',
  'curator-composer': 'readymade',
  'artist-composer': 'artist',
  'seed-composer': 'tracks',
  'recipe-composer': 'tags',
  /** @deprecated way of creating tag stations */
  'simple-recipe-composer': 'tags',
} as const
