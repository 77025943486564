import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectors } from './slice'
import { ThemeContext } from './theme-context'

export function useTheme() {
  const userSetting = useSelector(selectors.mode)
  const { systemMode, modeSelectEnabled } = useContext(ThemeContext)
  let mode = userSetting === 'system' ? systemMode : userSetting
  if (!modeSelectEnabled) {
    mode = 'light'
  }
  return useMemo(() => {
    return {
      mode,
      isDarkMode: mode === 'dark',
    }
  }, [mode])
}
