import { type FieldPolicy, type Reference } from '@apollo/client/cache'
import { mergeDeep } from '@apollo/client/utilities/common/mergeDeep'
import { __rest } from 'tslib'
import type { RelayPageInfo } from './types'

type KeyArgs = FieldPolicy<any>['keyArgs']

/**
 * @group Typescript
 * @private
 */
export type RelayEdgeType<TNode> =
  | { cursor?: string; node: TNode }
  | (Reference & { cursor?: string })

/**
 * @group Typescript
 * @private
 */
export type RelayExistingType<TNode> = Readonly<{
  edges: RelayEdgeType<TNode>[]
  pageInfo: RelayPageInfo
}>

/**
 * @group Typescript
 * @private
 */
export type RelayIncomingType<TNode> = {
  edges?: RelayEdgeType<TNode>[]
  pageInfo?: RelayPageInfo
}

/**
 * @group Typescript
 * @private
 */
export type RelayFieldPolicy<TNode> = FieldPolicy<
  RelayExistingType<TNode> | null,
  RelayIncomingType<TNode> | null,
  RelayIncomingType<TNode> | null
>

/**
 * Re-implementation of built-in [`relayStylePagination`] which respects the
 * `first` argument. It should be revised whenever the upstream file changes.
 *
 * [`relayStylePagination`]: https://github.com/apollographql/apollo-client/blob/main/src/utilities/policies/pagination.ts
 *
 * @group Type Policies
 */
export function relayPagination<TNode = Reference>(
  /** Identical to regular relayStylePagination keyArgs */
  keyArgs: KeyArgs = false,
  options: {
    /** Return all cached entries instead of respecting `$first/$after`? */
    all?: boolean
    /** Allow query to be resolved by partially filled cache (`hasNextPage && edges.length < $first`)? */
    partial?: boolean
  } = {},
): RelayFieldPolicy<TNode> {
  return {
    keyArgs,

    read(existing, { canRead, readField, args }) {
      if (!existing) {
        return existing
      }
      const cachedCount = existing.edges.length

      const edges: RelayEdgeType<TNode>[] = []

      let firstEdgeCursor = ''
      let lastEdgeCursor = ''
      let { startCursor, endCursor, hasNextPage, hasPreviousPage } =
        existing.pageInfo || {}

      let startIndex = 0
      if (args?.after) {
        const indexAfter = existing.edges.findIndex(
          (x) => x.cursor === args!.after,
        )
        if (indexAfter >= 0) {
          startIndex = indexAfter + 1
          hasPreviousPage = true
        }
      }

      if (
        !options.partial &&
        hasNextPage &&
        args!.first &&
        startIndex + args!.first > cachedCount
      ) {
        // Trigger fetch since the query requested more entries than what is cached
        return undefined
      }

      const endIndex =
        options.all || !args?.first
          ? cachedCount
          : Math.min(cachedCount, startIndex + args.first)

      for (let i = startIndex; i < endIndex; i++) {
        const edge = existing.edges[i]
        if (canRead(readField('node', edge as any))) {
          edges.push(edge)
          if (edge.cursor) {
            firstEdgeCursor = firstEdgeCursor || edge.cursor || ''
            lastEdgeCursor = edge.cursor || lastEdgeCursor
          }
        }
      }

      return {
        // Some implementations return additional Connection fields, such
        // as existing.totalCount. These fields are saved by the merge
        // function, so the read function should also preserve them.
        ...getExtras(existing),
        edges,
        pageInfo: {
          ...existing.pageInfo,
          hasNextPage,
          hasPreviousPage,
          startCursor: firstEdgeCursor || startCursor,
          endCursor: lastEdgeCursor || endCursor,
        },
      }
    },

    merge(existing, incoming, { args, isReference, readField }) {
      if (!existing) {
        existing = makeEmptyData()
      }

      if (!incoming) {
        return existing
      }

      const incomingEdges = incoming.edges
        ? incoming.edges.map((edge) => {
            if (isReference((edge = { ...edge }))) {
              // In case edge is a Reference, we read out its cursor field and
              // store it as an extra property of the Reference object.
              edge.cursor = readField<string>('cursor', edge)
            }
            return edge
          })
        : []

      if (incoming.pageInfo) {
        const { pageInfo } = incoming
        const { startCursor, endCursor } = pageInfo
        const firstEdge = incomingEdges[0]
        const lastEdge = incomingEdges[incomingEdges.length - 1]
        // In case we did not request the cursor field for edges in this
        // query, we can still infer cursors from pageInfo.
        if (firstEdge && startCursor) {
          firstEdge.cursor = startCursor
        }
        if (lastEdge && endCursor) {
          lastEdge.cursor = endCursor
        }
        // Cursors can also come from edges, so we default
        // pageInfo.{start,end}Cursor to {first,last}Edge.cursor.
        const firstCursor = firstEdge && firstEdge.cursor
        if (firstCursor && !startCursor) {
          incoming = mergeDeep(incoming, {
            pageInfo: {
              startCursor: firstCursor,
            },
          })
        }
        const lastCursor = lastEdge && lastEdge.cursor
        if (lastCursor && !endCursor) {
          incoming = mergeDeep(incoming, {
            pageInfo: {
              endCursor: lastCursor,
            },
          })
        }
      }

      let prefix = existing.edges
      let suffix: typeof prefix = []

      if (args && args.after) {
        // This comparison does not need to use readField('cursor', edge),
        // because we stored the cursor field of any Reference edges as an
        // extra property of the Reference object.
        const index = prefix.findIndex((edge) => edge.cursor === args.after)
        if (index >= 0) {
          prefix = prefix.slice(0, index + 1)
        }
      } else if (args && args.before) {
        const index = prefix.findIndex((edge) => edge.cursor === args.before)
        suffix = index < 0 ? prefix : prefix.slice(index)
        prefix = []
      } else if (
        incoming.pageInfo?.startCursor &&
        incoming.pageInfo.startCursor === existing.pageInfo?.startCursor
      ) {
        prefix = [] // Consider this a complete refresh by default
        // Determine prefix & suffix by looking for the start/end cursor
        // within existing cursors
        for (let i = 0; i < existing.edges.length; i++) {
          const oldEdge = existing.edges[i]
          const newEdge = incoming.edges?.[i]
          if (
            !oldEdge.cursor ||
            (newEdge && newEdge.cursor !== oldEdge.cursor)
          ) {
            // Retaining (some of) existing edges is only possible if cursors are populated and edge ordering hasn't changed
            break
          }
          if (incoming.pageInfo.startCursor === oldEdge.cursor) {
            prefix = existing.edges.slice(0, i)
          }
          if (incoming.pageInfo.endCursor === oldEdge.cursor) {
            let index = i + 1
            const incomingLength = incoming.edges?.length || 0
            const incomingMissing = args?.first
              ? args.first - incomingLength
              : 0
            if (incomingMissing > 0 && existing.edges.length > incomingLength) {
              // If we get fewer incoming edges than requested we assume that the lost edges were removed by a client-side update
              // One example of such an edge case is removing individual tracks by spliceManualPlaylist
              index += incomingMissing
            }
            suffix = existing.edges.slice(index)
            break // We're done now, no need to keep going through any remaining edges
          }
        }
      } else if (incoming.edges) {
        // If we have neither args.after, args.before nor start and end
        // cursors, the incoming edges cannot be spliced into the existing
        // edges, so they must replace the existing edges. See
        // https://github.com/apollographql/apollo-client/issues/6592 for a motivating example.
        prefix = []
      }

      const edges = [...prefix, ...incomingEdges, ...suffix]

      const pageInfo: RelayPageInfo = {
        // The ordering of these two ...spreads may be surprising, but it
        // makes sense because we want to combine PageInfo properties with a
        // preference for existing values, *unless* the existing values are
        // overridden by the logic below, which is permitted only when the
        // incoming page falls at the beginning or end of the data.
        ...incoming.pageInfo,
        ...existing.pageInfo,
      }

      if (incoming.pageInfo) {
        const {
          hasPreviousPage,
          hasNextPage,
          startCursor,
          endCursor,
          ...extras
        } = incoming.pageInfo

        // If incoming.pageInfo had any extra non-standard properties,
        // assume they should take precedence over any existing properties
        // of the same name, regardless of where this page falls with
        // respect to the existing data.
        Object.assign(pageInfo, extras)

        // Keep existing.pageInfo.has{Previous,Next}Page unless the
        // placement of the incoming edges means incoming.hasPreviousPage
        // or incoming.hasNextPage should become the new values for those
        // properties in existing.pageInfo. Note that these updates are
        // only permitted when the beginning or end of the incoming page
        // coincides with the beginning or end of the existing data, as
        // determined using prefix.length and suffix.length.
        if (!prefix.length) {
          if (undefined !== hasPreviousPage) {
            pageInfo.hasPreviousPage = hasPreviousPage
          }
          if (undefined !== startCursor) {
            pageInfo.startCursor = startCursor
          }
        }
        if (!suffix.length) {
          if (undefined !== hasNextPage) {
            pageInfo.hasNextPage = hasNextPage
          }
          if (undefined !== endCursor) {
            pageInfo.endCursor = endCursor
          }
        }
      }

      return {
        ...getExtras(existing),
        ...getExtras(incoming),
        edges,
        pageInfo,
      }
    },
  }
}

// Returns any unrecognized properties of the given object.
const getExtras = (obj: Record<string, any>) => __rest(obj, notExtras)
const notExtras = ['edges', 'pageInfo']

function makeEmptyData(): RelayExistingType<any> {
  return {
    edges: [],
    pageInfo: {
      hasPreviousPage: false,
      hasNextPage: true,
      startCursor: '',
      endCursor: '',
    },
  }
}
