import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
export class Toggled extends React.Component {
    static displayName = 'Toggled';
    static propTypes = {
        toggle: PropTypes.element.isRequired,
        onToggle: PropTypes.func,
        active: PropTypes.bool,
        wrapperClassName: PropTypes.string,
        avoidRendering: PropTypes.bool,
    };
    static defaultProps = {
        active: false,
        avoidRendering: false,
    };
    state = {
        active: this.props.active,
    };
    isActive() {
        return this.props.onToggle ? this.props.active : this.state.active;
    }
    handleToggle = () => {
        if (this.props.onToggle) {
            this.props.onToggle(!this.props.active);
        }
        else {
            this.setState({ active: !this.state.active });
        }
    };
    render() {
        let { toggle, onToggle, active, wrapperClassName, avoidRendering, ...props } = this.props;
        active = this.isActive();
        toggle = React.cloneElement(toggle, {
            onClick: this.handleToggle,
            className: classNames(toggle.props.className, 'toggled__trigger'),
        });
        props.className = classNames(props.className, 'toggled__target');
        wrapperClassName = classNames(wrapperClassName, 'toggled toggled--' + (active ? 'active' : 'inactive'));
        return (_jsxs("div", { className: wrapperClassName, children: [toggle, _jsx("div", { ...props, children: !active && avoidRendering ? null : this.props.children })] }));
    }
}
