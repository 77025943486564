/**
 * Wrapper for HTMLElement.contains() which works with SVG children in IE.
 *
 * SVGElement.contains is not defined in IE, but .compareDocumentPosition() is.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Node/compareDocumentPosition
 * @param parent
 * @param child
 * @param trueWhenEqual - return true if parent & child are the same element
 * @return true if child is contained in parent
 */
export function containsElement(parent, child, trueWhenEqual = false) {
    if (!parent || !child)
        return false;
    if (trueWhenEqual && parent === child)
        return true;
    return typeof parent.contains === 'function'
        ? parent.contains(child)
        : !!(parent.compareDocumentPosition(child) & 16); // contains
}
/**
 * Finds and returns the first parent node that satisfies `match`.
 *
 * Passing a string for `match` and/or `boundary` will match against class,
 * whereas functions will be called with each ancestor, stopping if the
 * function returns true.
 *
 * @param element - Starting element to traverse ancestor for
 * @param match - Ancestor to look for
 * @param boundary - Abort if found before reaching `match`
 * @return the matched element if found
 */
export function findParent(element, match, boundary = null) {
    if (typeof match === 'string') {
        const matchClass = match;
        match = (node) => node?.classList?.contains(matchClass);
    }
    if (typeof match !== 'function') {
        throw new Error(`findParent: match must be a function or class string, got ${typeof match}`);
    }
    if (typeof boundary === 'string') {
        const boundaryClass = boundary;
        boundary = (node) => node?.classList?.contains(boundaryClass);
    }
    else if (boundary && typeof boundary !== 'function') {
        const boundaryElement = boundary;
        boundary = (node) => node === boundaryElement;
    }
    let node = element;
    // eslint-disable-next-line no-unmodified-loop-condition
    while (node && (!boundary || !boundary(node))) {
        if (match(node)) {
            return node;
        }
        node = node.parentElement;
    }
    return null;
}
/**
 * Finds and returns the first interactive parent node, unless boundary is
 * reached.
 *
 * @param element - Starting element to traverse ancestor for
 * @param boundary - Abort if found before reaching `match`
 * @return the closest interactive element if found
 */
export function findInteractiveParent(element, boundary = null) {
    return findParent(element, isInteractiveElement, boundary);
}
/**
 * Finds and returns the closest element that is scrollable, traversing upwards
 * the DOM hierarchy.
 *
 * @param element - Starting element to traverse ancestors for
 * @return The closest scrollable element
 */
export function findScrollParent(element) {
    const scrollingElement = document.scrollingElement || document.documentElement;
    if (!(element instanceof HTMLElement || element instanceof SVGElement)) {
        return scrollingElement;
    }
    let style = getComputedStyle(element);
    if (style.position === 'fixed') {
        return scrollingElement;
    }
    const excludeStaticParent = style.position === 'absolute';
    const overflowRegex = /(auto|scroll|overlay)/;
    let parent = element;
    while (parent) {
        style = getComputedStyle(parent);
        if (excludeStaticParent && style.position === 'static') {
            continue;
        }
        if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
            return parent === document.body ? scrollingElement : parent;
        }
        parent = parent.parentElement;
    }
    return scrollingElement;
}
export const FOCUSABLE_ELEMENTS = ['select', 'input', 'textarea', 'label'];
export const INTERACTIVE_ELEMENTS = FOCUSABLE_ELEMENTS.concat([
    'a',
    'button',
    'details',
    'dialog',
    'form',
    'menu',
    'summary',
]);
const INTERACTIVE_ROLES = [
    'button',
    'checkbox',
    'link',
    'menuitem',
    'menuitemcheckbox',
    'menuitemradio',
    'option',
    'radio',
    'scrollbar',
    'searchbox',
    'slider',
    'spinbutton',
    'switch',
    'tab',
    'textbox',
    'treeitem',
    'combobox',
    'grid',
    'listbox',
    'menu',
    'menubar',
    'radiogroup',
    'tablist',
    'tree',
    'treegrid',
].reduce((obj, k) => {
    obj[k] = true;
    return obj;
}, {});
export function isElementType(element, types) {
    return types.includes(element.nodeName.toLowerCase());
}
export function isInteractiveRole(element) {
    const role = element.getAttribute('role');
    return !!role && INTERACTIVE_ROLES[role];
}
export function isInteractiveElement(element) {
    return (isElementType(element, INTERACTIVE_ELEMENTS) || isInteractiveRole(element));
}
export function isFocusableElement(element) {
    return isElementType(element, FOCUSABLE_ELEMENTS);
}
export function isFocusedElement(node) {
    return !!(node && document.activeElement === node);
}
/**
 * Returns the currently visible text for the given input element.
 * `null` will be returned if text cannot be determined.
 */
export function getInputVisibleText(input) {
    if (!input) {
        return null;
    }
    if (input instanceof HTMLSelectElement) {
        return input.selectedIndex < 0
            ? null
            : input.options[input.selectedIndex].text;
    }
    return input['value'] ?? null;
}
