import composeRefs from '@seznam/compose-react-refs';
import PropTypes from 'prop-types';
import * as React from 'react';
import { isForwardRef } from '../lib/isForwardRef';
import { Input } from './Input';
import { useFormContext } from './useForm';
import { useFormSpy } from './useFormSpy';
HookField.propTypes = {
    name: PropTypes.string.isRequired,
    tag: PropTypes.elementType,
    valueProp: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    fieldRef: PropTypes.object,
};
HookField.displayName = 'HookField';
/**
 * Renders a input component hooked up to the closest HookForm ancestor (or the
 * specified `form`).
 *
 * The underlying input component may be specified via `tag` (defaults to
 * `<Input>`). Injects `name`, `value` (overridable via `valueProp`),
 * `onChange`, `onFocus`, and `onBlur`, in addition to any other unknown props
 * passed to `<HookField>`.
 *
 * @example Simple `<Input>`
 * <HookForm form={form}>
 *   <HookField name="emailAddress" type="email" label="Email address" />
 * </HookForm>
 */
export function HookField({ form: propForm, tag = Input, name, validate, defaultValue, valueProp, fieldRef, ...forwardedProps }) {
    const ref = React.useRef();
    const contextForm = useFormContext();
    const form = propForm || contextForm;
    const fieldState = useFormSpy(form, name);
    const props = {
        ...forwardedProps,
        name,
        onChange: (...args) => {
            if (typeof forwardedProps.onChange === 'function') {
                // @ts-ignore
                forwardedProps.onChange(...args);
            }
            // @ts-ignore
            form.handleChange(...args);
        },
        onFocus: (event) => {
            forwardedProps.onFocus?.(event);
            form.handleFocus(event);
        },
        onBlur: (event) => {
            forwardedProps.onBlur?.(event);
            form.handleBlur(event);
        },
    };
    // Use first non-undefined value
    valueProp ||= tag['valueProp'] || 'value';
    props[valueProp] = [
        fieldState.value,
        defaultValue,
        tag['defaultValue'],
        '',
    ].find((v) => v !== undefined);
    // Attach error prop if tag is a UI form input
    if (tag['IsFormInput']) {
        props.error = fieldState.error;
    }
    const refProp = typeof tag === 'string' || isForwardRef(tag) ? 'ref' : 'fieldRef';
    props[refProp] = composeRefs(ref, fieldRef);
    React.useEffect(() => {
        if (!ref && !validate) {
            return;
        }
        form.registerField(name, {
            ref,
            validate,
        });
    }, [form, name, validate]);
    React.useEffect(() => {
        return () => {
            form.unregisterField(name);
        };
    }, [form, name]);
    return React.createElement(tag, props);
}
