import { getIn } from '@soundtrackyourbrand/object-utils.js';
export function getControlledValue(props) {
    let { value } = props;
    if (value === undefined && props.model && (props.name || props.field)) {
        value = getIn(props.model, props.name || props.field);
        if (value === undefined) {
            value = props.defaultValue;
        }
    }
    return value;
}
