import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
export const SVG_ALIGN = {
    topleft: 'xMinYMin',
    top: 'xMidYMin',
    topright: 'xMaxYMin',
    left: 'xMinYMid',
    center: 'xMidYMid',
    right: 'xMaxYMid',
    bottomleft: 'xMinYMax',
    bottom: 'xMidYMax',
    bottomright: 'xMaxYMax',
};
const iconExistsMap = {};
/**
 * Checks if a specific icon exists in the icon sprite sheet.
 *
 * @param name - Icon name
 */
export function iconExists(name) {
    if (!name) {
        return false;
    }
    const iconName = `icon-${name}`;
    return (iconExistsMap[iconName] ??= !!document.getElementById(iconName));
}
const IconComponent = React.forwardRef(({ name, fallbackName, inline, size, align, middle, ...restProps }, ref) => {
    const props = restProps;
    if (fallbackName && (!name || !iconExists(name))) {
        name = fallbackName;
    }
    const iconName = name ? `icon-${name}` : null;
    const alignValue = align ? SVG_ALIGN[align] + ' meet' : null;
    props.ref = ref;
    props.className = classNames('icon', iconName, {
        [`icon--${size}`]: !!size, // Empty is regular size (default)
        'icon--injected': !!inline,
        'icon--middle': middle,
    }, props.className);
    // Inline icons lack most functionality as we don't want to parse the markup
    if (typeof inline === 'string') {
        if (alignValue) {
            inline = inline.replace('<svg ', `<svg preserveAspectRatio="${alignValue}" `);
        }
        return (_jsx("span", { ...props, dangerouslySetInnerHTML: { __html: inline } }));
    }
    if (alignValue) {
        props.preserveAspectRatio = alignValue;
        if (iconName) {
            // Retrieve viewBox attribute from SVG sprite symbol
            const symbol = document.getElementById(iconName);
            if (symbol)
                props.viewBox = symbol.getAttribute('viewBox') || undefined;
        }
    }
    if (iconName) {
        props.children = _jsx("use", { xlinkHref: `#${iconName}`, href: `#${iconName}` });
    }
    return React.createElement('svg', props);
});
/**
 * Used to fine-tune vertical alignment of icon with adjacent text within
 * a flexbox container.
 *
 * @example
 * <div className="flex">
 *   <Icon.FlexAligner><Icon name="info" size="medium" middle /></Icon>
 *   <p className="mb-0">Text whose first line should be aligned with icon.</p>
 * </div>
 */
const FlexAligner = (props) => {
    return React.createElement('span', {
        ...props,
        className: classNames('icon-flex-aligner', props.className),
    });
};
FlexAligner.displayName = 'Icon.FlexAligner';
export const Icon = Object.assign(React.memo(IconComponent), {
    displayName: 'Icon',
    propTypes: {
        name(props, propName, componentName) {
            if (typeof props[propName] !== 'string' &&
                typeof props.inline !== 'string') {
                return new Error(`Invariant violation: Must provide either '${propName}' or 'inline' in '${componentName}'`);
            }
            return null;
        },
        fallbackName: PropTypes.string,
        inline: PropTypes.string,
        size: PropTypes.oneOf(['medium', 'large', 'extra-large']),
        align: PropTypes.oneOf(Object.keys(SVG_ALIGN)),
        middle: PropTypes.bool,
    },
    FlexAligner,
    exists: iconExists,
});
