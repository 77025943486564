import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import composeRefs from '@seznam/compose-react-refs';
import * as React from 'react';
import { isPseudoEvent } from './eventFromOnChange';
import { FieldLabel, FieldMessage } from './field-adornments';
import { getControlledValue } from './getControlledValue';
import { getFieldErrorMessage } from './getFieldErrorMessage';
import { getInputValue } from './getInputValue';
/**
 * Provides handling of input value, error and messages.
 */
export function useField({ name, field, model, label, error, errors, message, messageClassName, className, fieldRef, onChange: originalOnChange, ...props }, { ref, fieldType = 'custom', valueResolver = getInputValue, defaultValue = '', } = {}) {
    const inputRef = React.useRef(null);
    if (!name && field) {
        name = Array.isArray(field) ? field.join('.') : field;
    }
    const controlledValue = getControlledValue({
        value: props.value,
        name,
        model,
        defaultValue,
    });
    if (controlledValue !== undefined) {
        props.value = controlledValue;
    }
    error = getFieldErrorMessage({ error, errors, name });
    // Support 1-3 argument signatures: `([value], [name], event)` or `(value)`
    const onChange = originalOnChange
        ? function useFieldOnChange(...args) {
            // Final argument is expected to be an Event
            const event = args[args.length - 1];
            switch (args.length) {
                case 1:
                    // Single argument values are either assumed to be a Event or value
                    return isPseudoEvent(event)
                        ? originalOnChange(valueResolver(event.target), name, event)
                        : originalOnChange(event, name);
                case 2:
                    return originalOnChange(args[0], name, event);
            }
            // 3+ (or 0)
            return originalOnChange(...args);
        }
        : undefined;
    const classes = [
        `field field--${fieldType}`,
        error ? 'is-invalid' : 'is-valid',
        props.required && 'is-required',
        props.disabled && 'disabled',
        className,
    ].filter(Boolean);
    const messages = [];
    if (message) {
        messages.push(_jsx(FieldMessage, { className: messageClassName, children: message }, "messsage"));
    }
    if (error) {
        messages.push(_jsx(FieldMessage, { error: true, children: error }, "error"));
    }
    return {
        props: {
            ...props,
            ref: composeRefs(inputRef, fieldRef, ref),
            name,
            onChange,
        },
        label: !!label && _jsx(FieldLabel, { children: label }),
        ref: inputRef,
        wrap: (children) => (_jsxs("div", { className: classes.join(' '), children: [children, messages] })),
    };
}
