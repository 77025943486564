import * as Sentry from '@sentry/core'
import type SentryTypes from '@sentry/types'

export type LogLevel = keyof typeof logLevels
export const logLevels = {
  silly: 6,
  debug: 5,
  info: 4,
  notice: 3,
  warn: 2,
  error: 1,
  none: 0,
} as const satisfies Record<string, number>

export interface Logger {
  level: LogLevel
  transport: Transport

  scope(scope: string): Logger
  silly(message?: any, ...optionalParams: any[]): void
  debug(message?: any, ...optionalParams: any[]): void
  info(message?: any, ...optionalParams: any[]): void
  notice(message?: any, ...optionalParams: any[]): void
  warn(message?: any, ...optionalParams: any[]): void
  error(message?: any, ...optionalParams: any[]): void
  report(
    error: unknown,
    message?: string,
    addExtras?: (scope: Sentry.Scope) => Sentry.Scope,
  ): void
}
export interface Transport {
  readonly scopeName: string | undefined

  log(
    level: Exclude<LogLevel, 'none'>,
    message?: any,
    ...optionalParams: any[]
  ): void
  scope(scope: string): Transport
}

const consoleLogLevels = {
  silly: 'debug',
  debug: 'debug',
  info: 'info',
  notice: 'log',
  warn: 'warn',
  error: 'error',
} as const
const consoleTransport: Transport = {
  scopeName: undefined,
  log(level, message, ...optionalParams) {
    const method = consoleLogLevels[level]
    console[method](
      this.scopeName ? `[${this.scopeName}] ${message}` : message,
      ...optionalParams,
    )
  },
  scope(scopeName) {
    return { ...this, scopeName }
  },
}

function _log(
  level: LogLevel,
  logger: Logger,
  message?: any,
  ...optionalParams: any[]
) {
  if (logLevels[level] <= logLevels[logger.level] && level !== 'none') {
    logger.transport.log(level, message, ...optionalParams)
  } else {
    // Default `console.` calls are already captured by Sentry, but if we're
    // not logging it then we'll send logs explicitly
    const sentryLevel = getSentryLogLevel(level)
    if (!sentryLevel || process.env.NODE_ENV === 'test') return
    Sentry.addBreadcrumb({
      category: 'console',
      level: sentryLevel,
      message,
      data: {
        props: optionalParams,
        scope: logger.transport?.scopeName,
      },
    })
  }
}

/** Gets the severity level for Sentry logged. Returning `false` implies it should be skipped. */
function getSentryLogLevel(level: LogLevel): SentryTypes.SeverityLevel | false {
  switch (level) {
    case 'none':
    case 'silly':
      return false
    case 'debug':
      return 'debug'
    case 'info':
    case 'notice':
      return 'info'
    case 'warn':
      return 'warning'
    case 'error':
      return 'error'
    default:
      return 'info'
  }
}

export const log: Logger = {
  level: process.env.NODE_ENV === 'production' ? 'warn' : 'debug',
  transport: consoleTransport,

  scope(scope: string): Logger {
    return { ...this, transport: this.transport.scope(scope) }
  },
  silly(message?: any, ...optionalParams: any[]) {
    _log('silly', this, message, ...optionalParams)
  },
  debug(message?: any, ...optionalParams: any[]) {
    _log('debug', this, message, ...optionalParams)
  },
  info(message?: any, ...optionalParams: any[]) {
    _log('info', this, message, ...optionalParams)
  },
  notice(message?: any, ...optionalParams: any[]) {
    _log('notice', this, message, ...optionalParams)
  },
  warn(message?: any, ...optionalParams: any[]) {
    _log('warn', this, message, ...optionalParams)
  },
  error(message?: any, ...optionalParams: any[]) {
    _log('error', this, message, ...optionalParams)
  },
  report(
    error: unknown,
    message?: string,
    addExtras?: (scope: Sentry.Scope) => Sentry.Scope,
  ) {
    const exception =
      error instanceof Error
        ? error
        : new Error(`${(error as any)?.message ?? error}`)
    const logMessage = `${
      message ? `${message} ${exception.message}` : exception.message
    }\n${exception.stack}`
    const errorProperties = typeof error === 'object' ? { ...error } : {}
    _log('error', this, logMessage, { errorProperties })
    Sentry.captureException(exception, (scope) => {
      scope.setExtra('scope', this.transport.scopeName ?? '')
      if (addExtras) {
        return addExtras(scope)
      }
      return scope
    })
  },
}

export function scope(scope: string) {
  return log.scope(scope)
}
