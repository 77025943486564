import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { setInImmutable } from '@soundtrackyourbrand/object-utils.js';
import im from 'immutable';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Message } from '../Message';
import { isPseudoEvent } from './eventFromOnChange';
import { getInputValue } from './getInputValue';
import { httpErrorParser, multiModelErrorParser } from './httpErrorParser';
/**
 * Legacy implementation for interactive <form> with validations and tracking
 * of validation/submissions.
 *
 * @deprecated Prefer using <HookForm> instead
 */
export class Form extends React.PureComponent {
    static displayName = 'Form';
    static propTypes = {
        /*
         * validator is an object with the following functions:
         * - fieldHasErrors(errors, field)
         * - fieldsWithErrors(errors)
         * - validate(object, validations)
         *
         * Corresponds to validation/validation.js `capsule`.
         */
        validator: PropTypes.shape({
            fieldHasErrors: PropTypes.func.isRequired,
            fieldsWithErrors: PropTypes.func.isRequired,
            validationsForType: PropTypes.func.isRequired,
            validateType: PropTypes.func.isRequired,
            validate: PropTypes.func.isRequired,
        }).isRequired,
        /*
         * The actual validations. I.e. an object with functions named to
         * match the field to validate.
         */
        validations: PropTypes.object,
        /*
         * The type to validate the model as if no `validations` are given
         */
        validationType: PropTypes.string,
        validatedFieldsAreRequired: PropTypes.bool,
        /*
         * Optional error parser to use for handling HTTP errors in addition to the default one.
         */
        HttpErrorParser: PropTypes.func,
        httpErrorParser: PropTypes.func, // case insensitive
        multiModel: PropTypes.bool,
        /**
         * NOTE: Updates to the `model` prop are currently not picked up unless
         * <Form> has an onChange listener. This is somewhat by design since forms
         * would otherwise easily lose their state due to updates from
         * @soundtrackyourbrand/capsules <Fetch> and similar components.
         *
         * Use a `key` property on the <Form> to force updates if you really need to.
         */
        model: PropTypes.object,
        /** Called via onChange(field, value), where field may be a string[] or dot.separated.path. */
        onChange: PropTypes.func,
        onErrors: PropTypes.func,
        onSubmit: PropTypes.func,
        nested: PropTypes.bool,
    };
    static IsFormInput = true;
    constructor(props, context) {
        super(props, context);
        // References to form fields
        this.fields = {};
        this._mounted = true;
        this.state = this.getInitialState();
    }
    getInitialState() {
        return {
            loading: false,
            validationErrors: null,
            errors: this.props.errors,
            model: this.props.model || im.Map(),
        };
    }
    static getDerivedStateFromProps(props, state) {
        const derived = {};
        if (props.onChange && props.model !== state.model) {
            derived.model = props.model;
            if (state.validationErrors) {
                derived.validationErrors = props.validator.validate(derived.model, getValidations(props));
            }
        }
        if (props.errors !== state.errors && props.errors !== undefined) {
            derived.errors = props.errors;
        }
        return derived;
    }
    componentWillUnmount() {
        this._mounted = false;
    }
    get model() {
        return this.props.onChange ? this.props.model : this.state.model;
    }
    reset = () => {
        this.setState(this.getInitialState());
    };
    validate = (model = this.model) => {
        return this.props.validator.validate(model, getValidations(this.props));
    };
    handleSubmit = (event) => {
        event.preventDefault();
        const validationErrors = this.validate(this.model);
        this.setState({ validationErrors, errors: null });
        if (validationErrors) {
            this.focusFirstError(validationErrors);
            this.props.onErrors && this.props.onErrors(validationErrors);
            return;
        }
        const ret = this.props.onSubmit(this.model, event);
        if (ret && typeof ret.then === 'function') {
            this.setState({ loading: true });
            ret
                .then((arg) => {
                this._mounted && this.setState({ loading: false });
                return arg;
            })
                .catch(this.handleSubmitError);
        }
    };
    handleSubmitError = (err) => {
        const defaultParser = this.props.multiModel
            ? multiModelErrorParser
            : httpErrorParser;
        let errors = defaultParser.call(this, err);
        const propsParser = this.props.HttpErrorParser || this.props.httpErrorParser;
        if (propsParser) {
            errors = propsParser(err, errors);
        }
        this._mounted && this.setState({ errors, loading: false });
        if (typeof err.name === 'string' && err.name !== 'Error') {
            // Rethrow errors
            throw err;
        }
    };
    focusFirstError(errs) {
        if (!this._mounted) {
            return;
        }
        const fieldsWithErrors = this.props.validator.fieldsWithErrors(errs);
        for (const field of fieldsWithErrors) {
            if (this.fields[field]) {
                this.fields[field].focus();
                break;
            }
        }
    }
    handleChildChange(originalOnChange, value, field, event) {
        if (isPseudoEvent(value)) {
            event = value;
            value = getInputValue(event.target);
            field ||= event.target.name;
        }
        else if (isPseudoEvent(field)) {
            event = field;
            field = event.target.name;
        }
        if (typeof field !== 'string' && !Array.isArray(field)) {
            console.error(`Form.handleChildChange: Field path couldn't be determined`, { field, value, event });
            return;
        }
        if (typeof originalOnChange === 'function') {
            originalOnChange(value, field, event);
        }
        if (this.props.onChange) {
            return this.props.onChange(value, field, event);
        }
        const updated = setInImmutable(this.model, field, value);
        if (this.state.validationErrors) {
            this.setState({
                model: updated,
                validationErrors: this.validate(updated),
            });
        }
        else {
            this.setState({ model: updated });
        }
    }
    setupChildren(nodes, errors, validations) {
        const model = this.model;
        return React.Children.map(nodes, (node) => {
            if (!React.isValidElement(node)) {
                return node;
            }
            const props = {};
            if (node.type.IsFormInput) {
                props.onChange = this.handleChildChange.bind(this, node.props && node.props.onChange);
                props.errors = errors;
                if (node.props.field) {
                    props.fieldRef = (ref) => (this.fields[node.props.field] = ref);
                    if (this.props.validatedFieldsAreRequired &&
                        node.props.required == null &&
                        validations &&
                        validations[node.props.field]) {
                        props.required = true;
                    }
                }
                if (!node.props.model) {
                    props.model = model;
                }
            }
            else if (node.props && node.props.children) {
                props.children = this.setupChildren(node.props.children, errors, validations);
            }
            /*
             * Attach `loading` prop to components that accept it
             * and set other components to `disabled` while loading
             */
            if (typeof node.type !== 'string') {
                if (node.props.primary &&
                    node.type.propTypes &&
                    node.type.propTypes.loading) {
                    props.loading = this.state.loading;
                }
                else if (!node.props.disabled) {
                    props.disabled = this.state.loading;
                }
            }
            return React.cloneElement(node, props);
        }, this);
    }
    handleDismissError = () => {
        const errors = Object.assign({}, this.state.errors);
        delete errors.__global;
        this.setState({ errors });
    };
    render() {
        const { validator, validations, validationType, validatedFieldsAreRequired, HttpErrorParser, httpErrorParser, multiModel, model, errors, onChange, onErrors, nested, ...props } = this.props;
        const allErrors = {};
        [this.state.errors, this.state.validationErrors].forEach((errors) => {
            for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key) &&
                    Array.isArray(errors[key]) &&
                    errors[key].length) {
                    allErrors[key] = allErrors[key]?.concat(errors[key]) || errors[key];
                }
            }
        });
        const Tag = nested ? 'div' : 'form';
        if (!nested) {
            props.onSubmit = this.handleSubmit;
            props.noValidate = true;
        }
        return (_jsxs(Tag, { ...props, children: [allErrors && !!allErrors.__global && (_jsx(Message, { type: "error", onDismiss: this.handleDismissError, children: allErrors.__global })), this.setupChildren(this.props.children, allErrors, getValidations(this.props))] }));
    }
}
function getValidations(props) {
    return (props.validations ||
        props.validator.validationsForType(props.validationType) ||
        {});
}
