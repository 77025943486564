/**
 * Function to retry an action until success with a backoff between attempts
 *
 * After calling start, the attempt function is repeatedly called until
 * it resolves sucessfully.
 * If you need to manually restart attempts, call the returned retry function.
 *
 * Calling stop will stop any scheduled retry attempts and prevent any further
 * ones until start is called again.
 */
export function retryWithBackoff(options: {
  attempt(attempt: number): Promise<void>
  /**
   * The first retry attempt will happen after min millisseconds and then
   * raise in a fibonacci sequence from up to max.
   */
  backOff: { min: number; max: number }
  /**
   * Percentage of random delay to add to avoid a self-ddos if we are having
   * server issues
   *
   * @default 0.5 (50%)
   */
  jitter?: number
}): {
  start(): void
  stop(): void
  /**
   * Call when you need to restart attemps, for example when an error happens
   * in a subscription that's created in connect.
   * This function is safe to call repeatedly at any time, calling when an
   * attempt is already scheduled will just reschedule the attempt and calling
   * when an attempt is ongoing is a no-op.
   */
  retry(): void
  setJitterFactor(newJitterFactor: number): void
} {
  let jitter = options.jitter ?? 0.5
  let attemptTimeout: undefined | NodeJS.Timeout
  let attempts = 0
  let isStopped = false
  let isRunningAttempt = false

  function scheduleAttempt() {
    if (isStopped || isRunningAttempt) return
    if (attemptTimeout) {
      // Prevent simultaneous attempts
      clearTimeout(attemptTimeout)
    }
    const attempt = attempts
    let delay = Math.min(
      fib(attempt) * options.backOff.min,
      options.backOff.max,
    )
    delay += delay * jitter * Math.random()
    attemptTimeout = setTimeout(async () => {
      attempts += 1
      if (isStopped) return
      try {
        isRunningAttempt = true
        await options.attempt(attempt)
        isRunningAttempt = false
        attempts = 0
      } catch {
        isRunningAttempt = false
        scheduleAttempt()
      }
    }, delay)
  }

  return {
    start: () => {
      isStopped = false
      attempts = 0
      scheduleAttempt()
    },
    stop() {
      isStopped = true
      if (attemptTimeout) clearTimeout(attemptTimeout)
    },
    retry: () => {
      if (attempts === 0) attempts = 1
      scheduleAttempt()
    },
    setJitterFactor(newJitterFactor: number) {
      jitter = newJitterFactor
    },
  }
}

// Linear time algoritm from https://wiki.c2.com/?FibonacciSequence
export function fib(n: number) {
  // This algortim starts of as 1, 1, 0, 1, 1, 2, 3, 5, etc.
  // By adding two we can realign it to start as expected
  n += 2
  let m = 1
  let k = 0

  for (let i = 1; i < n; i++) {
    const tmp = m + k
    m = k
    k = tmp
  }
  return m
}
