import {
  type ApolloClient,
  type ApolloQueryResult,
  type DocumentNode,
  type OperationVariables,
  type QueryHookOptions,
  type QueryOptions,
  type QueryResult,
  type TypedDocumentNode,
  useQuery,
} from '@apollo/client'
import ms from 'ms'
// import { AppState } from 'react-native'
import * as React from 'react'
import { getMainDefinition } from './getMainDefinition'
import { type FetchAllOptions } from './link'

/** Default {@link QueryTtlOptions} `ttl` value in milliseconds */
const DEFAULT_TTL = 10 * 60e3

/**
 * Options for {@link queryTtl} and {@link useQueryTtl}.
 *
 * @group TTL-based Queries
 */
export interface QueryTtlOptions {
  /**
   * Unique ID for used for tracking TTL, defaults to name of first operation
   * in provided GraphQL doc
   */
  ttlId?: string
  /** Time-to-live - milliseconds if number, parsed by {@link https://github.com/vercel/ms `ms(ttl)`} if string */
  ttl?: number | string
  /** Track queries with different variables separately (default = true) */
  ttlVary?: boolean
}

/**
 * Options for {@link useQueryTtl}.
 *
 * @group TTL-based Queries
 */
export interface UseQueryTtlOptions extends QueryTtlOptions {
  /** Enable polling - refetch query whenever TTL expires while component is mounted (default = false) */
  ttlPoll?: boolean
  /** If unexpected GraphQL errors should be automatically reported (default = true) */
  reportErrors?: boolean
}

/**
 * Simplified abstraction of `NavigationContext` from
 * `@react-navigation/native`, referenced by {@link useQueryTtl}.
 *
 * @group TTL-based Queries
 * @private
 */
export type NavigationContextType = {
  addListener: (event: 'focus' | 'blur', callback: () => void) => () => void
}

/**
 * Optional overridable navigation context.
 * Used by {@link useQueryTtl} to subscribe to focus/blur events to enable/disable polling.
 * Must be overridden to provide any functionality.
 *
 * @group TTL-based Queries
 * @example
 * Usage in react-native together with react-navigation
 * ```ts
 * import { NavigationContext } from '@react-navigation/native'
 * import { useQueryTtl } from '@soundtrackyourbrand/apollo-client'
 * useQueryTtl.NavigationContext = NavigationContext
 * ```
 */
useQueryTtl.NavigationContext = React.createContext<
  NavigationContextType | undefined
>(undefined)

/**
 * Simplified abstraction of `AppState` from `react-native`, referenced by
 * {@link useQueryTtl}.
 *
 * @group TTL-based Queries
 * @private
 */
export type AppStateListenerType = (
  callback: (state: 'active' | string) => void,
) => () => void

/**
 * Optional overridable app state listener.
 * Used by {@link useQueryTtl} to subscribe to app state changes to enable/disable polling.
 * Must be overridden to provide any functionality.
 *
 * @group TTL-based Queries
 * @example
 * Usage in react-native
 * ```ts
 * import { AppState } from 'react-native'
 * import { useQueryTtl } from '@soundtrackyourbrand/apollo-client'
 * useQueryTtl.addAppStateListener = (cb) => {
 *   return AppState.addEventListener('change', cb).remove
 * }
 * ```
 */
useQueryTtl.addAppStateListener = undefined as AppStateListenerType | undefined

/**
 * Extension of {@link useQuery} hook that adds TTL (Time-to-live) support.
 * TTL is specified using the `ttl` parameter, and defaults to 10 minutes (`10m`).
 *
 * Stale data is still returned (if present) after the TTL has expired,
 * but a network request will be triggered in the background via `query.refetch()`.
 *
 * Queries are distinguished via the `ttlId` parameter, which means that it must
 * be unique with regards to your GraphQL query. It is generated by {@link ttlIdFor}
 * by default. The query `variables` will be appended to this id unless `ttlVary: false`.
 *
 * Enable `ttlPoll` to enable "polling" every TTL "window" in addition to
 * conditionally triggering a refetch on mount.
 *
 * See {@link UseQueryTtlOptions} and {@link QueryTtlOptions} for available options.
 *
 * @group TTL-based Queries
 * @example
 * ```ts
 * import { useQueryTtl } from '@soundtrackyourbrand/apollo-client'
 * const query = useQueryTtl(MyQueryDoc, {
 *   ttl: 120e3, // refetch on mount if last result is older than this (in ms)
 *   ttlPoll: true, // optionally refetch every `ttl` ms while mounted
 * })
 * ```
 */
export function useQueryTtl<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: QueryHookOptions<TData, TVariables> & UseQueryTtlOptions,
): QueryResult<TData, TVariables> {
  const {
    ttlId: ttlIdInput,
    ttl = DEFAULT_TTL,
    ttlVary = true,
    ttlPoll = false,
    reportErrors = true,
    ...queryOptions
  } = options
  queryOptions.context ??= { reportErrors }
  queryOptions.context.reportErrors ??= reportErrors

  const ttlId = ttlIdFor(
    ttlIdInput || query,
    ttlVary
      ? { variables: options.variables, fetchAll: options.context?.fetchAll }
      : undefined,
  )

  // Used to pause refetching when in the background
  const navigationContext = React.useContext(useQueryTtl.NavigationContext)

  function onCompleted(data: TData): Promise<ApolloQueryResult<TData>> | null {
    // onCompleted only runs **once** until this is fixed:
    // https://github.com/apollographql/apollo-client/issues/5531
    if (ctx.current.initialFetchTriggered) {
      // Only re-schedule if initial fetch has been comlpeted
      const ttlMs = updateTtlFor(ttlId, ttl)
      scheduleRefetch(ttlMs)
    } else if (!ttlPoll && !ctx.current.initialFetchViaTtl) {
      // We're not polling and data has already been fetched
      clearTimeout(ctx.current.refetchTimeout)
    }
    return options.onCompleted?.(data) || null
  }

  queryOptions.onCompleted = onCompleted
  const q = useQuery(query, queryOptions)

  const ctx = React.useRef({
    mounted: true,
    onCompleted,
    scheduleRefetch,
    refetchTimeout: undefined as any,
    initialFetchTriggered: q.loading,
    initialFetchViaTtl: !q.loading && ttlDeltaFor(ttlId) <= 0,
  })
  ctx.current.onCompleted = onCompleted
  ctx.current.scheduleRefetch = scheduleRefetch

  function scheduleRefetch(delay: number) {
    clearTimeout(ctx.current.refetchTimeout)
    if (
      !ctx.current.mounted ||
      options.skip ||
      !ttlId ||
      (ctx.current.initialFetchTriggered && !ttlPoll)
    ) {
      // console.debug('useQueryTtl', ttlId, 'unscheduling')
      return
    }
    // console.debug('useQueryTtl', ttlId, 'scheduling', delay)
    ctx.current.refetchTimeout = setTimeout(
      () => {
        const latestDelay = ttlDeltaFor(ttlId)
        if (latestDelay > 100) {
          // console.debug('useQueryTtl', ttlId, 'rescheduling in', latestDelay)
          scheduleRefetch(latestDelay)
        } else {
          // console.debug('useQueryTtl', ttlId, 'automatic refetch')
          q.refetch()
            .then((result) => {
              ctx.current.initialFetchTriggered = true
              ctx.current.initialFetchViaTtl = false
              // Only necessary due to `onCompleted` not being called after refetch()
              ctx.current.onCompleted(result.data)
            })
            .catch((error) => {
              // Swallow GraphQLErrors to avoid "uncaught promise rejection",
              // they're handled by useQuery() anyway
            })
        }
      },
      Math.max(0, delay),
    )
  }

  React.useEffect(() => {
    if (!ttlId) {
      return
    }

    // Re-schedule refetch on mount and when ttlId changes
    ctx.current.scheduleRefetch(ttlDeltaFor(ttlId))

    const subscriptions: Array<() => void> = []

    if (useQueryTtl.addAppStateListener) {
      let currentAppState = ''
      subscriptions.push(
        useQueryTtl.addAppStateListener((state) => {
          const wasActive = currentAppState === 'active'
          const isActive = state === 'active'
          currentAppState = state
          if (isActive !== wasActive) {
            // console.debug('useQueryTtl', ttlId, isActive ? 'scheduling via app focus' : 'unscheduling via app blur')
            if (isActive) {
              ctx.current.scheduleRefetch(ttlDeltaFor(ttlId))
            } else {
              clearTimeout(ctx.current.refetchTimeout)
            }
          }
        }),
      )
    }

    if (navigationContext) {
      // Only schedule refetches while react-navigation screen is focused
      subscriptions.push(
        navigationContext.addListener('focus', () => {
          // console.debug('useQueryTtl', ttlId, 'scheduling via focus')
          ctx.current.scheduleRefetch(ttlDeltaFor(ttlId))
        }),
        navigationContext.addListener('blur', () => {
          // console.debug('useQueryTtl', ttlId, 'unscheduling via blur')
          clearTimeout(ctx.current.refetchTimeout)
        }),
      )
    }

    return () => {
      subscriptions.forEach((unsubscribe) => unsubscribe())
    }
  }, [ttlId, navigationContext])

  React.useEffect(() => {
    const context = ctx.current
    return () => {
      // We always want to clear the most recent timeout
      clearTimeout(context.refetchTimeout)
      context.mounted = false
    }
  }, [navigationContext])

  return {
    ...q,
    // Only necessary to wrap this function due to apollo not calling
    // `onCompleted` if the data hasn't changed
    refetch: React.useCallback(() => {
      // console.debug('useQueryTtl', ttlId, 'manual refetch')
      return q.refetch().then((result) => {
        ctx.current.initialFetchTriggered = true
        ctx.current.initialFetchViaTtl = false
        ctx.current.onCompleted(result.data)
        return result
      })
    }, [q.refetch]), // eslint-disable-line react-hooks/exhaustive-deps
  }
}

/**
 * Extended version `apollo.query()` that adds TTL (Time-to-live) support.
 * TTL is specified using the `ttl` parameter, and defaults to 10 minutes (`10m`).
 *
 * If an identical query (with the same explicit ID or variables) have been
 * returned in less than the specified TTL window, the cached result will be
 * returned immediately (as if `cache-first` was used). Otherwise a
 * `network-only` request will be triggered.
 *
 * Queries are distinguished via the `ttlId` parameter, which means that it must
 * be unique with regards to your GraphQL query. It is generated by {@link ttlIdFor}
 * by default. The query `variables` will be appended to this id unless `ttlVary: false`.
 *
 * If you prefer not having to pass the `apollo` instance to the function on
 * each invocation you can instead opt to inject `queryTtl()` into the apollo
 * instance itself using {@link augmentApolloClient}.
 *
 * See {@link QueryTtlOptions} for available options.
 *
 * @group TTL-based Queries
 * @example
 * ```ts
 * import { apollo } from 'app/apollo'
 * import { queryTtl } from '@soundtrackyourbrand/apollo-client'
 * queryTtl(apollo, {
 *   query: MyQueryDoc,
 * }).then(result => { console.log(result.data) })
 * ```
 */
export function queryTtl<
  T = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  apolloInstance: ApolloClient<any>,
  options: QueryOptions<TVariables, T> & QueryTtlOptions,
): Promise<ApolloQueryResult<T>> {
  const {
    ttlId: ttlIdInput,
    ttl = DEFAULT_TTL,
    ttlVary = true,
    ...queryOptions
  } = options
  const ttlId = ttlIdFor(
    ttlIdInput || options.query,
    ttlVary
      ? { variables: options.variables, fetchAll: options.context?.fetchAll }
      : undefined,
  )
  const shouldFetch = ttlDeltaFor(ttlId) <= 0
  return apolloInstance
    .query({
      ...queryOptions,
      fetchPolicy: shouldFetch ? 'network-only' : 'cache-first',
    })
    .then((result) => {
      shouldFetch && updateTtlFor(ttlId, ttl)
      return result
    })
}

export type QueryTtlFunction = <
  T = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  options: QueryOptions<TVariables, T> & QueryTtlOptions,
) => Promise<ApolloQueryResult<T>>

export interface AugmentedApolloClient<Cache = any>
  extends ApolloClient<Cache> {
  /**
   * Provided by `@soundtrackyourbrand/apollo-client` through {@link augmentApolloClient}.
   * {@link queryTtl} function bound to this Apollo instance.
   *
   * Extended version of `apollo.query()` that adds TTL (Time-to-live) support.
   * TTL is specified using the `ttl` parameter, and defaults to 10 minutes (`10m`).
   *
   * If an identical query (with the same explicit ID or variables) have been
   * returned in less than the specified TTL window, the cached result will be
   * returned immediately (as if `cache-first` was used). Otherwise a
   * `network-only` request will be triggered.
   *
   * Queries are distinguished via the `ttlId` parameter, which means that it must
   * be unique with regards to your GraphQL query. It is generated by {@link ttlIdFor}
   * by default. The query `variables` will be appended to this id unless `ttlVary: false`.
   *
   * See {@link QueryTtlOptions} for available options.
   *
   * @group TTL-based Queries
   * @example
   * ```ts
   * import { apollo } from 'app/apollo'
   * import { queryTtl } from '@soundtrackyourbrand/apollo-client'
   *
   * queryTtl(apollo, {
   *   query: MyQueryDoc,
   * }).then(result => { console.log(result.data) })
   * ```
   */
  queryTtl: QueryTtlFunction
}

/**
 * Augments a {@link ApolloClient} instance with additional APIs:
 * - apollo.{@link queryTtl}()
 *
 * Make sure to define a properly typed `useApolloClient()` if you intend on
 * using these APIs from within react. See the example code below for how to do so.
 *
 * @group TTL-based Queries
 * @example
 * ```ts
 * import { ApolloClient, useApolloClient } from '@apollo/client'
 * import { augmentApolloClient } from '@soundtrackyourbrand/apollo-client'
 * export const apollo = augmentApolloClient(new ApolloClient({ ... }))
 * export const useApolloClient = Apollo.useApolloClient as () => typeof apollo
 *
 * apollo.queryTtl({
 *   query: MyQueryDoc,
 *   ttl: '1m',
 * }).then(result => { console.log(result.data) })
 * ```
 */
export function augmentApolloClient<Cache = any>(
  client: ApolloClient<Cache>,
): AugmentedApolloClient<Cache> {
  Object.assign(client, {
    queryTtl: (options: any) => queryTtl(client, options),
  })
  return client as any
}

/** Stores timestamp for when a given query Id was last requested */
const ttlQueryIdTimestamps = new Map<string, number>()

/**
 * Computes the TTL ID for a given query.
 *
 * Will include the query variables in the final ID if provided - do not pass
 * `variables` if `ttlVary=false` in the original query.
 * Omits {@link FetchAllLink} variables from the final ID if `fetchAll` config
 * is provided.
 *
 * @group TTL-based Queries
 */
export function ttlIdFor(
  /** Query operation name or AST object containing a single GraphQL query */
  queryOrOperationName: DocumentNode | string,
  /** Additional properties that is used to determine the final ID */
  params?: {
    variables?: Record<string, any>
    fetchAll?: FetchAllOptions
  },
): string {
  let ttlId =
    typeof queryOrOperationName === 'string'
      ? queryOrOperationName
      : (getMainDefinition(queryOrOperationName).name?.value as string)
  if (!ttlId) {
    throw Object.assign(
      new Error(`No 'ttlId' provided and unable to infer from query`),
      {
        ttlId,
        queryOrOperationName,
      },
    )
  }

  let variables = params?.variables
  if (variables) {
    if (params?.fetchAll) {
      // Exclude $first & $after from composed ttlId when `fetchAll` support is used
      variables = Object.assign({}, variables)
      delete variables[params.fetchAll.limitVar || 'first']
      delete variables[params.fetchAll.cursorVar || 'after']
    }
    ttlId += '@' + JSON.stringify(variables, jsonStringifyStableReplacer)
  }

  return ttlId
}

/**
 * Marks the given query ID as stale.
 *
 * Will include the query variables in the final ID if provided - do not pass
 * `variables` if `ttlVary=false` in the original query.
 * Omits {@link FetchAllLink} variables from the final ID if `fetchAll` config
 * is provided.
 *
 * Disclaimer: calling this function will not automatically trigger a refetch of
 * any active queries.
 *
 * @group TTL-based Queries
 * @protected
 */
export function invalidateQueryTtl(
  /** Query operation name or AST object containing a single GraphQL query */
  queryOrOperationName: DocumentNode | string,
  /** Additional properties that is used to determine the final ID */
  params?: {
    variables?: Record<string, any>
    fetchAll?: FetchAllOptions
  },
): boolean {
  return ttlQueryIdTimestamps.delete(ttlIdFor(queryOrOperationName, params))
}

/**
 * Returns TTL cache in case the consumer needs to inspect it.
 *
 * @group TTL-based Queries
 * @protected
 */
export function getQueryTtls() {
  return ttlQueryIdTimestamps
}

/**
 * Resets TTL cache, thereby marking all queries as stale.
 *
 * Disclaimer: calling this function will not automatically trigger a refetch of
 * any active queries. Use `apollo.resetStore()` to clear all cached data and
 * trigger refetches of active queries.
 *
 * @group TTL-based Queries
 * @protected
 */
export function resetQueryTtl(): void {
  ttlQueryIdTimestamps.clear()
}

/**
 * Updates the expiration time (TTL entry) for a given query ID.
 * `ttl` is milliseconds if number, parsed by {@link https://github.com/vercel/ms `ms(ttl)`} if string.
 *
 * Returns the new TTL in milliseconds.
 *
 * @group TTL-based Queries
 * @protected
 */
export function updateTtlFor(
  ttlId: string,
  ttl: string | number,
  now = Date.now(),
): number {
  const ttlMs = typeof ttl === 'string' ? ms(ttl) : ttl
  ttlQueryIdTimestamps.set(ttlId, now + ttlMs)
  return ttlMs
}

/**
 * Returns the expiration time (TTL) for a given query ID.
 *
 * @group TTL-based Queries
 * @protected
 */
export function ttlDeltaFor(ttlId: string, now = Date.now()): number {
  const expiresAt = ttlQueryIdTimestamps.get(ttlId)
  return expiresAt ? expiresAt - now : 0
}

/**
 * Used with `JSON.stringify(obj, jsonStringifyStableReplacer)` to return
 * a stable JSON representation of `obj`, irregardless of initial property order.
 *
 * @group TTL-based Queries
 * @private
 */
function jsonStringifyStableReplacer(_key: string, value: any): any {
  if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
    value = Object.keys(value)
      .sort()
      .reduce(
        (copy, key) => {
          copy[key] = value[key]
          return copy
        },
        {} as Record<string, any>,
      )
  }
  return value
}
