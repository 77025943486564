import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { fieldComponent } from './fieldComponent';
import { useField } from './useField';
export const Checkbox = fieldComponent(({ label, title, isSwitch = false, ...props }, ref) => {
    const field = useField(props, {
        ref,
        fieldType: isSwitch ? 'switch' : 'checkbox',
        valueResolver: checkboxValueResolver,
        defaultValue: false,
    });
    // `value` prop of type boolean manages `checked`
    if (typeof field.props.value === 'boolean') {
        field.props.checked ||= field.props.value;
        delete field.props.value;
    }
    const input = (_jsx("input", { ...field.props, type: "checkbox", className: isSwitch ? 'switch' : undefined }));
    return field.wrap(label ? (_jsxs("label", { className: "inline-label", title: title, children: [input, label] })) : (input));
}, {
    displayName: 'Checkbox',
    defaultValue: false,
});
function checkboxValueResolver(element) {
    return element.checked;
}
