import {
  ApolloLink,
  type FetchResult,
  Observable,
  type Operation,
} from '@apollo/client/core'
import { type ExecutionResult, type GraphQLError, print } from 'graphql'
import { type Client, type ClientOptions, createClient } from 'graphql-ws'
import { type ApolloErrorExtra } from './errorLink'

/**
 * {@link ApolloLink} that enables execution over websockets.
 *
 * Adapted from {@link https://github.com/enisdenjo/graphql-ws graphql-ws}.
 *
 * Socket is automatically connected unless `options.autoconnect = false`, in
 * which case `.connect()` must be called manually. Pass `options.lazy = true`
 * to defer connection until the first incoming request.
 *
 * @group Apollo Links
 */
export class WebSocketLink extends ApolloLink {
  public client?: Client
  /** Optional callback triggered on graceful restarts  */
  public onRestart?: () => void
  /** Timestamp of most recent response from server */
  public lastInteractionAt = 0
  private socket: WebSocket | undefined
  private restartRequestedBeforeConnect = false

  constructor(private options: ClientOptions & { autoconnect?: boolean }) {
    super()
    if (options.autoconnect !== false) {
      this.connect()
    }
  }

  /**
   * (Re)connects the websocket.
   */
  public connect() {
    let timedOut: any
    this.disconnect()
    this.client = createClient({
      keepAlive: 10e3,
      connectionAckWaitTimeout: 10e3,
      ...this.options,
      shouldRetry: (event: any) => {
        /**
         * Do not automatically retry on terminations (manually triggered by ping/pong timeouts) or fatal errors
         * See {@link ClientOptions} `retryAttempts` jsdoc for a detailed list of fatal errors.
         */
        const code = event?.code
        if ([4499, 4400, 4401, 4406, 4429].includes(code)) {
          return false // Avoid silently retrying and instead let caller handle retries
        }
        return this.options.shouldRetry?.(event) || true
      },
      on: {
        connected: (socket) => {
          this.socket = socket as WebSocket
          if (this.restartRequestedBeforeConnect) {
            this.restartRequestedBeforeConnect = false
            this.gracefullyRestart()
          }
        },
        ping: (received) => {
          if (!received) {
            timedOut = setTimeout(() => {
              if (this.socket && this.socket.readyState === WebSocket.OPEN) {
                this.socket.close(4408, 'Request Timeout')
                this.client?.terminate()
              }
            }, 5e3)
          }
        },
        pong: (received) => {
          if (received) {
            clearTimeout(timedOut)
          }
        },
        message: (message) => {
          this.lastInteractionAt = Math.max(this.lastInteractionAt, Date.now())
        },
      },
    })
  }

  /**
   * Disconnects the websocket if it is currently open.
   * Call {@link connect} to reconnect.
   */
  public disconnect() {
    this.client?.dispose()
    this.client = undefined
    this.socket = undefined
  }

  /**
   * Gracefully closes the connection if it is currently open.
   * It will be reconnected automatically if there are any active subscriptions.
   *
   * Adapted from the following example:
   * https://github.com/enisdenjo/graphql-ws/issues/105#issuecomment-772680343
   */
  public gracefullyRestart() {
    if (this.socket?.readyState === WebSocket.OPEN) {
      this.socket.close(4205, 'Client Restart')
      this.onRestart?.()
    }
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      const sinkError = (
        error: Error,
        additionalProps?: Partial<ApolloErrorExtra['gqlContext']>,
      ) => {
        Object.assign(error, {
          lastInteractionAt: this.lastInteractionAt,
          gqlContext: {
            identifier: [operation.operationName],
            operation: operation.operationName,
            variables: operation.variables,
            ...additionalProps,
          },
        })
        return sink.error(error)
      }

      if (!this.client) {
        return sinkError(
          new Error(
            `WebSocketLink received request while socket is disconnected`,
          ),
        )
      }

      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          // ExecutionResult type bypass is because of wrong type in graphql-ws,
          // see https://github.com/apollographql/apollo-client/blob/1f0333e1644cb95b6862441ff957e60ec5f7a59e/src/link/subscriptions/index.ts#L85
          next: sink.next.bind(sink) as any satisfies ExecutionResult<
            FetchResult,
            unknown
          >,
          complete: sink.complete.bind(sink),
          error: (err) => {
            if (!err || typeof err !== 'object') {
              return sinkError(new Error('Socket closed with unknown event'))
            }
            if (err instanceof Error) {
              return sinkError(err)
            }

            const closeErr = err as CloseEvent
            if (
              (closeErr as any).name === 'CloseEvent' ||
              closeErr.type === 'close' || // native close event
              closeErr.code === 4499 || // timeouts propagated via `client.terminate()`
              (typeof Event !== 'undefined' && err instanceof Event) // browser socket timeout
            ) {
              return sinkError(
                // reason will be available on clean closes
                new Error(
                  `Socket closed with event ${closeErr.code || 0} ${
                    closeErr.reason || ''
                  }`,
                ),
                {
                  code: closeErr.reason,
                  status: closeErr.code,
                  isExpected: true,
                },
              )
            }
            if (err instanceof Array) {
              return sinkError(
                new Error(
                  (err as GraphQLError[])
                    .map(({ message }) => message)
                    .join(', '),
                ),
                {
                  cause: err[0],
                  graphqlErrors: err,
                } as any,
              )
            }

            return sinkError(
              new Error(
                (err as any).message || 'Socket closed with unknown event',
              ),
            )
          },
        },
      )
    })
  }
}
