import {
  ApolloLink,
  type FetchResult,
  type NextLink,
  Observable,
  type Operation,
} from '@apollo/client'
import * as Sentry from '@sentry/core'
import { uuid4 } from '@sentry/utils'
import { type ASTNode, type DocumentNode, visit } from 'graphql'
import { getMainDefinition } from '../getMainDefinition'

// Augment ApolloClient interfaces
declare module '@apollo/client' {
  export interface DefaultContext {
    /**
     * trace id for the request, will be automatically created by the tracing link
     * if not set. Set to false to disable tracing for this request.
     */
    traceId?: string | false
  }
}

/**
 * {@link ApolloLink} that injects a `traceparent` (configurable) header into
 * all requests, unless `context.traceId` is set to `false`.
 *
 * NOTE: This link should be placed first in the link chain
 *
 * The generated id can be selected in the query through the root field
 * `traceId` (configurable). For GraphQL tooling to not raise errors on
 * queries with this field selected it must be added to the schema by including
 * {@link https://github.com/soundtrackyourbrand/apollo-client/blob/main/example/traceId.graphql example/traceId.graphql}
 * in the graphql schema config:
 *
 * ```yaml
 * schema:
 *   - 'https://api.soundtrackyourbrand.com/v2'
 *   - './node_modules/@soundtrackyourbrand/apollo-client/example/traceId.graphql'
 * ```
 *
 * @example
 * ```ts
 * const MyQueryDoc = gql(`
 *   query MyQuery {
 *     traceId
 *     # ... additional fields to select
 *   }
 * `)
 *
 * apollo.query({ query: MyQueryDoc }).then(result => {
 *   console.log(result.data.traceId)
 * })
 * ```
 *
 * @group Apollo Links
 */
export class TracingLink extends ApolloLink {
  options: {
    headerName: string
    fieldName: string
  }

  constructor(options?: { headerName?: string; fieldName?: string }) {
    super()
    this.options = Object.assign(
      {
        headerName: 'traceparent',
        fieldName: 'traceId',
      },
      options,
    )
  }

  request(operation: Operation, forward: NextLink): Observable<FetchResult> {
    const def = getMainDefinition(operation.query)
    const ctx = operation.getContext()

    if (ctx.traceId === false) {
      return forward(operation)
    }

    const traceId = uuid4()
    return Sentry.startSpanManual(
      {
        name: def.name?.value ?? 'anonymous',
        op: `http.graphql.${def.operation}`,
        attributes: { traceId },
      },
      (span, finish) => {
        const spanContext = span.spanContext()
        const traceparent = `00-${traceId}-${spanContext.spanId}-01`

        ctx.traceId = traceId
        ctx.headers ||= {}
        ctx.headers[this.options.headerName] = traceparent
        // Continue sending x-tracking-id until the backend no longer depends on it
        ctx.headers['x-tracking-id'] = traceId
        operation.setContext(ctx)

        // Check if we also select Query.traceId
        const requestsTrackingId =
          ['query', 'mutation'].includes(def.operation) &&
          def.selectionSet.selections.some(
            (s) =>
              s.kind === 'Field' &&
              (s.alias?.value || s.name.value) === this.options.fieldName,
          )

        const fieldName = this.options.fieldName
        return new Observable((observer) => {
          if (requestsTrackingId) {
            // Remove the id field from selection before sending the request
            operation.query = removeFieldFromDocument(
              operation.query,
              fieldName,
            )
          }
          const result = forward(operation)
          const sub = result.subscribe({
            next(value) {
              if (requestsTrackingId && value.data) {
                // Set field value in the response
                value.data[fieldName] = traceId
              }
              observer.next(value)
            },
            error(errorValue) {
              observer.error(errorValue)
            },
            complete() {
              finish()
              observer.complete()
            },
          })

          return () => {
            sub.unsubscribe()
          }
        })
      },
    )
  }
}

/**
 * Removes a specific root field from the provided GraphQL query/mutation document.
 */
export function removeFieldFromDocument(
  document: DocumentNode,
  fieldName: string,
) {
  return visit(document, {
    Field: (node, _key, _parent, _path, ancestors) => {
      if ((node.alias?.value || node.name.value) === fieldName) {
        const grandParent = ancestors[ancestors.length - 2] as
          | ASTNode
          | undefined
        if (
          grandParent?.kind === 'OperationDefinition' &&
          ['query', 'mutation'].includes(grandParent.operation)
        ) {
          return null
        }
      }
    },
  })
}
