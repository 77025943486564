import type { DesktopTypes } from '@soundtrack/desktop-shared-types'

function keys<T extends {}>(obj: T) {
  return Object.keys(obj) as (keyof T)[]
}

export function hotkeyMatches(
  a: DesktopTypes.Hotkey,
  b: DesktopTypes.Hotkey,
): boolean {
  const { key: aKey, ...aMods } = a
  const { key: bKey, ...bMods } = b
  return (
    aKey === bKey &&
    // can treat undefined as false for modifier keys
    [...new Set(keys(aMods).concat(keys(bMods)))].every(
      (key) => !!aMods[key] === !!bMods[key],
    )
  )
}
