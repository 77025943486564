/**
 * Returns true if the user is (assumed to be) located in a region that requires
 * cookie consent, currently European Union and California.
 *
 * Uses the configured timezone of the visitor's computer.
 *
 * This is far from accurate, but good enough since it only means we'll be
 * "incorrectly" giving some additional countries the option to configure their
 * consent (mainly countries on the African continent).
 *
 * Inspired by https://github.com/segmentio/in-eu
 * Based off https://en.wikipedia.org/wiki/Time_in_Europe
 * and https://en.wikipedia.org/wiki/List_of_time_zones_by_country
 */
export function regionRequiresConsent(): boolean {
  // Hack to enable mocking the return value
  if (regionRequiresConsent.forceResult != null) {
    return regionRequiresConsent.forceResult as boolean
  }

  const date = new Date()
  return inEU(date) || inCA(date)
}

regionRequiresConsent.forceResult = null as null | boolean

/**
 * Returns true if the user is (assumed to be) located in the EU.
 */
export function inEU(date: Date = new Date()): boolean {
  // Greenland is in similar timezone as EU since 2024 so should be excluded from below check
  if (isGreenland()) return false

  const offset = -(date.getTimezoneOffset() / 60)
  return offset >= -1 && offset <= +3
}

/**
 * Returns true if the user is (assumed to be) located in California, which
 * has similar cookie consent rules as EU.
 */
export function inCA(date: Date = new Date()): boolean {
  const tzName = getTimeZoneName()
  if (!tzName) {
    const offset = -(date.getTimezoneOffset() / 60)
    return isDST(date) ? offset === -7 : offset === -8
  }
  return tzName === 'America/Los_Angeles'
}

/**
 * Returns true if the user is (assumed to be) located in Greenland,
 * which changed timezone in 2024 to fall in the same timezone as EU.
 *
 * @see https://data.iana.org/time-zones/tzdb/NEWS
 */
export function isGreenland(): boolean {
  const tzName = getTimeZoneName()
  return (
    tzName === 'America/Nuuk' ||
    // This is the old obsolete name for the same timezone
    tzName === 'America/Godthab'
  )
}

function getTimeZoneName() {
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    // Don't return unexpected time zone formats
    if (!timeZone || !timeZone.includes('/')) {
      return undefined
    }
    return timeZone
  } catch (_) {
    return undefined
  }
}

function isDST(date: Date) {
  const jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset()
  const jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset()
  return Math.max(jan, jul) !== date.getTimezoneOffset()
}
