export * as keys from './keys'
export * from './cache'
export * from './createCachePersistor'
export * from './errors'
export * from './getMainDefinition'
export * from './link'
export * from './relayPagination'
export * from './typePolicies'
export * from './types'
export * from './queryTtl'

/** Returns the complete URL for the public Soundtrack GraphQL API */
export function graphqlApiUrl(
  env = 'production',
  protocol: 'https' | 'wss' = 'https',
) {
  const suffix = env === 'production' ? '' : `-${env}`
  const base = `api${suffix}.soundtrackyourbrand.com/v2`
  return protocol === 'wss'
    ? `wss://${base}/graphql-transport-ws`
    : `${protocol}://${base}`
}
