import type { ApolloError } from '@apollo/client'
import type { NetworkError } from '@apollo/client/errors'
import type { ErrorResponse } from '@apollo/client/link/error'

/**
 * {@link NetworkError} with `statusCode` property.
 *
 * @group Error Handling
 */
export type NetworkErrorWithStatus = NetworkError & { statusCode?: number }

/**
 * Returns true if the provided error response represents a rate limit error.
 *
 * @group Error Handling
 */
export function isRateLimitError(
  response: ApolloError | Partial<ErrorResponse>,
): boolean {
  return (
    response.graphQLErrors?.some((error) => {
      return (
        ('code' in error && error.code === 'RATE_LIMITED') ||
        error.extensions?.code === 'RATE_LIMITED'
      )
    }) ?? false
  )
}

/**
 * Returns true if the provided error represents a network error returned by
 * `fetch()`. Uses known cross browser error messages to determine this.
 *
 * @group Error Handling
 */
export function isFetchNetworkError(error: unknown): boolean {
  return (
    !!error &&
    typeof error === 'object' &&
    'message' in error &&
    typeof error.message === 'string' &&
    /^(Failed to fetch|Load failed|NetworkError when attempting to fetch resource|Request has been terminated)/.test(
      error.message,
    )
  )
}

/**
 * Returns true if the provided error response represents a network error.
 *
 * @group Error Handling
 */
export function isNetworkError(
  response: ApolloError | Partial<ErrorResponse> | Error,
  /** Treat retryable server errors as network errors? */
  includeServerErrors = false,
): boolean {
  if (!response) return false
  const netError = (response as ApolloError | Partial<ErrorResponse>)
    ?.networkError
  if (includeServerErrors && netError && 'statusCode' in netError) {
    // See https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
    return [408, 429, 502, 503, 504, 520, 521, 522, 523, 524].includes(
      netError.statusCode,
    )
  }
  return isFetchNetworkError(netError) || isFetchNetworkError(response)
}

/**
 * Returns true if the provided error response represents an server error.
 *
 * @group Error Handling
 */
export function isServerError(response: Partial<ErrorResponse>): boolean {
  const networkError = response.networkError as
    | NetworkErrorWithStatus
    | undefined
  return (
    (networkError?.statusCode || 200) >= 500 ||
    !!response.graphQLErrors?.some((error) => {
      return (
        ('code' in error && error.code === 'INTERNAL_SERVER_ERROR') ||
        error.extensions?.code === 'INTERNAL_SERVER_ERROR'
      )
    })
  )
}

/**
 * Structure for the `error.extensions.exception.validationErrors` object of a
 * `BAD_USER_INPUT` GraphQL error.
 *
 * Errors are typically strings, but the type is intentionally loose to allow
 * consumers some flexibility.
 */
export type ValidationErrors = Record<string, any>

/**
 * Extracts and returns the `error.extensions.exception.validationErrors` object
 * from the most relevant `BAD_USER_INPUT` error on the provided GraphQL error
 * response, if any.
 *
 * See {@link https://github.com/soundtrackyourbrand/soundtrack/blob/main/graphql-api/errors.md our GraphQL user input error conventions}.
 *
 * @example
 * Basic usage
 * ```ts
 * apollo.query(...).catch((error) => {
 *   const validationErrors = extractValidationErrors(error)
 *   if (validationErrors?.password) {
 *     alert('Missing or invalid password')
 *   }
 * })
 * ```
 *
 * @example
 * Usage together with `useForm()` and `attachValidationErrors()` from `@soundtrackyourbrand/ui`
 * ```ts
 * apollo.query(...).catch((error) => {
 *   throw attachValidationErrors(error, extractValidationErrors(error))
 * })
 * ```
 */
export function extractValidationErrors(
  /** apollo-client error response to extract validation errors from */
  error: ApolloError | Partial<ErrorResponse> | null | undefined,
): ValidationErrors | undefined {
  if (!error || 'graphQLErrors' in error === false) {
    return undefined
  }
  const exception = error.graphQLErrors?.find(
    (e) => e?.extensions?.code === 'BAD_USER_INPUT',
  )?.extensions?.exception as undefined | { validationErrors: ValidationErrors }

  if (!exception?.validationErrors) {
    return undefined
  }

  return exception.validationErrors
}
