import {
  ApolloLink,
  type FetchResult,
  type NextLink,
  Observable,
  type Operation,
} from '@apollo/client'
import { createOperation } from '@apollo/client/link/utils'
import {
  type ObservableSubscription,
  type Observer,
} from '@apollo/client/utilities'
import { getIn, updateIn } from '@soundtrackyourbrand/object-utils.js'
import type { GraphQLFormattedError } from 'graphql'

// Augment ApolloClient interfaces
declare module '@apollo/client' {
  export interface DefaultContext {
    /** @see FetchAllOptions */
    fetchAll?: FetchAllOptions
  }
}

/**
 * Enables seamless "fetch remaining pages" support for paginated fields
 * that adhere to the relay connection spec.
 *
 * Implemented as an Apollo link that intercepts outgoing queries that have
 * provided a `fetchAll` object on the apollo query context.
 * A `fetchAll.path` string should be included, representing the dot-delimeted
 * path from the root of the query down to the paginated relay connection
 * containing `pageInfo` and `edges`. This is used by the link to look up
 * `pageInfo.hasNextPage` and `pageInfo.endCursor` to determine if more pages
 * should be fetched, which then happens recursively. All pages are then
 * aggregated into a single query result, as if they were retrieved in a single
 * query.
 *
 * The GQL query must accept variables for limit (= `$first`) and cursor
 * (= `$after`) which should then be passed to the paginated field. The names
 * of these variables can be customized via the options.
 *
 * The query won't be resolved until all remaining pages have been returned, and
 * since fetching happens recursively 1 page at a time this can take quite a
 * while. For this reason it's recommended to use `fetchAll` primarily in
 * `query.fetchMore()` calls that are triggered after the initial page has been
 * returned (see _Manually fetch remaining pages_ example).
 *
 * This link depends on the custom pagination behaviour provided by
 * {@link relayPagination}, and may thus not work as expected when
 * querying fields that doesn't use it in the configured `typePolicies`.
 *
 * @example
 * Immediately fetch all (account) pages for a query
 * ```
 * const query = useQuery(gql`
 *   query Zones($account: ID!, $first: Int!, $after: String) {
 *     account(id: $account) {
 *       soundZones(first: $first, after: $after) {
 *         pageInfo { hasNextPage endCursor } # required for fetchAll
 *         edges {
 *           cursor # required for relayStrictPagination()
 *           node { id name }
 *         }
 *       }
 *     }
 *   }
 * `, {
 *   variables: { account: props.accountId },
 *   context: {
 *     fetchAll: { path: 'account.soundZones', perPage: 100 },
 *   },
 * })
 * ```
 *
 * @example
 * Manually fetch remaining pages
 * ```
 * const [showAll, setShowAll] = React.useState(false)
 *
 * const query = useQuery(gql`
 *   query Zones($account: ID!, $first: Int!, $after: String) {
 *     account(id: $account) {
 *       soundZones(first: $first, after: $after) {
 *         pageInfo { hasNextPage endCursor } # required for fetchAll
 *         edges {
 *           cursor # required for relayStrictPagination()
 *           node { id name }
 *         }
 *       }
 *     }
 *   }
 * `, {
 *   variables: {
 *     account: props.accountId,
 *     first: showAll ? 1e5 : 10, // initially fetch 10 items
 *   },
 * })
 *
 * const fetchRemaining = () => {
 *   query.fetchMore({
 *     context: {
 *       fetchAll: {
 *         path: 'account.soundZones',
 *         perPage: 50, // can fetch more items per page (if backend allows)
 *       }
 *     },
 *     variables: {
 *       after: query.data.account.soundZones.pageInfo.endCursor,
 *     },
 *   }).then(() => {
 *     setShowAll(true)
 *   })
 * }
 * ```
 *
 * @group Apollo Links
 */
export type FetchAllOptions = {
  /**
   * Dot-delimeted path from the root of the query down to the paginated relay
   * connection containing `pageInfo` and `edges`.
   */
  path: string
  /** Disable link when explicitly set to false (enabled if prop is omitted) */
  enabled?: boolean
  /** Number of edges request in each page query using the `limitVar` (defaults to `variables[limitVar]`) */
  perPage?: number
  /** Name of query variable that controls page size (defaults to `first`) */
  limitVar?: string
  /** Name of query variable used for cursor pagination (defaults to `after`) */
  cursorVar?: string
}

/**
 * {@link ApolloLink} that enables seamless "fetch all pages" support for paginated
 * fields which adheres to the relay connection spec.
 *
 * See {@link FetchAllOptions} for more details on how to use it.
 *
 * @group Apollo Links
 */
export class FetchAllLink extends ApolloLink {
  options: Required<Omit<FetchAllOptions, 'path'>>

  constructor(options?: Omit<FetchAllOptions, 'path'>) {
    super()
    this.options = Object.assign(
      {
        enabled: true,
        perPage: 100,
        limitVar: 'first',
        cursorVar: 'after',
      },
      options,
    )
  }

  request(operation: Operation, forward: NextLink): Observable<FetchResult> {
    const ctx = operation.getContext()
    if (!ctx.fetchAll || ('enabled' in ctx.fetchAll && !ctx.fetchAll.enabled)) {
      return forward(operation)
    }

    const {
      path,
      perPage = (operation.variables?.[
        ctx.fetchAll.limitVar || 'first'
      ] as number) || this.options.perPage,
      limitVar = this.options.limitVar,
      cursorVar = this.options.cursorVar,
    } = ctx.fetchAll as FetchAllOptions

    const subscribers: Array<Observer<FetchResult> | null> = []
    const pageSubscriptions: ObservableSubscription[] = []
    let subscriberCount = 0

    const combinedResult: FetchResult = {}
    const combinedEdges: RelayConnection['edges'] = []
    const combinedErrors: GraphQLFormattedError[] = []

    // Start cursor from first page and endCursor from last
    let startCursor: string | undefined
    let endCursor: string | undefined

    function propagateErrorToSubscriptions(err: Error) {
      pageSubscriptions.forEach((pageSubscription) => {
        pageSubscription.unsubscribe()
      })
      subscribers.forEach((subscriber) => {
        if (subscriber != null) {
          subscriber.error!(err)
        }
      })
    }

    const fetchNextPage = (cursor: string | undefined) => {
      const page = pageSubscriptions.length
      const nextOperation = createOperation(operation.getContext(), {
        ...operation,
        variables: {
          ...operation.variables,
          [limitVar]: perPage,
          [cursorVar]: cursor,
        },
      })

      pageSubscriptions[page] = forward(nextOperation).subscribe({
        next: (res) => {
          const hasErrors = res.errors?.length
          if (hasErrors) {
            combinedErrors.push(...res.errors!)
          }

          const pageConnection = getIn(res.data, path) as RelayConnection
          const pageInfo = pageConnection?.pageInfo

          // Throw if query result didn't include necessary pageInfo fields
          if (
            !(
              pageInfo &&
              'hasNextPage' in pageInfo &&
              'endCursor' in pageInfo
            ) &&
            !hasErrors
          ) {
            propagateErrorToSubscriptions(
              new Error(
                `fetchAllLink: Expected path \`${path}\` to include \`pageInfo { hasNextPage endCursor }\` in selection`,
              ),
            )
            return
          }

          if (page === 0) {
            // Use result of first page as the basis for the combined result
            Object.assign(combinedResult, res)
            startCursor = pageInfo?.startCursor
          }

          if (pageConnection) {
            combinedEdges.push(...pageConnection.edges)
            endCursor = pageInfo.endCursor

            if (pageInfo.hasNextPage && pageInfo.endCursor) {
              // Continue fetching next page
              fetchNextPage(pageInfo.endCursor)
              return
            }
          }

          // All pages fetched - combine them and notify observers
          if (combinedEdges.length > 0) {
            updateIn(combinedResult.data, path, (existing) => ({
              ...existing,
              edges: combinedEdges,
              pageInfo: {
                ...existing?.pageInfo,
                startCursor,
                endCursor,
                hasNextPage: false,
                hasPreviousPage: false,
              },
            }))
          }

          if (combinedErrors.length > 0) {
            combinedResult.errors = combinedErrors
          }

          subscribers.forEach((subscriber) => {
            if (subscriber != null) {
              subscriber.next!(combinedResult)
              subscriber.complete!()
            }
          })
        },
        error: propagateErrorToSubscriptions,
      })
    }

    // Start recursive fetching from initial page cursor
    fetchNextPage(operation.variables?.[cursorVar])

    return new Observable((subscriber) => {
      const subscriberIndex = subscribers.push(subscriber) - 1
      subscriberCount += 1
      return () => {
        // comment from apollo-link-retry source code:
        // Note that we are careful not to change the order or length of the array,
        // as we are often mid-iteration when calling this method.
        subscribers[subscriberIndex] = null
        subscriberCount -= 1
        if (subscriberCount === 0) {
          pageSubscriptions.forEach((pageSubscription) => {
            pageSubscription.unsubscribe()
          })
        }
      }
    })
  }
}

/** Relay connection page information */
type PageInfo = {
  hasPreviousPage?: boolean
  hasNextPage?: boolean
  startCursor?: string
  endCursor?: string
}

/** Relay connection base type */
type RelayConnection = {
  pageInfo: PageInfo
  edges: Array<{ node: any }>
}
