import { type FieldPolicy } from '@apollo/client'

export const blockTrack: FieldPolicy<any> = {
  merge(existing, incoming, c) {
    const id = c.cache.identify({
      __typename: 'SoundZone',
      id: incoming.parent,
    })
    if (id) {
      c.cache.modify({
        id,
        fields: {
          blockedTracks(entries) {
            entries = {
              ...entries,
              edges: entries.edges.concat({
                __typename: 'BlockedTrackEdge',
                node: incoming.blockedTrack,
              }),
            }
            return entries
          },
        },
      })
    }
    return c.mergeObjects(existing, incoming)
  },
}

export const unblockTrack: FieldPolicy<any> = {
  merge(existing, incoming, c) {
    const id = c.cache.identify({
      __typename: 'SoundZone',
      id: incoming.parent,
    })
    if (id) {
      c.cache.modify({
        id,
        fields: {
          blockedTracks(entries) {
            entries = {
              ...entries,
              edges: entries.edges.filter((edge: any) => {
                const trackId = c.readField(
                  'id',
                  c.readField('track', edge.node),
                )
                return incoming.source !== trackId
              }),
            }
            return entries
          },
        },
      })
    }
    return c.mergeObjects(existing, incoming)
  },
}
