import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import * as React from 'react';
import { Modal } from './Modal';
import { PillButton } from './levels/button/PillButton';
export class ModalDialog extends React.Component {
    static displayName = 'ModalDialog';
    static propTypes = {
        onOutcome: PropTypes.func.isRequired,
        type: PropTypes.oneOf(['commit', 'destructive', 'acknowledge']),
        awaitOnOutcomePromise: PropTypes.bool,
        commit: PropTypes.node.isRequired,
        cancel: PropTypes.node,
    };
    static defaultProps = {
        type: 'commit',
        commit: 'Ok',
        cancel: 'Cancel',
    };
    state = {
        outcome: undefined,
        pendingOutcome: undefined,
    };
    _mounted = false;
    resolveOnClose = null;
    componentDidMount() {
        this._mounted = true;
    }
    componentWillUnmount() {
        this._mounted = false;
    }
    handleOutcome(outcome, awaitClose = true) {
        if (this.props.awaitOnOutcomePromise) {
            let initiatedClose = false;
            const close = () => {
                if (!this._mounted || !awaitClose) {
                    return Promise.resolve();
                }
                this.setState({ outcome });
                initiatedClose = true;
                return new Promise((resolve) => {
                    this.resolveOnClose = resolve;
                });
            };
            const result = this.props.onOutcome(outcome, close);
            if (result && result.then) {
                // Only re-render loading state if `close()` hasn't been called after a "promise tick"
                setTimeout(() => {
                    if (!this._mounted || initiatedClose) {
                        return;
                    }
                    this.setState((state) => {
                        return state.outcome ? {} : { pendingOutcome: outcome };
                    });
                }, 0);
                result.then(() => {
                    this._mounted && this.setState({ outcome });
                }, (error) => {
                    this._mounted && this.setState({ pendingOutcome: undefined });
                    throw error;
                });
                return;
            }
        }
        // Trigger closing of dialog immediately
        this.setState({ outcome });
    }
    handleCommit = () => this.handleOutcome(true);
    handleCancel = () => this.handleOutcome(false);
    onModalClose = () => {
        if (this.resolveOnClose) {
            this.resolveOnClose();
            return;
        }
        if (!this.props.awaitOnOutcomePromise) {
            this.props.onOutcome(this.state.outcome ?? null, () => Promise.resolve());
        }
        this.handleOutcome(false, false);
    };
    render() {
        const { onOutcome, awaitOnOutcomePromise, type, commit, cancel, ...props } = this.props;
        const { outcome, pendingOutcome } = this.state;
        const actions = [
            _jsx(PillButton, { type: "submit", disabled: pendingOutcome !== undefined, loading: pendingOutcome === true, variant: "accent", onClick: this.handleCommit, children: commit }, "commit"),
        ];
        if (type !== 'acknowledge') {
            actions.unshift(_jsx(PillButton, { disabled: pendingOutcome !== undefined, loading: pendingOutcome === false, onClick: this.handleCancel, children: cancel }, "cancel"));
        }
        return (_jsx(Modal, { ...props, type: "simple", closed: outcome !== undefined, levels: true, actions: actions, onClose: this.onModalClose }));
    }
}
