import composeRefs from '@seznam/compose-react-refs';
import classNames from 'classnames';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { isFirefox } from './lib/browser-detection';
var STATE;
(function (STATE) {
    STATE["ERROR"] = "error";
    STATE["INACTIVE"] = "inactive";
    STATE["LOADED"] = "loaded";
    STATE["LOADING"] = "loading";
})(STATE || (STATE = {}));
export const Image = React.forwardRef(({ tag, src, srcSet, sizes, faded = false, fadeThreshold = 50, previousWhileLoading = false, onUpdate, ...props }, ref) => {
    src = src ? String(src) : '';
    tag = tag || 'img';
    const timing = getTiming();
    const [state, setState] = React.useState(src ? STATE.LOADING : STATE.INACTIVE);
    const [activeSrc, setActiveSrc] = React.useState({ src: '' });
    const preloader = React.useRef({
        started: timing,
    });
    const elementRef = React.useRef(null);
    const currentElement = elementRef.current;
    React.useEffect(() => {
        const updateState = (state, committedSrc) => {
            if (state === STATE.LOADED &&
                committedSrc?.src !== preloader.current.config?.src) {
                // Skip if we have started loading a different image
                return;
            }
            setState(state);
            if (committedSrc !== undefined) {
                setActiveSrc(committedSrc);
            }
            onUpdate && onUpdate(state, src);
        };
        let { image, config } = preloader.current;
        const cleanup = () => {
            if (image) {
                image.onload = null;
                image.onerror = null;
                preloader.current.image = undefined;
                preloader.current.config = undefined;
            }
        };
        if (!src) {
            cleanup();
            updateState(STATE.INACTIVE, { src: '' });
        }
        else if (!config ||
            image?.src !== src ||
            (srcSet && image?.srcset !== srcSet) ||
            (sizes && image?.sizes !== sizes)) {
            const imageConfig = { src, srcSet, sizes };
            cleanup();
            image = new window.Image();
            image.onerror = (e) => ReactDOM.unstable_batchedUpdates(() => {
                updateState(STATE.ERROR, imageConfig);
                cleanup();
            });
            if (!isFirefox || !srcSet)
                image.src = src;
            if (srcSet)
                image.srcset = srcSet;
            if (sizes)
                image.sizes = sizes;
            preloader.current.config = imageConfig;
            preloader.current.image = image;
            preloader.current.started = getTiming();
            if (image.complete) {
                updateState(STATE.LOADED, imageConfig);
            }
            else {
                image.onload = (e) => ReactDOM.unstable_batchedUpdates(() => {
                    updateState(STATE.LOADED, imageConfig);
                    cleanup();
                });
                updateState(STATE.LOADING, previousWhileLoading || faded ? undefined : { src: '' });
            }
        }
    }, [
        src,
        srcSet,
        sizes,
        previousWhileLoading,
        faded,
        onUpdate,
        currentElement,
    ]);
    React.useEffect(() => {
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            const { image } = preloader.current;
            if (image) {
                image.onload = null;
                image.onerror = null;
            }
        };
    }, []);
    const showingPrevious = previousWhileLoading && activeSrc && src !== activeSrc.src;
    const shouldFade = faded &&
        ((activeSrc.src && src !== activeSrc.src) ||
            timing - preloader.current.started > fadeThreshold);
    props.className = classNames(`image image--${state}`, showingPrevious && 'image--previous', shouldFade && 'image--faded', props.className);
    if (activeSrc.src) {
        if (tag === 'img') {
            // There appear to be a bug in Firefox where providing both src and srcSet
            // sometimes cause Firefox to start loading the src url, then abort it and
            // load the correct image from srcSet instead.
            // Sometimes when this happens the image is never rendered even though
            // devtools show the new request as completed and can display it.
            if (!isFirefox || !srcSet) {
                props.src = activeSrc.src;
            }
            props.srcSet = activeSrc.srcSet;
            props.sizes = activeSrc.sizes;
        }
        else {
            props.style = Object.assign({}, props.style, {
                backgroundImage: `url(${activeSrc.src})`,
            });
        }
    }
    props.ref = composeRefs(ref, elementRef);
    return React.createElement(tag, props);
});
Image.displayName = 'Image';
function getTiming() {
    return window.performance ? window.performance.now() : Date.now();
}
