import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { Button } from '../Button';
import { InputBase } from './InputBase';
import { fieldComponent } from './fieldComponent';
import { useField } from './useField';
export const Password = fieldComponent(({ unrevealable = false, ...props }, ref) => {
    const [reveal, setReveal] = React.useState(false);
    props.floatingLabel ??= !props.placeholder;
    if (!props.suffix && !unrevealable) {
        props.suffix = (_jsx(Button, { flat: true, icon: reveal ? 'hidden' : 'visible', onClick: (e) => {
                e.stopPropagation();
                setReveal((x) => !x);
            }, className: "password-field-toggle" }));
    }
    const [baseProps, fieldProps] = InputBase.partitionProps(props);
    const field = useField(fieldProps, {
        ref,
        fieldType: 'password',
    });
    return field.wrap(_jsx(InputBase, { fieldRef: field.ref, ...baseProps, value: field.props.value, children: _jsx("input", { ...field.props, type: reveal && !unrevealable ? 'text' : 'password' }) }));
}, {
    displayName: 'Password',
});
