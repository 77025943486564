import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import composeRefs from '@seznam/compose-react-refs';
import { getIn } from '@soundtrackyourbrand/object-utils.js';
import * as React from 'react';
import { withModifier } from '../lib';
import { InputAutoSizer } from './InputAutoSizer';
import { InputBase } from './InputBase';
import { fieldComponent } from './fieldComponent';
import { useField } from './useField';
/**
 * Field version of SelectInput, exposing the same API.
 */
export const Select = fieldComponent(({ ...props }, ref) => {
    // Reserve space for dropdown arrow
    props.suffix ??= _jsx(React.Fragment, {});
    props.floatingLabel ??= !props.placeholder;
    const [baseProps, fieldProps] = InputBase.partitionProps(props);
    const field = useField(fieldProps, {
        ref,
        fieldType: 'select',
    });
    return field.wrap(_jsx(InputBase, { fieldRef: field.ref, ...baseProps, value: field.props.value, children: _jsx(SelectInput, { ...field.props }) }));
}, {
    displayName: 'Select',
});
Select.toOptions = (label, value) => ({ value, label });
Select.labelSort = ({ label }) => label.toString().toLowerCase();
/**
 * Wrapper for <select> which adds a few quality of life improvements.
 *
 * <option> tags can be provided as children, and/or generated by providing an
 * array/im.List as `options`.
 *
 * `options` should only contain either primitives (strings/numbers), plain JS
 * objects, or im.Map objects. Primitives will be rendered with the label and
 * value being identical, while labels and values for objects are looked up
 * using `labelKey` and `valueKey`. `<optgroup>`s can be generated by providing
 * another array as the value referenced by `valueKey`.
 *
 * @example Options whose labels are the same as their values
 * <Select options={['one', 'two', 'three']} />
 *
 * @example Options with different value and labels
 * <Select options={[{ value: 1, label: 'one' }, { value: 2, label: 'two' }]} />
 *
 * @example Option groups
 * <Select options={[
 *   { label: 'group 1', value: [11, 12, 13] },
 *   { label: 'group 2', value: [21, 22, 23] },
 * ]} />
 */
export const SelectInput = React.forwardRef(({ options, placeholder, disablePlaceholder, valueKey = 'value', labelKey = 'label', autosize = false, ...props }, ref) => {
    const localRef = React.useRef(null);
    const selectOption = (option, index) => {
        const type = typeof option;
        const isPrimitive = type === 'string' || type === 'number';
        const obj = option;
        const value = isPrimitive ? option : getIn(obj, valueKey);
        const label = isPrimitive ? option : getIn(obj, labelKey);
        const disabled = !isPrimitive && getIn(obj, 'disabled') === true;
        if (value && typeof value.map === 'function') {
            return (_jsx("optgroup", { label: label, disabled: disabled, children: value.map(selectOption) }, `optgroup-${label || index}`));
        }
        return (_jsx("option", { value: value, disabled: disabled, children: label }, `opt-${value ?? index}`));
    };
    return (_jsxs(_Fragment, { children: [_jsxs("select", { ref: composeRefs(ref, localRef), ...props, children: [placeholder != null && (_jsx("option", { value: "", className: withModifier('select__placeholder', disablePlaceholder && 'remove'), children: placeholder }, "opt-placeholder")), !!options && options.map(selectOption), props.children] }), autosize && (_jsx(InputAutoSizer, { inputRef: localRef, placeholder: placeholder }))] }));
});
SelectInput.displayName = 'SelectInput';
