import PropTypes from 'prop-types';
import * as React from 'react';
import { accessedPropsProxy, isShallowSubsetOf } from '../lib/object';
import { useFormContext, } from './useForm';
/**
 * A hook that subscribes to updates for a given `useForm()` instance, either on a
 * field-level (by providing a `path` to subscribe to) or globally.
 *
 * The hook tracks which properties of the returned objects that are accessed.
 * This allows it to minimize the re-renders of the consuming component by only
 * triggering an update if the value of any of the accessed props changes
 * (using referential/shallow equality checking).
 *
 * Avoid enumerating/spreading the returned object if you wish to minimize renders.
 *
 * @example Track when the value of a specific field changes
 * const { value } = useFormSpy(form, 'path.to.field')
 *
 * @example Track the `value`, `error`, and `validating` state of a field
 * const { value, error, validating } = useFormSpy(form, 'path.to.field')
 *
 * @example Track interesting state changes of a form
 * // Bad for performance - subscribes to changes of all props (including values)
 * const { values, ...interestingBits } = useFormSpy(form)
 * // Good - only subscribes to the relevant changes
 * const { valid, validating, errors, submitting, submitCount } = useFormSpy(form)
 * const interestingBits = { valid, validating, errors, submitting, submitCount }
 *
 * @example Track active form submissions - updates when form switches from submitting to idling
 * const { submitting } = useFormSpy(form)
 *
 * @param form - Form to subscribe to changes for - return value of `useForm()`
 * @param [path] - Optional path to a specific field to limit updates to
 * @return An object representing the state of the given form or field
 */
export function useFormSpy(
/** Allow `useForm()` return value to be provided via prop if not available through context */
form, 
/** Optional path of field to subscribe to (dot separated) */
path) {
    if (!form || typeof form.subscribe !== 'function') {
        throw new Error(`useFormSpy requires a form (got '${form}')`);
    }
    const getState = () => {
        return typeof path === 'string'
            ? form.fieldState(path)
            : form.formState;
    };
    const forceUpdate = React.useReducer((x) => x + 1, 0)[1];
    const latestState = React.useRef();
    const accessedState = React.useRef({});
    if (!latestState.current) {
        latestState.current = getState();
    }
    React.useEffect(() => {
        return form.subscribe(() => {
            const newState = getState();
            if (!isShallowSubsetOf(newState, accessedState.current)) {
                accessedState.current = {};
                latestState.current = newState;
                forceUpdate();
            }
        });
    }, [path]); // eslint-disable-line react-hooks/exhaustive-deps
    return accessedPropsProxy(latestState.current, accessedState);
}
FormSpy.propTypes = {
    form: PropTypes.object,
    path: PropTypes.string,
    children: PropTypes.func.isRequired,
};
/**
 * Render-prop based component version of the `useFormSpy()` hook.
 *
 * @see useFormSpy
 * @param form - Form to subscribe to changes for - return value of `useForm()`
 * @param [path] - Optional path to a specific field to limit updates to
 * @return An object representing the state of the given form or field
 */
export function FormSpy({ form: propForm, path, children, }) {
    const contextForm = useFormContext();
    const form = propForm || contextForm;
    const state = useFormSpy(form, path);
    if (typeof children !== 'function') {
        throw new Error(`FormSpy: Expected a callable 'children' prop`);
    }
    return children(state) || null;
}
