import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import * as React from 'react';
import { Throbber } from '../../BaseThrobber';
import { Icon } from '../../Icon';
import { withModifier } from '../../lib';
/**
 * Don't output this directly, it is a base component for our buttons.
 * Supports only the most reusable parts of the button logic and design.
 * @experimental May be subject to change.
 */
export const BaseButton = React.forwardRef(ButtonInner);
Object.assign(BaseButton, { displayName: 'BaseButton' });
function ButtonInner({ block, wide, loading, enabledWhileLoading, icon, iconRight, tag, iconStyle, ...attrs }, ref) {
    // @ts-expect-error `tag` can't be manually assigned
    tag = tag || 'button';
    // @ts-expect-error `attrs.type` is not defined for all tags
    if (tag === 'button' && !attrs.type) {
        // @ts-expect-error `attrs.type` is not defined for all tags
        attrs.type = 'button';
    }
    const childCount = React.Children.count(attrs.children);
    Object.assign(attrs, {
        ref,
        disabled: attrs.disabled || (loading && !enabledWhileLoading),
        className: classNames(withModifier('button', attrs.size, block && 'block', wide && 'wide', loading && 'loading', childCount === 0 && 'icon', icon && childCount > 0 && iconRight && 'icon-right'), attrs.className),
    });
    return React.createElement(tag, attrs, [
        icon && _jsx(Icon, { name: icon, style: iconStyle }, "icon"),
        _jsx("span", { className: "button__content", children: attrs.children }, "content"),
        loading && _jsx(Throbber, {}, "throbber"),
    ]);
}
