import {
  ApolloLink,
  type DefaultContext,
  type NextLink,
  type Operation,
  fromPromise,
} from '@apollo/client'
import { getMainDefinition } from '../getMainDefinition'

// Augment ApolloClient interfaces
declare module '@apollo/client' {
  export interface DefaultContext {
    /** Custom HTTP headers to set on outgoing request */
    headers?: Record<string, any>
    /**
     * Controls the inclusion of the `Authorization` HTTP header
     * - `undefined` will result in the stored token being used, if it exists (default)
     * - `true` will require a token to be returned, if no token exists an error will be thrown
     * - `false` will result in the token always being omitted from the request
     * - `string` will be used as the token value (overriding any stored token)
     */
    auth?: boolean | string
  }
}

/**
 * Options for {@link HeadersLink}.
 *
 * @group Apollo Links
 */
export type HeadersLinkOptions = {
  /**
   * Optional function called with `context` which can be used to modify
   * `context.headers`.
   */
  before?: (context: DefaultContext) => void
  /**
   * Providing this function will enable the link to automatically inject an
   * `Authorization` header when desired. Whether or not the header is injected
   * is determined by the `context.auth` property.
   * TODO: Link to `context.auth` documentation.
   */
  resolveAuthToken?: () =>
    | string
    | null
    | undefined
    | Promise<string | null | undefined>
}

/**
 * {@link ApolloLink} that provides a simple way to inject custom HTTP headers
 * into outgoing requests through the optional `context.headers` object.
 *
 * If a {@link HeadersLinkOptions.resolveAuthToken resolveAuthToken} function is
 * provided, the link will manage the `Authorization` header automatically. The
 * inclusion/exclusion of the header along with its value can be controlled on a
 * per-operation basis using the custom `context.auth` property.
 *
 * @example
 * ```ts
 * const queryAuth = useQuery(MyQueryDoc, {
 *   context: { auth: true }, // require valid token - throws error if operation runs without a valid token
 * })
 * const queryAnon = useQuery(MyQueryDoc, {
 *   context: { auth: false }, // omit Authorization header entirely
 * })
 * apollo.mutate({
 *   mutation: MyMutationDoc,
 *   context: { auth: 'custom soundtrack token' }, // specify custom Authorization token
 * })
 * ```
 *
 * @group Apollo Links
 */
export class HeadersLink extends ApolloLink {
  constructor(public options: HeadersLinkOptions) {
    super()
  }

  public request(operation: Operation, forward: NextLink) {
    const context = operation.getContext()
    context.headers ||= {}

    // Allow callback to modify request context
    this.options.before?.(context)

    // Avoid calling `resolveAuthToken()` if context.auth is provided or false
    const authTokenPromise = Promise.resolve(
      typeof context.auth === 'string' && context.auth.length
        ? context.auth
        : context.auth === false
          ? undefined
          : this.options.resolveAuthToken?.(),
    )

    return fromPromise(authTokenPromise).flatMap((token) => {
      if (!token && context.auth === true) {
        const def = getMainDefinition(operation.query)
        throw new Error(
          `headersLink: Attempted to execute ${def.operation} '${def.name?.value}' without token`,
        )
      }
      if (token) {
        context.headers!.Authorization = 'Bearer ' + token
      }

      // Omit empty values and ensure remaining values are strings
      Object.keys(context.headers!).forEach((key) => {
        const value = context.headers![key]
        // eslint-disable-next-line eqeqeq
        if (value == undefined) {
          delete context.headers![key]
        } else if (typeof value !== 'string') {
          context.headers![key] = JSON.stringify(value)
        }
      })

      operation.setContext(context)
      return forward(operation)
    })
  }
}
