import type { FieldPolicy, ModifierDetails } from '@apollo/client/cache'
import {
  type FieldReadFunction,
  type KeyFieldsFunction,
} from '@apollo/client/cache/inmemory/policies'
import {
  type Reference,
  type StoreObject,
  relayStylePagination,
} from '@apollo/client/utilities'
import type { TypedTypePolicies } from './graphql.generated'
import { parseId } from './keys'
import { relayPagination as relayStrictPagination } from './relayPagination'

/**
 * Generates entity references from objects only having an `id`.
 *
 * @group Type Policies
 */
export const resolveById: FieldReadFunction = (
  existing,
  { args, toReference },
) => {
  return existing || (args?.id ? toReference(args.id) : undefined)
}

/**
 * Default cache key resolver
 *
 * @group Type Policies
 */
export const dataIdFromObject: KeyFieldsFunction = (stored) => {
  return stored.id as string
}

/**
 * `keyFields` function that resolves to `id` property (omitting `__typename`)
 *
 * @group Type Policies
 */
export const keyFieldOnId = dataIdFromObject as any

/**
 * Default type policies for apollo-client cache.
 *
 * @example
 * ```ts
 * // Provide the types from graphql-codegen generated module
 * const typePolicies = defaultTypePolicies<TypedTypePolicies>()
 * // Optionally extend it with local type policies
 * typePolicies.Query.fields.user = {...}
 * // Pass it to the cache constructor
 * const cache = new Apollo.InMemoryCache({
 *   possibleTypes: possibleTypes, // imported from module generated by graphql-codegen
 *   typePolicies,
 * })
 * ```
 *
 * @group Type Policies
 */
export function defaultTypePolicies<TypedPolicies = unknown>() {
  const policies = {
    Query: {
      fields: {
        account: resolveById,
        album: resolveById,
        artist: resolveById,
        node: resolveById,
        playlist: resolveById,
        schedule: resolveById,
        search: relayStrictPagination(['type', 'query', 'market']),
      },
    },
    User: {
      keyFields: keyFieldOnId,
      fields: {
        accounts: relayStrictPagination(),
      },
    },
    Account: {
      keyFields: keyFieldOnId,
      fields: {
        soundZones: relayStrictPagination(['filters']),
        locations: relayStrictPagination(),
      },
    },
    Location: {
      keyFields: keyFieldOnId,
      fields: {
        soundZones: relayStrictPagination(['filters']),
      },
    },
    SoundZone: {
      keyFields: keyFieldOnId,
      fields: {
        playbackHistory: relayStylePagination(['startAt']),
        blockedTracks: relayStylePagination(),
        subscription: { merge: true },
        nowPlaying: { merge: true },
        // TODO: read playFrom and nowPlaying.playFrom from Playback.playFrom
        // this depend on first fetching zone.playback to have the correct id in cache
      },
    },
    Device: {
      keyFields: keyFieldOnId,
      merge(existing, incoming, c) {
        // Update soundZone.device field
        if (c.variables?.zone) {
          c.cache.modify({
            id: c.variables.zone,
            fields: {
              device: (value, ctx) => {
                return incoming
              },
            },
          })
        }
        return c.mergeObjects(existing, incoming)
      },
    },
    Playback: {
      // Playback.id is a device id so we need to add typename to make it unique
      keyFields: (({ __typename, id }: any) => `${__typename}::${id}`) as any,
    },
    MusicLibrary: {
      keyFields: ['id'],
      merge: true,
      fields: {
        ids: {
          // Update or invalidate MusicLibrary { playlists schedules } on changes to `ids` (received through subscription)
          merge(existing: string[] | undefined, incoming: string[], c) {
            const libraryId: string | undefined = c.variables
              ? c.variables.libraryId || c.variables.accountId
              : undefined
            if (!libraryId) {
              const vars = JSON.stringify(c.variables)
              throw new Error(
                'Must include one of `libraryId`/`accountId` as variable when fetching `MusicLibrary.ids` - got ' +
                  vars,
              )
            }
            if (existing && incoming) {
              const enum Action {
                None = 0,
                Filter,
                Invalidate,
              }
              const shouldInvalidate = {
                Playlist: Action.None,
                Schedule: Action.None,
              }
              const remainingIds = new Map(existing.map((id) => [id, false]))
              const invalidateTypeFor = (
                id: string,
                action: Action,
              ): boolean => {
                const type = parseId(id).__typename
                if (
                  type &&
                  type in shouldInvalidate &&
                  shouldInvalidate[type as keyof typeof shouldInvalidate] <
                    action
                ) {
                  shouldInvalidate[type as keyof typeof shouldInvalidate] =
                    action
                }
                // Optimization to bail early if we know that we need to invalidate both
                return (
                  shouldInvalidate.Playlist >= Action.Invalidate &&
                  shouldInvalidate.Schedule >= Action.Invalidate
                )
              }
              // Invalidate due to new additions?
              for (const id of incoming) {
                if (remainingIds.has(id)) {
                  remainingIds.set(id, true)
                } else if (invalidateTypeFor(id, Action.Invalidate)) {
                  break // New ID encountered - invalidate corresponding type
                }
              }
              // Invalidate due to removals?
              for (const [id, wasKept] of remainingIds) {
                if (!wasKept) {
                  const bailEarly = invalidateTypeFor(id, Action.Filter)
                  if (bailEarly) break
                }
              }
              const cursorRegex = /^cursor:\["(\d+)"\]/
              const updateFunction = (
                cached: any,
                ctx: ModifierDetails,
                type: keyof typeof shouldInvalidate,
              ) => {
                if (!cached || !shouldInvalidate[type]) return cached
                let canBeUpdated = false
                try {
                  // We can only apply removals in the client if we can deterministically determine the `endCursor`
                  const decodedCursor = atob(cached.pageInfo?.endCursor)
                  if (cursorRegex.test(decodedCursor)) {
                    canBeUpdated = true
                  }
                } catch (error) {
                  if (!(error instanceof DOMException)) {
                    throw error
                  }
                }
                // Invalidate cache if we don't know how to update it
                if (
                  !canBeUpdated ||
                  shouldInvalidate[type] === Action.Invalidate
                ) {
                  return ctx.DELETE
                }
                // Filter items from list if present, updating cursors of remaining items
                let cursor = 0
                const edges: any[] = []
                for (const edge of cached.edges) {
                  const node: StoreObject | Reference = c.isReference(edge)
                    ? c.readField('node', edge)
                    : edge.node
                  if (!node) return false
                  const id = c.readField('id', node) as string
                  if (id && remainingIds.get(id)) {
                    const cursorIndex = cursor++
                    const cursorString = atob(edge.cursor).replace(
                      cursorRegex,
                      () => `cursor:["${cursorIndex}"]`,
                    )
                    edges.push({ ...edge, cursor: btoa(cursorString) })
                  }
                }
                return {
                  ...cached,
                  edges,
                  pageInfo: {
                    ...cached.pageInfo,
                    endCursor: edges[edges.length - 1]?.cursor || null,
                  },
                }
              }
              // Appears to have to be done asynchronously for apollo-client to broadcast changes to active queries
              setTimeout(() => {
                c.cache.modify({
                  id: c.cache.identify({
                    __typename: 'MusicLibrary',
                    id: libraryId,
                  }),
                  fields: {
                    playlists: (cached, ctx) =>
                      updateFunction(cached, ctx, 'Playlist'),
                    schedules: (cached, ctx) =>
                      updateFunction(cached, ctx, 'Schedule'),
                  },
                })
              }, 0)
            }
            return incoming
          },
        },
        playlists: relayStrictPagination(['orderBy'], {
          all: true,
          partial: true,
        }),
        schedules: relayStrictPagination(['orderBy'], {
          all: true,
          partial: true,
        }),
      },
    },
    BrowseCategory: {
      keyFields: (({ __typename, id }: any) => {
        // Support passing both soundtrack URIs in addition to the short ID
        const normalizedId = id?.replace(/^soundtrack:browse:/, '')
        // BrowseCategory ids are not globally unique so we need to prefix the ID
        return normalizedId ? `${__typename}::${normalizedId}` : undefined
      }) as any,
    },
    BrowsePage: {
      keyFields: (({ __typename, id }: any) => {
        // Support passing both soundtrack URIs in addition to the short ID
        const normalizedId = id?.replace(/^soundtrack:browse:/, '')
        // BrowsePage ids are not globally unique so we need to prefix the ID
        return normalizedId ? `${__typename}::${normalizedId}` : undefined
      }) as any,
    },
    Schedule: {
      keyFields: keyFieldOnId,
    },
    Playlist: {
      keyFields: keyFieldOnId,
      fields: {
        // TODO: Switch to relayStrictPagination once SPM has been verified to support it
        // tracks: relayStrictPagination(['market'], { all: true }), // `relayStrictPagination` doesn't handle track removals well
        tracks: relayStylePagination(['market']),
        presets: { merge: true },
        trackStatistics: { merge: true },
      },
    },
    Artist: {
      keyFields: keyFieldOnId,
      fields: {
        albums: relayStrictPagination(['albumType', 'market']),
      },
    },
    Album: {
      keyFields: keyFieldOnId,
      fields: {
        tracks: relayStylePagination(['market']),
      },
    },
    Track: {
      keyFields: keyFieldOnId,
    },
    Displayable: {
      fields: {
        display: { merge: true },
      },
    },
    Display: {
      fields: {
        image: { merge: true },
        colors: { merge: true },
      },
    },
    Image: {
      fields: {
        sizes: { merge: true },
      },
    },
    Colors: {
      fields: {
        primary: { merge: true },
        secondary: { merge: true },
      },
    },
    EditorialPage: {
      // This configuration will apply to all types that implement `EditorialPage`
      fields: {
        sections: relayStrictPagination(undefined, { all: true }), // always return all cached sections
      },
    },
    EditorialSection: {
      // This configuration will apply to all types that implement `EditorialSection`
      keyFields: false, // prevent search:myQuery:section:artists in "all" and "artists" tabs from being merged
    },
    Mutation: {
      fields: {
        soundZoneDelete: {
          merge(_, incoming, c) {
            // TODO: Invalidate zones list of account (currently unknown)?
            c.cache.evict({ id: c.variables!.zone })
          },
        },
      },
    },
    Subscription: {
      fields: {} as Record<string, FieldPolicy>,
    },
  } satisfies TypedTypePolicies

  return policies as typeof policies & TypedPolicies
}
