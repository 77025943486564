import {
  type AnyAction,
  type Reducer,
  type ThunkAction,
  createSelector,
} from '@reduxjs/toolkit'
import { log } from '@soundtrack/utils/log'
import type { Immutable, Mutable } from '@soundtrack/utils/types'
import type {
  IsoCountry,
  PlaybackMode,
  PlaybackOrder,
  PlaybackState,
} from '../graphql/graphql.js'
import { type RootState, curry } from './storeUtils.js'

// Need to re-export GraphQL types referenced in other exports from this file
// to avoid TS2742 in for example desktop store.ts.
export type { IsoCountry, PlaybackMode, PlaybackOrder, PlaybackState }

export type PlaybackSlice = ReturnType<typeof createPlaybackSlice>
export type ReducerState = Record<string, State>
export type State = Immutable<{
  /**
   * Array of partial states from local actions, for quick UI updates
   * before an actual state update is received from the server.
   *
   * Example:
   * Pressing the pause buttion will add an the entry `{ status: 'paused' }`
   * to this array. When the server responds with the actual state, the
   * entry will be removed from the array. If the server doesn't respond
   * within a certain time, the entry will also be removed from the array.
   *
   * The playbackState and all other selectors except rawPlaybackState will
   * include these updates in the returned state as if they were applied
   * on the actual state.
   */
  optimisticUpdates: Array<{
    id: number
    state: OptimisticState
  }>
  /**
   * If the controlled player is online.
   * Online status is tracked in local mode and thus will always be null.
   *
   * Suggested interpretention:
   * - null: Unknown, but assume online/controllable
   * - true: Online and controllable
   * - false: Offline or not controllable
   */
  online: null | boolean
  mode: null | 'local' | 'remote'
  accountId: null | string
  zone: null | ZoneInfo
  tier: null | 0 | 1 | 2 | 3 | 4
  deviceId: null | string
  status: PlaybackState
  playOrder: null | PlaybackOrder
  volume: null | number
  /**
   * The currently assigned source, note that this may be different from
   * the source of the currently playing track, e.g. when assigning
   * another source using "play next"
   *
   * To get the source of the current track use current.source
   */
  source: null | Source
  /** Id of the currently playing track, should always be set if track exists */
  trackId: null | string
  /**
   * The currently playing track, may be missing even if trackId is set if we
   * didn't have enough data when the track started playing but will be populated
   * as soon as we do.
   */
  track: null | Track
  progress: null | Progress
  current: null | Omit<PlayableEntry, 'track'>
  upcoming: null | Array<PlayableEntry>
  /**
   * If playing a schedule which has slots, when next slot will start (ISO 8601)
   * unknown: We don't yet know if the schedule has slots or not
   *
   * @deprecated Temporary field until we have a proper schedule timeline in the API
   */
  nextSlotStartsAt: null | 'unknown' | string
}>

export type ZoneInfo = Immutable<{
  id: string
  name: string
  locationId: string
  country: IsoCountry
  settings: {
    crossfade: boolean
    crossfadeLength: number
  }
}>
export type Source = Immutable<{
  __typename: 'Playlist' | 'Schedule'
  id: string
  name: string
  type: 'playlist' | 'station' | 'schedule'
  composerType?: string
  presets?: null | { playbackMode?: null | PlaybackMode }
  display?: null | Display
}>
export type Track = Immutable<{
  id: string
  title: string
  durationMs?: null | number
  explicit?: null | boolean
  isAvailable?: null | boolean
  album?: null | {
    id?: null | string
    title?: null | string
    display?: null | Display
  }
  artists?: null | Array<{ id: string; name: string }>
}>
export type Display = Immutable<{
  colors?: null | {
    primary?: null | { hex?: null | string }
    secondary?: null | { hex?: null | string }
  }
  image?: null | {
    hero?: null | string
    thumbnail?: null | string
  }
}>

export type Progress = {
  /** Timestamp when the progress was last updated */
  updatedAt: string
  /**
   * Timestamp when the progress update was received
   * Will differ from updatedAt by latency of the event + clock sync
   */
  receivedAt: string
  /** How many milliseconds has been played */
  progressMs: number
}

export type PlayableEntry = Immutable<{
  id: string
  /** If the track is queued or selected naturally from the current source */
  fromQueue: boolean
  source?: null | Source
  track: Track
}>

export type OptimisticState = Partial<
  Omit<
    { [P in keyof State]: State[P] | OptimisticUpdater<State[P]> },
    'optimisticUpdates'
  >
>
export type OptimisticUpdater<T> = (current: State) => T

const rawPlaybackState = (playerId: string, state: RootState): State => {
  return state.playback[playerId] ?? INITIAL_STATE
}
const applyOptimisticUpdates = (state: State) => {
  if (state.optimisticUpdates.length === 0) return state
  state = { ...state }
  for (const update of state.optimisticUpdates) {
    Object.assign(
      state,
      normalizeState(
        Object.fromEntries(
          Object.entries(update.state).map(([key, value]) => [
            key,
            typeof value === 'function' ? value(state) : value,
          ]),
        ),
        state,
      ),
    )
  }
  return state
}
/** Current playback state with all optimistic updates applied */
const playbackState = createSelector(rawPlaybackState, applyOptimisticUpdates)

const tier = (playerId: string, state: RootState) => {
  return playbackState(playerId, state).tier
}

const canPlayMusic = (
  playerId: string,
  state: RootState,
): boolean | undefined => {
  const zoneTier = tier(playerId, state)
  return zoneTier != null ? zoneTier >= 1 : undefined
}

const isUnlimited = (playerId: string, state: RootState) => {
  return (playbackState(playerId, state).tier ?? 0) >= 3
}

const sourceType = (playerId: string, state: RootState) => {
  return playbackState(playerId, state).source?.type
}

const canChangePlayOrder = createSelector(
  isUnlimited,
  sourceType,
  (isUnlimited, sourceType) => isUnlimited && sourceType === 'playlist',
)

export const selectors = {
  // playerConfig: (state: RootState) => state.playback[config],
  rawPlaybackState: curry(rawPlaybackState),
  playbackState: curry(playbackState),
  status: curry((playerId: string, state: RootState) => {
    return playbackState(playerId, state).status
  }),
  mode: curry((playerId: string, state: RootState) => {
    return playbackState(playerId, state).mode
  }),
  zone: curry((playerId: string, state: RootState) => {
    return playbackState(playerId, state).zone
  }),
  accountId: curry((playerId: string, state: RootState) => {
    return playbackState(playerId, state).accountId
  }),
  zoneId: curry((playerId: string, state: RootState) => {
    return playbackState(playerId, state).zone?.id
  }),
  zoneName: curry((playerId: string, state: RootState) => {
    return playbackState(playerId, state).zone?.name
  }),
  locationId: curry((playerId: string, state: RootState) => {
    return playbackState(playerId, state).zone?.locationId
  }),
  zoneCountry: curry((playerId: string, state: RootState) => {
    return playbackState(playerId, state).zone?.country
  }),
  tier: curry((playerId: string, state: RootState) => {
    return playbackState(playerId, state).tier
  }),
  canPlayMusic: curry(canPlayMusic),
  isUnlimited: curry(isUnlimited),
  deviceId: curry((playerId: string, state: RootState) => {
    return playbackState(playerId, state).deviceId
  }),
  playOrder: curry(
    (playerId: string, state: RootState): null | 'SHUFFLE' | 'LINEAR' => {
      const playOrder = playbackState(playerId, state).playOrder
      if (playOrder === 'AUTO') {
        const source = playbackState(playerId, state).source
        return source?.presets?.playbackMode === 'shuffle'
          ? 'SHUFFLE'
          : 'LINEAR'
      }
      return playOrder
    },
  ),
  canChangePlayOrder: curry((playerId: string, state: RootState) => {
    return canChangePlayOrder(playerId, state)
  }),
  volume: curry((playerId: string, state: RootState) => {
    return playbackState(playerId, state).volume
  }),
  sourceId: curry((playerId: string, state: RootState) => {
    return playbackState(playerId, state).source?.id
  }),
  source: curry((playerId: string, state: RootState): Source | null => {
    return playbackState(playerId, state).source
  }),
  trackId: curry((playerId: string, state: RootState) => {
    return playbackState(playerId, state).trackId
  }),
  track: curry((playerId: string, state: RootState): Track | null => {
    return playbackState(playerId, state).track
  }),
  display: curry(
    (
      playerId: string,
      state: RootState,
    ): NonNullable<Track['album']>['display'] => {
      const pState = playbackState(playerId, state)
      return pState.track?.album?.display ?? pState.source?.display
    },
  ),
  progress: curry((playerId: string, state: RootState) => {
    return playbackState(playerId, state).progress
  }),
  currentEntry: curry(
    (playerId: string, state: RootState): PlayableEntry | null => {
      const playback = playbackState(playerId, state)
      return (
        playback.current &&
        playback.track && {
          ...playback.current,
          track: playback.track,
        }
      )
    },
  ),
  nextEntry: curry((playerId: string, state: RootState) => {
    return playbackState(playerId, state).upcoming?.[0]
  }),
  upcoming: curry((playerId: string, state: RootState) => {
    return playbackState(playerId, state).upcoming
  }),
} as const

export const INITIAL_STATE = {
  optimisticUpdates: [],
  online: null,
  mode: null,
  accountId: null,
  zone: null,
  tier: null,
  deviceId: null,
  status: 'paused',
  playOrder: null,
  volume: null,
  source: null,
  trackId: null,
  track: null,
  progress: null,
  current: null,
  upcoming: null,
  nextSlotStartsAt: 'unknown',
} as const satisfies State

export interface PlaybackSliceConfig {
  /** Return true if optimistic updates should be disabled */
  disableOptimisticUpdates?: () => boolean | undefined
}

export function createPlaybackSlice(config: PlaybackSliceConfig) {
  const reducer: Reducer<ReducerState, AnyAction> = function reducer(
    state = {},
    action,
  ): ReducerState {
    switch (action.type) {
      // case 'PLAYBACK/SET_PLAYER_CONFIG': {
      //   return {
      //     ...state,
      //     [config]: action.config,
      //   }
      // }
      case 'PLAYBACK/UPDATE_STATE': {
        let playerState = state[action.playerId] ?? INITIAL_STATE
        playerState = {
          ...playerState,
          ...normalizeState(action.state, applyOptimisticUpdates(playerState)),
        }
        // Remove any optimistic updates that have been fulfilled
        // must be done after applying the action to see the new changes
        ;(playerState as Mutable<State>).optimisticUpdates =
          playerState.optimisticUpdates.filter((update) => {
            return Object.keys(update.state).some((key_) => {
              const key = key_ as keyof typeof update.state
              const field = update.state[key]
              const value =
                typeof field === 'function' ? field(playerState) : field
              return playerState[key] !== value
            })
          })
        state = {
          ...state,
          [action.playerId]: playerState,
        }
        return state
      }
      case 'PLAYBACK/OPTIMISTIC_UPDATE': {
        const updatesToReplace = action.updatesToReplace as
          | readonly number[]
          | undefined
        const playerState = state[action.playerId] ?? INITIAL_STATE
        return {
          ...state,
          [action.playerId]: {
            ...playerState,
            optimisticUpdates: [
              ...(updatesToReplace
                ? playerState.optimisticUpdates.filter(
                    (u) => !updatesToReplace.includes(u.id),
                  )
                : playerState.optimisticUpdates),
              {
                id: action.id,
                state: normalizeState(action.state),
              },
            ],
          },
        }
      }
      case 'PLAYBACK/REMOVE_OPTIMISTIC_UPDATE': {
        const playerState = state[action.playerId] ?? INITIAL_STATE
        return {
          ...state,
          [action.playerId]: {
            ...playerState,
            optimisticUpdates: playerState.optimisticUpdates.filter(
              (update) => update.id !== action.id,
            ),
          },
        }
      }
    }

    return state
  }

  const actions = {
    update: (state: Partial<State>, playerId: string) => ({
      type: 'PLAYBACK/UPDATE_STATE' as const,
      state,
      playerId,
    }),
    optimisticUpdate:
      <T>(
        actionPromise: Promise<T>,
        optimisticState: OptimisticState,
        playerId: string,
        {
          timeout = 5000,
          updatesToReplace = undefined as undefined | ReadonlyArray<number>,
        } = {},
      ): ThunkAction<Promise<T>, RootState, unknown, AnyAction> =>
      async (dispatch, getState) => {
        const id = Date.now() + Math.random()
        const noOptimisticUpdates = config.disableOptimisticUpdates?.() ?? false
        if (!noOptimisticUpdates) {
          dispatch({
            type: 'PLAYBACK/OPTIMISTIC_UPDATE',
            id,
            state: optimisticState,
            playerId,
            updatesToReplace,
          })
        }

        if (!noOptimisticUpdates) {
          setTimeout(() => {
            const state = getState()
            if (
              rawPlaybackState(playerId, state).optimisticUpdates.some(
                (update) => update.id === id,
              )
            ) {
              if (process.env.NODE_ENV === 'development') {
                log.warn(
                  'Optimistic update timed out, reverting to server state',
                  optimisticState,
                  state,
                )
              }
              dispatch({
                type: 'PLAYBACK/REMOVE_OPTIMISTIC_UPDATE',
                id,
                playerId,
              })
            }
          }, timeout)
        }

        try {
          return await actionPromise
        } catch (error) {
          if (!noOptimisticUpdates) {
            dispatch({
              type: 'PLAYBACK/REMOVE_OPTIMISTIC_UPDATE',
              id,
              playerId,
            })
          }
          throw error
        }
      },
  } as const

  return { reducer, actions, selectors }
}

/**
 * Ensures that trackId and track are in sync.
 *
 * If only the id is provided, the object property is set to null.
 * If only the object property is provided, the id is set to the objects id.
 *
 * Clears nextSlotStartsAt if the source changes.
 */
function normalizeState<T extends Partial<Omit<State, 'optimisticUpdates'>>>(
  updatedState: T,
  existingState?: State,
): T {
  if (
    updatedState.source &&
    updatedState.source.id !== existingState?.source?.id &&
    !('nextSlotStartsAt' in updatedState)
  ) {
    updatedState = {
      ...updatedState,
      nextSlotStartsAt: 'unknown',
    }
  }
  if (updatedState.trackId && updatedState.track) {
    if (
      typeof updatedState.trackId !== 'function' &&
      typeof updatedState.track !== 'function' &&
      updatedState.trackId !== updatedState.track.id
    ) {
      throw new Error(
        `trackId ${updatedState.trackId} does not match track.id ${updatedState.track.id}`,
      )
    }
  } else if (updatedState.trackId && !updatedState.track) {
    if (
      typeof updatedState.trackId !== 'function' &&
      existingState &&
      existingState.track &&
      updatedState.trackId !== existingState.track.id
    ) {
      updatedState = { ...updatedState, track: null }
    }
  } else if (
    typeof updatedState.track !== 'function' &&
    !updatedState.trackId &&
    updatedState.track
  ) {
    if (existingState && existingState.trackId !== updatedState.track.id) {
      updatedState = { ...updatedState, trackId: updatedState.track.id }
    }
  }
  return updatedState
}
