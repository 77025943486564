import React from 'react'
import type { Mode } from './types'

export type ThemeContextValue = {
  /**
   * Whether the dark mode feature is enabled or not. Should be used based on feature flag.
   * Can be removed once feature is stable and fully released.
   */
  modeSelectEnabled: boolean
  systemMode: Mode
}

/**
 * The context provider is platform-specific and must be implemented by the consumer and
 * attached to the root of the application.
 * The hooks provided from this package use this context.
 */
export const ThemeContext = React.createContext<ThemeContextValue>(
  undefined as any,
)
