import {
  ApolloLink,
  type FetchResult,
  type NextLink,
  type Operation,
} from '@apollo/client'
import { type OperationDefinitionNode } from 'graphql'
import { Observable } from 'zen-observable-ts'

/** @protected */
export type FaroPushEventCallback = (
  name: string,
  attributes?: Record<string, string>,
  domain?: string,
  options?: { skipDedupe?: boolean },
) => void

/**
 * {@link ApolloLink} that reports GraphQL operations to Faro via the
 * `pushEvent` callback of an instantiated Faro web SDK instance.
 *
 * @group Apollo Links
 */
export class FaroLink extends ApolloLink {
  constructor(
    private pushEventCallback: FaroPushEventCallback,
    private sampleRate: number,
  ) {
    super()
  }

  request(operation: Operation, forward: NextLink): Observable<FetchResult> {
    if (Math.random() > this.sampleRate) {
      return forward(operation)
    }
    const startTime = performance.now()
    const result = forward(operation)
    const opDefinition = operation.query.definitions.find(
      (d) => d.kind === 'OperationDefinition',
    ) as OperationDefinitionNode | undefined

    if (!opDefinition || opDefinition.operation === 'subscription') {
      return result
    }
    return new Observable((observer) => {
      const sub = result.subscribe({
        next: (data) => {
          this.report(
            operation,
            opDefinition,
            startTime,
            (data.errors?.length ?? 0) === 0,
          )
          observer.next(data)
        },
        error: (error) => {
          this.report(operation, opDefinition, startTime, false)
          observer.error(error)
        },
        complete: observer.complete.bind(observer),
      })
      return () => {
        sub.unsubscribe()
      }
    })
  }

  report(
    operation: Operation,
    opDefinition: OperationDefinitionNode,
    startTime: number,
    success: boolean,
  ) {
    const duration = (performance.now() - startTime).toString()
    this.pushEventCallback(
      'GraphQL Operation',
      {
        operation: operation.operationName,
        type: opDefinition.operation,
        result: success ? 'success' : 'failure',
        duration,
      },
      undefined,
      { skipDedupe: true },
    )
  }
}
