/**
 * This module provides utilities for parsing and encoding
 * - soundtrack IDs: `QWNjb3VudCwseHl6MTIzLw..`, `Q29sbGVjdGlvbiwsYWJjNzg5L0FjY291bnQsLHh5ejEyMy8.`, ...
 * - URIs: `soundtrack:user:abc123`, `soundtrack:artist:xyz789`, ...
 * @packageDocumentation
 */

export type Identifiable = {
  __typename?: string
  id: string
  shortId?: string
}

/** Length of ID part of a soundtrack URI (`soundtrack:artist:ID`) */
const SOUNDTRACK_URI_ID_LENGTH = 22

const TYPENAME_TO_ID_TYPE = {
  Album: 'album',
  Artist: 'artist',
  BrowseCategory: 'tag',
  Track: 'track',
  /*
  Still identified via syb-core legacy IDs:
  Playlist: 'playlist',
  Schedule: 'schedule',
  Soundtrack: 'soundtrack',
  */
} as const

const ID_TYPE_TO_TYPENAME = Object.keys(TYPENAME_TO_ID_TYPE).reduce(
  (obj, key) => {
    // @ts-ignore
    obj[TYPENAME_TO_ID_TYPE[key]] = key
    return obj
  },
  {} as Record<string, keyof typeof TYPENAME_TO_ID_TYPE>,
)

/**
 * Parses a long ID (`soundtrack:$type:$shortId`) into `__typename` + `shortId`.
 * Also able to deduce `__typename` and `id` from legacy IDs.
 *
 * Validity of the ID can be determined by checking the presence of
 * `__typename` in the returned object.
 *
 * @param id - ID string to parse
 * @param [strict] - Only consider ids that pass basic sanity checking to be valid
 */
export function parseId(id: string, strict = false): Identifiable {
  const parts = id.split(':')

  // Handle legacy IDs
  if (parts.length === 1 && /[A-Z]/.test(id)) {
    let kind = parse(id)?.kind
    if (!kind) {
      return { id }
    }
    if (kind === 'Collection') {
      kind = 'Playlist'
    }
    return { __typename: kind as any, id }
  }

  if (parts[0] === 'soundtrack') {
    parts.shift()
    if (
      strict &&
      parts.length === 2 &&
      parts[1].length !== SOUNDTRACK_URI_ID_LENGTH
    ) {
      return { id }
    }
  }

  const type = parts.length > 1 && ID_TYPE_TO_TYPENAME[parts[0]]
  return type ? { __typename: type, id, shortId: parts[1] } : { id }
}

/**
 * Parses a soundtrack id (in base64/string format depending on `decodeBefore`) into its parts
 *
 * @param str - Soundtrack id to parse
 * @param [decodeBefore] - Is `str` in base64 format?
 */
export function parse(str: string, decodeBefore = true) {
  if (decodeBefore) {
    str = decode(str)
  }

  const [self, parent] = str.split('/')
  if (!self) {
    return null
  }
  const [kind, stringID, rawIntID] = self.split(',')
  if (!kind || (!stringID && !rawIntID)) {
    return null
  }
  const intID = parseInt(rawIntID, 36)

  return {
    kind,
    stringID,
    intID,
    parent: parent ? encode(parent) : '',
  }
}

/** Decodes a given soundtrack base64 key into its string format */
export function decode(input: string): string {
  return typeof input === 'string' ? atob(input.replace(/\./g, '=')) : ''
}

/** Encodes a soundtrack key in string format into its base64 representation */
export function encode(input: string): string {
  return btoa(input || '').replace(/\./g, '=')
}

/**
 * Returns the base64 string with padding characters readded (if missing).
 * Also removes anything after # and ? in case they've sneaked through.
 *
 * @param input - base64 string
 * @param [stripSuffix] - Toggles stripping of [#?] suffixes in input
 * @return Padded base64 string
 */
export function pad(input: string, stripSuffix = true): string {
  if (typeof input !== 'string') {
    return input
  }
  if (stripSuffix) {
    input = input.replace(/[#?].*/, '')
  }
  while (input.length % 4 !== 0) {
    input += '.'
  }
  return input
}
