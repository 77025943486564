import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { fromDateValue, isValidDate, leftPad } from '../lib/date';
// Used for memoization
let _hasDateInputSupport;
let _localeFormatInfo;
export function hasDateInputSupport() {
    if (_hasDateInputSupport != null) {
        return _hasDateInputSupport;
    }
    if (typeof document === 'undefined' || !document.createElement) {
        return (_hasDateInputSupport = false);
    }
    const input = document.createElement('input');
    const notADateValue = 'not-a-date';
    input.setAttribute('type', 'date');
    input.setAttribute('value', notADateValue);
    return (_hasDateInputSupport = !(input.value === notADateValue));
}
export const DateInputShim = React.forwardRef((props, ref) => {
    const dateLocale = localeFormatInfo();
    // Used to track changes to props.value
    const propValue = React.useRef('');
    const [value, setValue] = React.useState(props.value);
    const inputRef = React.useRef(null);
    // Forward input ref to parent if desired
    React.useEffect(() => {
        if (typeof ref === 'function') {
            ref(inputRef.current);
        }
        else if (ref && 'current' in ref) {
            ref.current = inputRef.current;
        }
    }, [ref]);
    // Propagate upstream values to input
    if (props.value !== propValue.current) {
        const value = props.value;
        propValue.current = value;
        const updatedValue = dateToLocaleFormat(fromDateValue(value));
        setValue(updatedValue);
    }
    const onChange = (event) => {
        const { target } = event;
        const { value: inputValue, selectionStart, selectionEnd } = target;
        const dateValue = localeValueToDateValue(inputValue);
        propValue.current = dateValue;
        // Update the input value so that parent onChange handler gets correct data
        target.value = dateValue;
        props.onChange?.(event);
        // Reset input to entered value
        setValue(inputValue);
        // Manually restore caret position since React doesn't want to
        requestAnimationFrame(() => {
            target.setSelectionRange(selectionStart, selectionEnd);
        });
    };
    return (_jsx("input", { ...props, ref: inputRef, type: "text", value: value, onChange: onChange, placeholder: dateLocale.placeholder }));
});
DateInputShim.displayName = 'DateInputShim';
/** Native <input> for browsers supporting input[type=date] and shimmed version otherwise */
export const DateInput = hasDateInputSupport() ? 'input' : DateInputShim;
// Based on https://stackoverflow.com/a/54115358/1527562
export function localeFormatInfo() {
    if (_localeFormatInfo) {
        return _localeFormatInfo;
    }
    const sample = dateToLocaleFormat(new Date(1970, 11, 31));
    const yi = sample.indexOf('1970');
    const mi = sample.indexOf('12');
    const di = sample.indexOf('31');
    let month = 0;
    let day = 1;
    let year = 2;
    // IE 10 or earlier, iOS 9 or earlier, non-Latin numbering system
    // or non-Gregorian calendar; fall back to mm/dd/yyyy
    if (yi >= 0 && mi >= 0 && di >= 0) {
        // @ts-ignore
        month = (mi > yi) + (mi > di); // @ts-ignore
        day = (di > yi) + (di > mi); // @ts-ignore
        year = (yi > mi) + (yi > di);
    }
    const delimeterMatch = sample.match(/[-.]/);
    const delimeter = (delimeterMatch && delimeterMatch[0]) || '/';
    const placeholder = [];
    placeholder[year] = 'YYYY';
    placeholder[month] = 'MM';
    placeholder[day] = 'DD';
    const regexp = [];
    regexp[year] = '(\\d{4})';
    regexp[month] = regexp[day] = '(\\d{1,2})';
    return (_localeFormatInfo = {
        placeholder: placeholder.join(delimeter),
        regexp: new RegExp('^' + regexp.join(delimeter.replace('.', '\\.')) + '$'),
        delimeter,
        month,
        day,
        year,
    });
}
export function dateToLocaleFormat(date) {
    if (!isValidDate(date)) {
        return '';
    }
    const language = window.navigator?.language || 'en-US';
    return new Intl.DateTimeFormat(language)
        .format(date)
        .replace(/[^0-9./-]/g, '');
}
/**
 * Generates `value` property of input[type="date"] for a given
 * input string.
 *
 * @param str - Input
 * @return Corresponding value property
 */
function localeValueToDateValue(str) {
    if (!str.length) {
        return ''; // empty string is a valid date input value
    }
    const dateLocale = localeFormatInfo();
    const parts = str.match(dateLocale.regexp);
    if (!parts) {
        return '';
    }
    // Remove full match to make the indices line up
    parts.shift();
    const dateValue = [
        parts[dateLocale.year],
        leftPad(parts[dateLocale.month], 2),
        leftPad(parts[dateLocale.day], 2),
    ].join('-');
    return isValidDate(fromDateValue(dateValue)) ? dateValue : '';
}
