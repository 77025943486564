import { type FieldPolicy } from '@apollo/client'
import { mergeDeep } from '@apollo/client/utilities'
import {
  type TRelayEdge,
  type TRelayPageInfo,
} from '@apollo/client/utilities/policies/pagination'
import { __rest } from 'tslib'

/**
 * Playback history pagination does not follow the relay specification
 * so this reimplements apollos relayStylePagination to work with before/after
 * as dates instead of cursors.
 *
 * It also differs from the relayStylePagination in that it respects before/after
 * from the query since our UI requires showing only a day per page.
 *
 * Based on the relayStylePagination implementation in
 * https://github.com/apollographql/apollo-client/blob/f1d8bc40c3d8e39340f721f4f1c3fd0ed77b8a6b/src/utilities/policies/pagination.ts#L91-L286
 */
export function playbackHistory({
  onError,
}: { onError: (error: string) => void }) {
  return {
    // Shard the cache on after to have individual entires per day so that it's possible to maintain
    // correct hasPreviousPage/hasNextPage for paginating within a day.
    // NOTE: before is used to paginate within a day so it must not be sharded.
    keyArgs: ['after'],

    read(existing, { canRead, readField, args }) {
      if (!existing) return existing
      const { before, after } = args || {}

      const edges: TRelayEdge<unknown>[] = []
      let firstEdgeCursor = ''
      let lastEdgeCursor = ''
      existing.edges.forEach((edge: any) => {
        const cursor = readField('cursor', edge) as string | undefined
        if (!cursor) {
          onError('Missing cursor field on playbackHistory edge, ignoring')
          return
        }
        if (before && cursor >= before) return
        if (after && cursor < after) return
        // Edges themselves could be Reference objects, so it's important
        // to use readField to access the edge.edge.node property.
        if (canRead(readField('node', edge))) {
          edges.push(edge)
          if (edge.cursor) {
            firstEdgeCursor = firstEdgeCursor || edge.cursor || ''
            lastEdgeCursor = edge.cursor || lastEdgeCursor
          }
        }
      })

      if (edges.length > 1 && firstEdgeCursor === lastEdgeCursor) {
        firstEdgeCursor = ''
      }

      const { startCursor, endCursor } = existing.pageInfo || {}

      return {
        // Some implementations return additional Connection fields, such
        // as existing.totalCount. These fields are saved by the merge
        // function, so the read function should also preserve them.
        ...getExtras(existing),
        edges,
        pageInfo: {
          ...existing.pageInfo,
          // If existing.pageInfo.{start,end}Cursor are undefined or "", default
          // to firstEdgeCursor and/or lastEdgeCursor.
          startCursor: startCursor || firstEdgeCursor,
          endCursor: endCursor || lastEdgeCursor,
        },
      }
    },

    merge(existing, incoming, { isReference, readField }) {
      if (!existing) {
        existing = {
          edges: [],
          pageInfo: {
            hasPreviousPage: false,
            hasNextPage: true,
            startCursor: '',
            endCursor: '',
          },
        }
      }

      if (!incoming) {
        return existing
      }

      const incomingEdges = incoming.edges
        ? incoming.edges.map((edge: any) => {
            if (isReference((edge = { ...edge }))) {
              // In case edge is a Reference, we read out its cursor field and
              // store it as an extra property of the Reference object.
              // @ts-expect-error
              edge.cursor = readField('cursor', edge)
            }
            return edge
          })
        : []

      if (incoming.pageInfo) {
        const { pageInfo } = incoming
        const { startCursor, endCursor } = pageInfo
        const firstEdge = incomingEdges[0]
        const lastEdge = incomingEdges[incomingEdges.length - 1]
        // In case we did not request the cursor field for edges in this
        // query, we can still infer cursors from pageInfo.
        if (firstEdge && startCursor) {
          firstEdge.cursor = startCursor
        }
        if (lastEdge && endCursor) {
          lastEdge.cursor = endCursor
        }
        // Cursors can also come from edges, so we default
        // pageInfo.{start,end}Cursor to {first,last}Edge.cursor.
        const firstCursor = firstEdge && firstEdge.cursor
        if (firstCursor && !startCursor) {
          incoming = mergeDeep(incoming, {
            pageInfo: {
              startCursor: firstCursor,
            },
          })
        }
        const lastCursor = lastEdge && lastEdge.cursor
        if (lastCursor && !endCursor) {
          incoming = mergeDeep(incoming, {
            pageInfo: {
              endCursor: lastCursor,
            },
          })
        }
      }

      const { startCursor, endCursor } = incoming.pageInfo
      const edges = [...existing.edges]

      // Find position to splice in the incoming edges
      // NOTE: edges are sorted in decending order so "after" is earlier in the array
      // and vice-versa
      let insertIndex = edges.length
      for (let i = 0; i < edges.length; i++) {
        const edge = edges[i]
        if (edge.cursor <= startCursor) {
          insertIndex = i
          break
        }
      }

      let overwriteCount = 0
      for (let i = insertIndex; i < edges.length; i++) {
        const edge = edges[i]
        if (edge.cursor < endCursor) {
          break
        }
        overwriteCount++
      }

      edges.splice(insertIndex, overwriteCount, ...incomingEdges)

      const pageInfo: TRelayPageInfo = {
        // The ordering of these two ...spreads may be surprising, but it
        // makes sense because we want to combine PageInfo properties with a
        // preference for existing values, *unless* the existing values are
        // overridden by the logic below, which is permitted only when the
        // incoming page falls at the beginning or end of the data.
        ...incoming.pageInfo,
        ...existing.pageInfo,
      }

      if (incoming.pageInfo) {
        const {
          hasPreviousPage,
          hasNextPage,
          startCursor,
          endCursor,
          ...extras
        } = incoming.pageInfo

        // If incoming.pageInfo had any extra non-standard properties,
        // assume they should take precedence over any existing properties
        // of the same name, regardless of where this page falls with
        // respect to the existing data.
        Object.assign(pageInfo, extras)

        // Keep existing.pageInfo.has{Previous,Next}Page unless the
        // placement of the incoming edges means incoming.hasPreviousPage
        // or incoming.hasNextPage should become the new values for those
        // properties in existing.pageInfo. Note that these updates are
        // only permitted when the beginning or end of the incoming page
        // coincides with the beginning or end of the existing data.
        if (insertIndex === 0) {
          if (void 0 !== hasNextPage) pageInfo.hasNextPage = hasNextPage
          if (void 0 !== startCursor) pageInfo.startCursor = startCursor
        }
        if (insertIndex + incomingEdges.length === edges.length) {
          if (void 0 !== hasPreviousPage)
            pageInfo.hasPreviousPage = hasPreviousPage
          if (void 0 !== endCursor) pageInfo.endCursor = endCursor
        }
      }

      return {
        ...getExtras(existing),
        ...getExtras(incoming),
        edges,
        pageInfo,
      }
    },
  } satisfies FieldPolicy<any>
}
// Returns any unrecognized properties of the given object.
const getExtras = (obj: Record<string, any>) => __rest(obj, notExtras)
const notExtras = ['edges', 'pageInfo']
