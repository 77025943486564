import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { Button } from './Button';
import { Section } from './Section';
import { withModifier } from './lib/modifier';
import portalContainer from './lib/portal-container';
import scrollToggle from './lib/scroll-toggle';
const SUPPORTED_TYPES = [
    'sheet',
    'dimmer',
    'overlay',
    'simple',
    'simple overlay',
];
ModalDefaultHeader.displayName = 'ModalHeader';
function ModalDefaultHeader(props) {
    return _jsx(Section, { type: "navigation", children: props.children });
}
ModalActions.displayName = 'ModalActions';
/**
 * Render actions row for {@link Modal}.
 *
 * @example
 * ```tsx
 * // Levels buttons (pill style)
 * <Modal onClose={onClose}>
 *   <Section>Content</Section>
 *   <Modal.Actions levels>
 *     <PillButton children="Cancel" />
 *     <PillButton variant="accent" children="Submit" />
 *   </Modal.Actions>
 * </Modal>
 *
 * // Legacy (flat) buttons
 * <Modal onClose={onClose}>
 *   <Section>Content</Section>
 *   <Modal.Actions>
 *     <Button children="Cancel" />
 *     <Button variant="primary" children="Submit" />
 *   </Modal.Actions>
 * </Modal>
 * ```
 */
function ModalActions(props) {
    return (_jsx("section", { className: withModifier('modal__actions', props.levels ? 'levels' : 'legacy'), children: _jsx("div", { className: "layout-row", children: props.children }) }));
}
export class Modal extends React.Component {
    static Actions = ModalActions;
    static propTypes = {
        onClose: PropTypes.func,
        closed: PropTypes.bool,
        closeText: PropTypes.node,
        closeButton: PropTypes.bool,
        actions: PropTypes.node,
        type(props, propName, componentName) {
            if (typeof props[propName] !== 'string') {
                return new Error(`Expect prop '${propName}' to be a string in ${componentName}`);
            }
            const t = props[propName];
            if (!t)
                return null;
            if (!SUPPORTED_TYPES.includes(t)) {
                return new Error('Unsupported value `' +
                    t +
                    '` for prop `type` in `' +
                    componentName +
                    '`, supported types are: ' +
                    SUPPORTED_TYPES.join(', ') +
                    '.');
            }
            return null;
        },
        width: PropTypes.string,
        wide: PropTypes.bool, // deprecated
        thin: PropTypes.bool, // deprecated
        noScroll: PropTypes.bool,
        inline: PropTypes.bool,
        portal: PropTypes.object,
        ignoreCloseInteractions: PropTypes.bool,
        sheetHeader: PropTypes.elementType,
    };
    static contextTypes = {
        modalForcedInline: PropTypes.bool,
    };
    static defaultProps = {
        type: 'overlay',
        closeText: 'Close',
        ignoreCloseInteractions: false,
    };
    state = {
        closed: !!this.props.closed,
        closedProp: this.props.closed,
    };
    static getDerivedStateFromProps(props, state) {
        if (props.closed !== state.closedProp) {
            return {
                closed: props.closed,
                closedProp: props.closed,
            };
        }
        return null;
    }
    componentDidMount() {
        window.addEventListener('keydown', this.handleKeyPress);
        if (!this.isInline) {
            scrollToggle.disable();
        }
    }
    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyPress);
        if (!this.isInline) {
            scrollToggle.enableOne();
        }
    }
    get isServerSide() {
        return typeof window === 'undefined';
    }
    get isInline() {
        return (this.props.inline === true ||
            this.context.modalForcedInline === true ||
            this.isServerSide);
    }
    get isFullscreen() {
        const { type } = this.props;
        return type === 'sheet' || type === 'dimmer';
    }
    get isIgnoringCloseInteractions() {
        return (!this.props.onClose ||
            this.props.ignoreCloseInteractions ||
            this.isFullscreen);
    }
    handleKeyPress = (e) => {
        switch (e.keyCode) {
            case 27: // Escape closes modal unless type is sheet
                if (!this.isIgnoringCloseInteractions) {
                    this.setState({ closed: true });
                }
                break;
        }
    };
    closeModal = () => this.setState({ closed: true });
    render() {
        const { isFullscreen } = this;
        let { actions, children, closed, closeButton, closeText, inline, noScroll, onClose, portal, ignoreCloseInteractions, sheetHeader, type, thin, wide, width, levels, ...props } = this.props;
        type = type === 'simple' ? 'simple overlay' : type || 'overlay';
        if (this.isInline)
            type += ' inline';
        // Support '80rem' 'wide', 'thin', 'xl', etc., widths
        width = wide ? 'wide' : thin ? 'thin' : width;
        let widthModifier;
        if (width !== 'auto' && !/^\d+/.test(width || '')) {
            widthModifier = width;
            width = undefined;
        }
        const Header = sheetHeader || ModalDefaultHeader;
        props.className =
            withModifier('modal', ...type.split(' '), widthModifier, noScroll ? 'no-scroll' : null, 'no-backdrop') + (props.className ? ' ' + props.className : '');
        // Automatically wrap simple modal contents with a section for consistency
        if (type.indexOf('simple') >= 0) {
            children = _jsx(Section, { children: children });
        }
        const layer = (_jsx(CSSTransition, { appear: true, in: !this.state.closed, timeout: 250, classNames: "modal-", onExited: onClose, children: _jsxs("div", { "data-is-modal": true, ...props, children: [!isFullscreen && (_jsx("div", { className: "modal__backdrop", onClick: this.isIgnoringCloseInteractions
                            ? undefined
                            : (e) => {
                                e.stopPropagation();
                                this.closeModal();
                            } })), isFullscreen && (sheetHeader || actions || onClose) && (_jsxs(Header, { onClose: this.closeModal, children: [actions, !!onClose && (_jsx(Button, { onClick: this.closeModal, children: closeText }))] })), _jsxs("div", { className: "modal__content", style: { width }, children: [!isFullscreen && closeButton && (_jsx(Button, { flat: true, icon: "cross", className: "modal__content--close", onClick: this.closeModal })), children, !!actions && _jsx(ModalActions, { levels: levels, children: actions })] })] }) }));
        return this.isInline
            ? layer
            : ReactDOM.createPortal(layer, portal || portalContainer());
    }
}
