import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import * as React from 'react';
export const BaseThrobber = React.forwardRef((props, ref) => {
    const cx = classNames('throbber', {
        'throbber--s': props.size === 'small',
        'throbber--l': props.size === 'large' || props.size === 'xlarge',
        'throbber--centered': props.centered,
        'throbber--inline': props.inline,
        'throbber--inverted': props.inverted,
    }, props.className);
    return _jsx("span", { className: cx });
});
BaseThrobber.displayName = 'BaseThrobber';
export { BaseThrobber as Throbber };
