import * as React from 'react';
import { eventFromOnChange } from './eventFromOnChange';
import { getControlledValue } from './getControlledValue';
BufferedField.displayName = 'BufferedField';
/**
 * Wraps any kind of form input to make it buffered, allowing the
 * passed `onChange` to be selectively called instead of automatically on every
 * value change.
 *
 * This is typically used to debounce an input and/or apply changes upon
 * pressing Enter, or on input blur.
 *
 * The component expects `children` to be a render prop function (see
 * https://reactjs.org/docs/render-props.html). This function will be called on
 * every render of the <BufferedField>, as well as when the buffered value
 * changes.
 *
 * Field value is provided to `<BufferedField>` via `value`, or `model` +
 * `field`/`name`, similar to how `useField()` powered inputs work.
 * Any changes to this field value will result in the buffered value being
 * reset to it, dropping any uncommitted pending changes.
 *
 * @example Debounced search input whose value is immediately applied on blur/Enter
 * const x = <BufferedField
 *   value={searchQuery}
 *   onChange={event => { setSearchQuery(event.target.value) }}
 *   debounce={500}
 *   children={({ value, queueChange, commit, cancel, props }) => (
 *     <input
 *       {...props}
 *       value={value}
 *       onChange={queueChange}
 *       onBlur={commit}
 *       onKeyDown={event => {
 *         if (event.key === 'Enter') {
 *           event.stopPropagation()
 *           commit()
 *         }
 *       }}
 *       suffix={!!value.length && (
 *         <Button flat icon="cross" onClick={() => setSearchQuery('') } />
 *       )}
 *     />
 *   )}
 * />
 */
export function BufferedField({ value, model, defaultValue, onChange, onChangeCancel, cancelPendingOnChange = false, debounce = 0, children, ...props }) {
    const upstreamValue = getControlledValue({
        value,
        model,
        defaultValue,
        name: props.name,
        field: props.field,
    });
    // Internal buffered state
    const state = React.useRef({ onChange, onChangeCancel }); // eslint-disable-line indent
    const upstreamValueChanged = state.current.upstreamValue !== upstreamValue;
    Object.assign(state.current, { onChange, onChangeCancel, upstreamValue });
    const [bufferedValue, setBufferedValue] = React.useState(upstreamValue);
    const commit = React.useCallback(() => {
        const ctx = state.current;
        if (ctx.pendingArgs) {
            // @ts-ignore Expected at least X arguments, but got X or more.
            ctx.onChange(...ctx.pendingArgs);
        }
        else if (ctx.onChangeCancel) {
            ctx.onChangeCancel();
        }
        clearTimeout(ctx.debounceTimeout);
        ctx.pendingArgs = ctx.debounceTimeout = undefined;
    }, []);
    const cancel = React.useCallback(() => {
        const ctx = state.current;
        ctx.onChangeCancel?.();
        clearTimeout(ctx.debounceTimeout);
        ctx.pendingArgs = ctx.debounceTimeout = undefined;
        setBufferedValue(state.current.upstreamValue);
    }, []);
    const queueChange = React.useCallback((...args) => {
        // @ts-ignore Expected at least X arguments, but got X or more.
        const event = eventFromOnChange(...args);
        const ctx = state.current;
        clearTimeout(ctx.debounceTimeout);
        ctx.pendingArgs = args;
        ctx.debounceTimeout =
            debounce > 0 ? setTimeout(commit, debounce) : undefined;
        setBufferedValue(event.target.value);
    }, [commit, debounce]);
    // Apply upstream value change when there's no pending value
    if (upstreamValueChanged &&
        (cancelPendingOnChange || !state.current.pendingArgs)) {
        const ctx = state.current;
        clearTimeout(ctx.debounceTimeout);
        ctx.pendingArgs = ctx.debounceTimeout = undefined;
        setBufferedValue((ctx.upstreamValue = upstreamValue));
    }
    // Cleanup debounce state on unmount
    React.useEffect(() => {
        return cancel;
    }, [cancel]);
    return children({
        value: bufferedValue,
        upstreamValue,
        isPending: bufferedValue !== upstreamValue,
        queueChange: queueChange,
        onChange,
        commit,
        cancel,
        props,
    });
}
