// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { type ApolloError, type ApolloLink, fromPromise } from '@apollo/client'
import { type ErrorResponse, onError } from '@apollo/client/link/error'
import { GraphQLError } from 'graphql'
import { type NetworkErrorWithStatus, isFetchNetworkError } from '../errors'
import { getMainDefinition } from '../getMainDefinition'

// Augment ApolloClient interfaces
declare module '@apollo/client' {
  export interface DefaultContext {
    /**
     * Errors to to set `error.gqlContext.isExpected = true` on when encountered by
     * {@link errorLink}. Matched against `error.extensions.code`, `error.message`
     * and HTTP status code.
     *
     * This property can then be used to identify errors that should not be
     * reported to Sentry.
     */
    expectedErrors?: ExpectedErrorValue[]
    /**
     * If errors should be reported to Sentry by the link. By default errors needs
     * to be uncaught in order to reach Sentry, but when using the query hooks all
     * errors are caught and provided on the error property, which will not report
     * them to Sentry unless explicitly done so in the component.
     * Setting this to true make the link chain report these on reponse even if the
     * error is caught.
     */
    reportErrors?: boolean
  }
}

/**
 * {@link ApolloError} with additional `gqlContext` property populated by {@link errorLink}.
 *
 * @group Error Handling
 */
export type ApolloErrorExtra<T extends Error = ApolloError> = T & {
  /** GraphQL metadata for query/mutation that triggered the error */
  gqlContext: {
    /** GraphQL operation name */
    operation: string
    /** GraphQL operation type */
    type: 'query' | 'mutation' | 'subscription' | undefined
    /** GraphQL operation variables */
    variables: Record<string, any>
    /** Status code */
    status?: number
    /** Error code */
    code?: string
    /** Marks error as expected, preventing it from being reported */
    isExpected?: boolean
    /** Used to generate `error.sentryFingerprint` */
    identifier: string[]
    /** traceId sent in traceparent header */
    traceId?: string | false
  }
}

/**
 * Type guard to that returns true if the specified error is an
 * {@link ApolloErrorExtra}.
 *
 * Only works in environments using {@link patchApolloError} and {@link errorLink}.
 *
 * @group Error Handling
 */
export function isApolloError<T extends Error = Error>(
  error: T,
): error is ApolloErrorExtra<T> {
  return !!error && 'gqlContext' in error && !!error.gqlContext
}

/**
 * Errors to to set `error.gqlContext.isExpected = true` on when encountered by
 * {@link errorLink}. Matched against `error.extensions.code`, `error.message`
 * and HTTP status code.
 *
 * @group Error Handling
 * @protected
 */
export type ExpectedErrorValue =
  | 'UNAUTHENTICATED'
  | 'UNAUTHORIZED'
  | 'FORBIDDEN'
  | 'BAD_USER_INPUT'
  | 'NOT_FOUND'
  | 'RATE_LIMITED'
  | (string & Record<never, never>) // allow all strings but only suggest the above
  | RegExp
  | number

/**
 * Options for {@link errorLink}.
 *
 * @group Error Handling
 */
export type ErrorLinkOptions = {
  /**
   * Providing this function will enable the link to autotically retry requests
   * that fail due to auth token being expired.
   */
  refreshAuthToken?: () =>
    | string
    | null
    | undefined
    | Promise<string | null | undefined>
  /** Optional callback triggered on error - can be used to log errors in a consistent way */
  onError?: (
    error: ApolloErrorExtra<GraphQLError | NetworkErrorWithStatus>,
    response: ErrorResponse,
  ) => void
  expectedErrors?: ExpectedErrorValue[]
}

/**
 * Default errors to ignore by {@link errorLink}
 *
 * @group Error Handling
 * @protected
 */
export const DEFAULT_EXPECTED_ERRORS = [
  /^(Network request failed|Unable to resolve host|Software caused connection abort|Network is down|Socket is not connected|Connection reset by peer|Client is null|4499: Terminated)/i,
] satisfies ExpectedErrorValue[]

/**
 * {@link ApolloLink} that decorates GraphQL and network errors with additional
 * information available via the `gqlContext` property.
 *
 * Pair with {@link patchApolloError} to expose the added metadata on the errors
 * propagated to the apollo-client APIs (`apollo.query()` and the like).
 *
 * If a `onError()` callback is provided it will also be called once per error
 * encountered (a single GraphQL request may result int multiple errors), which
 * can be used to debug the errors.
 *
 * @group Error Handling
 */
export function errorLink(options: ErrorLinkOptions) {
  return onError((err) => {
    const { operationName = '', variables } = err.operation
    const operationType = getMainDefinition(err.operation.query).operation
    const context = err.operation.getContext()

    const expectedErrors = (context.expectedErrors || []).concat(
      options.expectedErrors ?? DEFAULT_EXPECTED_ERRORS,
    )
    const checkExpected = (input: string | number | undefined) => {
      if (input == null || input === '') {
        return false
      }
      return expectedErrors.some((expected) => {
        return {}.toString.call(expected) === '[object RegExp]'
          ? (expected as RegExp).test(String(input))
          : String(expected) === String(input)
      })
    }

    if (err.networkError) {
      const error = err.networkError as ApolloErrorExtra<NetworkErrorWithStatus>
      const status = error.statusCode
      const isExpected =
        isFetchNetworkError(error) ||
        checkExpected(status) ||
        checkExpected(error.message)
      error.gqlContext = Object.assign(
        {
          traceId: context.traceId,
          identifier: [operationName].concat(status ? [`[${status}]`] : []),
          isExpected,
          operation: operationName,
          type: operationType,
          status,
          variables,
        },
        error.gqlContext,
      )

      // Retry Unauthorized/Forbidden errors after refreshing token
      if (
        !isExpected &&
        [401, 403].includes(status!) &&
        context.auth !== false // skip queries that don't require auth (such as RefreshLogin)
      ) {
        return fromPromise(Promise.resolve(options.refreshAuthToken?.()))
          .filter((token) => {
            if (
              !token ||
              token === context.headers?.Authorization?.split(' ')[1]
            ) {
              options.onError?.(
                Object.assign(
                  new Error(
                    `errorLink: Unable to refresh token when recovering from ${operationName}`,
                  ),
                  {
                    cause: error,
                    gqlContext: error.gqlContext,
                    token,
                  },
                ),
                err,
              )
            }
            return !!token
          })
          .flatMap((token) => {
            context.headers!.Authorization = token
            err.operation.setContext(context)
            return err.forward(err.operation)
          })
      }

      options.onError?.(error, err)
    }

    if (err.graphQLErrors) {
      for (let i = 0; i < err.graphQLErrors.length; i++) {
        let error = err.graphQLErrors[i] as ApolloErrorExtra<GraphQLError>
        // graphQLErrors are sometimes just objects with the same properties of GraphQLError, make them
        // into actual GraphQLErrors as all the types suggests them are and which get a much better
        // representation by Sentry
        if (!(error instanceof Error)) {
          error = new GraphQLError(
            (error as any).message,
            error,
          ) as ApolloErrorExtra<GraphQLError>
        }
        let status: number | undefined
        let code = error.extensions?.code as string
        const parts = error.message?.match(/HTTP Error ?(.*): (.*)/)
        if (parts) {
          status ||= parseInt(parts[1] || '400', 10)
          code ||= parts[2]
        }
        error.gqlContext = Object.assign(
          {
            traceId: context.traceId,
            code,
            identifier: [operationName].concat((error.path || []) as any),
            isExpected:
              checkExpected(error.message) ||
              checkExpected(code) ||
              checkExpected(status),
            operation: operationName,
            type: operationType,
            status,
            variables,
          },
          error.gqlContext,
        )
        ;(err.graphQLErrors[i] as ApolloErrorExtra).gqlContext =
          error.gqlContext
        options.onError?.(error, err)
      }
    }
  })
}

/**
 * Patches the specified {@link ApolloError} prototype, adding a `gqlContext`
 * property that returns the first `gqlContext` available in any of the
 * underlying errors.
 *
 * Returns a function that undoes the patch.
 *
 * @example
 * ```ts
 * import { ApolloError } from '@apollo/client'
 * import { patchApolloError } from '@soundtrackyourbrand/apollo-client'
 *
 * patchApolloError(ApolloError)
 * ```
 *
 * @group Error Handling
 */
export function patchApolloError(errorClass: typeof ApolloError) {
  // @ts-ignore
  if (errorClass.prototype.gqlContext_) {
    return
  }

  const originalPrototype = errorClass.prototype

  errorClass.prototype = Object.create(originalPrototype)
  Object.defineProperties(errorClass.prototype, {
    gqlContext_: {
      enumerable: false,
      writable: true,
    },
    gqlContext: {
      enumerable: true,
      get() {
        return (this.gqlContext_ ||= [this.networkError]
          .concat(this.graphQLErrors)
          .find((err) => err?.gqlContext)?.gqlContext)
      },
    },
  })

  return () => {
    errorClass.prototype = originalPrototype
  }
}
