import { type UnknownAction, createSelector } from '@reduxjs/toolkit'
import { desktopSlice } from '@soundtrack/desktop-shared-components/desktopSlice'
import { type RootState, type Store, curry } from '../storeUtils.js'
import type { Playback, Sequencing, Session, TysonApi } from './index.js'
import type { Message } from './subscribe.js'

export const ActionTypes = {
  update: 'TYSON/update',
} as const

/** (Re)start subscription to synchronize tyson with redux state */
export function startSubscription(
  store: Store,
  tyson: TysonApi,
  playerId: string,
) {
  let oldSoundZone: undefined | null | { id: string; name: string }
  let unsubscribeEverything: undefined | (() => void)

  function subscribeEverything() {
    if (unsubscribeEverything) unsubscribeEverything()
    unsubscribeEverything = tyson.subscribe.everything((message) => {
      if (message.session?.soundZone) {
        const soundZone = message.session?.soundZone || null
        if (
          oldSoundZone !== undefined &&
          ((oldSoundZone?.id ?? '') !== soundZone?.id ||
            (oldSoundZone?.name ?? '') !== soundZone?.name)
        ) {
          store.dispatch(
            actions.update({ session: { subscription: undefined } }, playerId),
          )
          // Resubscribe when pairing changes as we have seen some cases
          // of data from the initial zone never updating after pairing
          // changes
          subscribeEverything()
        }
        oldSoundZone = soundZone
      }

      store.dispatch(actions.update(message, playerId))
    })
  }

  subscribeEverything()

  // The everything subscription doesn't seem to always catch paring changes
  const unsubscribePaired = tyson.subscribe.paired((paired) => {
    if (paired === false) {
      oldSoundZone = null
    }
    store.dispatch(
      actions.update({ session: { isPaired: { isPaired: paired } } }, playerId),
    )
  })

  return () => {
    unsubscribeEverything?.()
    unsubscribePaired()
  }
}

export const actions = {
  update: (data: Message, playerId: string) => ({
    type: ActionTypes.update,
    data,
    playerId,
  }),
}

export default actions

const INITIAL_STATE = {
  session: {} as Partial<Session.SubscribeSessionResponse>,
  sequencing: {} as Partial<Sequencing.SubscribeSequencingResponse>,
  playback: {} as Partial<Playback.SubscribePlaybackResponse>,
} as const

export type Action = ReturnType<(typeof actions)[keyof typeof actions]>
export type ReducerState = Record<string, State>
export type State = Readonly<typeof INITIAL_STATE>

function mergeTysonState(to: any, from: any, depth = 0) {
  const recreated = false
  for (const key of Object.keys(from)) {
    if (!recreated) {
      to = { ...to }
    }
    if (to[key] && from[key] && typeof from[key] === 'object') {
      to[key] = mergeTysonState(to[key], from[key], depth + 1)
    } else if (depth >= 2 || from[key] !== undefined) {
      to[key] = from[key]
    }
  }
  return to
}

export function reducer(
  state: ReducerState = {},
  _action: UnknownAction,
): ReducerState {
  const action = _action as Action
  switch (action.type) {
    case ActionTypes.update: {
      const playerState = state[action.playerId] ?? INITIAL_STATE
      const newState = mergeTysonState(playerState, action.data)

      return {
        ...state,
        [action.playerId]: newState,
      }
    }
    default: {
      return state
    }
  }
}

const state = (playerId: string, rootState: RootState): State =>
  rootState.tyson[playerId] ?? INITIAL_STATE
const session = (playerId: string, rootState: RootState) =>
  state(playerId, rootState).session

/** Whether or not tyson is currently paired to a zone */
const paired = (playerId: string, rootState: RootState) =>
  session(playerId, rootState)?.isPaired?.isPaired
const account = createSelector(paired, session, (paired, session) =>
  paired ? session.account : undefined,
)
/** Paired zone */
const soundZone = createSelector(paired, session, (paired, session) =>
  paired ? session.soundZone : undefined,
)
/** Device (paired or previously paired) */
const device = createSelector(session, (session) => session.device)

/** Is tyson paired to paid zone? `undefined` if the required data is not yet available */
const canPlayMusic = curry(
  createSelector(session, (session): boolean | undefined => {
    const paired = session?.isPaired?.isPaired
    const paidUntil = session?.subscription?.paidUntil
    // Sanity check to see that tyson has updated data
    if (
      !paired ||
      !session ||
      !session.soundZone?.id ||
      !session.account?.businessName ||
      session.soundZone.account !== session.account.id ||
      !(paidUntil instanceof Date)
    ) {
      return undefined // We don't know for sure yet (still waiting for data from tyson)
    }
    return paidUntil > new Date() || session?.subscription?.gracePeriod
  }),
)

export const selectors = {
  token: curry(
    (playerId: string, rootState: RootState) =>
      session(playerId, rootState)?.token?.token,
  ),
  pairedRaw: paired,
  paired: curry(paired),
  errors: createSelector(session, (session) => session?.errors?.errors),
  device: curry(device),
  account: curry(account),
  soundZone: curry(soundZone),
  remoteCode: curry(
    createSelector(soundZone, (soundZone) => soundZone?.remoteCode),
  ),
  versions: curry(createSelector(session, (session) => session?.versions)),
  unsupported: (rootState: RootState) =>
    desktopSlice.selectors.debugFlag(rootState as any, 'forceSupportLevel') ===
      'unsupported' ||
    Object.keys(rootState.tyson).some(
      (playerId) => session(playerId, rootState)?.versions?.isUnsupported,
    ),
  canPlayMusic,
} as const
