import * as React from 'react';
import { findScrollParent } from './lib/dom';
const HAS_INTERSECTION_OBSERVER = typeof window !== 'undefined' && !!window?.IntersectionObserver;
/**
 * Uses the native IntersectionObserver API to determine visibility of the
 * `target` element with regards to the provided `viewport` element (or the
 * scroll parent if omitted). Visibility is reported to the provided `callback`
 * every time it changes.
 *
 * In browsers which lack support for the native API the target will be reported
 * as visible immediately upon being mounted.
 *
 * See https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver
 * for more information.
 */
export function useVisibilityCallback({ 
/** Callback triggered when visibility changes */
callback, target, viewport = undefined, rootMargin = '0px', threshold = 0, once = false, }) {
    const context = React.useRef({
        callback,
        visible: null,
    });
    Object.assign(context.current, { callback });
    React.useLayoutEffect(() => {
        const targetElement = target.current;
        if (!targetElement) {
            return;
        }
        if (!HAS_INTERSECTION_OBSERVER) {
            context.current.callback(true);
            return;
        }
        let root = viewport && viewport.current instanceof HTMLElement
            ? viewport.current
            : findScrollParent(targetElement);
        if (root === document.documentElement) {
            root = null;
        }
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                const inViewport = entry.isIntersecting;
                if (inViewport && once) {
                    observer.unobserve(entry.target);
                }
                if (inViewport !== context.current.visible) {
                    context.current.callback(inViewport);
                    context.current.visible = inViewport;
                }
            });
        }, {
            root,
            rootMargin,
            threshold,
        });
        observer.observe(targetElement);
        return () => {
            observer.unobserve(targetElement);
        };
    }, [target, viewport, rootMargin, threshold, once]);
}
/**
 * Uses the native IntersectionObserver API to determine visibility of the
 * `target` element with regards to the provided `viewport` element (or the
 * scroll parent if omitted).
 *
 * Combines {@link useVisibilityCallback} with a `useState` hook, see its docs
 * for more information.
 *
 * @returns `true` if the element is visible, `false` if hidden, and `null` if
 *          not yet determined
 */
export function useVisibility(props) {
    const [visible, setVisible] = React.useState(HAS_INTERSECTION_OBSERVER ? null : true);
    useVisibilityCallback({ ...props, callback: setVisible });
    return visible;
}
