export interface PseudoLocation {
  protocol: string
  hostname: string
  pathname: string
  search: string
  hash: string
  host: string
  port: string
  href: string
}

/**
 * Returns a window.location-like object from the given URL.
 * URL must be absolute.
 *
 * @param {string} href - URL to parse
 * @return {null|object} window.location-like object for valid URLs, otherwise null
 */
export function getLocation(href: string): PseudoLocation | null {
  if (typeof href !== 'string') {
    return null
  }

  const match = href.match(
    /^(https?:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/,
  )

  return (
    match && {
      href,
      protocol: match[1],
      host: match[2],
      hostname: match[3],
      port: match[4],
      pathname: match[5],
      search: match[6],
      hash: match[7],
    }
  )
}
