import { ConsentCallback, ConsentMethod, ConsentValue } from './constants'
import { ComponentType, h, replace } from './jsx-dom'
import './polyfills'
import { LocaleGlossary, TFunction, getT, loadGlossary } from './t'

/** Consent form ID/class prefix */
export const ID = 'tjscm'
/** Consent form option ID/class prefix */
const OPT_ID = ID + '-opt'

export function showConsentModal(
  onSave: (consentValue: ConsentValue) => void,
  initialConsent: ConsentValue | 0 = 0,
  locale: string,
): Promise<ConsentModal> {
  return loadGlossary(locale).then((glossary) => {
    const modal = new ConsentModal({
      onSubmit: onSave,
      glossary,
      initialConsent,
    })
    window.document.body.appendChild(modal.template())
    return modal
  })
}

const CSS = `
#${ID}-modal {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  max-height: calc(100vh - 10vw);
  width: 640px;
}
#${ID}-modal label { cursor: pointer; }
#${ID}-modal a { text-decoration: underline; }
#${ID}-modal .icon { width: 1em; height: 1em; }
#${ID}-section {
  padding-top: 0;
  padding-bottom: 16px;
  max-height: none;
  overscroll-behavior: none;
  overflow-x: hidden;
  overflow-y: auto;
}
#${ID}-options { display: none }
.${ID}-form--options-visible #${ID}-options { display: block }
.${ID}-form--options-visible ~ #${ID}-fade::after {
  display: block;
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 20px;
  height: 32px;
  background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
  z-index: 1;
  pointer-events: none;
  content: '';
}
.${OPT_ID}-heading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}
.${OPT_ID}-title {
  display: inline;
  margin: 0;
}
.${OPT_ID}-input { /* remove once storefront uses latest ui */
  display: inline-flex;
  width: 1em;
  margin-right: 1em;
}
.${OPT_ID}-toggler { display: none }
@media screen and (max-width: 480px) {
  .${ID}-main-buttons { flex-direction: column-reverse }
  .${ID}-preferences-button { margin: 0 }
  .${ID}-ctas { flex: 0 0 auto }
}
@media screen and (max-width: 720px) {
  .${OPT_ID} {
    border-bottom: 1px solid #f2f2f2;
    margin-bottom: 16px;
  }
  .${OPT_ID}-toggle:not(:checked) ~ .${OPT_ID}-desc { display: none }
  .${OPT_ID}-toggler {
    flex-grow: 1;
    margin-right: -0.5em;
    padding: 0 0.5em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 1.25em;
  }
  .${OPT_ID}-toggle:checked ~ .${OPT_ID}-heading .icon {
    transform: scale(-1);
  }
}
`

export class ConsentModal implements ComponentType {
  options: ConsentOption[]
  onSubmit: ConsentCallback
  element?: HTMLElement
  styleElement?: HTMLStyleElement
  form?: HTMLElement
  submitButtons?: HTMLElement
  preferencesButton?: HTMLElement
  acceptButton?: HTMLElement
  secondaryButton?: HTMLElement
  section?: HTMLElement
  subtitle?: HTMLElement
  className = 'modal modal--overlay'
  t: TFunction

  constructor(options: {
    onSubmit: ConsentCallback
    glossary: LocaleGlossary
    initialConsent?: ConsentValue | 0
  }) {
    // Inject CSS
    const style = document.createElement('style') as HTMLStyleElement
    style.id = ID + '-style'
    style.type = 'text/css'
    style.appendChild(document.createTextNode(CSS))
    document.getElementById(style.id)?.remove()
    document.head.appendChild(style)
    this.styleElement = style

    this.onSubmit = options.onSubmit
    const t = getT(options.glossary)

    this.options = [
      new ConsentOption(
        ConsentValue.Required,
        t('required.name', false),
        t('required.description', false),
        true,
      ),
      new ConsentOption(
        ConsentValue.Analytics,
        t('analytics.name', false),
        t('analytics.description', false),
        (options.initialConsent || 0) >= ConsentValue.Analytics,
      ),
      new ConsentOption(
        ConsentValue.Marketing,
        t('marketing.name', false),
        t('marketing.description', false),
        (options.initialConsent || 0) >= ConsentValue.Marketing,
      ),
    ]

    this.t = t
  }

  classes(modifiers: string) {
    return (
      `${ID} modal ` +
      ('active overlay ' + modifiers)
        .split(' ')
        .filter(Boolean)
        .map((m) => 'modal--' + m)
        .join(' ')
    )
  }

  onFormSubmit = (event: SubmitEvent & { submitter: HTMLElement | null }) => {
    event.preventDefault()
    event.stopPropagation()

    switch (event.submitter?.id) {
      case `${ID}-accept-all-button`:
        this.acceptAll()
        break
      case `${ID}-secondary-button`:
        if (this.consentLevelsVisible()) {
          this.saveConsentLevel()
        } else {
          this.rejectAll()
        }
        break
      default:
        // Default to primary action
        if (this.consentLevelsVisible()) {
          this.saveConsentLevel()
        } else {
          this.acceptAll()
        }
        break
    }
  }

  acceptAll = () => {
    this.onSubmit(ConsentValue.Marketing, ConsentMethod.AcceptAll)
    this.closeModal()
  }

  rejectAll = () => {
    this.onSubmit(ConsentValue.Required, ConsentMethod.RejectAll)
    this.closeModal()
  }

  showConsentLevels = () => {
    this.preferencesButton?.remove()
    this.secondaryButton!.innerHTML = this.t('save', false)
    this.secondaryButton!.classList.add('btn--primary')
    this.acceptButton!.classList.remove('btn--primary')
    this.form!.classList.add(`${ID}-form--options-visible`)

    replace(
      this.subtitle!,
      h('p', {
        class: 'secondary label-text',
        children: this.t('help', {
          cookiePolicy: h('a', {
            href: this.t('cookiePolicyUrl', false),
            class: 'whitespace-nowrap',
            target: '_blank',
          }),
          cookieTable: h('a', {
            href: this.t('cookieTableUrl', false),
            class: 'whitespace-nowrap',
            target: '_blank',
          }),
        }),
      }),
    )
  }

  consentLevelsVisible = () =>
    this.form!.classList.contains(`${ID}-form--options-visible`)

  saveConsentLevel = () => {
    let i = this.options.length - 1
    for (; i > 0; i--) {
      if (this.options[i].input!.checked) {
        break
      }
    }
    this.onSubmit(i + 1, ConsentMethod.MultiChoice)
    this.closeModal()
  }

  closeModal = () => {
    this.element!.className = this.classes('exit exit-active')
    setTimeout(() => {
      this.element?.remove()
      this.styleElement?.remove()
      ;(['element', 'subtitle'] as const).forEach((key) => {
        // @ts-ignore
        this[key] = null
      })
    }, 500)
  }

  template(): HTMLElement {
    const t = this.t

    return (this.element = h('div', {
      id: ID,
      class: this.classes(''),
      style: 'overflow-y: auto',
      children: (this.form = h('form', {
        onsubmit: this.onFormSubmit,
        id: ID + '-modal',
        class: `modal__content ${ID}-form`,
        children: [
          h('section', {
            class: 'section--no-bottom',
            children: h('h3', { children: t('headline') }),
          }),
          (this.section = h('section', {
            id: ID + '-section',
            class: 'section--scrolled section--padded',
            children: [
              (this.subtitle = h('p', {
                class: 'secondary label-text mb-0',
                children: t('description', {
                  cookiePolicy: h('a', {
                    href: t('cookiePolicyUrl', false),
                    class: 'whitespace-nowrap',
                    target: '_blank',
                  }),
                  privacyPolicy: h('a', {
                    href: t('privacyPolicyUrl', false),
                    class: 'whitespace-nowrap',
                    target: '_blank',
                  }),
                }),
              })),
              h('div', {
                id: ID + '-options',
                children: this.options.map((option) => option.template()),
              }),
            ],
          })),
          h('div', { id: ID + '-fade', class: 'relative' }),
          h('section', {
            class: `${ID}-main-buttons inline-elements flex`,
            children: [
              (this.preferencesButton = h('button', {
                onclick: this.showConsentLevels,
                id: `${ID}-preferences-button`,
                class: `${ID}-preferences-button btn`,
                children: t('preferences'),
              })),
              (this.submitButtons = h('div', {
                class: `${ID}-ctas flex inline-elements`,
                children: [
                  (this.secondaryButton = h('button', {
                    type: 'submit',
                    id: `${ID}-secondary-button`,
                    class: 'btn btn--wide',
                    children: t('rejectAll'),
                  })),
                  (this.acceptButton = h('button', {
                    type: 'submit',
                    id: `${ID}-accept-all-button`,
                    class: 'btn btn--primary btn--wide',
                    children: t('acceptAll'),
                  })),
                ],
              })),
            ],
          }),
        ],
      })),
    }))
  }
}

class ConsentOption implements ComponentType {
  element?: HTMLElement
  input?: HTMLInputElement

  // eslint-disable-next-line no-useless-constructor
  constructor(
    public id: ConsentValue,
    public title: string,
    public description: string,
    protected initiallyChecked = false,
  ) {}

  onChange = (event: Event) => {
    const input = event.target as HTMLInputElement
    if (this.id === ConsentValue.Required) {
      event.preventDefault()
      event.stopPropagation()
      input.checked = true
      return
    }
    // Check all consents above on check and uncheck all consents below on uncheck
    const { checked } = input
    const to = checked ? this.id : ConsentValue.Marketing
    // Starts with 2 as the first checkbox is required and is always checked
    for (let i = checked ? 2 : this.id + 1; i <= to; i++) {
      const checkbox = document.getElementById(OPT_ID + i) as HTMLInputElement
      checkbox.checked = checked
    }
  }

  template(): HTMLElement {
    const id = OPT_ID + this.id
    const idToggle = id + '-toggle'
    const isStatic = this.id === ConsentValue.Required

    return (this.element = h('div', {
      class: OPT_ID,
      children: [
        h('input', {
          type: 'checkbox',
          id: idToggle,
          class: OPT_ID + '-toggle',
          style: 'display: none',
          value: String(this.id),
        }),
        h('div', {
          class: OPT_ID + '-heading',
          children: [
            h('label', {
              class: 'inline-label c-grow',
              children: [
                (this.input = h('input', {
                  type: 'checkbox',
                  name: String(this.id),
                  id,
                  class: OPT_ID + '-input',
                  checked: isStatic || this.initiallyChecked,
                  disabled: isStatic,
                  onchange: this.onChange,
                })),
                h('h4', { class: OPT_ID + '-title', children: this.title }),
              ],
            }),
            h('label', {
              for: idToggle,
              title: 'Toggle details',
              class: OPT_ID + '-toggler',
              children: h('svg', {
                class: 'icon',
                // @ts-ignore viewBox type
                viewBox: '0 0 24 24',
                xmlns: 'http://www.w3.org/2000/svg',
                children: h('path', {
                  'fill-rule': 'evenodd',
                  // @ts-ignore d type
                  d: 'M2.2928 7.707L12 17.414l9.707-9.707-1.4142-1.4143-8.2929 8.2929L3.707 6.2927 2.2928 7.707z',
                }),
              }),
            }),
          ],
        }),
        h('label', {
          for: id,
          class: OPT_ID + '-desc',
          style: isStatic ? 'cursor: default' : undefined,
          children: h('p', {
            class: 'subtle text3',
            children: this.description,
          }),
        }),
      ],
    }))
  }
}
