/**
 * Substitutes variables in a placeholder url to turn it into an image url.
 */
export function expandPlaceholderUri<Uri extends string | null | undefined>(
  /** URI containing '%w' and/or '%h' placeholders */
  uri: Uri,
  /** Width in pixels */
  width: number,
  /** Height in pixels, defaults to value of width */
  height: number = width,
): Exclude<Uri, null> {
  return uri?.replace('%w', String(width))?.replace('%h', String(height)) as any
}
