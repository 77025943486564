import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Throbber } from './BaseThrobber';
Tag = React.memo(Tag);
Tag.propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.bool,
    label: PropTypes.node,
    block: PropTypes.bool,
    className: PropTypes.string,
    field: PropTypes.string,
    fieldRef: PropTypes.func,
    loading: PropTypes.bool,
    type: PropTypes.oneOf(['primary', 'secondary']).isRequired,
    wide: PropTypes.bool,
};
Tag.displayName = 'Tag';
export function Tag(props) {
    const { block, className, disabled, field, fieldRef, loading, onChange, primary, secondary, type, value, wide, ...inputProps } = props;
    const isPrimary = type === 'primary';
    const isSecondary = type === 'secondary';
    const cx = classNames('tag', {
        'tag--is-disabled': disabled,
        'tag--is-active': !!value,
        'tag--primary': isPrimary,
        'tag--secondary': isSecondary,
        'tag--block': block,
        'tag--wide': wide,
        'tag--loading': loading,
    }, className);
    return (_jsxs("label", { className: cx, children: [_jsx("input", { ref: fieldRef, type: "checkbox", disabled: disabled, checked: !!value, onChange: (e) => !disabled && !loading && onChange(field, !value), ...inputProps }), _jsx("span", { name: "check", className: "tag__label", children: inputProps.label }), loading && _jsx(Throbber, {}, "throbber")] }));
}
