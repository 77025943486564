/**
 * Returns the value for a given HTML form field.
 *
 * @param input - HTML Element to get input value from
 * @return The value for the given input
 */
export function getInputValue(input) {
    switch (input.type) {
        case 'file':
            return input.files;
        case 'number':
        case 'range':
            // TODO: Verify that this doesn't reset the input on values such as `1e`/`1.`
            const num = parseFloat(input.value);
            return isNaN(num) || /^[+-]$|[,.eE]+$/.test(input.value)
                ? input.value
                : num;
        case 'checkbox':
            // Treat inputs with empty or default attribute value for `value` (`on`) as boolean toggles
            return input.value === 'on' || input.value === ''
                ? input.checked
                : input.value;
        case 'select-one':
            const select = input;
            return select.options[select.selectedIndex]?.value ?? '';
        case 'select-multiple':
            return Array.prototype.filter
                .call(input.options, (el) => el.selected)
                .map((el) => el.value);
    }
    return input.value;
}
