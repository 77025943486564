import * as React from 'react';
/**
 * Convenience function used to define a React functional component wrapped
 * with `React.forwardRef()`, optionally with additional `<HookField>` specific
 * properties.
 *
 * @param functionComponent - Original function component to wrap
 * @param staticProps - Optional additional props to define on the returned function
 * @return A forwardRef'd `functionComponent`
 */
export function fieldComponent(functionComponent, staticProps) {
    return Object.assign(React.forwardRef(functionComponent), {
        IsFormInput: true,
        defaultValue: '',
        valueProp: 'value',
    }, staticProps);
}
