import * as storage from '@soundtrackyourbrand/browser-storage.js'
import { EventEmitter } from 'eventemitter3'
import * as tinyCookie from 'tiny-cookie'
import { ConsentModal, showConsentModal } from './consent-modal'
import {
  ConsentMethod,
  ConsentValue,
  IS_COOKIE_RELATED_URL_REGEX,
} from './constants'
import {
  AppsflyerObject,
  DataLayer,
  HotjarObject,
  IntercomObject,
  IntercomSettings,
  MixpanelObject,
  setupAppsflyer,
  setupGtm,
  setupHotjar,
  setupIntercom,
  setupMixpanel,
} from './external'
import { PseudoLocation, getLocation } from './get-location'
import identifyReferrer from './identify-referrer'
import { normalizeSoundtrackUserId } from './lib/user'
import { MsInput, ms } from './ms'
import { decodeQuery, encodeQuery } from './query-string'
import { inCA, inEU, regionRequiresConsent } from './region-requires-consent'

export {
  encodeQuery,
  decodeQuery,
  getLocation,
  ConsentValue,
  ConsentMethod,
  inCA,
  inEU,
}

/** Cookie domain should be `.soundtrackyourbrand.com` or equivalent */
export const COOKIE_DOMAIN = cookieDomain(window.location.hostname)
/** Prefix for all cookie names */
export const COOKIE_PREFIX = 'syb.'
/** Consent cookie version. Bump to re-trigger modal in case of updated policy */
export const CONSENT_VERSION = 1
/** Cookie consent cookie name (numeric, maps to CONSENT_VALUES) */
export const CONSENT_COOKIE_NAME = COOKIE_PREFIX + 'consent' + CONSENT_VERSION
/** Prefix for all storage keys */
export const STORE_PREFIX = 'tracking:'
/** Google UTM query string parameter names */
export const UTM_PARAMS = ['source', 'medium', 'campaign', 'content'] as const
/** Optional UTM expiration time override */
export const UTM_EXPIRY_PARAM = 'utm_expiry'
/** Click ID query string parameter names */
export const CLICKID_PARAMS = 'gclid fbclid msclkid dclid li_fat_id'.split(' ')
/** Click IDs cookie name (for `clickIds`) */
export const CLICKIDS_COOKIE = 'clids'
/** Maximum number of persisted clids */
export const CLICKIDS_MAX_PERSISTED = 5

export const SOURCE_DIRECT = '(direct)'

type UtmValues = {
  source?: string
  medium?: string
  campaign?: string
  content?: string
}
type UtmParam = keyof UtmValues

type GtmEventParams = {
  category?: string
  action?: string
  label?: string
  value?: number
}

export type EventMetadata = {
  /**
    True will result in the event also being sent to Intercom, if enabled.
    Not included in final event properties.
  */
  intercom?: boolean
  /**
    Tagging this event in a Hotjar recording, if enabled.
    `true` will tag it with the same name as the event.
    `string` will tag it with the string.
    `[string, string]` will add all tags in the array.
    Not included in final event properties.
   */
  hotjar?: boolean | string | string[]

  /** Attribute event to the "Account" group */
  account_id?: string
  /** Attribute event to the "Location" group */
  location_id?: string
  /** Attribute event to the "Zone" group */
  zone_id?: string
  /** Reference to a Soundtrack device */
  syb_device_id?: string

  /** @deprecated Use `account_id` instead */
  Account?: string
  /** @deprecated Use `account_id` instead */
  'Account ID'?: string
  /** @deprecated Use `location_id` instead */
  Location?: string
  /** @deprecated Use `location_id` instead */
  'Location ID'?: string
  /** @deprecated Use `zone_id` instead */
  Zone?: string
  /** @deprecated Use `zone_id` instead */
  'Zone ID'?: string
  /** @deprecated Use `zone_id` instead */
  'Sound Zone ID'?: string
  /** @deprecated Use `syb_device_id` instead, to prevent confusion with Mixpanel's own generated Device IDs */
  'Device ID'?: string
  /** @deprecated Use `syb_device_id` instead, to prevent confusion with Mixpanel's own generated Device IDs */
  device_id?: string

  [key: string]: any
}

type SessionVariables = {
  userId?: string
  userEmail?: string
  userName?: string
  userCreatedAt?: string
  accountId?: string
  accountName?: string
  accountTier?: string
  accountCountry?: string
  accountCreatedAt?: string
  businessType?: string
  /** Whether or not this account profile should have some properties set for new accounts */
  isNewAccount?: boolean
  /** Hash of user id and a secret to prevent impersonation attacks */
  intercomHash?: string
}

type TimingProperties = {
  once?: string
}

type TrackingOptions = {
  /** Identifier of client using the tracking */
  clientId?: string
  /** Active URL (defaults to window.location.href) */
  url?: string
  /** Enables debug logging */
  debug?: boolean | ((...args: any[]) => void)
  /** Default UTM attribution expiration time */
  utmExpiry?: MsInput
  /** GTM container id, or already prepared dataLayer object */
  gtm?: string | DataLayer
  /**
    Mixpanel token, already prepared Mixpanel instance, or true to use
    `window.mixpanel` when available
  */
  mixpanel?: string | true | MixpanelObject
  /**
    Intercom app id, already prepared Intercom instance, or true to use
    `window.Intercom` when available
  */
  intercom?: string | true | IntercomObject
  /** Custom window.intercomSettings values */
  intercomSettings?: IntercomSettings
  /**
    Appsflyer web token, already prepared Appsflyer instance, or true to use
    `window.mixpanel` when available
  */
  appsflyer?: string | true | AppsflyerObject
  /** Default Appsflyer OneLink URL */
  appsflyerOneLinkUrl?: string
  /** Hotjar id or true to use `window.hj` when available. */
  hotjar?: string | true
  /**
    Report Google Optimize test participation to Mixpanel?
    Depends on `gtm` and `mixpanel` being enabled
  */
  trackGoogleOptimize?: boolean
}

type Events = {
  request(modal: ConsentModal): void
  consent(params: {
    consentValue: ConsentValue
    consentMethod: ConsentMethod
  }): void
  enabled(params: {
    consentValue: ConsentValue
    consentMethod?: ConsentMethod
  }): void
  cookies(values: UtmValues): void
  pageView(path: string): void
  gtm(params: GtmEventParams): void
  afEvent(eventName: string, eventValue: Record<string, unknown>): void
  afLink(params: AppsflyerOnelinkParams): void
  push(params: Record<string, unknown>): void
  track(eventName: string, properties: Record<string, unknown>): void
  /** Emitted when a session update has been triggered */
  session(action: string, vars: SessionVariables): void
  /** Emitted after session has been updated */
  sessionUpdated(action: string, vars: SessionVariables): void
}

export class Tracking extends EventEmitter<Events> {
  consentValue?: ConsentValue
  consentMethod?: ConsentMethod
  /** Active UTM values from query string or existing cookies */
  utm: UtmValues
  /** UTM cookies expiration time */
  utmExpiry: number
  /**
   * Ordered list of the 5 most recent click IDs in query string format.
   * Duplicates are allowed.
   * @example `gclid=X&fbclid=Y&gclid=Z`
   */
  clickIds: string
  /** UTM property map included in every Mixpanel event */
  utmForMixpanel: Record<string, string>
  /** Active URL */
  location: PseudoLocation | null
  /** Query parameters determined from `location.search` at time of instantiation */
  queryParams: Record<string, string>
  /** Associated GTM dataLayer */
  dataLayer?: DataLayer
  /** Associated Mixpanel JS SDK instance */
  mixpanel?: MixpanelObject
  /** Associated Intercom JS SDK instance */
  intercom?: IntercomObject
  /** Associated Appsflyer JS SDK instance */
  appsflyer?: AppsflyerObject
  /** Associated Hotjar JS SDK instance */
  hotjar?: HotjarObject
  /** Logged in SYB user ID */
  userId?: string

  protected mixpanelId?: string

  protected options: TrackingOptions
  protected debug: (...args: any[]) => void

  get consented(): boolean {
    return !!this.consentValue
  }

  constructor(options: TrackingOptions) {
    super()

    this.options = Object.assign(
      {
        appsflyerOneLinkUrl: 'https://click.soundtrackyourbrand.com/gwGL',
      },
      options,
    )

    this.location = options.url ? getLocation(options.url) : window.location

    if (!this.location) {
      throw new Error(`Tracking: Invalid location URL`)
    }

    this.debug =
      typeof options.debug === 'function'
        ? options.debug
        : options.debug
          ? console.log.bind(console, '[tracking]')
          : () => {}

    // Google Tag Manager setup
    if (options.gtm) {
      this.dataLayer =
        typeof options.gtm === 'string' ? setupGtm(options.gtm) : options.gtm
      if (typeof this.dataLayer.push !== 'function') {
        throw new Error(
          `Expected 'gtm' param to be a string or pushable array (got ${getType(this.dataLayer)})`,
        )
      }

      if (options.trackGoogleOptimize) {
        this.gtag('event', 'optimize.callback', {
          callback: (variation: number, experimentId: string) => {
            this.participate('Optimize - ' + experimentId, String(variation))
          },
        })
      }
    }

    // Mixpanel setup
    if (options.mixpanel) {
      const mixpanel =
        typeof options.mixpanel === 'string'
          ? setupMixpanel(options.mixpanel)
          : options.mixpanel === true
            ? null
            : options.mixpanel
      if (mixpanel && typeof mixpanel.track !== 'function') {
        throw new Error(
          `Expected 'mixpanel' param to be a string, true, or define a track() function (got ${getType(mixpanel)})`,
        )
      }
      this.exposeWindowPropertyWithFallback('mixpanel', 'mixpanel', mixpanel)
    }

    // Intercom setup
    if (options.intercom) {
      const intercom =
        typeof options.intercom === 'string'
          ? setupIntercom(options.intercom, undefined, options.intercomSettings)
          : options.intercom === true
            ? null
            : options.intercom
      if (intercom && typeof intercom !== 'function') {
        throw new Error(
          `Expected 'intercom' param to be a string or function (got ${getType(intercom)})`,
        )
      }
      this.exposeWindowPropertyWithFallback('intercom', 'Intercom', intercom)
    }

    // Appsflyer setup
    if (options.appsflyer) {
      const appsflyer =
        typeof options.appsflyer === 'string'
          ? setupAppsflyer(options.appsflyer)
          : options.appsflyer === true
            ? null
            : options.appsflyer
      if (appsflyer && typeof appsflyer !== 'function') {
        throw new Error(
          `Expected 'appsflyer' param to be a string or function (got ${getType(appsflyer)})`,
        )
      }
      this.exposeWindowPropertyWithFallback('appsflyer', 'AF', appsflyer)
    }

    // Hotjar setup
    if (options.hotjar) {
      const hotjar = setupHotjar(options.hotjar)
      if (hotjar && typeof hotjar !== 'function') {
        throw new Error(
          `Expected 'hotjar' param to be a string or true (got ${getType(hotjar)})`,
        )
      }
      this.exposeWindowPropertyWithFallback('hotjar', 'hj', hotjar)
    }

    const qs = decodeQuery(this.location.search)
    const cookies = tinyCookie.getAll()

    const clickIdsPresent = CLICKID_PARAMS.filter((key) => qs[key])

    const utmPresent = !!qs.utm_source
    const utmStored = !!cookies[COOKIE_PREFIX + 'source']
    this.utm = {}
    this.queryParams = qs

    // Determine UTM values from query string & existing cookies
    UTM_PARAMS.forEach((key) => {
      ;(this.utm as any)[key] = utmPresent
        ? qs['utm_' + key]
        : cookies[COOKIE_PREFIX + key]
    })

    if (!utmPresent && !utmStored) {
      // Determine values using referrer + *clid query params
      let source = identifyReferrer(document.referrer)
      if (source === '' || source === this.location.hostname) {
        source = SOURCE_DIRECT
      }
      this.utm.source = source
      this.utm.medium = clickIdsPresent.length
        ? 'cpc'
        : source === SOURCE_DIRECT
          ? 'direct'
          : 'organic'
    }

    this.utmForMixpanel = this.utmToMixpanel(undefined)

    // Prevent further changes
    Object.freeze(this.utm)
    Object.freeze(this.utmForMixpanel)

    this.utmExpiry = ms(
      qs[UTM_EXPIRY_PARAM] || this.options.utmExpiry,
    ) as number
    if (typeof this.utmExpiry !== 'number' || isNaN(this.utmExpiry)) {
      this.utmExpiry = 180 * ms.suffices.d
    }

    // Determine tracking consent
    if (Number(cookies[CONSENT_COOKIE_NAME]) in ConsentValue) {
      this.consentValue = Number(cookies[CONSENT_COOKIE_NAME])
      this.consentMethod = ConsentMethod.Cookie
    }

    if (!this.consentValue && !regionRequiresConsent()) {
      this.consentValue = ConsentValue.Marketing
      this.consentMethod = ConsentMethod.Implicit
    }

    // Combine stored clickIds with incoming from query string
    this.clickIds = cookies[COOKIE_PREFIX + CLICKIDS_COOKIE] || ''
    if (clickIdsPresent.length) {
      const idList = this.clickIds ? this.clickIds.split('&') : []
      const latest = idList.length ? idList[idList.length - 1] : undefined
      clickIdsPresent.forEach((key) => {
        const value = `${key}=${encodeURIComponent(qs[key])}`
        // Prevent refreshes from repeatedly appending the same id
        if (value !== latest) {
          idList.push(value)
        }
      })
      if (idList.length > CLICKIDS_MAX_PERSISTED) {
        idList.splice(0, idList.length - CLICKIDS_MAX_PERSISTED)
      }
      this.clickIds = idList.join('&')
    }

    const utmPersist = utmPresent || !utmStored
    this.persistValuesToCookies({ utm: utmPersist })
    if (utmPersist) {
      this.mixpanel?.people.set(this.utmToMixpanel('unknown'))
    }

    this.applyConsent()

    this.debug({
      consentValue: this.consentValue,
      consentMethod: this.consentMethod,
      utmPresent,
      utmStored,
      values: this.utm,
    })
  }

  /**
   * Display the consent modal, unless consent has already been given.
   *
   * @param options.evenIfConsented - Set to true to show consent modal even if user has already consented
   * @param options.locale - Show modal using this locale, falls back to 'en' for unsupported locales
   */
  requestConsent(
    options: { evenIfConsented?: boolean; locale?: string } = {},
  ): boolean {
    this.clearOldConsentCookies()
    if (this.consented) {
      if (options.evenIfConsented) {
        this.trackInternalEvent('Consent update intent')
        this.debug('consent update intent')
      } else {
        return false
      }
    } else if (!options.evenIfConsented) {
      const url = window.location.href
      if (url.match(IS_COOKIE_RELATED_URL_REGEX)) {
        this.debug(
          'consent request ignored since URL matches policy/table links',
        )
        return false
      }
    }
    showConsentModal(
      this.consent.bind(this),
      this.consentValue,
      options.locale || 'en',
    ).then((modal) => {
      this.emit('request', modal)
    })
    return true
  }

  clearOldConsentCookies() {
    for (let i = 1; i < CONSENT_VERSION; i++) {
      tinyCookie.removeCookie(COOKIE_PREFIX + 'consent' + i)
    }
  }

  /**
   * Register a consent response, potentially enabling tracking.
   */
  consent(
    consentValue: ConsentValue,
    consentMethod: ConsentMethod = ConsentMethod.Accept,
  ) {
    if (typeof consentValue !== 'number' || !(consentValue in ConsentValue)) {
      throw new Error(
        `tracking.consent: Invalid consentValue (got ${consentValue})`,
      )
    }
    if (consentValue === this.consentValue) {
      return
    }
    this.consentValue = consentValue
    this.consentMethod = consentMethod
    const data = { consentValue, consentMethod }
    this.emit('consent', data)
    this.push({ event: 'tracking.consent', ...data })
    this.trackInternalEvent('Consented')
    this.persistValuesToCookies({ permanently: true })
    this.applyConsent()
  }

  /**
   * Register a page view event, notifying relevant integrations.
   *
   * @param path - Path component of URL to register
   */
  pageView(path: string) {
    this.emit('pageView', path)
    this.push({
      event: 'virtualPageView',
      virtualPagePath: path,
    })
    // https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
    this.intercom?.('update')
  }

  /**
   * Push data to the GTM dataLayer.
   *
   * @param data - Data to push.
   */
  push(data: Record<string, unknown>) {
    this.emit('push', data)
    this.debug(this.dataLayer ? 'push' : 'suppressed', data)
    this.dataLayer?.push(data)
  }

  /**
   * gtag() function that interacts with the GTM dataLayer.
   * For some reason it's not possible to simply push an array of
   * gtag arguments to dataLayer, it has to be in the form of an
   * `arguments` object for GTM to not ignore it.
   *
   * @see https://developers.google.com/analytics/devguides/collection/gtagjs
   * @param args - gtag.js arguments
   */
  gtag(...args: unknown[]) {
    this.dataLayer?.push(arguments as any)
  }

  /**
   * Trigger a GTM event.
   *
   * The optional `additionalData` argument can be used to avoid calling
   * `push()` before `gtm()`.
   *
   * @param eventCategory
   * @param eventAction
   * @param [eventLabel]
   * @param [eventValue]
   * @param [additionalData] - Additional data to include in push to dataLayer
   */
  gtm(
    eventCategory: string,
    eventAction: string,
    eventLabel?: string,
    eventValue?: number,
    additionalData?: Record<string, unknown>,
  ) {
    this.emit('gtm', {
      category: eventCategory,
      action: eventAction,
      label: eventLabel,
      value: eventValue,
      ...additionalData,
    })
    this.push({
      event: 'ga',
      eventCategory,
      eventAction,
      eventLabel,
      eventValue,
      ...additionalData,
    })
  }

  /**
   * Trigger an AppsFlyer event
   */
  afEvent(eventName: string, eventValue: Record<string, unknown>) {
    this.emit('afEvent', eventName, eventValue)
    this.appsflyer?.('pba', 'event', {
      eventType: 'EVENT',
      eventName,
      eventValue,
    })
  }

  /**
   * Generates and returns a Appsflyer Onelink URL.
   * Uses the tracking.js UTM parameters (this.utm) as basis, but these can be overridden.
   */
  afLink({
    oneLinkUrl = this.options.appsflyerOneLinkUrl,
    source = this.utm.source,
    campaign = this.utm.campaign,
    medium: channel = this.utm.medium,
    content = this.utm.content,
    params = {},
  }: {
    /** URL Base for Appsflyer Onelink */
    oneLinkUrl?: string
    /** Onelink `mediaSource` (defaults to UTM source) */
    source?: string
    /** Onelink `campaign` (defaults to UTM campaign) */
    campaign?: string
    /** Onelink `channel` (defaults to UTM medium) */
    medium?: string
    /** Content included in Onelink payload (defaults to UTM content) */
    content?: string
    /** Manually defined Onelink parameters (takes precedence) */
    params?: AppsflyerOnelinkParams
  } = {}) {
    if (!oneLinkUrl || typeof oneLinkUrl !== 'string') {
      throw new Error(`Tracking: Must provide a oneLinkUrl`)
    }

    const mapping: AppsflyerOnelinkParams = {
      pid: source,
      c: campaign,
      af_channel: channel,
      af_sub1: this.queryParams.gclid,
      af_sub2: this.queryParams.fbclid,
      af_sub3: content,
    }
    // Prefer explicitly passed params to the ones defined in `mapping`
    Object.keys(mapping).forEach((param) => {
      const value = (mapping as any)[param]
      if (value && (params as any)[param] === undefined) {
        ;(params as any)[param] = value
      }
    })
    // Allow subscribers to override the params before generating the URL
    this.emit('afLink', params)
    return oneLinkUrl + '?' + new URLSearchParams(params as any).toString()
  }

  /**
   * Report a tracking event to Mixpanel, if enabled. Providing
   * `metadata.intercom = true` will result in the event also being reported to
   * Intercom, if enabled.
   *
   * @param eventName
   * @param [metadata]
   */
  track(eventName: string, metadata: EventMetadata = {}) {
    const finalProps = Object.assign(
      {
        Client: this.options.clientId || 'unknown',
      },
      this.utmForMixpanel,
      metadata,
    )

    this.emit('track', eventName, finalProps)
    this.debug('"' + eventName + '"', finalProps)
    const { intercom, hotjar } = finalProps
    delete finalProps.intercom
    delete finalProps.hotjar
    this.mixpanel?.track(eventName, finalProps)
    if (intercom && this.intercom) {
      this.intercom('trackEvent', eventName, finalProps)
    }
    if (hotjar && this.hotjar) {
      const tags =
        hotjar === true
          ? [eventName]
          : typeof hotjar === 'string'
            ? [hotjar]
            : hotjar.length
              ? hotjar
              : null
      if (tags && tags.length > 0) {
        this.debug('hotjar.tagRecording', tags)
        this.hotjar('tagRecording', tags)
      }
    }
  }

  /**
   * Instruct Mixpanel to include a $duration prop into the next tracking event
   * sent with a specific name. Corresponds to `mixpanel.time_event()`.
   *
   * @param eventName
   */
  timeNext(eventName: string) {
    this.debug('timeNext "' + eventName + '"')
    if (this.mixpanel) {
      this.mixpanel.time_event(eventName)
    }
  }

  /**
   * Handles session changes.
   *
   * @param action - 'login', 'logout', etc.
   * @param sessionVars
   */
  session(action: string, sessionVars: SessionVariables = {}) {
    const userId = sessionVars?.userId

    this.emit('session', action, sessionVars)
    this.debug('session', sessionVars)
    this.gtm('session', action, undefined, undefined, sessionVars)

    if (this.mixpanel) {
      if (userId !== this.userId && this.userId) {
        this.mixpanel.reset()
      }

      if (userId) {
        this.mixpanel.identify(userId)
        this.mixpanel.people.set({
          $created: sessionVars.userCreatedAt,
        })
      }

      if (sessionVars?.accountId) {
        this.mixpanel.set_group('account_id', sessionVars.accountId)
        const group = this.mixpanel.get_group(
          'account_id',
          sessionVars.accountId,
        )
        group.set({
          'Account ID': sessionVars.accountId,
          $created: sessionVars.accountCreatedAt,
          $name: sessionVars.accountName,
          $country_code: sessionVars.accountCountry,
          'Business Type': sessionVars.businessType,
          Tier: [sessionVars.accountTier], // Should be an array since we may someday support multiple Tiers
        })
        if (sessionVars.isNewAccount) {
          group.set({ Trial: true })
        }
      }
    }

    if (this.appsflyer) {
      this.onMixpanelIdUpdate()
    }

    if (this.intercom) {
      if (userId) {
        const intercomData: Record<string, any> = {
          // We NEED to make sure the userId is in the legacy format to prevent duplicate users
          // being created in Intercom, and risk users getting double emails. Make sure there's
          // a good plan in place before changing this.
          user_id: normalizeSoundtrackUserId(userId),
          user_hash: sessionVars.intercomHash,
          name: sessionVars.userName,
          email: sessionVars.userEmail,
          company: sessionVars.accountId
            ? {
                company_id: sessionVars.accountId,
                name: sessionVars.accountName,
                industry: sessionVars.businessType,
                plan: sessionVars.accountTier,
              }
            : null,
        }

        if (sessionVars.userCreatedAt) {
          intercomData.created_at = Math.floor(
            new Date(sessionVars.userCreatedAt).getTime() / 1e3,
          )
        }
        if (sessionVars.accountCreatedAt) {
          intercomData.company.created_at = Math.floor(
            new Date(sessionVars.accountCreatedAt).getTime() / 1e3,
          )
        }

        this.intercom('boot', intercomData)
      } else {
        this.intercom('shutdown')
      }
    }

    if (this.hotjar && userId) {
      this.hotjar(
        'identify',
        userId,
        sessionVars.accountId
          ? {
              'Account ID': sessionVars.accountId,
              'Account Name': sessionVars.accountName,
              'Signup Date': sessionVars.accountCreatedAt
                ? new Date(sessionVars.accountCreatedAt).toISOString()
                : null,
              'Business Type': sessionVars.businessType,
              Tier: sessionVars.accountTier,
            }
          : {},
      )
    }

    this.userId = userId
    this.emit('sessionUpdated', action, sessionVars)
  }

  /**
   * Handles sending participation in A/B tests to Mixpanel.
   * Client only needs to register A/B test participation once per user.
   * Client is responsible for remembering the applied variation for each user,
   * using localStorage or similar.
   *
   * @param experimentName
   * @param experimentVariation
   */
  participate(experimentName: string, experimentVariation: string) {
    if (!experimentName || !experimentVariation) {
      return
    }
    this.track('$experiment_started', {
      'Experiment name': experimentName,
      'Variant name': experimentVariation,
    })
  }

  /**
   * Initialize a timing measurement by storing the start time in
   * localStorage/sessionStorage. Doesn't overwrite existing start times unless
   * the `reset` flag is enabled.
   *
   * @param name - Key to store start time under
   * @param [multiSession] - Track across sessions
   * @param [reset] - Replace any existing start times
   * @return Recorded start time (in unix time milliseconds)
   */
  timingStart(name: string, multiSession = true, reset = false) {
    let t = storeGet(name + ':start', multiSession)
    if (reset || t == null) {
      t = storeUpdate(name + ':start', new Date().getTime(), multiSession)
      this.debug(`Timing start: ${name}`)
    }
    return t
  }

  /**
   * Completes a timing measurement by diffing the recorded start time for the
   * given `name` with the current time, and then pushing the data as a GTM event.
   * The third argument can be used to override default event values.
   *
   * @param name - Key to store start time under
   * @param [multiSession] - Track across sessions
   * @param [properties] - Additinal properties to pass to Mixpanel
   * @param [properties.once] - Avoid re-triggering the same event
   *        through repeated timingEnd calls?
   * @return Recorded timing (as duration in milliseconds)
   */
  timingEnd(
    name: string,
    multiSession = true,
    properties: TimingProperties = {},
  ) {
    // tslint:disable-next-line
    const t = parseInt(storeGet(name + ':start', multiSession), 10)
    if (!t) {
      return 0
    }
    // Prevent timing from triggering again by setting empty start value
    storeUpdate(name + ':start', properties.once ? '' : null, multiSession)
    delete properties.once

    return this.timing(name, t, new Date(), properties)
  }

  /**
   * Immediately reports a timing measurement to Mixpanel + GTM by calculating
   * the duration between two timestamps.
   *
   * @param name - Event string; `category.action.label` for GTM,
   *        converted to `Category - Action - Label` for Mixpanel
   * @param startTime - Timing start as Date (or Date.getTime() result)
   * @param [endTime] - Timing end
   * @param [properties] - Additinal properties to pass to Mixpanel
   * @return Recorded timing (as duration in milliseconds)
   */
  timing(
    name: string,
    startTime: Date | number,
    endTime: Date | number = new Date(),
    properties: Record<string, unknown> = {},
  ) {
    if (startTime instanceof Date) {
      startTime = startTime.getTime()
    }
    if (endTime instanceof Date) {
      endTime = endTime.getTime()
    }
    const duration = endTime - startTime
    const nameParts = name.split('.')
    this.push({
      event: 'timing',
      eventCategory: nameParts[0] || 'duration',
      eventAction: nameParts[1] || name,
      eventLabel: nameParts[2],
      // Don't use eventValue since the product also triggers regular events for timings,
      // which would inflate the event value mostly associated with $$$.
      timingValue: duration,
    })
    this.track(this.gtmEventToMixpanelName(name), {
      ...properties,
      $duration: duration / 1e3,
      Duration: duration / 1e3,
    })
    return duration
  }

  /** Clear any persisted click IDs */
  clearClickIds() {
    this.clickIds = ''
    setCookie(CLICKIDS_COOKIE, null, true)
  }

  /**
   * Returns true if user is in California and false otherwise.
   *
   * Re-export of {@link inCA}.
   */
  inCA() {
    return inCA()
  }

  /**
   * Returns true if user is in the EU and false otherwise.
   *
   * Re-export of {@link inEU}.
   */
  inEU() {
    return inEU()
  }

  /**
   * Converts a GA event name into a Mixpanel event name.
   * Example input: 'category-name.action-name.label'
   *        output: 'Category Name - Action Name - Label'
   *
   * @param eventString - Name in GA timing format
   * @return Mixpanel event name
   */
  protected gtmEventToMixpanelName(eventString: string) {
    return eventString
      .replace(/-/g, ' ')
      .replace(/\./g, ' - ')
      .replace(/^.| ./g, (m) => m.toUpperCase())
  }

  /**
   * Returns the Mixpanel property map for the current UTM
   * params. Empty params will be omitted from the object by
   * default, use the `fallback` to specify the value for those.
   *
   * @param [fallback] - Value to assign to empty/missing UTM params
   * @return UTM params named according to Mixpanel convention
   */
  protected utmToMixpanel(
    fallback: any = undefined,
    values: Record<string, any> = {},
  ): Record<string, string> {
    UTM_PARAMS.forEach((param) => {
      if (this.utm[param] || fallback !== undefined) {
        const capitalized = param[0].toUpperCase() + param.substr(1)
        values[`Latest UTM ${capitalized} (syb)`] = this.utm[param] ?? fallback
      }
    })
    return values
  }

  /**
   * Persists current consent setting as cookie and loads external scripts if enabled.
   */
  protected applyConsent() {
    const { consentValue, consentMethod } = this
    const existingValue = tinyCookie.get(CONSENT_COOKIE_NAME) || null
    const newValue = consentValue ? String(consentValue) : null
    if (existingValue !== newValue) {
      setCookie(CONSENT_COOKIE_NAME, newValue, false, '365d')
    }
    if (!consentValue) {
      return
    }
    if (consentValue >= ConsentValue.Analytics) {
      this.dataLayer?.__load?.()
      this.mixpanel?.__load?.().then(() => {
        this.onMixpanelIdUpdate()
      })
      this.appsflyer?.__load?.()
      this.intercom?.__load?.()
      this.hotjar?.__load?.()
    }
    this.push({ event: 'tracking.enabled', consentValue, consentMethod })
    this.emit('enabled', { consentValue, consentMethod })
  }

  /**
   * Persists tracking values as cookies.
   */
  protected persistValuesToCookies({
    /** Use false to only persist throughout session */
    permanently = this.consented,
    /** Persist UTM values? */
    utm = true,
    /** Persist clickIds? */
    clickIds = true,
  }) {
    this.debug(
      `persisting ${permanently ? 'permanently' : 'throughout session'}`,
    )
    if (utm) {
      Object.keys(this.utm).forEach((key) => {
        setCookie(
          key,
          this.utm[key as UtmParam],
          true,
          permanently ? this.utmExpiry : 0,
        )
      })
    }
    // Set/refresh clickIds cookie when requested and it differs from the stored value
    if (
      clickIds &&
      this.clickIds !== (getCookie(COOKIE_PREFIX + CLICKIDS_COOKIE) || '')
    ) {
      setCookie(
        CLICKIDS_COOKIE,
        this.clickIds || null,
        true,
        permanently ? '180d' : 0,
      )
    }
    this.emit('cookies', this.utm)
  }

  /**
   * Used to simplify tracking of tracking.js specific events.
   * Only reported to Mixpanel when Analytics consent is given.
   */
  protected trackInternalEvent(eventName: string) {
    return this.track('Tracking - ' + eventName, {
      'Consent Value': this.consentValue,
      'Consent Method': this.consentMethod,
    })
  }

  protected onMixpanelIdUpdate(id: string = this.mixpanel?.get_distinct_id()) {
    if (id !== this.mixpanelId) {
      this.mixpanelId = id

      // Sync AF CUID with the mixpanel id
      id && this.appsflyer?.('pba', 'setCustomerUserId', id)
    }
  }

  /**
   * Mixpanel, Intercom and Hotjar replace the stub provided by the `setup*()`
   * functions, so we need to check them to ensure that the correct instance is
   * accessible.
   */
  protected exposeWindowPropertyWithFallback<T>(
    getterName: string,
    windowPropertyName: string,
    fallbackValue: T,
  ) {
    Object.defineProperty(this, getterName, {
      enumerable: true,
      get: (): T => (window as any)[windowPropertyName] || fallbackValue,
    })
  }
}

export default Tracking

/**
 * Determines the appropriate cookie (sub)domain to store SYB cookies on.
 *
 * @param hostname - Hostname, usually `window.location.hostname`
 */
export function cookieDomain(hostname: string): string {
  if (hostname === 'localhost') {
    return ''
  }
  // Leave IP addresses as-is
  if (!hostname || /^\d{1,3}(?:\.\d{1,3}){3}$/.test(hostname)) {
    return hostname || ''
  }
  // Attempt to extract domain ancestor containing 'soundtrack'
  const sybMatch = hostname.match(/(?:^|\.)(soundtrack[^.]*\..+)/)
  return sybMatch ? '.' + sybMatch[1] : hostname
}

/**
 * Sets a SYB tracking cookie.
 * Provide a null/undefined value to remove a cookie.
 *
 * @param name
 * @param value
 * @param [prefix] - Add prefix if true
 * @param [maxAge] - Expiration (0 for a session cookie)
 */
export function setCookie(
  name: string,
  value: any,
  prefix = false,
  maxAge: MsInput = '180d',
) {
  const options: {
    domain: string
    secure: boolean
    expires?: number | string
    maxAge?: number
  } = {
    domain: COOKIE_DOMAIN,
    secure: window.location.protocol === 'https:',
  }
  if (prefix) {
    name = COOKIE_PREFIX + name
  }
  if (value == null) {
    options.expires = 'Thu, 01 Jan 1970 00:00:00 GMT'
    value = ''
  } else if (maxAge) {
    // max-age is specified in seconds
    ;(options as any)['max-age'] = (ms(maxAge) || 180 * ms.suffices.d) / 1e3
  }
  tinyCookie.set(name, value, options)
}

export const getCookie = tinyCookie.get

export function storeGet(name: string, multiSession = false) {
  return storage[multiSession ? 'local' : 'session'].getItem(
    STORE_PREFIX + name,
  )
}

/**
 * Updates a session/locale storage entry.
 * Use `null` to remove an item.
 */
export function storeUpdate(name: string, value: any, multiSession = false) {
  const whichStorage = storage[multiSession ? 'local' : 'session']
  return value == null
    ? whichStorage.removeItem(STORE_PREFIX + name)
    : whichStorage.setItem(STORE_PREFIX + name, value)
}

function getType(value: any) {
  return {}.toString.call(value).slice(8, -1)
}

export type AppsflyerOnelinkParams = {
  /** Media source */
  pid?: string
  /** Campaign */
  c?: string
  /** The name for the specific in-app content that users will be directed to */
  deep_link_value?: string
  af_ad?: string
  af_adset?: string
  /** Redirect Android users to a different URL than the app page on Google Play. Use for third-party app stores. */
  af_android_url?: string
  af_channel?: string
  /** The URI scheme fallback value to launch the app */
  af_dp?: string
  /** Use this for landing page redirections to redirect iOS (iPhone or iPad) users to a different URL than the app page on iTunes */
  af_ios_url?: string
  /** Redirection URL path, coupled with `af_redirect` */
  af_r?: string
  af_redirect?: 'true' | 'false' | null | undefined
  /** Unique ID that identifies the publisher */
  af_siteid?: string
  af_sub1?: string
  af_sub2?: string
  af_sub3?: string
  af_sub4?: string
  af_sub5?: string
  /** URL to redirect desktop (for example, Windows or Mac) users to a different web page than configured in the OneLink template. */
  af_web_dp?: string
  af_xp?: string
}
