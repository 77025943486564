import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { withModifier } from '@soundtrackyourbrand/ui';
import classNames from 'classnames';
import React, { forwardRef } from 'react';
import { BaseButton } from './BaseButton';
/**
 * Labeled Icon Buttons have a round icon-button with a label below.
 */
export const LabelIconButton = forwardRef(LabelIconButtonInner);
Object.assign(LabelIconButton, { displayName: 'LabelIconButton' });
function LabelIconButtonInner({ title, variant = 'outlined', children, onClick, ...attrs }, ref) {
    return (_jsxs("span", { ref: ref, className: classNames(attrs.className, withModifier('label-icon-button', variant)), onClick: attrs.disabled ? undefined : onClick, role: "button", "aria-disabled": attrs.disabled ? true : undefined, title: !title && typeof children === 'string' ? children : undefined, children: [React.createElement(BaseButton, attrs), _jsx("span", { className: "label-icon-button__label", children: children })] }));
}
