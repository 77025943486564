import type { ApolloCache, NormalizedCacheObject } from '@apollo/client'
import { CachePersistor } from 'apollo3-cache-persist'
import type { ApolloPersistOptions } from 'apollo3-cache-persist/lib/types'
import type { Logger } from './types'

export type CachePersistorConfig<T> = ApolloPersistOptions<T> & {
  /**
   * Cache version - bump when changes to GraphQL schema or typePolicies are made.
   * Should also include enviroment in order to purge cache when switching.
   * Example: `SYB.environment + '-' + SYB.version`
   */
  version: string | (() => string)
  /** Storage key storing version of serialized cache */
  versionKey?: string
  /** Disable the default garbage collection run triggered before persistence? */
  disableAutomaticGarbageCollection?: boolean
  /**
   * Optional GC function that runs when the size of the cache has grown too big to persist.
   * `apollo.cache.gc()` will run after this function to evict any dangling references.
   *
   * @example
   * Evict all ROOT_QUERY fields that match a specific regex pattern.
   * ```ts
   * function manualGc() {
   *   const fieldRegex = /^(editorial\w*|search|tracks|playlist|schedule|browse)\b/
   *   a.invalidateCacheFields(
   *     cache,
   *     (key) => {
   *       return key.match(fieldRegex)?.[1]
   *     },
   *     { broadcast: false },
   *   )
   * }
   * ```
   */
  manualGc?: (cache: ApolloCache<T>) => void
  /** Optional logger */
  logger?: Logger
}

/**
 * Configures apollo-client cache instance to be persisted using
 * apollo3-cache-persist.
 *
 * Returns the resulting persistor and a method to rehydrate the cache.
 */
export function createCachePersistor<T = NormalizedCacheObject>(
  config: CachePersistorConfig<T>,
) {
  /** Track most recently written cache size */
  let mostRecentSize = 0

  /** Currently running manual garbage collection during persistence? */
  let runningManualGc = false

  type ExtractMethodNames<T> = {
    [K in keyof T]: T[K] extends (...args: any[]) => any ? K : never
  }[keyof T]

  config = Object.assign(
    {
      key: 'syb.cache',
      versionKey: 'syb.cacheVersion',
      trigger: (persist) => {
        // Wrap cache methods to schedule persistence on specific events
        const wrap = (method: ExtractMethodNames<typeof config.cache>) => {
          const cache = config.cache as any
          const fn = cache[method!]
          cache[method!] = (...args: any[]) => {
            const result = fn.apply(cache, args)
            if (!runningManualGc) persist() // Persistence is already in progress
            return result
          }
          return () => {
            cache[method!] = fn
          }
        }
        const subscriptions = [wrap('write'), wrap('evict'), wrap('modify')]
        return () => {
          subscriptions.forEach((cb) => cb())
        }
      },
      debounce: 2e3,
      maxSize: 4 * 1024 * 1024,
    } satisfies Partial<CachePersistorConfig<T>>,
    config,
  )

  const persistor = new CachePersistor<T>(config)

  // Override default persist method with additional features
  // Original: https://github.com/apollographql/apollo-cache-persist/blob/master/src/Persistor.ts
  persistor.persistor.persist = async function customPersist() {
    if (this.paused && !runningManualGc) {
      return
    }
    /** Attempting to running manual GC before persisting?  */
    const triedManualGc =
      runningManualGc && typeof config.manualGc === 'function'
    try {
      // Run garbage collection before persisting cache?
      if (triedManualGc || !config.disableAutomaticGarbageCollection) {
        if (triedManualGc) {
          config.manualGc!(config.cache)
        }
        // Keep `runningManualGc` true until manualGc completes as it's used to
        // prevent schedule additional persistence runs
        runningManualGc = false
        const removed = config.cache.gc()
        if (removed.length > 0) {
          config.logger?.debug(
            `Garbage collected ${removed.length} records before persisting`,
          )
        }
      }
      const data = this.cache.extract()
      if (!data) return
      if (typeof data !== 'string') {
        throw new Error(
          `Cache persistence only supports strings, got ${{}.toString.call(data).slice(8, -1)}`,
        )
      }
      const retryOrPause = (reason: string) => {
        const manualGcAvailable = typeof config.manualGc === 'function'
        if (manualGcAvailable && !triedManualGc) {
          config.logger?.debug(`${reason} - retrying after manual GC`)
          runningManualGc = true
          return this.persist()
        }
        if (manualGcAvailable) reason += ' after manual GC'
        config.logger?.warn(
          `Cache ${reason} - purging cache and pausing persistence`,
        )
        runningManualGc = false
        this.paused = true
        return this.purge()
      }
      mostRecentSize = data.length
      // Set version key in addition to updating data
      config.storage.setItem(config.versionKey!, getVersion())
      const kb = (mostRecentSize / 1024).toFixed(2)
      config.logger?.debug(`Persisting ${kb} Kb of serialized cache`)
      if (this.maxSize && mostRecentSize > this.maxSize) {
        return retryOrPause('maxSize exceeded')
      }
      await this.storage.write(data).catch((error) => {
        if (error.name === 'QuotaExceededError') {
          return retryOrPause('QuotaExceededError')
        }
        throw error
      })
    } finally {
      runningManualGc = false
    }
  }

  function getVersion() {
    return typeof config.version === 'function'
      ? config.version()
      : config.version
  }

  /**
   * Asynchronously restores a persisted cache into memory.
   * Resets the cache if the app version is different from the stored version.
   */
  async function rehydrate() {
    const storedVersion = config.storage.getItem(config.versionKey!)
    const version = getVersion()
    if (storedVersion !== version) {
      config.logger?.info(
        `Persisted cache version mismatch (${storedVersion} != ${version}) - purging`,
      )
      return persistor.purge()
    }
    config.logger?.debug(`Restoring persisted cache`)
    return persistor.restore()
  }

  return {
    persistor,
    rehydrate,
    reset: () => persistor.purge(),
  } as const
}
