export interface ComponentType {
  element?: HTMLElement
  template: () => HTMLElement
}

export interface ElementProps extends Partial<GlobalEventHandlers> {
  [prop: string]: any
  style?: string | Record<string, string | number | null | undefined>
}

// For SVG support
export type ElementMap = SVGElementTagNameMap & HTMLElementTagNameMap
export type ElementName = keyof ElementMap
export const SVG_NS = 'http://www.w3.org/2000/svg'

/**
 * Tags which should be created using SVG_NS DOM namespace
 * TODO: Provide correct types for svg[viewBox], path[d], etc.
 */
const SVG_TAGS: Record<keyof SVGElementEventMap, true> = (
  'animate animateMotion animateTransform circle clipPath defs desc ellipse ' +
  'feBlend feColorMatrix feComponentTransfer feComposite feConvolveMatrix ' +
  'feDiffuseLighting feDisplacementMap feDistantLight feDropShadow feFlood ' +
  'feFuncA feFuncB feFuncG feFuncR feGaussianBlur feImage feMerge feMergeNode ' +
  'feMorphology feOffset fePointLight feSpecularLighting feSpotLight feTile ' +
  'feTurbulence filter foreignObject g image line linearGradient marker mask ' +
  'metadata mpath path pattern polygon polyline radialGradient rect set ' +
  'stop svg switch symbol text textPath title tspan use view'
)
  .split(' ')
  .reduce((o, t) => ((o[t] = true), o), Object.create(null))

export const h = createElement

export function createElement<K extends keyof ElementMap>(
  tagName: K,
  props?: ElementProps,
  ...children: unknown[]
): ElementMap[K] {
  const ns = (SVG_TAGS as any)[tagName] === true ? SVG_NS : undefined
  const element = ns
    ? document.createElementNS(ns, tagName.replace(/^svg:/, ''))
    : document.createElement(tagName)

  if (props) {
    if (props.children) {
      children.unshift(props.children)
      delete props.children
    }

    if (props.class || props.className) {
      props.class = String(props.class || props.className)
      delete props.className
    }

    if (props.style && typeof props.style === 'object') {
      const styleProps: string[] = []
      for (const key in props.style) {
        const value = props.style[key]
        if (value != null) {
          const kebabKey = key
            .replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2')
            .toLowerCase()
          styleProps.push(`${kebabKey}: ${value}`)
        }
      }
      props.style = styleProps.join('; ')
    }

    for (const key in props) {
      const value = props[key]
      if (key.substr(0, 2) === 'on' && typeof value === 'function') {
        element.addEventListener(
          key.substr(2).toLowerCase(),
          value as EventListenerOrEventListenerObject,
        )
      } else if (typeof value === 'boolean') {
        if (value) element.setAttribute(key, '')
      } else {
        element.setAttribute(key, String(value))
      }
    }
  }

  appendChild(element, children)

  return element as ElementMap[K]
}

function appendChild(parent: Node, child: any) {
  if (child == null || typeof child === 'boolean') {
    return
  }
  if (Array.isArray(child)) {
    for (const value of child) {
      appendChild(parent, value)
    }
  } else if (typeof child === 'string') {
    parent.appendChild(document.createTextNode(child))
  } else if (child instanceof Node) {
    parent.appendChild(child)
  } else {
    parent.appendChild(document.createTextNode(String(child)))
  }
}

export function render(component: ComponentType) {
  if (!component || !component.element) {
    throw new Error(`render: Element hasn't been rendered yet`)
  }
  return replace(component.element, component.template())
}

export function replace(prev: HTMLElement, next: HTMLElement) {
  prev.parentNode!.replaceChild(next, prev)
}
