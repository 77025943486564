import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { fieldComponent } from './fieldComponent';
import { useField } from './useField';
export const RadioButton = fieldComponent(({ title, ...props }, ref) => {
    const field = useField(props, {
        fieldType: 'radio',
    });
    if (typeof field.props.checked === 'string') {
        field.props.checked = field.props.checked === props.value;
    }
    const input = _jsx("input", { ...field.props, type: "radio" });
    return field.wrap(props.label ? (_jsxs("label", { className: "inline-label", title: title, children: [input, props.label] })) : (input));
}, {
    displayName: 'RadioButton',
    valueProp: 'checked',
});
