import type { ApolloClient, DefaultContext } from '@apollo/client'
import type { Unsubscribe } from '@reduxjs/toolkit'
import { captureException } from '@sentry/core'
import { scope } from '@soundtrack/utils/log'
import { queryAndObserve } from '@soundtrack/utils/queryAndObserve'
import { retryWithBackoff } from '@soundtrack/utils/retryWithBackoff'
import { isApolloError } from '@soundtrackyourbrand/apollo-client'
import { getIn } from '@soundtrackyourbrand/object-utils.js'
import type { Subscription } from 'zen-observable-ts'
import { graphql } from '../graphql/gql.js'
import type {
  PlayableSourceInfoFragment,
  PlaybackInfoFragment,
  PlaybackOrder,
  PlaylistInfoFragment,
  ScheduleInfoFragment,
  TrackInfoFragment,
  ZoneSubscriptionInfoFragment,
} from '../graphql/graphql.js'
import {
  type AssignSourceImplInput,
  type BasePlaybackConfig,
  type BasePlaybackEvents,
  type BlockTrackInput,
  Playback,
  type QueueTracksImplInput,
  type SkipTrackImplInput,
  convertDisplay,
} from './base.js'
import type * as playbackSlice from './slice.js'
import { INITIAL_STATE } from './slice.js'

/**
 * How often we should query new data while connected.
 * Queue updates doesn't trigger subscription updates so this is our way
 * of getting them. It also helps with re-aligning the track progress if
 * it has come out of sync and finally is our backup if events are missed
 * for any reason.
 */
const pollInterval = 10_000

const log = scope('RemotePlayback')
/**
 * Players might not be online when the user does actions like pause or skip,
 * avoid sending error reports for that.
 * This sits on all actions that go to a player instead of being set on the zone
 */
const GQL_CONTEXT = {
  expectedErrors: ['NOT_FOUND'],
} as const satisfies DefaultContext

export const VOLUME_RESOLUTION = 16

export interface RemotePlaybackConfig extends BasePlaybackConfig {
  apollo: ApolloClient<object>
  isLoggedInSelector: (state: any) => boolean
  tierSelector?: (state: any) => playbackSlice.State['tier']
  isOnlineSelector?: (state: any) => boolean
  country?: playbackSlice.IsoCountry
  /**
   * In lightweight mode, RemotePlayback only listens to the playbackUpdate subscription
   * of a zone. It doesn't run any immediate queries nor do any polling.
   *
   * For this to work, please provide country, isOnlineSelector and isUnlimitedSelector
   */
  lightweight?: boolean
}

export class RemotePlayback extends Playback {
  private isDisposed = false
  private isInitialized = false
  private zoneSubscriptions: Array<Unsubscribe | Subscription> = []
  private globalSubscriptions: Array<Unsubscribe> = []
  private retryController = retryWithBackoff({
    attempt: (attempt) => {
      log.debug(
        attempt === 0 ? `Connecting` : `Reconnecting, attempt ${attempt}`,
      )
      return this.connectSoundZone().catch((error) => {
        log.debug(`Reconnect failed: ${error?.message ?? error}`)
        throw error
      })
    },
    backOff: {
      min: 1500,
      max: 15_000,
    },
  })

  constructor(
    playerId: string,
    public readonly targetZoneId: string,
    protected config: RemotePlaybackConfig,
    protected events: BasePlaybackEvents,
  ) {
    super(playerId, config, events)
  }

  private get soundZoneId() {
    if (this.isDisposed) {
      throw new Error('Instance has been disposed')
    }
    return this.targetZoneId
  }

  private get apollo() {
    return this.config.apollo
  }

  async playImpl() {
    await this.apollo.mutate({
      mutation: PlayDoc,
      variables: {
        input: {
          soundZone: this.soundZoneId,
        },
      },
      context: GQL_CONTEXT,
    })
  }

  async pauseImpl() {
    await this.apollo.mutate({
      mutation: PauseDoc,
      variables: {
        input: {
          soundZone: this.soundZoneId,
        },
      },
      context: GQL_CONTEXT,
    })
  }

  async skipImpl(input: SkipTrackImplInput) {
    await this.apollo.mutate({
      mutation: SkipTracksDoc,
      variables: {
        input: {
          soundZone: this.soundZoneId,
          crossfade: input.crossfade,
          tracksToSkip: input.tracksToSkip,
        },
      },
      context: GQL_CONTEXT,
    })
    this.queryStatus()
  }

  async setPlayOrderImpl(playOrder: PlaybackOrder) {
    await this.apollo.mutate({
      mutation: SetPlayOrderDoc,
      variables: {
        input: {
          soundZone: this.soundZoneId,
          playbackOrder: playOrder,
        },
      },
      context: GQL_CONTEXT,
    })
    this.queryStatus()
  }

  async clearQueueImpl(): Promise<void> {
    await this.apollo.mutate({
      mutation: ClearQueuedTracksDoc,
      variables: {
        input: {
          soundZone: this.soundZoneId,
        },
      },
      context: GQL_CONTEXT,
    })
    this.queryStatus()
  }

  get volumeResolution() {
    return VOLUME_RESOLUTION
  }
  async setVolumeImpl(volume: number) {
    await this.apollo.mutate({
      mutation: SetVolumeDoc,
      variables: {
        input: {
          soundZone: this.soundZoneId,
          volume,
        },
      },
      context: GQL_CONTEXT,
    })
  }

  async blockTrackImpl(input: BlockTrackInput) {
    await this.apollo.mutate({
      mutation: BlockTrackDock,
      variables: {
        input: {
          parent: this.soundZoneId,
          source: input.trackId,
          reasons: input.reasons,
          playFrom: input.sourceId,
        },
      },
    })
  }

  async unblockTrackImpl(trackId: string) {
    await this.apollo.mutate({
      mutation: UnblockTrackDock,
      variables: {
        input: {
          parent: this.soundZoneId,
          source: trackId,
        },
      },
    })
  }

  async assignSourceImpl(input: AssignSourceImplInput): Promise<void> {
    if (!input.keepQueue) {
      await this.clearQueueImpl()
    }
    await this.apollo.mutate({
      mutation: AssignSourceDoc,
      variables: {
        input: {
          soundZones: [this.soundZoneId],
          source: input.source.id,
          sourceSnapshot:
            'snapshot' in input.source ? input.source.snapshot : undefined,
          immediate: input.immediate,
          sourceTrackIndex: input.trackIndex,
          track: input.track,
        },
      },
    })
    this.queryStatus()
  }

  protected async queueTracksImpl(input: QueueTracksImplInput): Promise<void> {
    const trackIds = input.tracks.map((track) =>
      typeof track === 'string' ? track : getIn(track, ['id']),
    )

    await this.apollo.mutate({
      mutation: QueueTracksDoc,
      variables: {
        input: {
          soundZone: this.soundZoneId,
          tracks: trackIds,
          immediate: input.immediate,
          clearQueuedTracks: input.clearQueue,
        },
      },
      context: GQL_CONTEXT,
    })
    this.queryStatus()
  }

  /**
   * Query current status, used after mutations that
   * updates the queue since it doesn't trigger
   * subscription updates.
   */
  public queryStatus() {
    if (this.isDisposed) return
    const country =
      this.selectors.zoneCountry(this.store.getState()) ?? this.config.country
    const isUnlimited =
      this.selectors.isUnlimited(this.store.getState()) ?? false

    if (!country) {
      throw new Error('No country set')
    }

    this.apollo
      .query({
        query: PlaybackStatusDoc,
        variables: {
          soundZoneId: this.soundZoneId,
          market: country,
          isUnlimited,
        },
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      })
      .then(
        (result) => {
          if (this.isDisposed) return
          const soundZone = result.data?.soundZone
          this.store.dispatch(
            this.playbackSlice.actions.update(
              {
                online: this.config.isOnlineSelector
                  ? this.config.isOnlineSelector(this.store.getState())
                  : (soundZone?.online ?? false),
                ...parsePlayback(soundZone?.playback),
              },
              this.playerId,
            ),
          )
        },
        (error: Error) => handleApolloError('Query status error', error),
      )
  }

  async connectSoundZone() {
    const loggedIn = this.config.isLoggedInSelector(this.store.getState())

    if (!loggedIn) {
      throw new Error('Not logged in')
    }

    for (const sub of this.zoneSubscriptions) {
      typeof sub === 'function' ? sub() : sub.unsubscribe()
    }
    this.zoneSubscriptions = []

    if (!this.soundZoneId) return
    if (!this.config.lightweight) {
      await new Promise<void>((resolve, reject) => {
        this.zoneSubscriptions.push(
          this.apollo
            .watchQuery({
              query: ZoneInfoDoc,
              variables: { soundZoneId: this.soundZoneId },
              fetchPolicy: 'cache-and-network',
              errorPolicy: 'all',
            })
            .subscribe(
              (result) => {
                if (this.isDisposed) return
                const soundZone = result.data?.soundZone
                this.store.dispatch(
                  this.playbackSlice.actions.update(
                    {
                      accountId: soundZone?.account?.id,
                      zone: soundZone?.location
                        ? {
                            id: soundZone.id,
                            name: soundZone.name,
                            locationId: soundZone.location.id,
                            country:
                              this.config.country ??
                              soundZone.location.physicalAddress.country,
                            settings: {
                              crossfade: soundZone.settings.crossfade ?? false,
                              crossfadeLength:
                                soundZone.settings.crossfadeLength ?? 0,
                            },
                          }
                        : null,
                      tier: this.config.tierSelector
                        ? this.config.tierSelector(this.store.getState())
                        : soundZone
                          ? parseTier(soundZone)
                          : null,
                    },
                    this.playerId,
                  ),
                )
                resolve()
              },
              (error) => {
                handleApolloError('Watch ZoneInfo error', error)
                reject(error)
              },
            ),
        )
      })
    }

    const country =
      this.selectors.zoneCountry(this.store.getState()) ?? this.config.country

    if (!country) {
      throw new Error('No country set')
    }

    this.store.dispatch(
      this.playbackSlice.actions.update({ mode: 'remote' }, this.playerId),
    )

    if (this.config.isOnlineSelector) {
      this.zoneSubscriptions.push(
        queryAndObserve(
          this.config.isOnlineSelector,

          (online) => {
            this.store.dispatch(
              this.playbackSlice.actions.update({ online }, this.playerId),
            )
          },
          this.store,
          { invokeImmediately: false },
        ),
      )
    }
    if (this.config.tierSelector) {
      this.zoneSubscriptions.push(
        queryAndObserve(
          this.config.tierSelector,

          (tier) => {
            this.store.dispatch(
              this.playbackSlice.actions.update({ tier }, this.playerId),
            )
          },
          this.store,
        ),
      )
    }

    await new Promise<void>((resolve, reject) => {
      if (this.isDisposed) return

      const handleSubscriptionError = (error: any) => {
        handleApolloError('Subscription error', error)
        this.retryController.retry()
        // Reject incase the error happened during initial connection,
        // its otherwise a no-op
        reject(error)
      }

      if (!this.config.lightweight) {
        this.zoneSubscriptions.push(
          this.apollo
            .subscribe({
              query: SoundZoneUpdateDoc,
              variables: {
                input: { soundZone: this.soundZoneId },
              },
              errorPolicy: 'all',
            })
            // GraphQL subscriptionsresults are handled via the apollo cache
            // by the watchQuery below, hence this subscriber is empty.
            .subscribe({ error: handleSubscriptionError }),
        )
      }

      let subscriptions: Array<Subscription> = []
      this.zoneSubscriptions.push(function dipsoseOvservedSubscriptions() {
        for (const subscription of subscriptions) {
          subscription.unsubscribe()
        }
      })
      this.zoneSubscriptions.push(
        queryAndObserve(
          this.selectors.isUnlimited,
          (isUnlimited) => {
            isUnlimited ??= false
            for (const subscription of subscriptions) {
              subscription.unsubscribe()
              const index = this.zoneSubscriptions.indexOf(subscription)
              if (index >= 0) {
                this.zoneSubscriptions.splice(index, 1)
              }
            }
            subscriptions = []

            subscriptions.push(
              this.apollo
                .subscribe({
                  query: PlaybackUpdateDoc,
                  variables: {
                    input: { soundZone: this.soundZoneId },
                    market: country,
                    isUnlimited,
                  },
                  errorPolicy: 'all',
                })
                .subscribe({
                  // When in lightweight mode, the watch query below may not return anything
                  // since there might be no SoundZone in cache. We also want to resolve the
                  // initial promise from the initial subscription event as we don't actually
                  // fire any query.
                  next: this.config.lightweight
                    ? (result) => {
                        const playback = result.data?.playbackUpdate?.playback
                        resolve()
                        this.store.dispatch(
                          this.playbackSlice.actions.update(
                            parsePlayback(playback),
                            this.playerId,
                          ),
                        )
                      }
                    : undefined,
                  error: handleSubscriptionError,
                }),
            )
            subscriptions.push(
              this.apollo
                .watchQuery({
                  query: PlaybackStatusDoc,
                  variables: {
                    soundZoneId: this.soundZoneId,
                    market: country,
                    isUnlimited,
                  },
                  // When in lightweight mode, don't actually do a query. However we still want
                  // to listen to the cache for quicker inital data if possible and to respond
                  // to cache updates from other places.
                  fetchPolicy: this.config.lightweight
                    ? 'cache-only'
                    : 'cache-and-network',
                  initialFetchPolicy: this.config.lightweight
                    ? 'cache-only'
                    : 'network-only',
                  pollInterval: this.config.lightweight
                    ? undefined
                    : pollInterval,
                  errorPolicy: 'all',
                })
                .subscribe(
                  (result) => {
                    if (this.isDisposed) return
                    // Only resolve after we have gotten a result from the server
                    // in order to not reset the retry controller on every retry
                    // if there is cached data available.
                    if (!this.config.lightweight && !result.loading) {
                      resolve()
                    }
                    const soundZone = result.data?.soundZone
                    this.store.dispatch(
                      this.playbackSlice.actions.update(
                        {
                          online: this.config.isOnlineSelector
                            ? this.config.isOnlineSelector(
                                this.store.getState(),
                              )
                            : (soundZone?.online ?? false),
                          ...parsePlayback(soundZone?.playback),
                        },
                        this.playerId,
                      ),
                    )
                  },
                  (error) => {
                    handleApolloError('Watch query error', error)
                    this.retryController.retry()
                    reject(error)
                  },
                ),
            )
          },
          this.store,
        ),
      )
    })
  }

  init(): void {
    if (this.isInitialized) return
    this.isInitialized = true
    this.globalSubscriptions.push(
      queryAndObserve(
        this.config.isLoggedInSelector,
        (loggedIn) => {
          if (this.isDisposed) return
          if (loggedIn) {
            this.retryController.start()
          } else {
            this.retryController.stop()
            for (const sub of this.zoneSubscriptions) {
              typeof sub === 'function' ? sub() : sub.unsubscribe()
            }
            this.zoneSubscriptions = []
            this.store.dispatch(
              this.playbackSlice.actions.update(
                { ...INITIAL_STATE, mode: 'remote' },
                this.playerId,
              ),
            )
          }
        },
        this.store,
      ),
    )
  }
  dispose(): void {
    this.isDisposed = true
    this.retryController.stop()
    for (const sub of this.zoneSubscriptions) {
      typeof sub === 'function' ? sub() : sub.unsubscribe()
    }
    this.zoneSubscriptions = []
    for (const unsubscribe of this.globalSubscriptions) {
      unsubscribe()
    }
    this.globalSubscriptions = []
  }
}

function handleApolloError(message: string, error: Error) {
  if (isApolloError(error) && error.gqlContext.isExpected) return
  captureException(
    Object.assign(new Error(`[remote] ${message}`), { cause: error }),
  )
}

function parsePlayback(playback: PlaybackInfoFragment | undefined | null) {
  return {
    status: playback?.state ?? 'paused',
    playOrder: playback?.playbackMode ?? null,
    volume: playback?.volume ?? 0,
    source:
      playback?.playFrom && playback.playFrom.__typename !== 'Soundtrack'
        ? parseSource(playback.playFrom)
        : null,
    trackId: playback?.current?.playable?.id ?? null,
    track:
      playback?.current?.playable.__typename === 'Track'
        ? parseTrack(playback.current.playable)
        : null,
    current: playback?.current
      ? {
          id: playback.current.id,
          fromQueue: playback.current.source.__typename === 'ManuallyQueued',
          source: parsePlayableSource(playback.current.source),
        }
      : null,
    upcoming: playback?.upcoming
      ?.filter((entry) => entry.playable.__typename === 'Track')
      .map((entry) => ({
        id: entry.id,
        fromQueue: entry.source.__typename === 'ManuallyQueued',
        track: parseTrack(entry.playable),
        source: parsePlayableSource(entry.source),
      })),
    progress: playback?.progress
      ? {
          updatedAt: playback.progress.updatedAt,
          receivedAt: new Date().toISOString(),
          progressMs: playback.progress.progressMs,
        }
      : null,
    nextSlotStartsAt: playback ? playback.nextSlotStartsAt : 'unknown',
  } satisfies Partial<playbackSlice.State>
}

function parseSource(
  source: PlaylistInfoFragment | ScheduleInfoFragment,
): playbackSlice.Source {
  return {
    __typename: source.__typename,
    id: source.id,
    name: source.name,
    type:
      source.__typename === 'Schedule'
        ? 'schedule'
        : (source.presentAs ?? 'playlist'),
    display: source.display && convertDisplay(source.display),
  }
}

function parsePlayableSource(
  source: PlayableSourceInfoFragment,
): playbackSlice.Source | null {
  return source.__typename === 'Playlist'
    ? parseSource(source)
    : source.__typename === 'ScheduleSource'
      ? parseSource(source.schedule)
      : null
}

function parseTier(zone: ZoneSubscriptionInfoFragment) {
  if (!zone.subscription.isActive) return 0
  return zone.account?.plan === 'UNLIMITED' ? 3 : 1
}

function parseTrack(track: TrackInfoFragment): playbackSlice.Track {
  return {
    ...track,
    durationMs: track.durationMs ?? null,
    album: track.album
      ? {
          ...track.album,
          display: track.album.display && convertDisplay(track.album.display),
        }
      : null,
    artists: track.artists ?? null,
  }
}

const PlayDoc = graphql(/* GraphQL */ `
  mutation Play($input: PlayInput!) {
    play(input: $input) {
      status
    }
  }
`)

const PauseDoc = graphql(/* GraphQL */ `
  mutation Pause($input: PauseInput!) {
    pause(input: $input) {
      status
    }
  }
`)

const SkipTracksDoc = graphql(/* GraphQL */ `
  mutation SkipTracks($input: SkipTracksInput!) {
    skipTracks(input: $input) {
      status
    }
  }
`)

const SetPlayOrderDoc = graphql(/* GraphQL */ `
  mutation SetPlayOrder($input: SoundZoneSetPlaybackOrderInput!) {
    soundZoneSetPlaybackOrder(input: $input) {
      status
    }
  }
`)

const ClearQueuedTracksDoc = graphql(/* GraphQL */ `
  mutation ClearQueuedTracks($input: SoundZoneClearQueuedTracksInput!) {
    soundZoneClearQueuedTracks(input: $input) {
      status
    }
  }
`)

const SetVolumeDoc = graphql(/* GraphQL */ `
  mutation SetVolume($input: SetVolumeInput!) {
    setVolume(input: $input) {
      volume
    }
  }
`)

const BlockTrackDock = graphql(/* GraphQL */ `
  mutation BlockTrack($input: BlockTrackInput!) {
    blockTrack(input: $input) {
      source
    }
  }
`)

const UnblockTrackDock = graphql(/* GraphQL */ `
  mutation UnblockTrack($input: UnblockTrackInput!) {
    unblockTrack(input: $input) {
      source
    }
  }
`)

const AssignSourceDoc = graphql(/* GraphQL */ `
  mutation AssignSource($input: SoundZoneAssignSourceInput!) {
    soundZoneAssignSource(input: $input) {
      soundZones
    }
  }
`)

const QueueTracksDoc = graphql(/* GraphQL */ `
  mutation QueueTracks($input: SoundZoneQueueTracksInput!) {
    soundZoneQueueTracks(input: $input) {
      status
    }
  }
`)

export const PlaybackStatusDoc = graphql(/* GraphQL */ `
  query PlaybackStatus(
    $soundZoneId: ID!
    $market: IsoCountry!
    $isUnlimited: Boolean!
  ) {
    soundZone(id: $soundZoneId) {
      id
      online
      playback {
        ...PlaybackInfo
      }
    }
  }
`)

export const ZoneInfoDoc = graphql(/* GraphQL */ `
  query ZoneInfo($soundZoneId: ID!) {
    soundZone(id: $soundZoneId) {
      id
      name
      location {
        id
        physicalAddress {
          country
        }
      }
      account { id }
      ...ZoneSubscriptionInfo
      settings {
        crossfade
        crossfadeLength
      }
    }
  }
`)

export const SoundZoneUpdateDoc = graphql(/* GraphQL */ `
  subscription SoundZoneUpdateSubscription($input: SoundZoneUpdateInput!) {
    soundZoneUpdate(input: $input) {
      soundZone {
        id
        online
        settings {
          crossfade
          crossfadeLength
        }
        playFrom {
          __typename
          ...PlaylistInfo
          ...ScheduleInfo
        }
      }
    }
  }
`)
export const PlaybackUpdateDoc = graphql(/* GraphQL */ `
  subscription PlaybackSubscription(
    $input: PlaybackUpdateInput!
    $market: IsoCountry!
    $isUnlimited: Boolean!
  ) {
    playbackUpdate(input: $input) {
      playback {
        ...PlaybackInfo
      }
    }
  }
`)

graphql(/* GraphQL */ `
  fragment ZoneSubscriptionInfo on SoundZone {
    subscription {
      isActive
      activeUntil
      state
    }
    account {
      id
      plan
    }
  }
`)
graphql(/* GraphQL */ `
  fragment PlaylistInfo on Playlist {
    __typename
    id
    name
    presentAs
    display {
      ...PlaybackDisplay
    }
  }
`)
graphql(/* GraphQL */ `
  fragment ScheduleInfo on Schedule {
    __typename
    id
    name
    display {
      ...PlaybackDisplay
    }
  }
`)
graphql(/* GraphQL */ `
  fragment PlayableSourceInfo on PlayableSource {
    __typename
    ...PlaylistInfo
    ... on ScheduleSource {
      schedule {
        ...ScheduleInfo
      }
    }
  }
`)

graphql(/* GraphQL */ `
  fragment PlaybackInfo on Playback {
    id
    soundZone
    state
    playbackMode
    volume
    progress {
      progressMs
      updatedAt
    }
    # This returns the currently assigned source for the zone which can differ from the
    # source of the current track
    playFrom {
      __typename
      ...PlaylistInfo
      ...ScheduleInfo
    }
    current {
      id
      playable {
        ...TrackInfo
      }
      source {
        ...PlayableSourceInfo
      }
    }
    upcoming @include(if: $isUnlimited) {
      id
      playable {
        ...TrackInfo
      }
      source {
        ...PlayableSourceInfo
      }
    }
    nextSlotStartsAt
  }
`)
