/**
 * Ensures that the user ID is always in the legacy format, even if a URI is passed.
 *
 * @param id - Either a legacy ID in the format of "VXNlciwsMWJ3eWhwN3BiN2sv" or a URI in
 * the format of "soundtrack:user:xyz"
 *
 * @returns A legacy ID format, such as "VXNlciwsMWJ3eWhwN3BiN2sv"
 */
export function normalizeSoundtrackUserId(id: string, includePrefix = true) {
  if (!id || typeof id !== 'string') {
    return id
  }
  const newId = id.match(/^soundtrack:user:(.+)/)?.[1]
  return newId
    ? btoa('User,' + (includePrefix ? id : newId) + ',0/').replace(/=/g, '.')
    : id
}
