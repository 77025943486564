export const FORMATTERS = {
    iso: toISO,
    syb: toSYB,
    date: (date) => date,
    native: toDateValue,
};
export const PARSERS = {
    iso: fromISO,
    syb: fromSYB,
    date: (date) => new Date(date), // guard against mutation
    native: fromDateValue,
};
export const FORMATS = Object.keys(FORMATTERS);
export function fromFormat(format, value, localTimezone = true) {
    const date = PARSERS[format](value);
    if (!isValidDate(date)) {
        return null;
    }
    if (localTimezone) {
        toLocalTZ(date);
    }
    return date;
}
export function toFormat(format, date) {
    if (!isValidDate(date)) {
        return null;
    }
    return FORMATTERS[format](date);
}
export function toLocalTZ(date) {
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date;
}
export function fromISO(str) {
    return new Date(str);
}
export function fromSYB(str) {
    return new Date(Date.UTC(Number(str.substr(0, 4)), parseInt(str.substr(4, 2), 10) - 1, Number(str.substr(6, 2)), Number(str.substr(8, 2)), Number(str.substr(10, 2)), Number(str.substr(12, 2))));
}
export function toISO(date) {
    return date.toISOString();
}
export function toSYB(date) {
    return [
        leftPad(date.getUTCFullYear(), 4),
        leftPad(date.getUTCMonth() + 1, 2),
        leftPad(date.getUTCDate(), 2),
        leftPad(date.getUTCHours(), 2),
        leftPad(date.getUTCMinutes(), 2),
        leftPad(date.getUTCSeconds(), 2),
    ].join('');
}
export function toDatetimeValue(date) {
    return isValidDate(date)
        ? toLocalTZ(date).toISOString().replace(/\..+/, '')
        : '';
}
export function toDateValue(date) {
    return isValidDate(date) ? toLocalTZ(date).toISOString().split('T')[0] : '';
}
export function fromDateValue(str) {
    if (typeof str !== 'string') {
        return null;
    }
    const parts = str.split('-');
    if (parts.length < 3) {
        return null;
    }
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const date = parseInt(parts[2], 10);
    const d = new Date(year, month, date);
    // Only return valid inputs
    return d.getFullYear() === year &&
        d.getMonth() === month &&
        d.getDate() === date
        ? d
        : null;
}
export function isValidDate(date) {
    return !!date && date instanceof Date && !isNaN(date.getTime());
}
export function leftPad(str, length, char = '0') {
    str = String(str);
    while (str.length < length) {
        str = char + str;
    }
    return str;
}
