export const SUFFICES = {
  ms: 1,
  s: 1e3,
  m: 60e3,
  h: 3600e3,
  d: 24 * 3600e3,
  w: 7 * 24 * 3600e3,
  y: 365.25 * 24 * 3600e3,
} as const

// export type MsInput = number | `${number}${keyof SUFFICES}`
export type MsInput = number | string

/**
 * Converts a string formatted like `7d` to the corresponding duration in
 * milliseconds.
 *
 * Minimal adaption of the `ms()` function from the 'ms' NPM package.
 * Only supports single letter suffices (see `SUFFICES` for whats available).
 *
 * @see https://github.com/vercel/ms
 * @param input - Number to convert to milliseconds.
 * @return Corresponding duration in milliseconds, or `undefined` if invalid
 */
export function ms(input: MsInput | undefined): number | undefined {
  if (typeof input === 'number') {
    return input
  }
  const match = /^(-?(?:\d+)?\.?\d+) *(ms|s|m|h|d|w|y)?$/i.exec(String(input))
  if (!match) {
    return undefined
  }
  const num = parseFloat(match[1])
  const suffix = match[2]?.toLowerCase()
  if (!suffix) {
    return num
  }
  if (!(SUFFICES as any)[suffix]) {
    return undefined
  }
  return num * (SUFFICES as any)[suffix]
}

/** Expose suffix -> ms map */
ms.suffices = SUFFICES
