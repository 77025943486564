interface QueryObject {
  [key: string]: string
}

type AllowedKeys = string[]

/**
 * Decodes a query string, optionally only including the specified keys.
 *
 * @param {string} str - Query string to decode
 * @param {string[]} [allowedKeys] - Only include the specified keys in the returned object
 * @return {object} Key-value object
 */
export function decodeQuery(
  str: string,
  allowedKeys?: AllowedKeys,
): QueryObject {
  const res = {} as QueryObject
  if (!str) {
    return res
  }

  try {
    return (str[0] === '?' ? str.substring(1, str.length) : str)
      .replace(/\+/g, ' ')
      .split('&')
      .map((x) => x.split('='))
      .reduce((obj, [key, value]) => {
        if (!allowedKeys || allowedKeys.indexOf(key) >= 0) {
          obj[key] = decodeURIComponent(value)
        }
        return obj
      }, res)
  } catch (e) {
    return res
  }
}

/**
 * Encodes an object into a query string, optionally only including the specified keys.
 *
 * @param {object} obj - Object to encode
 * @param {string[]} [allowedKeys] - Only include the specified keys in the generated query string
 * @return {string} Encoded query string
 */
export function encodeQuery(
  obj: Record<string, unknown>,
  allowedKeys: AllowedKeys = Object.keys(obj),
): string {
  if (!obj) {
    return ''
  }
  return allowedKeys
    .reduce((ary, key) => {
      if (obj[key]) {
        ary.push(
          key + '=' + encodeURIComponent(String(obj[key]).replace(/ /g, '+')),
        )
      }
      return ary
    }, [] as string[])
    .join('&')
}
