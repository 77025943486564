import { findInteractiveParent } from './dom';
/**
 * Generates the properties necessary to make a `<div>` or another
 * non-interactive HTML element behave as an (accessible) button according to
 * [ARIA guidelines](https://www.w3.org/WAI/ARIA/apg/patterns/button/examples/button/).
 *
 * A regular `<button>` or `<a>` element should be used instead of this whenever
 * possible. A valid use case for this is when you need to make an element
 * interactive that already contains other interactive elements.
 *
 * The returned properties should be spread onto the element.
 *
 * @example
 * ```tsx
 * <div {...fakeButtonProps(() => alert('pressed'))}>
 *   <button>Pressing me doesn't trigger the alert</button>
 * </div>
 * ```
 */
export function fakeButtonProps(
/** Callback to trigger on interaction */
onInteraction) {
    const handler = (event) => {
        if (event.type === 'keydown') {
            if (event.key !== 'Enter' && event.key !== ' ') {
                // Ignore all unhandled keys
                return;
            }
            // Prevent scrolling or other standard behaviour
            event.preventDefault();
        }
        if (findInteractiveParent(event.target, event.currentTarget)) {
            // Ignore interactions with interactive children
            return;
        }
        return onInteraction(event);
    };
    return {
        tabIndex: 0,
        role: 'button',
        onClick: handler,
        onKeyDown: handler,
    };
}
