import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
import { BaseThrobber } from '../BaseThrobber';
import { Icon } from '../Icon';
import { useFormContext } from './useForm';
import { useFormSpy } from './useFormSpy';
FieldLabel.propTypes = {
    disabled: PropTypes.bool,
    children: PropTypes.node,
};
FieldLabel.displayName = 'FieldLabel';
export function FieldLabel({ disabled = false, ...props }) {
    props.className = classNames('field-label', { disabled }, props.className);
    return React.createElement('label', props);
}
FieldMessage.propTypes = {
    error: PropTypes.bool,
    role: PropTypes.string,
    children: PropTypes.any,
};
FieldMessage.displayName = 'FieldMessage';
export function FieldMessage({ error = false, ...props }) {
    props.className = classNames('field-message', { error }, props.className);
    if (error) {
        props.role = props.role || 'alert';
        props.children = (_jsxs(_Fragment, { children: [_jsx(Icon, { name: "alert", middle: true, className: "field-message__icon" }), props.children] }));
    }
    return React.createElement('span', props);
}
FieldValidityIndicator.propTypes = {
    touched: PropTypes.bool.isRequired,
    valid: PropTypes.bool.isRequired,
    validating: PropTypes.bool,
};
FieldValidityIndicator.displayName = 'FieldValidityIndicator';
/**
 * Displays a loading spinner if `validating`,
 * or a ✅ / ❌ icon if `touched` and valid/invalid.
 *
 * @example Used together with `useFormSpy()`
 * <HookField
 *   name="field.path"
 *   validate={[required, validatorReturningPromise]}
 *   suffix={
 *     <FormSpy
 *       path="field.path"
 *       children={props => <FieldValidityIndicator {...props} />}
 *     />
 *   }
 * />
 */
export function FieldValidityIndicator(props) {
    const cx = 'field-validity-indicator';
    return props.validating ? (_jsx(BaseThrobber, { className: cx })) : props.touched ? (_jsx(Icon, { name: props.valid ? 'check' : 'cross', className: cx })) : null;
}
HookFieldValidityIndicator.propTypes = {
    path: PropTypes.string.isRequired,
};
HookFieldValidityIndicator.displayName = 'HookFieldValidityIndicator';
/**
 * Displays an loading spinner/icon that indicates the validity of a form field
 * within a `<HookForm>` form. Uses `<FieldValidityIndicator>` and
 * `useFormSpy()` under the hood.
 *
 * @example Used together with `<HookField>`
 * <HookField
 *   name="field.path"
 *   validate={[required, validatorReturningPromise]}
 *   suffix={<HookFieldValidityIndicator path="field.path" />}
 * />
 */
export function HookFieldValidityIndicator(props) {
    const form = useFormContext();
    const state = useFormSpy(form, props.path);
    return _jsx(FieldValidityIndicator, { ...state });
}
