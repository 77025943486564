import { isValidationError, } from '../lib/validate';
export function getFieldErrorMessage({ errors, error, name, field, }) {
    const path = name || field;
    let found = error ||
        (errors && path
            ? errors[Array.isArray(path) ? path.join('.') : path]
            : undefined);
    // The capsule `validate()` function returns empty array for valid fields
    if (Array.isArray(found) && found.length === 0) {
        return undefined;
    }
    // Extract message from error objects
    if (found && typeof found === 'object' && 'message' in found) {
        found = found['message'];
    }
    return isValidationError(found) ? found : undefined;
}
