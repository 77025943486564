import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { type ModeSetting } from './types'

const initialState = {
  userModeSetting: 'system' as ModeSetting,
}

const slice = createSlice({
  name: 'theme',
  initialState: initialState,
  reducers: {
    setUserModeSetting: (state, action: PayloadAction<ModeSetting>) => {
      state.userModeSetting = action.payload
    },
  },
  selectors: {
    mode(state) {
      return state.userModeSetting
    },
  },
})

export const actions = {
  // this works around this bug:
  // https://github.com/reduxjs/redux-toolkit/issues/1806
  // it seems to be a bug with our current @reduxjs/toolkit version but it is not fixed yet as of v2.2.7
  ...slice.actions,
}

export const { reducer, selectors } = slice
