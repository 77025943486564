/* eslint-disable */
import * as types from './graphql.js';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment PlaylistSource on Playlist {\n    id\n    name\n    snapshot\n    presentAs\n    composerType\n    curated\n    presets {\n      playbackMode\n    }\n    trackStatistics(market: $market) {\n      playable\n    }\n    display {\n      ...PlaybackDisplay\n    }\n  }\n": types.PlaylistSourceFragmentDoc,
    "\n  fragment ScheduleSource on Schedule {\n    id\n    name\n    composerType\n    display {\n      ...PlaybackDisplay\n    }\n  }\n": types.ScheduleSourceFragmentDoc,
    "\n  fragment TrackInfo on Track {\n    id\n    title\n    durationMs\n    explicit\n    isAvailable(market: $market)\n    album {\n      id\n      title\n      display {\n        ...PlaybackDisplay\n      }\n    }\n    artists {\n      id\n      name\n    }\n  }\n": types.TrackInfoFragmentDoc,
    "\n  fragment PlaybackDisplay on Display {\n    image {\n      hero: size(height: 1200, width: 1200)\n      thumbnail: size(height: 150, width: 150)\n    }\n    colors {\n      primary {\n        hex\n      }\n    }\n    palette(theme: DarkTheme) {\n      backgroundPrimary {\n        hex\n      }\n    }\n  }\n": types.PlaybackDisplayFragmentDoc,
    "\n  mutation Play($input: PlayInput!) {\n    play(input: $input) {\n      status\n    }\n  }\n": types.PlayDocument,
    "\n  mutation Pause($input: PauseInput!) {\n    pause(input: $input) {\n      status\n    }\n  }\n": types.PauseDocument,
    "\n  mutation SkipTracks($input: SkipTracksInput!) {\n    skipTracks(input: $input) {\n      status\n    }\n  }\n": types.SkipTracksDocument,
    "\n  mutation SetPlayOrder($input: SoundZoneSetPlaybackOrderInput!) {\n    soundZoneSetPlaybackOrder(input: $input) {\n      status\n    }\n  }\n": types.SetPlayOrderDocument,
    "\n  mutation ClearQueuedTracks($input: SoundZoneClearQueuedTracksInput!) {\n    soundZoneClearQueuedTracks(input: $input) {\n      status\n    }\n  }\n": types.ClearQueuedTracksDocument,
    "\n  mutation SetVolume($input: SetVolumeInput!) {\n    setVolume(input: $input) {\n      volume\n    }\n  }\n": types.SetVolumeDocument,
    "\n  mutation BlockTrack($input: BlockTrackInput!) {\n    blockTrack(input: $input) {\n      source\n    }\n  }\n": types.BlockTrackDocument,
    "\n  mutation UnblockTrack($input: UnblockTrackInput!) {\n    unblockTrack(input: $input) {\n      source\n    }\n  }\n": types.UnblockTrackDocument,
    "\n  mutation AssignSource($input: SoundZoneAssignSourceInput!) {\n    soundZoneAssignSource(input: $input) {\n      soundZones\n    }\n  }\n": types.AssignSourceDocument,
    "\n  mutation QueueTracks($input: SoundZoneQueueTracksInput!) {\n    soundZoneQueueTracks(input: $input) {\n      status\n    }\n  }\n": types.QueueTracksDocument,
    "\n  query PlaybackStatus(\n    $soundZoneId: ID!\n    $market: IsoCountry!\n    $isUnlimited: Boolean!\n  ) {\n    soundZone(id: $soundZoneId) {\n      id\n      online\n      playback {\n        ...PlaybackInfo\n      }\n    }\n  }\n": types.PlaybackStatusDocument,
    "\n  query ZoneInfo($soundZoneId: ID!) {\n    soundZone(id: $soundZoneId) {\n      id\n      name\n      location {\n        id\n        physicalAddress {\n          country\n        }\n      }\n      account { id }\n      ...ZoneSubscriptionInfo\n      settings {\n        crossfade\n        crossfadeLength\n      }\n    }\n  }\n": types.ZoneInfoDocument,
    "\n  subscription SoundZoneUpdateSubscription($input: SoundZoneUpdateInput!) {\n    soundZoneUpdate(input: $input) {\n      soundZone {\n        id\n        online\n        settings {\n          crossfade\n          crossfadeLength\n        }\n        playFrom {\n          __typename\n          ...PlaylistInfo\n          ...ScheduleInfo\n        }\n      }\n    }\n  }\n": types.SoundZoneUpdateSubscriptionDocument,
    "\n  subscription PlaybackSubscription(\n    $input: PlaybackUpdateInput!\n    $market: IsoCountry!\n    $isUnlimited: Boolean!\n  ) {\n    playbackUpdate(input: $input) {\n      playback {\n        ...PlaybackInfo\n      }\n    }\n  }\n": types.PlaybackSubscriptionDocument,
    "\n  fragment ZoneSubscriptionInfo on SoundZone {\n    subscription {\n      isActive\n      activeUntil\n      state\n    }\n    account {\n      id\n      plan\n    }\n  }\n": types.ZoneSubscriptionInfoFragmentDoc,
    "\n  fragment PlaylistInfo on Playlist {\n    __typename\n    id\n    name\n    presentAs\n    display {\n      ...PlaybackDisplay\n    }\n  }\n": types.PlaylistInfoFragmentDoc,
    "\n  fragment ScheduleInfo on Schedule {\n    __typename\n    id\n    name\n    display {\n      ...PlaybackDisplay\n    }\n  }\n": types.ScheduleInfoFragmentDoc,
    "\n  fragment PlayableSourceInfo on PlayableSource {\n    __typename\n    ...PlaylistInfo\n    ... on ScheduleSource {\n      schedule {\n        ...ScheduleInfo\n      }\n    }\n  }\n": types.PlayableSourceInfoFragmentDoc,
    "\n  fragment PlaybackInfo on Playback {\n    id\n    soundZone\n    state\n    playbackMode\n    volume\n    progress {\n      progressMs\n      updatedAt\n    }\n    # This returns the currently assigned source for the zone which can differ from the\n    # source of the current track\n    playFrom {\n      __typename\n      ...PlaylistInfo\n      ...ScheduleInfo\n    }\n    current {\n      id\n      playable {\n        ...TrackInfo\n      }\n      source {\n        ...PlayableSourceInfo\n      }\n    }\n    upcoming @include(if: $isUnlimited) {\n      id\n      playable {\n        ...TrackInfo\n      }\n      source {\n        ...PlayableSourceInfo\n      }\n    }\n    nextSlotStartsAt\n  }\n": types.PlaybackInfoFragmentDoc,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PlaylistSource on Playlist {\n    id\n    name\n    snapshot\n    presentAs\n    composerType\n    curated\n    presets {\n      playbackMode\n    }\n    trackStatistics(market: $market) {\n      playable\n    }\n    display {\n      ...PlaybackDisplay\n    }\n  }\n"): (typeof documents)["\n  fragment PlaylistSource on Playlist {\n    id\n    name\n    snapshot\n    presentAs\n    composerType\n    curated\n    presets {\n      playbackMode\n    }\n    trackStatistics(market: $market) {\n      playable\n    }\n    display {\n      ...PlaybackDisplay\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ScheduleSource on Schedule {\n    id\n    name\n    composerType\n    display {\n      ...PlaybackDisplay\n    }\n  }\n"): (typeof documents)["\n  fragment ScheduleSource on Schedule {\n    id\n    name\n    composerType\n    display {\n      ...PlaybackDisplay\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TrackInfo on Track {\n    id\n    title\n    durationMs\n    explicit\n    isAvailable(market: $market)\n    album {\n      id\n      title\n      display {\n        ...PlaybackDisplay\n      }\n    }\n    artists {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  fragment TrackInfo on Track {\n    id\n    title\n    durationMs\n    explicit\n    isAvailable(market: $market)\n    album {\n      id\n      title\n      display {\n        ...PlaybackDisplay\n      }\n    }\n    artists {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PlaybackDisplay on Display {\n    image {\n      hero: size(height: 1200, width: 1200)\n      thumbnail: size(height: 150, width: 150)\n    }\n    colors {\n      primary {\n        hex\n      }\n    }\n    palette(theme: DarkTheme) {\n      backgroundPrimary {\n        hex\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment PlaybackDisplay on Display {\n    image {\n      hero: size(height: 1200, width: 1200)\n      thumbnail: size(height: 150, width: 150)\n    }\n    colors {\n      primary {\n        hex\n      }\n    }\n    palette(theme: DarkTheme) {\n      backgroundPrimary {\n        hex\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation Play($input: PlayInput!) {\n    play(input: $input) {\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation Play($input: PlayInput!) {\n    play(input: $input) {\n      status\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation Pause($input: PauseInput!) {\n    pause(input: $input) {\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation Pause($input: PauseInput!) {\n    pause(input: $input) {\n      status\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SkipTracks($input: SkipTracksInput!) {\n    skipTracks(input: $input) {\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation SkipTracks($input: SkipTracksInput!) {\n    skipTracks(input: $input) {\n      status\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetPlayOrder($input: SoundZoneSetPlaybackOrderInput!) {\n    soundZoneSetPlaybackOrder(input: $input) {\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation SetPlayOrder($input: SoundZoneSetPlaybackOrderInput!) {\n    soundZoneSetPlaybackOrder(input: $input) {\n      status\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ClearQueuedTracks($input: SoundZoneClearQueuedTracksInput!) {\n    soundZoneClearQueuedTracks(input: $input) {\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation ClearQueuedTracks($input: SoundZoneClearQueuedTracksInput!) {\n    soundZoneClearQueuedTracks(input: $input) {\n      status\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetVolume($input: SetVolumeInput!) {\n    setVolume(input: $input) {\n      volume\n    }\n  }\n"): (typeof documents)["\n  mutation SetVolume($input: SetVolumeInput!) {\n    setVolume(input: $input) {\n      volume\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation BlockTrack($input: BlockTrackInput!) {\n    blockTrack(input: $input) {\n      source\n    }\n  }\n"): (typeof documents)["\n  mutation BlockTrack($input: BlockTrackInput!) {\n    blockTrack(input: $input) {\n      source\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UnblockTrack($input: UnblockTrackInput!) {\n    unblockTrack(input: $input) {\n      source\n    }\n  }\n"): (typeof documents)["\n  mutation UnblockTrack($input: UnblockTrackInput!) {\n    unblockTrack(input: $input) {\n      source\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AssignSource($input: SoundZoneAssignSourceInput!) {\n    soundZoneAssignSource(input: $input) {\n      soundZones\n    }\n  }\n"): (typeof documents)["\n  mutation AssignSource($input: SoundZoneAssignSourceInput!) {\n    soundZoneAssignSource(input: $input) {\n      soundZones\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation QueueTracks($input: SoundZoneQueueTracksInput!) {\n    soundZoneQueueTracks(input: $input) {\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation QueueTracks($input: SoundZoneQueueTracksInput!) {\n    soundZoneQueueTracks(input: $input) {\n      status\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PlaybackStatus(\n    $soundZoneId: ID!\n    $market: IsoCountry!\n    $isUnlimited: Boolean!\n  ) {\n    soundZone(id: $soundZoneId) {\n      id\n      online\n      playback {\n        ...PlaybackInfo\n      }\n    }\n  }\n"): (typeof documents)["\n  query PlaybackStatus(\n    $soundZoneId: ID!\n    $market: IsoCountry!\n    $isUnlimited: Boolean!\n  ) {\n    soundZone(id: $soundZoneId) {\n      id\n      online\n      playback {\n        ...PlaybackInfo\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ZoneInfo($soundZoneId: ID!) {\n    soundZone(id: $soundZoneId) {\n      id\n      name\n      location {\n        id\n        physicalAddress {\n          country\n        }\n      }\n      account { id }\n      ...ZoneSubscriptionInfo\n      settings {\n        crossfade\n        crossfadeLength\n      }\n    }\n  }\n"): (typeof documents)["\n  query ZoneInfo($soundZoneId: ID!) {\n    soundZone(id: $soundZoneId) {\n      id\n      name\n      location {\n        id\n        physicalAddress {\n          country\n        }\n      }\n      account { id }\n      ...ZoneSubscriptionInfo\n      settings {\n        crossfade\n        crossfadeLength\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription SoundZoneUpdateSubscription($input: SoundZoneUpdateInput!) {\n    soundZoneUpdate(input: $input) {\n      soundZone {\n        id\n        online\n        settings {\n          crossfade\n          crossfadeLength\n        }\n        playFrom {\n          __typename\n          ...PlaylistInfo\n          ...ScheduleInfo\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription SoundZoneUpdateSubscription($input: SoundZoneUpdateInput!) {\n    soundZoneUpdate(input: $input) {\n      soundZone {\n        id\n        online\n        settings {\n          crossfade\n          crossfadeLength\n        }\n        playFrom {\n          __typename\n          ...PlaylistInfo\n          ...ScheduleInfo\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription PlaybackSubscription(\n    $input: PlaybackUpdateInput!\n    $market: IsoCountry!\n    $isUnlimited: Boolean!\n  ) {\n    playbackUpdate(input: $input) {\n      playback {\n        ...PlaybackInfo\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription PlaybackSubscription(\n    $input: PlaybackUpdateInput!\n    $market: IsoCountry!\n    $isUnlimited: Boolean!\n  ) {\n    playbackUpdate(input: $input) {\n      playback {\n        ...PlaybackInfo\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ZoneSubscriptionInfo on SoundZone {\n    subscription {\n      isActive\n      activeUntil\n      state\n    }\n    account {\n      id\n      plan\n    }\n  }\n"): (typeof documents)["\n  fragment ZoneSubscriptionInfo on SoundZone {\n    subscription {\n      isActive\n      activeUntil\n      state\n    }\n    account {\n      id\n      plan\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PlaylistInfo on Playlist {\n    __typename\n    id\n    name\n    presentAs\n    display {\n      ...PlaybackDisplay\n    }\n  }\n"): (typeof documents)["\n  fragment PlaylistInfo on Playlist {\n    __typename\n    id\n    name\n    presentAs\n    display {\n      ...PlaybackDisplay\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ScheduleInfo on Schedule {\n    __typename\n    id\n    name\n    display {\n      ...PlaybackDisplay\n    }\n  }\n"): (typeof documents)["\n  fragment ScheduleInfo on Schedule {\n    __typename\n    id\n    name\n    display {\n      ...PlaybackDisplay\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PlayableSourceInfo on PlayableSource {\n    __typename\n    ...PlaylistInfo\n    ... on ScheduleSource {\n      schedule {\n        ...ScheduleInfo\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment PlayableSourceInfo on PlayableSource {\n    __typename\n    ...PlaylistInfo\n    ... on ScheduleSource {\n      schedule {\n        ...ScheduleInfo\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PlaybackInfo on Playback {\n    id\n    soundZone\n    state\n    playbackMode\n    volume\n    progress {\n      progressMs\n      updatedAt\n    }\n    # This returns the currently assigned source for the zone which can differ from the\n    # source of the current track\n    playFrom {\n      __typename\n      ...PlaylistInfo\n      ...ScheduleInfo\n    }\n    current {\n      id\n      playable {\n        ...TrackInfo\n      }\n      source {\n        ...PlayableSourceInfo\n      }\n    }\n    upcoming @include(if: $isUnlimited) {\n      id\n      playable {\n        ...TrackInfo\n      }\n      source {\n        ...PlayableSourceInfo\n      }\n    }\n    nextSlotStartsAt\n  }\n"): (typeof documents)["\n  fragment PlaybackInfo on Playback {\n    id\n    soundZone\n    state\n    playbackMode\n    volume\n    progress {\n      progressMs\n      updatedAt\n    }\n    # This returns the currently assigned source for the zone which can differ from the\n    # source of the current track\n    playFrom {\n      __typename\n      ...PlaylistInfo\n      ...ScheduleInfo\n    }\n    current {\n      id\n      playable {\n        ...TrackInfo\n      }\n      source {\n        ...PlayableSourceInfo\n      }\n    }\n    upcoming @include(if: $isUnlimited) {\n      id\n      playable {\n        ...TrackInfo\n      }\n      source {\n        ...PlayableSourceInfo\n      }\n    }\n    nextSlotStartsAt\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;