export function modifier(base, ...modifiers) {
    return modifiers
        .reduce((res, m) => {
        if (m) {
            res.push(`${base}--${m}`);
        }
        return res;
    }, [])
        .join(' ');
}
export function withModifier(base, ...modifiers) {
    const mod = modifier(base, ...modifiers);
    return mod ? base + ' ' + mod : base;
}
