import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

export type State = {
  updateStatus:
    | { status: 'idle' }
    | { status: 'checking' }
    | { status: 'downloading' }
    | { status: 'available'; version: string }
    | { status: 'not-available' }
    | { status: 'error'; error: string }
  isUnpairing: boolean
  debugFlags: {
    updateSource: null | string
    updateChannel: null | string
    forceSupportLevel: null | 'deprecated' | 'unsupported'
    platformOverride: null | string
    canary: null | string
    noCache: boolean
    noOptimisticUpdates: boolean
  }
}
export type RootState = { desktop: State }

const initialState: State = {
  updateStatus: { status: 'idle' },
  isUnpairing: false,
  debugFlags: {
    updateSource: null,
    updateChannel: null,
    forceSupportLevel: null,
    platformOverride: null,
    canary: null,
    noCache: false,
    noOptimisticUpdates: false,
  },
} as const

const slice = createSlice({
  name: 'desktop',
  initialState: initialState,
  reducers: {
    setUpdateStatus: (
      state: State,
      action: PayloadAction<State['updateStatus']>,
    ) => {
      return { ...state, updateStatus: action.payload }
    },
    setIsUnpairing: (state: State, action: PayloadAction<boolean>) => ({
      ...state,
      isUnpairing: action.payload,
    }),
    setDebugFlags: (
      state: State,
      action: PayloadAction<Partial<State['debugFlags']>>,
    ) => {
      return {
        ...state,
        debugFlags: { ...state.debugFlags, ...action.payload },
      }
    },
  },
  selectors: {
    updateStatus: (state) => state.updateStatus,
    isUnpairing: (state) => state.isUnpairing,
    debugFlags: (state) => state.debugFlags,
  },
})

function debugFlag<K extends keyof State['debugFlags']>(
  state: Partial<RootState>,
  key: K,
): State['debugFlags'][K]
function debugFlag<K extends keyof State['debugFlags']>(
  key: K,
): (state: Partial<RootState>) => State['debugFlags'][K]
function debugFlag<K extends keyof State['debugFlags']>(
  stateOrFlag: Partial<RootState> | K,
  key?: K,
) {
  if (typeof key === 'string') {
    return slice.selectors.debugFlags(stateOrFlag as any)?.[key]
  }
  return (state: Partial<RootState>) =>
    slice.selectors.debugFlags(state as any)?.[stateOrFlag as any as K]
}
export const desktopSlice = Object.assign(slice, {
  selectors: Object.assign(slice.selectors, {
    debugFlag,
  }),
})
