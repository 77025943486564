import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
import { modifier } from './lib/modifier';
const supportedTypes = [
    'navigation',
    'fixed',
    'header',
    'tight',
    'no-bottom',
    'scrolled',
    'padded',
];
/*
 * <Section>
 * = section > .layout-row
 * <Section width="medium">
 * = section > .layout-row.medium-element
 * <Section row>
 * = section > .layout-row > .row
 * <Section row="collapse">
 * = section > .layout-row > .row.collapse
 * <Section type="navigation" className="custom-class">
 * = section.section--navigation.custom-class > .layout-row
 * <Section layout="relative">
 * = section > .layout-row.relative
 */
export const Section = React.forwardRef(({ type, row, layout, width, className, children, ...sectionProps }, ref) => {
    let body = children;
    if (row) {
        body = _jsx("div", { className: classNames('row', row), children: body });
    }
    if (layout || width) {
        body = (_jsx("div", { className: classNames('layout-row', layout, width && `${width}-element`), children: body }));
    }
    sectionProps.className = classNames(className, type && modifier('section', ...type.split(' ')));
    return (_jsx("section", { ref: ref, ...sectionProps, children: body }));
});
Section.displayName = 'Section';
Section.propTypes = {
    type(props, propName, componentName) {
        // @ts-ignore
        const stringErr = PropTypes.string.apply(this, arguments);
        if (stringErr || !props[propName]) {
            return stringErr;
        }
        return props[propName].split(' ').reduce((err, t) => {
            return err || supportedTypes.indexOf(t) >= 0
                ? null
                : new Error(`Unsupported value '${t}' for prop 'type' in '${componentName}', supported types are: ${supportedTypes.join(', ')}.`);
        }, null);
    },
    row: PropTypes.any,
    layout: PropTypes.any,
    width: PropTypes.any,
};
Section.defaultProps = {
    width: 'normal',
};
