import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
export class DataList extends React.PureComponent {
    static DataRow = DataRow;
    static DataCell = DataCell;
    static DataTitle = DataTitle;
    static DataDetail = DataDetail;
    static propTypes = {
        title: PropTypes.node,
        actions: PropTypes.node,
        className: PropTypes.string,
        children: PropTypes.node,
    };
    render() {
        const { title, actions, ...args } = this.props;
        args.className = classNames(args.className, 'data-list');
        return (_jsxs("div", { ...args, children: [(title || actions) && (_jsxs(DataRow, { children: [title && (_jsx(DataCell, { children: _jsx("h3", { className: "data-list__title", children: title }) })), actions && _jsx(DataCell, { children: actions })] })), this.props.children] }));
    }
}
DataRow.displayName = 'DataRow';
DataRow.propTypes = {
    align: PropTypes.string,
    type: PropTypes.string,
    flex: PropTypes.bool,
    dataList: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
};
export function DataRow(props) {
    const { children, align, type, flex, dataList, ...args } = props;
    args.className = classNames(args.className, dataList ? 'data-list' : null, 'data-row', flex && `data-row--flex`, type && `data-row--${type}`, align && `data-row--${align}`);
    return _jsx("div", { ...args, children: children });
}
DataCell.displayName = 'DataCell';
DataCell.propTypes = {
    thin: PropTypes.bool,
    collapse: PropTypes.bool,
    nowrap: PropTypes.bool,
    align: PropTypes.string,
    size: PropTypes.string,
    breakpoint: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
};
export function DataCell(props) {
    let { collapse, thin, nowrap, align, size, breakpoint, ...args } = props;
    if (thin) {
        size = 0;
    }
    args.className = classNames(args.className, 'data-cell', isInt(size)
        ? size === 0
            ? 'data-cell--thin'
            : `${breakpoint || 'xs'}-${size}`
        : null, align ? `align-${align}` : null, {
        collapse,
        nowrap,
    });
    return React.createElement('div', args);
}
DataTitle.displayName = 'DataTitle';
export function DataTitle(props) {
    const { plain, ...args } = props;
    args.className = classNames(args.className, 'data-cell__title', {
        'data-cell__title--plain': plain,
    });
    return React.createElement('div', args);
}
DataDetail.displayName = 'DataDetail';
export function DataDetail(props) {
    const { ...args } = props;
    args.className = classNames(args.className, 'data-cell__detail');
    return React.createElement('div', args);
}
function isInt(x) {
    // eslint-disable-next-line eqeqeq
    return x == parseInt(x, 10);
}
