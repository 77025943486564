import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { getIn } from '@soundtrackyourbrand/object-utils.js';
import { useField } from './useField';
RadioGroup.displayName = 'RadioGroup';
RadioGroup.IsFormInput = true;
// TODO: Support `colorize` & `active` props?
export function RadioGroup({ options, layout = 'vertical', valueKey = 'value', labelKey = 'label', ...props }) {
    const field = useField(props, {
        fieldType: 'radio-group',
    });
    return field.wrap(_jsxs(_Fragment, { children: [field.label, _jsx("div", { className: `radio-group radio-group--${layout}`, children: options.map((opt, index) => {
                    const type = typeof opt;
                    const isPrimitive = type === 'string' || type === 'number';
                    const obj = opt;
                    const value = isPrimitive ? opt : getIn(obj, valueKey);
                    const label = isPrimitive ? opt : getIn(obj, labelKey);
                    return (_jsxs("label", { className: "inline-label field field--radio", children: [_jsx("input", { ...field.props, type: "radio", value: value, checked: field.props.value === value, disabled: !isPrimitive && getIn(obj, 'disabled') === true }), label] }, index));
                }) })] }));
}
