import { getLocation } from './get-location'
import searchEngines from './search-engines.json'

/**
 * Maps referrer URL to known sources (mostly search engines).
 *
 * Uses search-engines.json, which is based on the table of search engines on
 * https://support.google.com/analytics/answer/2795821
 *
 * @param {string} referrer - URL
 * @return {string} Name of source if known, otherwise empty string
 */
export default function identifyReferrer(referrer: string): string {
  if (!referrer) {
    return ''
  }
  const location = getLocation(referrer)
  if (!location) {
    return ''
  }

  for (const testString in searchEngines) {
    if (
      Object.prototype.hasOwnProperty.call(searchEngines, testString) &&
      location.hostname.indexOf(testString) === 0
    ) {
      return ((searchEngines as any)[testString] as string[])[0]
    }
  }

  return location.hostname
}
