import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from '../Icon';
import { DateInput } from './DateInput';
import { InputBase } from './InputBase';
import { fieldComponent } from './fieldComponent';
import { useField } from './useField';
const TYPES_WITH_NATIVE_PLACEHOLDER = [
    'date',
    'datetime-local',
    'month',
    'time',
    'year',
];
/**
 * Background default is semi-transparent black, or semi-transparent white in `.mono-container`.
 * Modifier `.opaque` has solid bkg-color.
 *
 * Renders any `<input>` type field whose value is a string, such as `text`,
 * `email`, `password`, `time`, `date`, etc.
 *
 * `checkbox` and `radio` are unsupported as they are controlled via the
 * `checked` attribute. They have their own dedicated React components.
 *
 * `hidden` inputs will be rendered without any additional markup (such as labels etc.), and `ref` will not be set.
 */
export const Input = fieldComponent(({ type = 'text', afterInput, ...props }, ref) => {
    props.floatingLabel ??=
        !props.placeholder && !TYPES_WITH_NATIVE_PLACEHOLDER.includes(type);
    // Render custom element for requested type?
    let InputElement = 'input';
    switch (type) {
        case 'date':
            InputElement = DateInput;
        // fallthrough
        case 'datetime-local':
        case 'month':
        case 'time':
            props.suffix ??= (_jsx(Icon, { name: type === 'time' ? 'clock' : 'calendar', className: "opaque" }));
            break;
    }
    const [baseProps, fieldProps] = InputBase.partitionProps(props);
    const field = useField(fieldProps, {
        ref,
        fieldType: type,
    });
    // Don’t render wrappers, labels and other add-ons for hidden inputs.
    if (field.props.hidden || field.props.type === 'hidden') {
        return _jsx(InputElement, { ...field.props, type: type });
    }
    return field.wrap(_jsxs(InputBase, { fieldRef: field.ref, ...baseProps, value: field.props.value, children: [_jsx(InputElement, { ...field.props, type: type }), afterInput] }));
}, {
    displayName: 'Input',
});
