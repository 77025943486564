import {
  ApolloLink,
  type FetchResult,
  type NextLink,
  Observable,
  type ObservableSubscription,
  type Observer,
  type Operation,
} from '@apollo/client'
import { type ErrorResponse } from '@apollo/client/link/error'
import { buildDelayFunction } from '@apollo/client/link/retry/delayFunction'
import { isNetworkError, isRateLimitError, isServerError } from '../errors'
import { getMainDefinition } from '../getMainDefinition'

export { buildDelayFunction }

/** @private */
export type RetryableErrorResponse = Omit<ErrorResponse, 'forward'> & {
  retryCount: number
}
/** @private */
export type RetryFunction = (response: RetryableErrorResponse) => RetryAction
/** @private */
export type RetryAction =
  | false
  | {
      delay: number
      /** If true the respones will be returned to the caller _and_ retried */
      useResponse?: boolean
    }

/**
 * Re-implementation of the built in [RetryLink]
 * that allows retrying on GraphQL errors (in addition to network errors) and allows
 * for using the response while also retrying.
 *
 * NOTE: You probably want to place this last in the link chain, just before
 * the http/websocket link.
 *
 * [RetryLink]: https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
 *
 * @group Apollo Links
 */
export class RetryLink extends ApolloLink {
  private shouldRetry: RetryFunction

  constructor({
    shouldRetry = retryStrategy(),
  }: {
    shouldRetry?: RetryFunction
  } = {}) {
    super()
    this.shouldRetry = shouldRetry
  }

  public request(
    operation: Operation,
    nextLink: NextLink,
  ): Observable<FetchResult> {
    const retryable = new RetryableOperation(
      operation,
      nextLink,
      this.shouldRetry,
    )
    retryable.start()

    return new Observable((observer) => {
      retryable.subscribe(observer)
      return () => {
        retryable.unsubscribe(observer)
      }
    })
  }
}

/**
 * Default retry strategy for the {@link RetryLink}. Either call this with
 * custom options or replace it for even more customization.
 *
 * Currently this will retry queries that fail with:
 * - Network errors (except 401 and 403) with jitter and an exponential backoff
 *   starting at 300ms
 * - Rate limit errors with jitter and an exponential backoff starting at 1.5s
 *
 * with a maximum of 5 attempts. Mutations or subscriptions are not retried.
 *
 * @group Apollo Links
 */
export function retryStrategy({
  attempts = 5,
  defaultDelay = buildDelayFunction(),
  rateLimitDelay = buildDelayFunction({ initial: 1500 }),
} = {}): RetryFunction {
  return (response) => {
    if (response.retryCount >= attempts) return false
    const def = getMainDefinition(response.operation.query)
    if (def.operation !== 'query') return false

    if (isNetworkError(response, true) || isServerError(response)) {
      return {
        delay: defaultDelay(
          response.retryCount,
          response.operation,
          response.networkError,
        ),
      }
    }

    if (isRateLimitError(response)) {
      return {
        delay: rateLimitDelay(
          response.retryCount,
          response.operation,
          response.networkError,
        ),
      }
    }

    return false
  }
}

/**
 * Tracking and management of operations that may be (or currently are) retried.
 * Created by the {@link RetryLink} for each operation.
 *
 * @group Apollo Links
 * @private
 */
class RetryableOperation<TValue = any> {
  private retryCount = 0
  private values: any[] = []
  private error: any
  private complete = false
  private canceled = false
  private observers: (Observer<TValue> | null)[] = []
  private currentSubscription: ObservableSubscription | null = null
  private timerId: number | undefined

  // eslint-disable-next-line no-useless-constructor
  constructor(
    private operation: Operation,
    private nextLink: NextLink,
    private shouldRetry: RetryFunction,
  ) {}

  /**
   * Register a new observer for this operation.
   *
   * If the operation has previously emitted other events, they will be
   * immediately triggered for the observer.
   */
  public subscribe(observer: Observer<TValue>) {
    if (this.canceled) {
      throw new Error(
        `Subscribing to a retryable link that was canceled is not supported`,
      )
    }
    this.observers.push(observer)

    // If we've already begun, catch this observer up.
    for (const value of this.values) {
      observer.next!(value)
    }

    if (this.complete) {
      observer.complete!()
    } else if (this.error) {
      observer.error!(this.error)
    }
  }

  /**
   * Remove a previously registered observer from this operation.
   *
   * If no observers remain, the operation will stop retrying, and unsubscribe
   * from its downstream link.
   */
  public unsubscribe(observer: Observer<TValue>) {
    const index = this.observers.indexOf(observer)
    if (index < 0) {
      throw new Error(
        `RetryLink BUG! Attempting to unsubscribe unknown observer!`,
      )
    }
    // Note that we are careful not to change the order of length of the array,
    // as we are often mid-iteration when calling this method.
    this.observers[index] = null

    // If this is the last observer, we're done.
    if (this.observers.every((o) => o === null)) {
      this.cancel()
    }
  }

  /**
   * Start the initial request.
   */
  public start() {
    if (this.currentSubscription) return // Already started.
    this.try()
  }

  /**
   * Stop retrying for the operation, and cancel any in-progress requests.
   */
  public cancel() {
    if (this.currentSubscription) {
      this.currentSubscription.unsubscribe()
    }
    clearTimeout(this.timerId)
    this.timerId = undefined
    this.currentSubscription = null
    this.canceled = true
  }

  private try() {
    const a = this.nextLink(this.operation)
    this.currentSubscription = a.subscribe({
      next: this.onNext,
      error: this.onError,
      complete: this.onComplete,
    })
  }

  private onNext = (result: any) => {
    if (result?.errors) {
      this.retryCount += 1
      const shouldRetry = this.shouldRetry({
        retryCount: this.retryCount,
        graphQLErrors: result.errors,
        response: result,
        operation: this.operation,
      })
      if (shouldRetry) {
        this.scheduleRetry(shouldRetry.delay)
        if (!shouldRetry.useResponse) return
      }
    }

    this.values.push(result)

    for (const observer of this.observers) {
      if (!observer) continue
      observer.next!(result)
    }
  }

  private onComplete = () => {
    if (this.timerId) return
    this.complete = true
    for (const observer of this.observers) {
      if (!observer) continue
      observer.complete!()
    }
  }

  private onError = (networkError: any) => {
    this.retryCount += 1

    // Should we retry?
    const shouldRetry = this.shouldRetry({
      retryCount: this.retryCount,
      networkError,
      // Network errors can return GraphQL errors on for example a 403
      graphQLErrors:
        networkError && networkError.result && networkError.result.errors,
      operation: this.operation,
    })
    if (shouldRetry) {
      this.scheduleRetry(shouldRetry.delay)
      return
    }

    this.error = networkError
    for (const observer of this.observers) {
      if (!observer) continue
      observer.error!(networkError)
    }
  }

  private scheduleRetry(delay: number) {
    if (this.timerId) {
      throw new Error(`RetryLink: BUG! Encountered overlapping retries`)
    }

    this.timerId = setTimeout(() => {
      this.timerId = undefined
      this.try()
    }, delay) as any as number
  }
}
