import type { Store, Unsubscribe } from '@reduxjs/toolkit'

interface QueryAndObserveOptions {
  /** Whether to immediately invoke the `onChange` callback. Defaults to `true` */
  invokeImmediately?: boolean
}

/** Fires the callback whenever the passed selector changes */
export const queryAndObserve: <RootState, ObservedData = any>(
  selector: (state: RootState) => ObservedData,
  onChange: (next: ObservedData, current: ObservedData, store: Store) => void,
  store: Store<RootState>,
  options?: QueryAndObserveOptions,
) => Unsubscribe = (selector, callback, store, options = {}) => {
  const { invokeImmediately = true } = options

  let currentState = selector(store.getState())

  if (invokeImmediately) {
    callback(currentState, currentState, store as any)
  }

  return store.subscribe(() => {
    const state = store.getState()
    const nextState = selector(state)
    if (nextState !== currentState) {
      const oldState = currentState
      currentState = nextState
      callback(nextState, oldState, store)
    }
  })
}
