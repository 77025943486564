type Procedure = (...args: any[]) => void

export interface DebouncedFunction<F extends Procedure> {
  (...args: Parameters<F>): void
}

export function debounce<F extends Procedure>(
  fn: F,
  delay: number,
): DebouncedFunction<F> {
  let timer: ReturnType<typeof setTimeout>
  return function (...args: Parameters<F>) {
    clearTimeout(timer)

    timer = setTimeout(() => {
      // @ts-ignore
      fn.apply(this, args)
    }, delay)
  }
}
