import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { withModifier } from '../lib';
import { getInputVisibleText, isFocusedElement } from '../lib/dom';
import { partitionObject } from '../lib/object';
/** DOM Events that can cause field activation state to change */
const ELEMENT_ACTIVATION_EVENTS = ['change', 'input', 'blur', 'focus'];
/** List of properties exclusive to <InputBase>, used with `partitionProps()` */
const EXCLUSIVE_PROPS = ['label', 'floatingLabel', 'prefix', 'suffix'];
InputBase.partitionProps = (props) => {
    // eslint-disable-next-line func-call-spacing
    return partitionObject(props, EXCLUSIVE_PROPS);
};
/**
 * Provides handling of input decorations such as label, prefix, suffix,
 * message, error, message, etc.
 *
 * Not intended for inline inputs such as checkboxes and radio buttons.
 */
export function InputBase({ fieldRef, value, label, floatingLabel, prefix, suffix, children, }) {
    const wrapperRef = React.useRef(null);
    // Manages `.field-input--activated` without re-rendering
    const updateActivated = React.useCallback((eventType) => {
        const input = fieldRef.current;
        const wrapper = wrapperRef.current;
        if (!wrapper || !input) {
            return;
        }
        let activated = !floatingLabel || getInputVisibleText(input) !== '';
        if (!(input instanceof HTMLSelectElement)) {
            // Select elements are only active while having visible text
            // Other inputs are active while focused or having a value
            activated =
                activated ||
                    eventType === 'focus' ||
                    (eventType !== 'blur' && isFocusedElement(input));
        }
        wrapper.classList[activated ? 'remove' : 'add']('field-input--inactive');
    }, [fieldRef, wrapperRef, floatingLabel]);
    /*
      Update `activated` after every render.
      This can't be done reliably during the React render phase since there's no
      simple way to determine the visible value of inputs such as `<select>`
      without using the DOM API.
    */
    React.useLayoutEffect(() => {
        updateActivated();
    });
    // Track `activated` state via input event listeners
    React.useEffect(() => {
        const input = fieldRef.current;
        const wrapper = wrapperRef.current;
        if (!floatingLabel || !input || !input.addEventListener || !wrapper) {
            updateActivated();
            return;
        }
        const onUpdate = (event) => updateActivated(event.type);
        ELEMENT_ACTIVATION_EVENTS.forEach((event) => {
            input.addEventListener(event, onUpdate);
        });
        return () => {
            ELEMENT_ACTIVATION_EVENTS.forEach((event) => {
                input.removeEventListener(event, onUpdate);
            });
        };
    }, [fieldRef, floatingLabel, updateActivated]);
    const cx = withModifier('field-input', !!prefix && 'prefix', !!suffix && 'suffix', floatingLabel && 'floating-label');
    return (_jsxs("label", { ref: wrapperRef, className: cx, children: [!!label && _jsx("label", { className: "field-label", children: label }), !!prefix && _jsx("span", { className: "field-prefix", children: prefix }), children, !!suffix && _jsx("span", { className: "field-suffix", children: suffix })] }));
}
