type RoundingMethod = 'round' | 'ceil' | 'floor'
let _dayFormat: Intl.DateTimeFormat
function dayFormat(): Intl.DateTimeFormat {
  _dayFormat ??= new Intl.DateTimeFormat(['en'], {
    weekday: 'long',
  })
  return _dayFormat
}
let _timeFormat: Intl.DateTimeFormat
function timeFormat(): Intl.DateTimeFormat {
  _timeFormat ??= Intl.DateTimeFormat(undefined, {
    hour: 'numeric',
    minute: 'numeric',
  })
  return _timeFormat
}
let _relativeTimeFormat: Intl.RelativeTimeFormat
function relativeTimeFormat(): Intl.RelativeTimeFormat {
  _relativeTimeFormat ??= new Intl.RelativeTimeFormat()
  return _relativeTimeFormat
}

const roundMilliseconds = (rounding: RoundingMethod, timeMs: number) =>
  Math[rounding](Math.abs(timeMs) / 1000)

/**
 * Returns a formatted time generally used for songs, e.g. 3:41
 * @param timeMs Time in milliseconds
 * @param rounding How to round the seconds, defaults to 'round'
 * @return "0:42", "3:52"
 */
export function millisecondsToMinutesAndSeconds(
  timeMs: number,
  rounding: RoundingMethod = 'round',
): string {
  const time = roundMilliseconds(rounding, timeMs)
  const minutes = Math.floor(time / 60)
  const seconds = (time % 60).toString()
  return `${timeMs < 0 ? '-' : ''}${minutes}:${seconds.padStart(2, '0')}`
}

/**
 * Returns a formatted time generally used for playlists, e.g. 16h 3m
 * @param timeMs Time in milliseconds
 * @param rounding How to round the minutes, defaults to 'round'
 * @return { hours: 0, minutes: 42 }, { hours: 3, minutes: 52 }
 */
export function millisecondsToHoursAndMinutes(
  timeMs: number,
  rounding: RoundingMethod = 'round',
): { hours: number; minutes: number } {
  const time = roundMilliseconds(rounding, timeMs)
  const hours = Math.floor(time / 3600)
  const minutes = Math.floor((time % 3600) / 60)
  return { hours, minutes }
}

export function millisecondsToDayAndTime(milliseconds: number) {
  const startTimeJs = new Date(milliseconds)
  const day = dayFormat().format(startTimeJs)
  const time = timeFormat().format(startTimeJs).replace(' ', '\u00A0')

  return { day, time }
}

const units: [Intl.RelativeTimeFormatUnit, number][] = [
  ['minute', 60],
  ['hour', 60],
  ['day', 24],
  ['week', 7],
  ['month', 4],
  ['year', 12],
]
export function relativeTime(date: Date | number) {
  date = +date
  let diff = date - Date.now()
  diff /= 1000
  let unit: Intl.RelativeTimeFormatUnit = 'second'

  for (const [u, value] of units) {
    if (Math.abs(diff) < value) break
    diff /= value
    unit = u
  }

  return relativeTimeFormat().format(Math.round(diff), unit)
}
