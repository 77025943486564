export const DEFAULT_SORT_STRING_SEPARATOR = ',';
/**
 * Multi column sort object.
 * Is immutable, so all mutations return a new copy.
 *
 * @typedef {object} SortObject
 * @property {string[]} by - Column priorities
 * @property {object} desc - Column to boolean map indicating if the column is descending
 */
class SortObject {
    /**
     * Instantiates a `SortObject` from a sort string.
     * Multi-column sorting is possible by separator column names with the `separator`.
     *
     * Prefix column names with a minus (`-`) to sort them in descending order.
     *
     * @param {string} sortString - Input
     * @param {string} [separator] - Column separator
     * @return {SortObject}
     */
    static fromString(sortString = '', separator = DEFAULT_SORT_STRING_SEPARATOR) {
        const by = [];
        const desc = {};
        if (typeof sortString === 'string') {
            sortString.split(separator).forEach((column) => {
                const descending = column.charAt(0) === '-';
                if (descending) {
                    column = column.slice(1);
                }
                by.push(column);
                desc[column] = descending;
            });
        }
        return new SortObject({ by, desc, fixDesc: false });
    }
    /**
     * Default propMapper for `comparator`, supports both immutable iterables and
     * plain objects.
     *
     * @param {object} obj - Object to retrieve props from
     * @param {string} prop - Property name to retrieve
     * @return {*} The property value
     */
    static defaultPropMapper(obj, prop) {
        return typeof obj.get === 'function' ? obj.get(prop) : obj[prop];
    }
    /**
     * Instantiates a new `SortObject`.
     *
     * @param {object} [input] - Plain object or existing SortObject instance
     * @param {string[]} [input.by] - Column priority
     * @param {Object.<string, boolean>} [input.desc] - Direction for each sorted column (true for descending)
     */
    constructor({ by, desc } = {}) {
        this.by = by || [];
        this.desc = desc || {};
    }
    /**
     * Returns a clone of this SortObject.
     *
     * @return {SortObject} Cloned instance
     */
    clone() {
        return new SortObject({
            by: this.by.slice(0),
            desc: Object.assign({}, this.desc),
        });
    }
    /**
     * Prevents the internal data structures from being modified.
     *
     * @return {SortObject} Self
     */
    freeze() {
        Object.freeze(this.by);
        Object.freeze(this.desc);
        return this;
    }
    /**
     * Returns true if the sort contains no ordering.
     *
     * @return {boolean}
     */
    isEmpty() {
        return this.by.length < 1;
    }
    /**
     * Returns sort direction for the given column, or null if not sorted by it.
     *
     * @param {string} column - Column to check
     * @return {"asc"|"desc"|undefined} undefined, 'asc' or 'desc'
     */
    getColumnDirection(column) {
        return this.by.indexOf(column) >= 0
            ? this.desc[column]
                ? 'desc'
                : 'asc'
            : undefined;
    }
    /**
     * Returns a sort string representation of this SortObject.
     *
     * @param {string} [separator] - Column separator
     * @return {string} Corresponding sort string
     */
    toString(separator = DEFAULT_SORT_STRING_SEPARATOR) {
        return this.by.reduce((str, column) => {
            if (this.desc[column]) {
                column = '-' + column;
            }
            return str ? str + separator + column : column;
        }, null);
    }
    /**
     * Toggle sort order of the selected column.
     *
     * @param {string} column - Column to sort by
     * @param {boolean} [append=false] - Append column instead of replacing existing
     * @return {SortObject} New instance
     */
    column(column, append = false) {
        const toDescending = this.desc[column] === false;
        let next;
        if (append) {
            next = this.clone();
            if (this.by.indexOf(column) < 0) {
                next.by.push(column);
            }
        }
        else {
            // Exclusive sort, so reset state
            next = new SortObject({ by: [column] });
        }
        next.desc[column] = toDescending;
        return next.freeze();
    }
    /**
     * Remove a column if present.
     *
     * @param {string} column - Column to stop sorting by
     * @return {SortObject} New instance if removed, self otherwise
     */
    remove(column) {
        const index = this.by.indexOf(column);
        if (index < 0) {
            return this;
        }
        const next = this.clone();
        next.by.splice(index, 1);
        delete next.desc[column];
        return next.freeze();
    }
    /**
     * Generates a comparison function compatible with the `sort` API of Array &
     * im.Iterable, which sorts data according to the given SortObject.
     *
     * An optional propMapper function can be provided, in which case it will be
     * expected to return a sortable primitive for a row & column combination.
     *
     * @param {function} [propMapper] - Row prop to sortable primitive mapping function
     * @return {function} Comparison function
     */
    comparator(propMapper = SortObject.defaultPropMapper) {
        const sort = this;
        return function sortComparator(a, b) {
            const { length } = sort.by;
            let i, prop, descending, aVal, bVal;
            if (a == null || b == null) {
                return a == null ? (b == null ? 0 : +1) : -1;
            }
            for (i = 0; i < length; i++) {
                prop = sort.by[i];
                descending = sort.desc[prop];
                aVal = propMapper(a, prop);
                bVal = propMapper(b, prop);
                if (typeof aVal === 'string' && typeof bVal === 'string') {
                    const cmp = aVal.localeCompare(bVal);
                    if (cmp !== 0) {
                        return descending ? -cmp : cmp;
                    }
                }
                else if (aVal < bVal) {
                    return descending ? +1 : -1;
                }
                else if (aVal > bVal) {
                    return descending ? -1 : +1;
                }
            }
            return 0;
        };
    }
}
export default SortObject;
