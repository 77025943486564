import { getIn, setInImmutable } from '@soundtrackyourbrand/object-utils.js';
import extend from 'extend';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { filterObject } from '../lib/object';
import { isEmptyObject, isFunction, isObject, isPromise, } from '../lib/type-checking';
import { isValidationError, validateField, } from '../lib/validate';
import { attachValidationErrors } from './attachValidationErrors';
import { isPseudoEvent } from './eventFromOnChange';
import { getInputValue } from './getInputValue';
const DEFAULT_OPTIONS = {
    validateOnChange: false,
    validateOnTouch: true,
    validateOnBlur: false,
    focusFirstErrorOnSubmit: true,
};
const FormContext = React.createContext(null);
FormContext.displayName = 'FormContext';
/** Access the {@link useForm} instance provided by the nearest {@link FormProvider} */
export const useFormContext = () => {
    return React.useContext(FormContext);
};
/**
 * Exposes form to `<HookField>` and `<FormSpy>`.
 * Without it `form` would have to be manually passed as a prop to
 * each of these components.
 */
export const FormProvider = FormContext.Provider;
/** Path string representing the global form itself (used for global errors, etc.) */
export const FORM_PATH = '';
/**
 * Hook that manages state, submission, validation, etc. for a single form.
 *
 * Use `<HookForm>` (which uses {@link FormProvider} internally) to
 * automatically forward the form instance to child form components.
 *
 * @example
 * ```tsx
 * const form = useForm(...)
 * return <FormProvider value={form}>
 *   <HookField name="foo" />
 *   <FormSpy path="">
 *     {({ error }) => (!!error &&
 *       <Message
 *         type="error"
 *         onDismiss={() => form.setError('', null)}
 *         children={error}
 *       />
 *     )}
 *   </FormSpy>
 *   <FormSpy>
 *     {({ disabled }) => <Button
 *       type="submit"
 *       disabled={disabled}
 *       loading={disabled === 'submitting'}
 *       children="Submit"
 *     />}
 *   </FormSpy>
 * </FormProvider>
 * ```
 */
export function useForm(optionsRaw) {
    const apiRef = React.useRef(null);
    const contextRef = React.useRef(null);
    const formStateRef = React.useRef({});
    // Initialize context once
    if (!contextRef.current) {
        contextRef.current = {
            options: optionsRaw,
            fields: {},
            subscriptions: [],
            subscriptionId: 0,
            pendingValidations: {},
            pendingUpdateTimeout: 0,
        };
    }
    // These references never change
    const formState = formStateRef.current;
    const context = contextRef.current;
    // Expose latest options on context
    context.options = Object.assign({}, DEFAULT_OPTIONS, optionsRaw);
    // Track if component is mounted
    const mounted = React.useRef(false);
    React.useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    });
    /** Calls all active subscription callbacks, allowing them to render */
    const triggerUpdate = React.useCallback(() => {
        const context = contextRef.current;
        clearTimeout(context.pendingUpdateTimeout);
        context.pendingUpdateTimeout = 0;
        if (mounted.current) {
            // TODO: Is this necessary?
            ReactDOM.unstable_batchedUpdates(() => {
                for (let i = 0; i < context.subscriptions.length; i++) {
                    context.subscriptions[i].callback(apiRef.current);
                }
            });
        }
    }, [contextRef, apiRef]);
    /** Queues a `triggerUpdate()` call */
    const queueUpdate = React.useCallback(() => {
        if (!context.pendingUpdateTimeout) {
            context.pendingUpdateTimeout = setTimeout(triggerUpdate, 0);
        }
    }, [context, triggerUpdate]);
    // Tracks api.formState prop accesses to know when to trigger updates
    // const accessedFormStateRef = React.useRef<Partial<UseFormState<Values>>>({})
    // Initialize returned API once
    if (!apiRef.current) {
        const api = (apiRef.current = {
            context,
            formState,
            /*
              TODO: Is this worth it? It will result in any access of the formState
              within subscriptions to trigger re-renders on subsequent changes
            */
            // formState: accessedPropsProxy(formStateRef.current, accessedFormStateRef),
            fieldState: (path) => {
                return {
                    value: getIn(formState.values, path),
                    validating: !!context.pendingValidations[path],
                    valid: !isValidationError(formState.errors[path]),
                    error: formState.errors[path] || null,
                    touched: !!formState.touched[path],
                    focused: formState.focused === path,
                };
            },
            reset: () => {
                const errors = context.options.initialErrors || {};
                let values = context.options.initialValues;
                let valuesPromise = undefined;
                if (typeof values === 'function') {
                    const returnedValues = values();
                    if (isPromise(returnedValues)) {
                        // Temporarily store async values in `valuesPromise` until resolved
                        // Disable form until resolved
                        values = undefined;
                        valuesPromise = returnedValues;
                        // Errors are intentionally not handled here as the form has reached a broken state.
                        // Instead the consumer is responsible for catching and recovering
                        // from errors in the `initialValues` function.
                        returnedValues.then((resolvedValues) => {
                            if (formState.valuesPromise !== valuesPromise) {
                                return;
                            }
                            Object.assign(formState, {
                                disabled: false,
                                valuesPromise: undefined,
                            });
                            api.setValues(resolvedValues);
                        });
                    }
                    else {
                        values = returnedValues;
                    }
                }
                queueUpdate();
                return Object.assign(formState, {
                    values: values || {},
                    valuesPromise,
                    errors,
                    // Touch all fields that are invalid from the start
                    touched: Object.keys(errors).reduce((o, key) => ((o[key] = true), o), {}),
                    focused: null,
                    dirty: false,
                    valid: true,
                    validating: false,
                    disabled: valuesPromise ? 'initializing' : false,
                    submitting: false,
                    submitAttempts: 0,
                    submitted: 0,
                });
            },
            subscribe: (callback) => {
                const id = context.subscriptionId++;
                let subscribed = true;
                context.subscriptions.push({ id, callback });
                // Return unsubscribe callback
                return () => {
                    if (!subscribed) {
                        return;
                    }
                    subscribed = false;
                    const index = context.subscriptions.findIndex((row) => row.id === id);
                    if (index >= 0) {
                        context.subscriptions.splice(index, 1);
                    }
                };
            },
            getValues: (path) => {
                return path?.length ? getIn(formState.values, path) : formState.values;
            },
            setValue: (path, value, { touch = true, validate = false } = {}) => {
                if (touch && !formState.touched[path]) {
                    api.touchFields([path]);
                }
                const current = getIn(formState.values, path);
                if (value === current) {
                    return;
                }
                formState.dirty = true;
                formState.values = setInImmutable(formState.values, path, value);
                delete context.pendingValidations[path];
                if (validate) {
                    api.validateField(path);
                }
                else {
                    queueUpdate();
                }
            },
            setValues: (values, { pathPrefix = '', deepMerge = true, touch = true, validate = false, } = {}) => {
                const currentValues = api.getValues(pathPrefix);
                const resolvedValues = isFunction(values)
                    ? values(currentValues)
                    : values;
                // TODO: Include nested properties as well?
                const updatedPaths = Object.keys(resolvedValues).map((p) => pathPrefix + p);
                if (touch) {
                    api.touchFields(updatedPaths);
                }
                const mergedValues = typeof currentValues.merge === 'function'
                    ? // Merge immutable.js records
                        deepMerge && typeof currentValues.mergeDeep === 'function'
                            ? currentValues.mergeDeep(values)
                            : currentValues.merge(values)
                    : // Merge plain old JS objects
                        deepMerge
                            ? extend(true, {}, currentValues, resolvedValues)
                            : Object.assign({}, currentValues, resolvedValues);
                // Apply updates
                formState.values =
                    pathPrefix === '' || pathPrefix == null
                        ? mergedValues
                        : setInImmutable(formState.values, pathPrefix.split('.'), mergedValues);
                formState.dirty = true;
                if (validate) {
                    // Validate paths included in values
                    const fields = api.validatedFields().filter((path) => {
                        return getIn(resolvedValues, path) !== undefined;
                    });
                    api.validateFields(fields);
                }
                else {
                    queueUpdate();
                }
            },
            setError: (path, result) => {
                if (result === formState.errors[path]) {
                    return;
                }
                const errors = Object.assign({}, formState.errors);
                if (isValidationError(result)) {
                    errors[path] = result;
                    formState.valid = false;
                }
                else {
                    delete errors[path];
                    if (isEmptyObject(errors)) {
                        formState.valid = true;
                    }
                }
                formState.errors = errors;
                queueUpdate();
            },
            setErrors: (errors) => {
                const unregisteredPathsWithErrors = [];
                formState.errors = filterObject(Object.assign({}, formState.errors, errors), isValidationError);
                const input = Object.assign({}, formState.errors, errors);
                const processed = {};
                let isValid = true;
                for (const prop in input) {
                    const value = input[prop];
                    if (Object.prototype.hasOwnProperty.call(input, prop) &&
                        isValidationError(value)) {
                        processed[prop] = value;
                        isValid = false;
                        if (!context.fields[prop]) {
                            unregisteredPathsWithErrors.push(prop);
                        }
                    }
                }
                formState.errors = processed;
                formState.valid = isValid;
                queueUpdate();
                return unregisteredPathsWithErrors;
            },
            validateField: (path, { returnPending = true } = {}) => {
                // Collect validators from options as well as those registered on field
                const validators = [
                    context.options.validations?.[path],
                    context.fields[path]?.validate,
                ].reduce((validators, v) => {
                    if (!v) {
                        return validators;
                    }
                    if (Array.isArray(v)) {
                        return validators.concat(v);
                    }
                    validators.push(v);
                    return validators;
                }, []);
                const value = api.getValues(path);
                const pendingValidation = context.pendingValidations[path];
                if (pendingValidation &&
                    pendingValidation.value === value &&
                    returnPending) {
                    return context.pendingValidations[path].promise;
                }
                const fieldResult = validateField(validators, formState.values, path, { pendingAsyncValidations: context.pendingValidations });
                const handleOutcome = (result) => {
                    const validatedValue = context.pendingValidations[path]
                        ? context.pendingValidations[path].value
                        : api.getValues(path);
                    if (mounted.current && validatedValue === value) {
                        api.setError(path, result);
                    }
                    return result;
                };
                if (isPromise(fieldResult)) {
                    queueUpdate();
                    return fieldResult
                        .catch((error) => {
                        console.error(`Error thrown in '${path}' field validate function:`);
                        console.error(error);
                        return error.message || null;
                    })
                        .then(handleOutcome);
                }
                else {
                    return handleOutcome(fieldResult);
                }
            },
            validateFields: (paths = api.validatedFields(), { returnPending = true } = {}) => {
                const promises = [];
                const fieldResults = {};
                paths.forEach((path) => {
                    const maybePromise = api.validateField(path, { returnPending });
                    if (isPromise(maybePromise)) {
                        promises.push(maybePromise.then((result) => {
                            fieldResults[path] = result;
                        }));
                    }
                    else {
                        fieldResults[path] = maybePromise;
                    }
                });
                return Promise.all(promises).then(() => fieldResults);
            },
            validateForm: () => {
                // Clear all existing errors
                formState.errors = {};
                const fieldValidations = api.validateFields();
                const formValidation = context.options.validate?.(formState.values);
                if (isPromise(formValidation)) {
                    context.pendingValidations[FORM_PATH] = formValidation;
                }
                queueUpdate();
                const handleOutcome = (results) => {
                    if (context.pendingValidations[FORM_PATH] === formValidation) {
                        delete context.pendingValidations[FORM_PATH];
                    }
                    api.setErrors(results);
                    return results;
                };
                return Promise.all([fieldValidations, formValidation]).then((results) => {
                    return handleOutcome(Object.assign({}, ...results));
                }, (error) => {
                    console.error(`Error thrown during validateForm:`);
                    console.error(error);
                    handleOutcome({ '': error.message });
                    throw error;
                });
            },
            validatedFields: () => {
                // Run predefined field validations first, followed by validations on registered fields
                const fields = {};
                if (context.options.validations) {
                    for (const key in context.options.validations) {
                        if (Object.prototype.hasOwnProperty.call(context.fields, key)) {
                            fields[key] = true;
                        }
                    }
                }
                for (const key in context.fields) {
                    if (Object.prototype.hasOwnProperty.call(context.fields, key)) {
                        fields[key] = true;
                    }
                }
                return Object.keys(fields);
            },
            focusField: (path) => {
                const f = context.fields[path];
                if (f) {
                    const ref = f.ref?.current || f.refs?.[0]?.current;
                    if (ref && isFunction(ref.focus)) {
                        ref.focus();
                        return true;
                    }
                }
                return false;
            },
            touchFields: (paths, isTouched = true) => {
                if (!paths.length ||
                    (paths.length === 1 && formState.touched[paths[0]])) {
                    // Bail if no changes would occur
                    return;
                }
                // TODO: Recursively touch nested paths (nested + nested.value)?
                formState.touched = Object.assign({}, formState.touched);
                paths.forEach((path) => {
                    formState.touched[path] = isTouched;
                });
            },
            handleSubmit: (event) => {
                if (event && isFunction(event.preventDefault)) {
                    event.preventDefault();
                }
                if (event && isFunction(event.stopPropagation)) {
                    event.stopPropagation();
                }
                if (formState.disabled) {
                    return Promise.resolve();
                }
                formState.submitting = true;
                formState.disabled = 'submitting';
                formState.submitAttempts += 1;
                api.touchFields(Object.keys(context.fields));
                queueUpdate();
                return api
                    .validateForm()
                    .then(() => {
                    if (formState.valid) {
                        return Promise.resolve(context.options.onSubmit(formState.values, event, apiRef.current)).then((result) => {
                            if (formState.valid) {
                                formState.submitted += 1;
                            }
                            return result;
                        });
                    }
                    if (context.options.onError) {
                        return context.options.onError(formState.errors, event, apiRef.current);
                    }
                })
                    .catch((error) => {
                    if (isObject(error)) {
                        // TODO: Consider moving this backend implementation detail out of the hook
                        const fieldErrors = getIn(error, 'body.fields');
                        if (isObject(fieldErrors)) {
                            attachValidationErrors(error, fieldErrors, false);
                        }
                        if (isObject(error.validationErrors) &&
                            Object.keys(error.validationErrors).length > 0 &&
                            api.setErrors(error.validationErrors).length < 1) {
                            // All validation errors was mapped to registered fields
                            return;
                        }
                    }
                    api.setError(FORM_PATH, error.localized || error.message);
                    throw error;
                })
                    .finally(() => {
                    formState.submitting = false;
                    formState.disabled = false;
                    queueUpdate();
                });
            },
            handleChange: (eventOrValue, path, event) => {
                let value = eventOrValue;
                if (isPseudoEvent(eventOrValue)) {
                    // Event passed as first argument
                    event = eventOrValue;
                    value = getInputValue(event.target);
                }
                else if (isPseudoEvent(path)) {
                    // Event passed as second argument
                    event = path;
                    path = undefined;
                }
                if (!path && event) {
                    path = event.target.name;
                }
                if (Array.isArray(path) && path.length) {
                    path = path.join('.');
                }
                if (typeof path !== 'string') {
                    console.error(`useForm.handleChange: Field path couldn't be determined`, {
                        path,
                        eventOrValue,
                        event,
                    });
                    return;
                }
                const validate = context.options.validateOnChange ||
                    (context.options.validateOnTouch && formState.touched[path]);
                api.setValue(path, value, { touch: false, validate });
                if (isPseudoEvent(event)) {
                    // Don't wait until blur to validate certain input types
                    switch (event.target['type']) {
                        case 'checkbox':
                        case 'file':
                        case 'radio':
                        case 'range':
                        case 'select-multiple':
                        case 'select-one':
                            api.touchFields([path]);
                            break;
                    }
                    // Synchronously re-render to ensure that input caret position is retained
                    if (formState.focused === path ||
                        (typeof document !== 'undefined' &&
                            document.activeElement === event.target)) {
                        triggerUpdate();
                    }
                }
            },
            handleFocus: (eventOrPath) => {
                const path = isPseudoEvent(eventOrPath)
                    ? eventOrPath.target.name
                    : eventOrPath;
                if (typeof path === 'string') {
                    formState.focused = path;
                }
                else {
                    console.error(`useForm.handleFocus: Field path couldn't be determined`, { path, eventOrPath });
                }
            },
            handleBlur: (eventOrPath) => {
                const path = isPseudoEvent(eventOrPath)
                    ? eventOrPath.target.name
                    : eventOrPath;
                if (typeof path === 'string') {
                    if (formState.focused === path) {
                        formState.focused = null;
                    }
                    api.touchFields([path]);
                    // TODO: Skip (async) validation if value hasn't changed
                    if (context.options.validateOnBlur ||
                        context.options.validateOnTouch) {
                        api.validateField(path);
                    }
                }
                else {
                    console.error(`useForm.handleBlur: Field path couldn't be determined`, { path, eventOrPath });
                }
            },
            registerField: (path, config) => {
                const existing = context.fields[path];
                // Register or replace single inputs
                if (!existing || existing.ref === config.ref) {
                    return (context.fields[path] = config);
                }
                // Append refs of multi input fields (having the same `name`) to existing record
                existing.refs = (existing.refs || [])
                    .concat([config.ref])
                    .filter((ref) => {
                    return typeof document === 'undefined' ||
                        typeof HTMLElement === 'undefined'
                        ? true
                        : // Remove any refs that no longer exist in the DOM (when DOM is available)
                            ref instanceof HTMLElement && document.contains(ref);
                });
                existing.validate = config.validate ?? existing.validate;
                return existing;
            },
            unregisterField: (path) => {
                // TODO: Do we need to avoid unregistering if there are still other `refs` remaining?
                delete context.fields[path];
                api.setError(path, null);
            },
        });
        // Set up formState on initialization
        api.reset();
    }
    // TODO: Does this need to be run within forceUpdate()?
    formState.validating = !isEmptyObject(context.pendingValidations);
    // Focus first field with error after submit attempts
    React.useEffect(() => {
        if (!context.options.focusFirstErrorOnSubmit) {
            return;
        }
        let lastSubmitAttempt = formStateRef.current.submitAttempts;
        return apiRef.current.subscribe(() => {
            const { submitAttempts, submitting } = formStateRef.current;
            if (submitting) {
                return;
            }
            if (submitAttempts > lastSubmitAttempt) {
                // Use timeout to allow field to be re-enabled first
                setTimeout(() => {
                    for (const path in formStateRef.current.errors) {
                        if (apiRef.current.focusField(path)) {
                            break;
                        }
                    }
                }, 0);
            }
            lastSubmitAttempt = submitAttempts;
        });
    }, [context.options.focusFirstErrorOnSubmit]);
    return apiRef.current;
}
