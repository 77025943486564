import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { getInputVisibleText } from '../lib/dom';
const SIZER_STYLE = {
    position: 'absolute',
    display: 'block',
    float: 'left',
    width: 'auto',
    visibility: 'hidden',
    whiteSpace: 'pre',
    top: '-2em',
    pointerEvents: 'none',
    zIndex: -10,
};
const INHERITED_STYLE_PROPS = [
    /*
      Do not include shorthand properties (e.g. "padding") as they're not
      supposed to be set according to the getPropertyValue() spec:
      https://www.w3.org/TR/DOM-Level-2-Style/css.html#CSS-CSSStyleDeclaration
      Explanation:
      https://stackoverflow.com/questions/32295296/window-getcomputedstyle-not-working-for-shorthand-properties-in-other-browsers-e#answer-32296604
    */
    'font',
    'letterSpacing',
    'textIndent',
    'wordSpacing',
    'paddingRight',
    'paddingLeft',
    'borderLeftWidth',
    'borderRightWidth',
];
export function InputAutoSizer(props) {
    const sizerRef = React.useRef(null);
    // Expose props to memoized blocks through ref
    const options = React.useRef({});
    options.current = {
        placeholder: '',
        minWidth: 20,
        extraWidth: 5,
        styleParent: true,
        ...props,
    };
    const resize = React.useCallback(() => {
        const o = options.current;
        const input = o.inputRef.current;
        const sizer = sizerRef.current;
        if (!input || !sizer) {
            return;
        }
        const style = window.getComputedStyle(input);
        INHERITED_STYLE_PROPS.forEach((prop) => {
            sizer.style[prop] = style[prop];
        });
        let value = getInputVisibleText(input);
        if (o.placeholder && (typeof value !== 'string' || value === '')) {
            value = o.placeholder;
        }
        sizer.textContent = value;
        let { width } = sizer.getBoundingClientRect();
        width = Math.ceil(Math.max(o.minWidth, width + o.extraWidth));
        const target = (o.styleParent && sizer.parentNode) || input;
        target.style.boxSizing = 'content-box';
        target.style.width = width + 'px';
    }, []);
    // Update size after every render
    React.useLayoutEffect(resize);
    // Subscribe to input changes
    React.useEffect(() => {
        const input = options.current.inputRef.current;
        if (!input) {
            return;
        }
        const isSelect = input.nodeName === 'SELECT';
        input.addEventListener(isSelect ? 'input' : 'change', resize);
        return () => {
            input.removeEventListener(isSelect ? 'input' : 'change', resize);
        };
    }, [props.inputRef, resize]);
    return _jsx("div", { ref: sizerRef, style: SIZER_STYLE });
}
