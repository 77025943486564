import * as React from 'react';
import ReactDOM from 'react-dom';
/**
 * Prop type validator which accepts any one of the following inputs:
 * - HTML element
 * - React component instance (maps to HTML element via `ReactDOM.findDOMNode()`)
 * - reference object from `React.createRef()` (maps to either of the above via `.current`)
 *
 * @param {Object} props
 * @param {String} propName
 * @param {String} componentName
 * @return {?Error}
 */
export function elementPropType(props, propName, componentName) {
    const value = props[propName];
    if (isReactRef(value)) {
        return;
    }
    const type = {}.toString.call(value).slice(8, -1);
    // Check if HTML element or React component instance
    if (value != null && !(value instanceof Element) && type !== 'Object') {
        return new Error(`Expected '${propName}' to be a HTML element or React component instance but got '${type}'`);
    }
}
/**
 * Returns the HTML element instance for a `assertElementProp` prop.
 *
 * @param {*} value
 * @return {?HTMLElement}
 */
export function getElementForProp(value) {
    if (isReactRef(value)) {
        value = value.current;
    }
    if (value && !(value instanceof HTMLElement)) {
        value = ReactDOM.findDOMNode(value);
    }
    return value || undefined;
}
export function isReactRef(value) {
    return !!(value && 'current' in value);
}
