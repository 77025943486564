import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
Message.displayName = 'Message';
Message.propTypes = {
    type: PropTypes.oneOf([
        'default',
        'warning',
        'error',
        'success',
        'neutral',
        'unknown',
    ]),
    onDismiss: PropTypes.func,
};
Message.defaultProps = {
    type: 'default',
};
export function Message({ type = 'default', onDismiss, ...attrs }) {
    attrs.className = classNames('message message--' + type, attrs.className);
    return (_jsxs("div", { ...attrs, children: [onDismiss && (_jsx("span", { onClick: onDismiss, className: "message__dismiss", role: "button", children: "\u00D7" })), attrs.children] }));
}
