export function httpErrorParser(err, errors = {}, prefix = '') {
    if (!err) {
        return errors;
    }
    switch (err.status) {
        case 400:
        case 409:
        case 417:
            if (err.body && isObject(err.body.fields)) {
                importErrors(errors, err.body.fields, prefix);
            }
            else if (err.body && isObject(err.body)) {
                importErrors(errors, err.body, prefix);
            }
            break;
        case 413:
            errors.__global = [err.body];
            break;
        default:
            errors.__global = [err.text || err.message];
            break;
    }
    return errors;
}
httpErrorParser.multiModel = multiModelErrorParser;
export function multiModelErrorParser(err, errors = {}, prefix = '') {
    if (!err) {
        return errors;
    }
    switch (err.status) {
        case 400:
        case 409:
        case 417:
            for (const key in err.body) {
                const keyParts = /^([a-z_]+)_error$/.exec(key);
                if (!isObject(err.body[key]) || !keyParts) {
                    continue;
                }
                if (err.body[key] && isObject(err.body[key].fields)) {
                    importErrors(errors, err.body[key].fields, prefix + keyParts[1] + '.');
                }
                else if (err.body[key] && isObject(err.body[key])) {
                    importErrors(errors, err.body[key], prefix + keyParts[1] + '.');
                }
            }
            break;
        case 413:
            errors.__global = [err.body];
            break;
        default:
            errors.__global = [err.text || err.message];
            break;
    }
    return errors;
}
function importErrors(target, source, prefix = '') {
    Object.keys(source).forEach((key) => {
        if (isObject(source[key])) {
            target[prefix + key] = [source[key].message];
        }
        else {
            target[prefix + key] = [source[key]];
        }
    });
    return target;
}
function isObject(some, type) {
    return {}.toString.call(some).slice(8, -1) === 'Object';
}
