import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from './Icon';
List.displayName = 'List';
List.Item = ListItem;
export function List(props) {
    const { ...attrs } = props;
    attrs.className = 'icon-list' + (props.className ? ' ' + props.className : '');
    return _jsx("ul", { ...attrs });
}
ListItem.displayName = 'ListItem';
export function ListItem(props) {
    const { icon, iconColor, ...rest } = props;
    const iconStyle = {};
    if (iconColor) {
        iconStyle.fill = iconColor;
    }
    return (_jsxs("li", { ...rest, children: [icon && _jsx(Icon, { name: icon, style: iconStyle }), props.children] }));
}
