import type {
  ApolloCache,
  Modifier,
  ModifierDetails,
  Reference,
} from '@apollo/client/cache'

export type { Reference }

/**
 * {@link Reference} wrapped in a Relay connection edge
 *
 * @private
 */
export type ConnectionReference = { __typename?: string; node: Reference }

/**
 * Helper function to add/remove a {@link Reference} from a cached list.
 * Typically used in a `cache.modify()` callback defined in `typePolicies`.
 *
 * Supports both plain arrays and Relay connections, provide a
 * `relayConnectionTypename` to enable the latter.
 *
 * @group Type Policies
 * @example
 * Type policy to update cached plain list on add/remove
 * ```ts
 * typePolicies.Mutation.fields.createThing =
 * typePolicies.Mutation.fields.deleteThing = {
 *   merge: (existing, incoming, c) => {
 *     c.cache.modify({
 *       fields: {
 *         getAllThings(entries: Reference[], d) {
 *           const field = cachedFieldArgs(d)
 *           // Only update relevant cache entries (same account in this case)
 *           if (field.accountId !== (c.args?.accountId || currentAccountId()) {
 *             return
 *           }
 *           return addOrRemoveFromCachedList({
 *             ref: incoming.__ref,
 *             // Figure out if we're adding or removing
 *             adding: c.fieldName === 'deleteThing' ? false : 'start',
 *             array: entries,
 *           })
 *         },
 *       },
 *     })
 *     return c.mergeObjects(existing, incoming)
 *   },
 * }
 * ```
 */
export function addOrRemoveFromCachedList(params: {
  ref: string
  array: Reference[]
  adding: 'start' | 'end' | false
}): Reference[]
export function addOrRemoveFromCachedList(params: {
  ref: string
  array: ConnectionReference[]
  adding: 'start' | 'end' | false
  relayConnectionTypename: string
}): ConnectionReference[]
export function addOrRemoveFromCachedList(params: {
  ref: string
  array: Array<Reference | ConnectionReference>
  adding: 'start' | 'end' | false
  relayConnectionTypename?: string
}) {
  let found = false
  const array = (params.array || []).filter((edge) => {
    const matches =
      // @ts-expect-error `relayConnectionTypename` controls structure of `edge
      (params.relayConnectionTypename ? edge.node.__ref : edge.__ref) ===
      params.ref
    if (!found && matches) {
      found = true
    }
    return matches ? params.adding : true
  })
  if (params.adding && !found) {
    array[params.adding === 'end' ? 'push' : 'unshift'](
      params.relayConnectionTypename
        ? ({
            __typename: params.relayConnectionTypename,
            node: { __ref: params.ref },
          } satisfies ConnectionReference)
        : ({
            __ref: params.ref,
          } satisfies Reference),
    )
  }
  return array
}

/**
 * Returns the field args for a cached field as an object.
 * Used in `cache.modify()` calls.
 *
 * @group Type Policies
 * @example
 * Access field args in `cache.modify()` field callback
 * ```ts
 * typePolicies.Mutation.fields.updateThing = {
 *   merge: (existing, incoming, c) => {
 *     c.cache.modify({
 *       fields: {
 *         getAllThings(entries: Reference[], d) {
 *           const field = cachedFieldArgs(d)
 *           // Only update relevant cache entries (same account in this case)
 *           if (field.accountId !== (c.args?.accountId || currentAccountId())) {
 *             return
 *           }
 *           // Matching cache entry found, update
 *         },
 *       },
 *     })
 *     return c.mergeObjects(existing, incoming)
 *   },
 * }
 * ```
 */
export function cachedFieldArgs(
  context: Pick<ModifierDetails, 'storeFieldName' | 'fieldName'>,
) {
  let args = context.storeFieldName
  if (args.startsWith(context.fieldName)) {
    args = args.slice(context.fieldName.length)
  }
  if (args.startsWith(':{')) {
    args = args.slice(1)
  } else if (args.startsWith('(') && args.endsWith(')')) {
    args = args.slice(1, args.length - 1)
  }
  return args.length
    ? // TODO: Consider wrapping this in try catch
      JSON.parse(args)
    : {}
}

/**
 * Default `fieldExtractor` for {@link invalidateCacheFields}.
 *
 * Invalidates `editorial*` fields.
 *
 * @group Type Policies
 */
export function invalidateCacheFieldExtractor(
  cacheKey: string,
): string | undefined {
  return cacheKey.match(/^(editorial\w*)\b/)?.[1]
}

/**
 * Deletes all cached fields which `fieldExtractor` returns a string (field name) for.
 *
 * The `fieldExtractor` callback is called once for each key on
 * `apollo.cache.data.data.ROOT_QUERY`, which is a string combining the field
 * name with a JSON string representing its `keyFields` arguments. Each unique
 * field name it returns will have all of its variations evicted from the cache.
 * Examples of possible strings it gets called with and what it should return to evict that field:
 * - `editorialHome({"id":"home"})` -> `editorialHome`
 * - `playlist({id:"abc"})` -> `playlist`
 * - `me` -> `me`
 *
 * @group Type Policies
 * @return Array of deleted field names.
 *
 * @example
 * Clear all `editorial*` and `playlist` fields on ROOT_QUERY, no matter which arguments they have
 * ```ts
 * invalidateCacheFields(apolloCache, (cacheKey) => {
 *   return cacheKey.match(/^(editorial\w*|playlist)\b/)?.[1]
 * })
 * ```
 */
export function invalidateCacheFields(
  /** Apollo cache instance */
  cache: ApolloCache<any>,
  /**
   * Filter function which is passed the field cache id, and is expected to
   * return the field name if the field is to be deleted from the cache.
   */
  fieldExtractor = invalidateCacheFieldExtractor,
  { broadcast = true } = {},
): string[] {
  const invalidate: Modifier<any> = (_, details) => details.DELETE
  const fields: Record<any, Modifier<any>> = {}
  const fieldsArray: string[] = []
  Object.keys(cache.extract().ROOT_QUERY || {}).forEach((key) => {
    const field = fieldExtractor(key)
    if (!field) return
    fields[field] = invalidate
    fieldsArray.push(field)
  })
  if (fieldsArray.length) {
    cache.modify({ fields, broadcast })
  }
  return fieldsArray
}
