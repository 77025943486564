import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { supportsPassiveEvents } from 'detect-passive-events';
import PopperJs from 'popper.js';
import PropTypes from 'prop-types';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { Portal } from './Portal';
import { containsElement } from './lib/dom';
import { elementPropType, getElementForProp } from './lib/element-prop-type';
import { withModifier } from './lib/modifier';
/**
 * @deprecated - Please use @floating-ui/react instead.
 *
 * Basic popper with minimal styling.
 *
 * Can be anchored to another element via `anchor`, in which case it relies on
 * Popper.js for positioning. Rendered inside the parent DOM node by default,
 * but can be rendered inside a portal by using the `portal` prop.
 *
 * Most remaining props control Popper.js options. Note that `anchor` must be
 * provided to enable these. Without anchor the component is completely
 * controlled by CSS.
 */
export class Popper extends React.PureComponent {
    /* eslint-disable valid-jsdoc */
    static propTypes = {
        /** Container tag */
        tag: PropTypes.elementType.isRequired,
        /** Popper.js placement */
        placement: PropTypes.string.isRequired,
        /** DOM/React element/ref to anchor to (can't be a stateless component) */
        anchor: elementPropType,
        /** Render within portal instead of directly? */
        portal: PropTypes.bool.isRequired,
        /** Manual Popper.js modifiers configuration (overrides offset/padding/flip) */
        modifiers: PropTypes.object,
        /** Popper.js offset modifier */
        offset: PropTypes.string,
        /** Popper.js preventOverflow.padding + flip.padding modifiers */
        padding: PropTypes.number.isRequired,
        /** Popper.js flip modifier enabled */
        flip: PropTypes.bool.isRequired,
        /** Prevent resize/scroll events from repositioning popper? */
        events: PropTypes.bool.isRequired,
        /** Triggered upon a click outside the popper */
        onClickOutside: PropTypes.func,
        /** Register onClickOutside as capture listener instead of regular bubbler? */
        clickOutsideCapture: PropTypes.bool,
    };
    /* eslint-enable valid-jsdoc */
    static defaultProps = {
        tag: 'div',
        placement: 'bottom-end',
        portal: false,
        events: false,
        offset: undefined,
        padding: 0,
        flip: true,
    };
    constructor(props) {
        super(props);
        // True when popper instance should be updated due to prop changes
        this.shouldUpdate = true;
        this.contentsRef = React.createRef();
    }
    componentDidMount() {
        // updatePopper() is triggered by onRender() when portal'ed
        if (!this.props.portal) {
            this.onRender();
        }
        this.manageOutsideClickListener();
    }
    componentDidUpdate(props) {
        this.shouldUpdate =
            props.anchor !== this.props.anchor ||
                props.portal !== this.props.portal ||
                props.placement !== this.props.placement ||
                props.modifiers !== this.props.modifiers ||
                props.offset !== this.props.offset ||
                props.padding !== this.props.padding ||
                props.flip !== this.props.flip ||
                props.events !== this.props.events;
        if (!this.props.portal) {
            this.onRender();
        }
    }
    componentWillUnmount() {
        this.destroyPopper();
        this.manageOutsideClickListener(false);
        clearTimeout(this._updateTimeout);
    }
    destroyPopper() {
        if (this._popper) {
            this._popper.destroy();
            this._popper = null;
        }
    }
    updatePopper(allowRetry = true) {
        // Popper can't be updated, so first we need to destroy any active instance
        this.destroyPopper();
        let { anchor } = this.props;
        if (!anchor || !this.contentsRef.current) {
            return;
        }
        anchor = getElementForProp(anchor);
        if (!anchor) {
            // Anchor has not yet been mounted - try again in a bit
            // This is kinda hackish but currently the only way to support using
            // parent elements as anchors since they are mounted after their children
            if (allowRetry) {
                this._updateTimeout = requestAnimationFrame(() => this.updatePopper(false));
            }
            return;
        }
        this._popper = new PopperJs(anchor, this.contentsRef.current, {
            placement: this.props.placement,
            eventsEnabled: !!this.props.events,
            modifiers: this.props.modifiers || {
                flip: {
                    enabled: this.props.flip,
                    order: 299, // has to run before preventOverflow
                    flipVariationsByContent: true,
                    padding: this.props.padding,
                },
                preventOverflow: {
                    order: 300,
                    padding: this.props.padding,
                },
                offset: {
                    enabled: this.props.offset != null,
                    offset: this.props.offset,
                },
            },
        });
    }
    manageOutsideClickListener(enable = typeof this.props.onClickOutside === 'function') {
        if (enable !== this.hasOutsideEventListener) {
            const capture = !!this.props.clickOutsideCapture;
            const method = enable ? 'addEventListener' : 'removeEventListener';
            const args = [
                this.onDocumentClick,
                // Allow scrolling to start immediately if possible
                supportsPassiveEvents ? { capture, passive: true } : capture,
            ];
            document[method]('mousedown', ...args);
            document[method]('touchstart', ...args);
            this.hasOutsideEventListener = enable;
        }
    }
    /**
     * Trigger repositioning after portal has been rendered.
     */
    onRender = () => {
        if (this.shouldUpdate) {
            this.updatePopper();
            this.shouldUpdate = false;
        }
    };
    /**
     * Intercepts document clicks, firing props.onClickOutside when applicable.
     *
     * @param {Event} event
     */
    onDocumentClick = (event) => {
        const contents = this.contentsRef.current;
        const { target } = event;
        if (contents && !containsElement(contents, target, true)) {
            this.props.onClickOutside(event);
        }
    };
    render() {
        const { anchor, events, flip, modifiers, offset, onClickOutside, padding, placement, portal, tag, supportContextInsteadOfBubbling, clickOutsideCapture, ...props } = this.props;
        props.className = classNames(withModifier('popper', ...placement.split('-'), anchor ? 'anchored' : null), props.className);
        props.ref = this.contentsRef;
        const element = React.createElement(tag, props);
        if (!anchor || !portal) {
            return element;
        }
        // FIXME: Switch to createPortal() once it can be configured to not bubble
        // events upwards: https://github.com/facebook/react/issues/11387
        // return ReactDOM.createPortal(this.renderContents(), portalContainer())
        return (_jsx(Portal, { supportContextInsteadOfBubbling: supportContextInsteadOfBubbling, onRender: this.onRender, children: element }));
    }
}
