import seededRandom from './lib/seeded-random';
/** @deprecated - Replaced by design token colors from npm package `@soundtrack/levels-tokens` */
const named = {
    /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
    syb: {
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        red: '#f23440',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        richPurple: '#3c0a5b',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        deepPurple: '#4c2b46',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        black: '#161616',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        darkGray: '#373239',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        darkGrey: '#373239',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        lightGray: '#f1f1f1',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        lightGrey: '#f1f1f1',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        beige: '#f6efec',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        white: '#ffffff',
    },
    /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
    colorPicker: {
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        charcoal: '#373239',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        signalRed: '#f23440',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        signalPink: '#f234a6',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        signalPurple: '#7134f2',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        signalOrange: '#ffa400',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        radiantPurple: '#b278ff',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        radiantBlue: '#789eff',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        radientMint: '#74edba',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        radiantTeal: '#26dbf4',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        softSand: '#dbd5d2',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        softGreen: '#cbd4ce',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        softGrey: '#cdcbd4',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        softRed: '#dabcbc',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        mellowPurple: '#4c2b46',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        mellowRed: '#5a2f37',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        mellowGrey: '#464442',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        mellowGreen: '#495237',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        richBlack: '#161616',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        richGreen: '#02310c',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        richBlue: '#022a4f',
        /** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
        richPurple: '#3c0a5b',
    },
};
/** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
named.soundtrack = named.syb;
/** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
named.rebrand = named.syb;
// Array version
/** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
const array = {};
for (const key in named) {
    array[key] = Object.keys(named[key]).map((k) => named[key][k]);
}
/** @deprecated - Replaced by design token colors from npm package `@soundtrack/levels-tokens` */
function toColorString(f, s) {
    return validateStrings(f, s) ? (f + ',' + s).replace(/\s+/g, '') : '';
}
/** @deprecated - Replace with npm package `@soundtrack/levels-tokens` */
function css(colors, style = {}) {
    if (!colors) {
        return style;
    }
    else if (typeof colors === 'string') {
        colors = colors.split(',');
    }
    style.backgroundColor = colors[0];
    if (colors[1]) {
        style.backgroundImage = `linear-gradient(to bottom right, ${colors[0]}, ${colors[1]})`;
    }
    return style;
}
/** @deprecated - Replaced by design token colors from npm package `@soundtrack/levels-tokens` */
function colors(product = 'rebrand') {
    return array[product];
}
/** @deprecated - Replaced by design token colors from npm package `@soundtrack/levels-tokens` */
function random(product = 'colorPicker') {
    const available = colors(product);
    const i = Math.min(Math.floor(Math.random() * available.length), available.length - 1);
    return available[i];
}
/** @deprecated - Replaced by design token colors from npm package `@soundtrack/levels-tokens` */
function bySeed(seed) {
    const spectrum = array.rebrand;
    return spectrum[seededRandom(seed)(spectrum.length - 1)];
}
/** @deprecated - Replaced by design token colors from npm package `@soundtrack/levels-tokens` */
export const Color = {
    array,
    colors,
    css,
    named,
    random,
    bySeed,
    toColorString,
};
function validateStrings(a, b) {
    return typeof a === 'string' && typeof b === 'string';
}
