import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Throbber } from './BaseThrobber';
import { Icon } from './Icon';
function isDeprecated(guide) {
    return (props, prop, component) => {
        if (!props[prop]) {
            return;
        }
        return new Error(`\nDeprecated prop '${prop}' used in ${component}.\n${guide}\n`);
    };
}
/**
 * @deprecated Replace with `<PillButton />` or `<FlatButton />` from `@soundtrackyourbrand/ui`
 *
 * Button layout migration:
 * 1. Remove margin-bottom reset overrides that are redundant with LevelsButton.
 * 2. If the Levels Button is sibling to UI Button:
 *   - Reset margin-bottom on sibling UI Buttons using `.nobottom`. If needed,
 *     add `.mb-5`, `.basebottom`, UI/Tailwind layout utils or local styles
 *     to parent wrapper of the buttons.
 *
 * Button props migration:
 * 1. Remove local color overrides that are redundant with Levels buttons.
 * 2. Import `<PillButton />` or `<FlatButton />` from `@soundtrackyourbrand/ui`.
 * 3. Replacements:
 *    - `<Button variant='primary' />` replace with `<PillButton variant='accent' />`
 *    - `<Button variant='secondary' />` replace with `<PillButton variant='primary' />`
 *    - `<Button flat />` replace with `<FlatButton />`
 *    - `<Button flat compact />` replace with `<FlatButton compact />`
 * 4. Replace grey or semi-transparent button overrides with `<PillButton variant='subtle' />`
 */
export const Button = React.forwardRef(ButtonInner);
Object.assign(Button, {
    displayName: 'Button',
    propTypes: {
        type: PropTypes.string,
        disabled: PropTypes.bool,
        loading: PropTypes.bool,
        icon: PropTypes.string,
        iconRight: PropTypes.bool,
        iconStyle: PropTypes.object,
        flat: PropTypes.bool,
        compact: PropTypes.bool,
        mini: isDeprecated('Replace with size="mini"'),
        small: isDeprecated('Replace with size="small"'),
        large: isDeprecated('Replace with size="large"'),
        primary: isDeprecated('Replace with variant="primary"'),
        secondary: isDeprecated('Replace with variant="secondary"'),
        destructive: isDeprecated('Replace with variant="primary"'),
        hollow: isDeprecated('Remove hollow and use default button style to get the outlined design: <Button />'),
        size: PropTypes.oneOf(['mini', 'small', 'large']),
        variant: PropTypes.oneOf(['primary', 'secondary']),
        block: PropTypes.bool,
        wide: PropTypes.bool,
    },
});
function ButtonInner({ primary, secondary, flat, mini, small, large, hollow, size, variant, block, wide, loading, enabledWhileLoading, icon, iconRight, tag, iconStyle, compact, ...attrs }, ref) {
    // @ts-expect-error `tag` can't be manually assigned
    tag = tag || 'button';
    // @ts-expect-error `attrs.type` is not defined for all tags
    if (tag === 'button' && !attrs.type) {
        // @ts-expect-error `attrs.type` is not defined for all tags
        attrs.type = 'button';
    }
    // TODO: Only for backwards compatibility to safe guard during button migration
    const legacySize = mini
        ? 'mini'
        : small
            ? 'small'
            : large
                ? 'large'
                : undefined; // Set undefined to enable responsive default size
    const btnSize = size ? size : legacySize;
    // TODO: Only for backwards compatibility to safe guard during button migration
    const legacyVariant = primary
        ? 'primary'
        : secondary
            ? 'secondary'
            : undefined; // undefined to set default button style (outlined version)
    const variantProp = variant ? variant : legacyVariant;
    Object.assign(attrs, {
        ref,
        disabled: attrs.disabled || (loading && !enabledWhileLoading),
        className: classNames(attrs.className, {
            btn: true,
            [`btn--${variantProp}`]: !!variantProp, // Allow default style by only setting class when primary/secondary is set
            [`btn--${btnSize}`]: !!btnSize, // Allow default size by only setting size class if size is set
            'btn--flat': flat,
            'btn--block': block,
            'btn--wide': wide,
            'btn--loading': loading,
            'btn--icon': icon && React.Children.count(attrs.children) === 0,
            'btn--icon-right': iconRight,
            'btn--compact': compact,
        }),
    });
    return React.createElement(tag, attrs, [
        icon && _jsx(Icon, { name: icon, style: iconStyle }, "icon"),
        _jsx("span", { className: "btn__content", children: attrs.children }, "content"),
        loading && _jsx(Throbber, {}, "throbber"),
    ]);
}
